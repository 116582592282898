import { Box, Grid, IconButton, makeStyles, Paper } from "@material-ui/core";
import React, { useState, useEffect, useContext } from "react";
import api from "../../../../services";
import { WindowContext } from "../../../../window/trackWindow";
import numeral from "numeral";
import {
  StyledToggleButtonGroup,
  StyledToggleButton,
} from "../../tabs/styledTabs";
import ErrorSnackBar from "../../commons/SnackBar";
import images from "../../../../images";
import InfoButton from "../../infoButton";
import { useHistory } from "react-router-dom";

interface IBigWidgetProps {
  token: any;
  firmId: string;
  pmType: string;
}
const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    height: 350,
    [theme.breakpoints.down("lg")]: {
      paddingLeft: theme.spacing(3),
    },
  },
  value: {
    fontSize: theme.typography.pxToRem(26),
    fontWeight: "bold",
    color: theme.palette.secondary.dark,
    margin: 0,
    [theme.breakpoints.down("lg")]: {
      paddingLeft: theme.spacing(2),
    },
  },
  title: {
    margin: 0,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.secondary.dark,
    [theme.breakpoints.down("lg")]: {
      paddingLeft: theme.spacing(2),
    },
  },
  prefix: {
    fontSize: theme.typography.pxToRem(18),
  },
  inner: {
    margin: theme.spacing(2),
    paddingTop: theme.spacing(1),
  },
}));
const InvoicesARRevenue: React.FunctionComponent<IBigWidgetProps> = ({
  token,
  firmId,
  pmType,
}) => {
  const classes = useStyles();
  const [loader, setLoader] = useState(true);
  const [active, setActive] = useState<number>(0);
  const [error, setError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const history = useHistory();
  const [data, setData] = useState<any>([
    {
      YTD: "0",
      pre_year: "0",
      quarterly: "0",
    },
    {
      YTD: "0",
      pre_year: "0",
      quarterly: "0",
    },
    {
      YTD: "0",
      pre_year: "0",
      quarterly: "0",
    },
  ]);
  const window = useContext(WindowContext);
  const handleTabChange = (event, value) => {
    if (value !== null) {
      setActive(value);
    }
  };
  useEffect(() => {
    let subscribe = true;
    if (loader) {
      let request = api.create(token);
      Promise.all([
        request.getInvoicesDashboard(firmId, pmType),
        request.getARDashboard(firmId, pmType),
        request.getRevenueDashboard(firmId, pmType),
      ])
        .then((res) => {
          if (subscribe) {
            // let [invoices, AR, revenue] = [
            //   res[0].data,
            //   res[1].data,
            //   res[2].data,
            // ];
            let invoices = res[0].data;
            let AR = res[1].data;
            let revenue = res[2].data;

            setData([
              {
                YTD: numeral(invoices.YTD).format("($ 0.0 a)"),
                quarterly: numeral(invoices.quarterly).format("($ 0.0 a)"),
                pre_year: numeral(invoices.pre_year).format("($ 0.0 a)"),
              },
              {
                YTD: numeral(AR.YTD).format("($ 0.0 a)"),
                quarterly: numeral(AR.quarterly).format("($ 0.0 a)"),
                pre_year: numeral(AR.pre_year).format("($ 0.0 a)"),
              },
              {
                YTD: numeral(revenue.YTD).format("($ 0.0 a)"),
                quarterly: numeral(revenue.quarterly).format("($ 0.0 a)"),
                pre_year: numeral(revenue.pre_year).format("($ 0.0 a)"),
              },
            ]);
            setLoader(false);
          }
        })
        .catch((error) => {
          if (subscribe) {
            if (error.code === "ECONNABORTED") {
              setErrorMsg("The API request timed out. Please Refresh the page");
              setError(true);
            } else {
              setErrorMsg(error.response.data.message);
              setError(true);
            }
          }
        });
    }
    return () => {
      subscribe = false;
    };
  }, [loader]);

  return (
    <Paper square className={classes.container}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <StyledToggleButtonGroup
          onChange={handleTabChange}
          exclusive
          value={active}
          size="small"
          style={{
            fontSize: window < 600 ? 11 : 13,
            marginLeft: 0,
            marginRight: 0,
          }}
        >
          <StyledToggleButton key={0} value={0}>
            Invoices
          </StyledToggleButton>
          <StyledToggleButton key={2} value={2}>
            Revenue
          </StyledToggleButton>
          <StyledToggleButton key={1} value={1}>
            AR
          </StyledToggleButton>
        </StyledToggleButtonGroup>
        <span>
          <InfoButton
            title={
              "Toggle between invoices, AR, and Revenue.  Displays the value of Invoices/AR/Revenue, for the current year to date, the previous 3 month total and the previous year."
            }
          />
          <IconButton
            onClick={() => history.push("/dashboard/financials")}
            style={{ padding: 0, margin: 0 }}
          >
            <img src={images.linkIcon} />
          </IconButton>
        </span>
      </div>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        className={classes.inner}
      >
        <span style={{ marginRight: 20 }}>
          <img src={images.ytd} alt="Year to Date" />
        </span>
        <span>
          <p className={classes.value}>
            <span className={classes.prefix}>
              {`${data[active].YTD.split(" ")[0]} `}
            </span>
            {data[active].YTD.split(" ")[1]}
            <span className={classes.prefix}>
              {data[active].YTD.split(" ")[2]}
            </span>
          </p>
          <p className={classes.title}>YTD</p>
        </span>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        className={classes.inner}
      >
        <span style={{ marginRight: 20 }}>
          <img src={images.quarterly} alt="Year to Date" />
        </span>
        <span>
          <p className={classes.value}>
            <span className={classes.prefix}>
              {`${data[active].quarterly.split(" ")[0]} `}
            </span>
            {data[active].quarterly.split(" ")[1]}
            <span className={classes.prefix}>
              {data[active].quarterly.split(" ")[2]}
            </span>
          </p>
          <p className={classes.title}>Last three months</p>
        </span>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        className={classes.inner}
      >
        <span style={{ marginRight: 20 }}>
          <img src={images.previousYear} alt="Year to Date" />
        </span>
        <span>
          <p className={classes.value}>
            <span className={classes.prefix}>
              {`${data[active].pre_year.split(" ")[0]} `}
            </span>
            {data[active].pre_year.split(" ")[1]}
            <span className={classes.prefix}>
              {data[active].pre_year.split(" ")[2]}
            </span>
          </p>
          <p className={classes.title}>Previous Year</p>
        </span>
      </Box>
      {error ? <ErrorSnackBar errorMsg={errorMsg} /> : null}
    </Paper>
  );
};

export default InvoicesARRevenue;
