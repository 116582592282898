import React, { useEffect, useState } from "react";
import {
  TableHead,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  Paper,
  Grid,
  TableFooter,
  TablePagination,
  IconButton,
} from "@material-ui/core";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import { makeStyles, Theme } from "@material-ui/core/styles";
import FilterUserTimePractice from "../../filters/genericFilter/filters";
import api from "../../../../services";
import useFilterState from "../../filters/genericFilter/filterStateHook";
import { MatterTrackerTypes } from "../../filters/genericFilter/types";
import Loader from "../../commons/loaders/loader";
import numeral from "numeral";
import moment from "moment";
import ErrorSnackBar from "../../commons/SnackBar";
import Widget from "./widget";
import InfoButton from "../../infoButton";
import useAuth from "../../../../hooks/useAuth";

interface IArGridProps {}

const useStyles = makeStyles((theme) => ({
  link: {
    cursor: "pointer",
    color: theme.palette.text.primary,
    backgroundColor: "#E7F3FA",
    paddingHorizontal: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    borderRadius: theme.spacing(1) / 2,
    fontWeight: theme.typography.fontWeightLight,
    "&:hover": {
      backgroundColor: theme.palette.background.default,
    },
  },

  headingText: {
    fontSize: "1.2em",
    display: "block",
    fontWeight: 600,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(2),
  },
  subText: {
    fontSize: "1em",
    display: "block",
    fontWeight: 600,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(2),
  },
  divider: {
    backgroundColor: "#EDEFF7",
    height: 1,
    marginBottom: theme.spacing(1),
  },
  paperloader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 450,
  },
  paper: {
    padding: theme.spacing(2),
  },
  small: {
    height: 200,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  sticky: {
    position: "sticky",
    top: theme.spacing(19),
    padding: theme.spacing(2),
  },
  textTitle: {
    color: theme.palette.text.primary,
    fontSize: "1.4em",
    fontWeight: 600,
    marginLeft: theme.spacing(3),
  },
  tallpaper: {
    height: `calc(100vh - ${theme.spacing(16)}px)`,
    position: "sticky",
    top: 112,
  },
  header: {
    position: "sticky",
    top: 0,
    backgroundColor: "#fff",
  },
  customTableContainer: {
    height: `calc(100vh - ${theme.spacing(16)}px)`,
  },
}));
const useStyles1 = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
}));
interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const classes = useStyles1();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </div>
  );
}

const initialFilterState: MatterTrackerTypes = {
  practiceArea: "",
  billingMethod: "",
};

const ArGrid = ({ handleToggle, id }) => {
  const classes = useStyles();
  const {
    generalStates: {
      token,
      activeFirm: {
        firm: { _id },
      },
    },
    integrationConfigState: { pmType },
  } = useAuth();
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");

  // *****
  // Current Values of Filters
  // *****

  const { filterRef, filterState, handleState } =
    useFilterState(initialFilterState);

  const handlePracticeAreas = (value: number | "") => {
    handleState.handlePracticeArea(value);
  };

  const handleBillingMethod = (value: string) => {
    handleState.handleBillingMethod(value);
  };

  // *****
  // Clear Filter States
  // *****

  const handleFilterClear = () => {
    handleState.reset(initialFilterState);
    setLoading(true);
  };
  const handleFilterSubmit = (e) => {
    if (filterState.practiceArea !== "" || filterState.billingMethod !== "") {
      setLoading(true);
    }
  };

  // *****
  // Previous Values of Filters
  // *****

  const prevFilterState = filterRef.current;

  useEffect(() => {
    let subscribe = true;
    if (loading) {
      let request = api.create(token);
      let queryParams = {
        practice_area_id:
          filterState.practiceArea === "" ? null : filterState.practiceArea,
        billing_method:
          filterState.billingMethod === "" ? null : filterState.billingMethod,
      };
      let prevQuery = {
        practice_area_id:
          filterState.practiceArea === "" ? null : filterState.practiceArea,
        billing_method:
          filterState.billingMethod === "" ? null : filterState.billingMethod,
        start_date: `${moment()
          .subtract(1, "years")
          .startOf("year")
          .format("YYYY-MM-DD")}`,
        end_date: `${moment()
          .subtract(1, "years")
          .endOf("year")
          .format("YYYY-MM-DD")}`,
      };
      let currQuery = {
        practice_area_id:
          filterState.practiceArea === "" ? null : filterState.practiceArea,
        billing_method:
          filterState.billingMethod === "" ? null : filterState.billingMethod,
        start_date: `${moment().startOf("year").format("YYYY-MM-DD")}`,
        end_date: `${moment().endOf("year").format("YYYY-MM-DD")}`,
      };
      Promise.all([
        request.getARBucket(_id, pmType, queryParams),
        request.getARConcentrationRatio(_id, pmType, prevQuery),
        request.getARConcentrationRatio(_id, pmType, currQuery),
      ])
        .then((res) => {
          if (subscribe) {
            setData(res[0].data);
            setLoading(false);
          }
        })
        .catch((error) => {
          if (subscribe) {
            if (error.code === "ECONNABORTED") {
              setErrorMsg("The API request timed out. Please Refresh the page");
              setError(true);
            } else {
              setError(true);
              setErrorMsg(error.response.data.message);
            }
          }
        });
    }

    return () => {
      subscribe = false;
    };
  }, [loading]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
        <Paper className={classes.tallpaper}>
          <Widget
            token={token}
            _id={_id}
            filterState={filterState}
            pmType={pmType}
            parentLoader={loading}
          />
        </Paper>
      </Grid>
      <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
        {loading ? (
          <Paper className={classes.paperloader}>
            <Loader />
          </Paper>
        ) : (
          <Paper className={classes.paper}>
            <Grid container justifyContent="space-between" alignItems="center">
              <span className={classes.textTitle}>AR</span>
              <span>
                <InfoButton
                  title={
                    <div>
                      Concentration ratio closer to Zero indicates that the AR
                      portfolio is less concentrated, which is a good indicator
                      that the risk is spread so that one single customer is not
                      a key source of income.
                      <br />
                      <br />
                      Diversity Index represents the firm’s AR Risk in client
                      representation. A diversity rate closer to 1 represents AR
                      Risk is consolidated to 1 client. A higher number
                      represents more equally distributed AR.
                    </div>
                  }
                />
                <FilterUserTimePractice
                  disabled={loading}
                  filterState={filterState}
                  handleBillingMethod={handleBillingMethod}
                  handlePracticeAreas={handlePracticeAreas}
                  handleFilterSubmit={handleFilterSubmit}
                  handleFilterClear={handleFilterClear}
                />
              </span>
            </Grid>
            <TableContainer classes={{ root: classes.customTableContainer }}>
              <Table>
                <TableHead className={classes.header}>
                  <TableRow>
                    <TableCell align={"left"}>Name</TableCell>
                    {id.map((item, index) => (
                      <TableCell
                        key={item.id}
                        align={"right"}
                        variant={"head"}
                        onClick={() => handleToggle(item.id)}
                      >
                        <span className={classes.link}>{item.name}</span>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(rowsPerPage > 0
                    ? data.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : data
                  ).map((item, index) => (
                    <TableRow
                      key={item.id}
                      style={index % 2 ? { background: "#eaeaea" } : {}}
                    >
                      <TableCell>{item.name}</TableCell>
                      <TableCell align={"right"}>
                        {numeral(item.range0_30).format("$0,0.00")}
                      </TableCell>
                      <TableCell align={"right"}>
                        {numeral(item.range31_60).format("$0,0.00")}
                      </TableCell>
                      <TableCell align={"right"}>
                        {numeral(item.range61_90).format("$0,0.00")}
                      </TableCell>
                      <TableCell align={"right"}>
                        {numeral(item.range91_120).format("$0,0.00")}
                      </TableCell>
                      <TableCell align={"right"}>
                        {numeral(item.range120plus).format("$0,0.00")}
                      </TableCell>
                    </TableRow>
                  ))}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell align="center" colSpan={6}>
                        No data available
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TablePagination
                      rowsPerPageOptions={[
                        5,
                        10,
                        25,
                        { label: "All", value: -1 },
                      ]}
                      colSpan={3}
                      count={data.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: { "aria-label": "rows per page" },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </Paper>
        )}
      </Grid>
      {error ? <ErrorSnackBar errorMsg={errorMsg} /> : null}
    </Grid>
  );
};

export default ArGrid;
