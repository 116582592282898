import React, { useEffect, useState } from "react";
import numeral from "numeral";
import moment from "moment";
import { Grid, Paper } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Loader from "../../commons/loaders/loader";
import api from "../../../../services";
import ErrorSnackBar from "../../commons/SnackBar";
import TrendingUpTwoToneIcon from "@material-ui/icons/TrendingUpTwoTone";
import TrendingDownTwoToneIcon from "@material-ui/icons/TrendingDownTwoTone";

const useStyles = makeStyles((theme) => ({
  headingText: {
    fontSize: "1.2em",
    display: "block",
    fontWeight: 600,
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
  sub: {
    fontSize: "1em",
    display: "block",
    fontWeight: 600,
    color: theme.palette.text.secondary,
    minWidth: "40%",
  },
  paper: {
    minHeight: 450,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  small: {
    height: 200,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  sticky: {
    position: "sticky",
  },
  container: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
  heading: {
    flexDirection: "row",
  },
  up: {
    color: theme.palette.secondary.main,
    textAlign: "center",
    alignItems: "center",
  },
  down: {
    color: theme.palette.error.main,
    textAlign: "center",
    alignItems: "center",
  },
}));
interface IWidgetProps {
  filterState: any;
  token: string;
  _id: string;
  parentLoader: boolean;
}

const initialTrending = {
  current: false,
  previous: false,
};
const initialRatio = {
  previous: {
    ar_concentration_ratio: 0,
    diversity_index: 0,
  },
  current: {
    ar_concentration_ratio: 0,
    diversity_index: 0,
  },
};
const Widget = ({ filterState, token, _id, parentLoader, pmType }) => {
  const [error, setError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [trending, setTrending] = useState(initialTrending);
  const [ratio, setRatio] = useState(initialRatio);
  const [loading, setLoading] = useState<boolean>(true);
  const currentYear = {
    start_date: moment().startOf("year").format("YYYY-MM-DD"),
    end_date: moment().endOf("year").format("YYYY-MM-DD"),
  };
  const prevYear = {
    start_date: moment()
      .subtract(1, "years")
      .startOf("year")
      .format("YYYY-MM-DD"),
    end_date: moment().subtract(1, "years").endOf("year").format("YYYY-MM-DD"),
  };

  useEffect(() => {
    let subscribe = true;
    if (parentLoader || loading) {
      let request = api.create(token);

      let prevQuery = {
        practice_area_id:
          filterState.practiceArea === "" ? null : filterState.practiceArea,
        billing_method:
          filterState.billingMethod === "" ? null : filterState.billingMethod,
        start_date: prevYear.start_date,
        end_date: prevYear.end_date,
      };
      let currQuery = {
        practice_area_id:
          filterState.practiceArea === "" ? null : filterState.practiceArea,
        billing_method:
          filterState.billingMethod === "" ? null : filterState.billingMethod,
        start_date: currentYear.start_date,
        end_date: currentYear.end_date,
      };
      Promise.all([
        request.getARConcentrationRatio(_id, pmType, prevQuery),
        request.getARConcentrationRatio(_id, pmType, currQuery),
      ])
        .then((res) => {
          if (subscribe) {
            setRatio({
              previous: {
                ar_concentration_ratio: res[0].data.ar_concentration_ratio,
                diversity_index: res[0].data.diversity_index,
              },
              current: {
                ar_concentration_ratio: res[1].data.ar_concentration_ratio,
                diversity_index: res[1].data.diversity_index,
              },
            });
            if (
              res[0].data.ar_concentration_ratio >
              res[1].data.ar_concentration_ratio
            ) {
              setTrending({
                current: false,
                previous: true,
              });
            } else {
              setTrending({
                current: true,
                previous: false,
              });
            }
            setLoading(false);
          }
        })
        .catch((error) => {
          if (subscribe) {
            if (error.code === "ECONNABORTED") {
              setErrorMsg("The API request timed out. Please Refresh the page");
              setError(true);
            } else {
              setError(true);
              setErrorMsg(error.response.data.message);
            }
          }
        });
    }
    return () => {
      subscribe = false;
    };
  }, [parentLoader]);
  const classes = useStyles();
  return (
    <div className={classes.sticky}>
      {parentLoader || loading ? (
        <div className={classes.small}>
          <Loader />
        </div>
      ) : (
        <React.Fragment>
          <div className={classes.container}>
            <span style={{ width: "40%" }}> </span>
            <span className={classes.headingText}>{`FY ${moment(
              prevYear.start_date
            ).year()}`}</span>
            {/* {trending.previous ? (
                <TrendingUpTwoToneIcon
                  className={trending.previous ? classes.up : classes.down}
                />
              ) : (
                <TrendingDownTwoToneIcon
                  className={trending.previous ? classes.up : classes.down}
                />
              )} */}
            <span className={classes.headingText}>{`FY ${moment(
              currentYear.start_date
            ).year()}`}</span>
            {/* {trending.current ? (
                <TrendingUpTwoToneIcon
                  className={trending.current ? classes.up : classes.down}
                />
              ) : (
                <TrendingDownTwoToneIcon
                  className={trending.current ? classes.up : classes.down}
                />
              )} */}
          </div>
          <div
            className={classes.container}
            style={{ backgroundColor: "#eaeaea" }}
          >
            <span className={classes.sub}>
              Concentration <br />
              Ratio:
            </span>
            <span className={trending.previous ? classes.up : classes.down}>
              {numeral(ratio.previous.ar_concentration_ratio).format("0[.]00")}
              {trending.previous ? (
                <TrendingUpTwoToneIcon fontSize={"small"} />
              ) : (
                <TrendingDownTwoToneIcon fontSize={"small"} />
              )}
            </span>

            <span className={trending.current ? classes.up : classes.down}>
              {numeral(ratio.current.ar_concentration_ratio).format("0[.]00")}
              {trending.current ? (
                <TrendingUpTwoToneIcon fontSize={"small"} />
              ) : (
                <TrendingDownTwoToneIcon fontSize={"small"} />
              )}
            </span>
          </div>
          <div className={classes.container}>
            <span className={classes.sub}>
              Diversity <br />
              Index:
            </span>
            <span className={trending.previous ? classes.up : classes.down}>
              {numeral(ratio.previous.diversity_index).format("0[.]00")}
              {trending.previous ? (
                <TrendingUpTwoToneIcon fontSize={"small"} />
              ) : (
                <TrendingDownTwoToneIcon fontSize={"small"} />
              )}
            </span>

            <span className={trending.current ? classes.up : classes.down}>
              {numeral(ratio.current.diversity_index).format("0[.]00")}
              {trending.current ? (
                <TrendingUpTwoToneIcon fontSize={"small"} />
              ) : (
                <TrendingDownTwoToneIcon fontSize={"small"} />
              )}
            </span>
          </div>
        </React.Fragment>
      )}
      {error ? <ErrorSnackBar errorMsg={errorMsg} /> : null}
    </div>
  );
};

export default Widget;
