import { utils, writeFile } from "xlsx/xlsx.mini";
import { ColumnDataType } from "./index";

function exportAsCSV(
  allColumns: ColumnDataType[],
  allData: object[],
  name: string
): void {
  //Sort data according to rows and columns
  const columns = allColumns.filter(
    (columnDef) => columnDef["export"] !== false
  );
  const exportedData = allData.map((rowData) =>
    columns.map((columnDef) => rowData[columnDef.field])
  );
  const exportedHeading = allColumns.map((col) => col.title);
  const data = [exportedHeading, ...exportedData];
  //Convert to csv format by a)Converting JSON to sheet, b) Creating an empty book & writing the file c) Exporting the file as .csv
  const workSheet = utils.json_to_sheet(data);
  //Workbook is a collection of sheets
  const workBook = utils.book_new();
  utils.book_append_sheet(workBook, workSheet, "file");
  writeFile(workBook, name + ".csv");
}

export { exportAsCSV };
