import { useState, useEffect } from "react";
import { Grid, Stepper, Step, StepLabel } from "@material-ui/core";
import StepOne from "./Step1";
import StepTwo from "./Step2";
import { makeStyles } from "@material-ui/core/styles";
import api from "../../../services";
import { useHistory } from "react-router-dom";
import ErrorSnackBar from "../../dashboard/commons/SnackBar";
import Popup from "../../commons/popup";
import _ from "lodash";
import useAuth from "../../../hooks/useAuth";
import { statSync } from "fs";
const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    alignItems: "center",
    display: "flex",
  },
  gridMargin: {
    marginBottom: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
  },
  stepper: {
    backgroundColor: "transparent",
    marginTop: "15%",
    paddingLeft: "15%",
    paddingRight: "15%",
  },
  step: {
    color: `${theme.palette.primary.light} !important`,
  },
}));

export default function Steps() {
  const [firmError, setFirmError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [syncingError, setSyncingError] = useState<boolean>(false);
  const [connectRedirect, setConnectRedirect] = useState<boolean>(false);
  const [integrationError, setIntegrationError] = useState<boolean>(false);
  const [firmSuccess, setFirmSuccess] = useState<boolean>(false);
  const [syncing, setSyncing] = useState<boolean>(false);
  const [progressAmount, setProgressAmount] = useState<number>(0);
  const [newValue, setNewValue] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const history = useHistory();
  const steps = ["Firm Info", "Integration"];
  const {
    generalStates,
    onboardingState,
    authorizationCodeState,
    generalActions,
    onboardingActions,
    authorizationCodeActions,
    currentAppStateActions,
    integrationConfigActions,
    getStatus,
  } = useAuth();
  //Extract clio and xero codes from app state
  const clioCode = authorizationCodeState.clio
    ? authorizationCodeState.clio.code
    : "";
  const xeroCode = authorizationCodeState.xero
    ? authorizationCodeState.xero.code
    : "";
  const ppCode = authorizationCodeState.pp
    ? authorizationCodeState.pp.code
    : "";
  const integrationType = onboardingState?.currentIntegration || "";
  //Set current code according to the integration type, used for the api call
  const currentCode =
    integrationType !== "" && integrationType === "XERO"
      ? xeroCode
      : integrationType === "CLIO"
      ? clioCode
      : ppCode;
  let token = generalStates.token;
  let firmName = onboardingState.firmName;
  let newFirmId = onboardingState.firmId;
  let onboardingStep = onboardingState.onboardingStep;
  const classes = useStyles();

  // *****
  // Show syncing progress
  // *****

  useEffect(() => {
    let subscribe = true;
    let interval;
    if (subscribe && syncing && newFirmId !== 0) {
      let request = api.create(token);
      interval = setInterval(() => {
        request
          .getFirmByID(newFirmId)
          .then(res => {
            // Generalizing sync status | sync had error & sync Progress
            const syncStatus =
              integrationType === "XERO"
                ? res.data.xero.sync_status
                : integrationType === "CLIO"
                ? res.data.clio.sync_status
                : res.data.practicePanther.sync_status;
            const syncHadError =
              integrationType === "XERO"
                ? res.data.xero.sync_had_error
                : integrationType === "CLIO"
                ? res.data.clio.sync_had_error
                : res.data.practicePanther.sync_had_error;
            const syncProgress =
              integrationType === "XERO"
                ? res.data.xero.sync_progress
                : integrationType === "CLIO"
                ? res.data.clio.sync_progress
                : res.data.practicePanther.sync_progress;

            //Local clio duplicate check
            const clioDuplicateCheck =
              integrationType === "CLIO" || integrationType === "PP"
                ? res.data[integrationType == "PP" ? "practicePanther" : "clio"]
                    .auth_bounced == true &&
                  res.data[integrationType == "PP" ? "practicePanther" : "clio"]
                    .sync_status == "first_in_progress" &&
                  res.data[integrationType == "PP" ? "practicePanther" : "clio"]
                    .error_log.length > 0
                  ? true
                  : false
                : false;

            setProgressAmount(syncProgress);
            if (syncStatus === "disconnected") {
              clearInterval(interval);
              setOpen(true);
            } else if (
              (syncStatus === "completed" && syncHadError === false) ||
              clioDuplicateCheck
            ) {
              if (clioDuplicateCheck) {
                //Update global state
                currentAppStateActions.setClioHasDuplicate(true);
              }

              clearInterval(interval);
              request
                .getUserProfile()
                .then(response => {
                  console.log(response.data);
                  const { firmRoles } = response.data;
                  let activeFirm =
                    firmRoles.length > 1
                      ? _.find(
                          firmRoles,
                          item => item.firm._id === newFirmId
                        )
                      : firmRoles[0];

                  if (activeFirm) {
                    generalActions.setActiveFirm(activeFirm);
                    generalActions.setUser(response.data);
                    onboardingActions.setFirmId("");
                    onboardingActions.setCurrentOnboardingIntegration(null);
                    authorizationCodeActions.setClio(null);
                    authorizationCodeActions.setXero(null);
                    authorizationCodeActions.setPP(null);
                    onboardingActions.setFirmName("");
                    history.push(`/dashboard`);
                    history.go(0);
                  }
                })
                .catch(error => {
                  if (error.code === "ECONNABORTED") {
                    setErrorMessage(
                      "The API request timed out. Please Refresh the page"
                    );
                    setSyncingError(true);
                  } else {
                    setErrorMessage(error.response.data.message);
                    setSyncingError(true);
                  }
                });
            } else if (syncHadError === true) {
              setSyncingError(true);
              setErrorMessage("The firm wasn't able to sync successfully!");
            }
          })
          .catch(error => {
            clearInterval(interval);
            if (error.code === "ECONNABORTED") {
              setErrorMessage(
                "The API request timed out. Please Refresh the page"
              );
              setSyncingError(true);
            } else {
              setErrorMessage(error.response.data.message);
              setSyncingError(true);
            }
            setSyncing(false);
          });
      }, 4000);
    }
    return () => {
      clearInterval(interval);
      subscribe = false;
    };
  }, [syncing]);

  useEffect(() => {
    if (newFirmId !== "") {
      onboardingActions.setOnboardingStep(1);
      console.log(newFirmId);
      setSyncing(true);
    }
  }, [newFirmId]);

  // *****
  // Invoke Endpoint when a firm is being created
  // *****

  useEffect(() => {
    if (firmName.length > 0 && newValue && currentCode.length > 0) {
      let refinedToken = token.replace(/['"]+/g, "");
      let request = api.create(refinedToken);
      request
        .createFirm({
          firmName: firmName,
        })
        .then(res => {
          // let firmId = res.data.createdFirm._id;
          const {
            createdFirm: { _id },
          } = res.data;

          onboardingActions.setFirmId(_id);
          if (currentCode.length > 0) {
            // Integration type for API URL
            const integration = integrationType.toLowerCase();
            // Request body
            const body =
              integrationType === "XERO" || integrationType === "PP"
                ? { code: currentCode }
                : { authCode: currentCode };
            request
              .firmIntegration(_id, integration, body)
              .then(res => {
                authorizationCodeActions.setClio(null);
                authorizationCodeActions.setXero(null);
                authorizationCodeActions.setPP(null);
                onboardingActions.setFirmName("");
                setFirmSuccess(true);
                setSyncing(true);
              })
              .catch(error => {
                if (error.code === "ECONNABORTED") {
                  setErrorMessage(
                    "The API request timed out. Please Refresh the page"
                  );
                  setIntegrationError(true);
                } else {
                  setErrorMessage(error.response.data.message);
                  setIntegrationError(true);
                }
              });
          }
        })
        .catch(error => {
          if (error.code === "ECONNABORTED") {
            setErrorMessage(
              "The API request timed out. Please Refresh the page"
            );
            setFirmError(true);
          } else {
            setErrorMessage(error.response.data.message);
            setFirmError(true);
          }
        });
    }
  }, [newValue]);

  // *****
  // Onboarding handles
  // *****

  const handleGoToFirm = () => {
    setNewValue(true);
  };

  const handleFirmError = () => {
    setFirmError(false);
    if (!integrationError) {
      onboardingActions.setOnboardingStep(0);
      authorizationCodeActions.setClio(null);
      authorizationCodeActions.setXero(null);
      authorizationCodeActions.setPP(null);
    }
  };

  const handleBack = () => {
    onboardingActions.setOnboardingStep(0);
    if (firmError) {
      setFirmError(false);
      setErrorMessage("");
      authorizationCodeActions.setClio(null);
      authorizationCodeActions.setXero(null);
      authorizationCodeActions.setPP(null);
    } else if (syncingError) {
      setSyncingError(false);
      setErrorMessage("");
    }
  };

  const handleForward = () => {
    if (firmName.length > 0 && integrationType.length > 0) {
      onboardingActions.setOnboardingStep(1);
    }
  };

  const handleConnectRedirect = () => {
    setConnectRedirect(true);
  };

  const handlePopup = () => {
    let request = api.create(token);
    request
      .getUserProfile()
      .then(response => {
        let activeFirm = _.find(
          response.data.firmRoles,
          item => item.firm._id === newFirmId
        );
        // Set active Integration Type
        if (activeFirm) {
          generalActions.setActiveFirm(activeFirm);
          generalActions.setUser(response.data);
          onboardingActions.setCurrentOnboardingIntegration(null);
          authorizationCodeActions.setClio(null);
          authorizationCodeActions.setPP(null);
          authorizationCodeActions.setXero(null);
          onboardingActions.setFirmName("");
          setOpen(false);
          history.push(`/dashboard/settings/integration`);
        }
      })
      .catch(error => {
        if (error.code === "ECONNABORTED") {
          setErrorMessage("The API request timed out. Please Refresh the page");
          setSyncingError(true);
        } else {
          setErrorMessage(error.response.data.message);
          setSyncingError(true);
        }
      });
  };

  // *****
  // Switch between steps
  // *****

  function getStepContent() {
    switch (onboardingStep) {
      case 0:
        return <StepOne handleForward={handleForward} />;
      case 1:
        return (
          <StepTwo
            handleBack={handleBack}
            handleConnectRedirect={handleConnectRedirect}
            handleGoToFrim={handleGoToFirm}
            connectRedirect={connectRedirect}
            currentCode={currentCode}
            firmError={firmError}
            handleFirmError={handleFirmError}
            integrationError={integrationError}
            errorMessage={errorMessage}
            firmSuccess={firmSuccess}
            syncing={syncing}
            progressAmount={progressAmount}
          />
        );
    }
  }

  return (
    <Grid container>
      <Popup
        title={`Xero has been disconnected!`}
        warning={true}
        open={open}
        buttonText={"Ok"}
        handleClick={handlePopup}
        handleClose={handlePopup}
        body={`To reconnect with Xero, goto Settings -> Integrations & click "Reconnect xero" button.`}
      />
      <Grid item lg={12} xs={12}>
        <Stepper
          activeStep={onboardingStep}
          alternativeLabel
          className={classes.stepper}
        >
          {steps.map(step => (
            <Step key={step}>
              <StepLabel
                StepIconProps={{
                  classes: {
                    active: classes.step,
                  },
                }}
              >
                {step}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        <div className={classes.paper}>{getStepContent()}</div>
      </Grid>
      {syncingError ? <ErrorSnackBar errorMsg={errorMessage} /> : null}
    </Grid>
  );
}
