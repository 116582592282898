import React, { useEffect, useState } from "react";
import { Grid, IconButton, makeStyles, Paper } from "@material-ui/core";
import Chart from "../../charts/fusionCharts";
import api from "../../../../services";
import _ from "lodash";
import EmptyPlaceholder from "../../commons/placeholders/emptyPlaceholder";
import { Skeleton } from "@material-ui/lab";
import {
  StyledToggleButtonGroup,
  StyledToggleButton,
} from "../../tabs/styledTabs";
import InfoButton from "../../infoButton";
import FilterUserTimePractice from "../../filters/genericFilter/filters";
import useFilterState from "../../filters/genericFilter/filterStateHook";
import { FilterDashboardType } from "../../filters/genericFilter/types";
import images from "../../../../images";
import { useHistory } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3),
  },
}));

const initialFilterState: FilterDashboardType = {
  user: "",
};

export default function ChartGrid({ token, firmId, pmType }) {
  const classes = useStyles();
  const [loader, setLoader] = useState<boolean>(true);
  const [data, setData] = useState<any>({ billable: [], non_billable: [] });
  const [years, setYears] = useState<any>([]);
  const [current, setCurrent] = useState<string>("yearly");
  const [topFilter, setTopFilter] = useState<string>(
    pmType == "clio" ? "hourly" : "Hourly"
  );
  const [hourValue, setHourValue] = useState<string>("0");
  const history = useHistory();
  const { filterRef, filterState, handleState } =
    useFilterState(initialFilterState);
  function getData(item, type) {
    switch (type) {
      case "yearly":
        return item.year;
      case "daily":
        return item.day;
      case "monthly":
        return item.month;
      case "weekly":
        return item.year_week;
    }
  }
  function returnKeyValue(item, type) {
    switch (type) {
      case "yearly":
        return { year: item, total_value: 0, total_hours: 0 };
      case "daily":
        return { day: item, total_value: 0, total_hours: 0 };
      case "monthly":
        return { month: item, total_value: 0, total_hours: 0 };
      case "weekly":
        return { year_week: item, total_value: 0, total_hours: 0 };
    }
  }
  function orderList(array, order) {
    let sortedArray = order.map((i) =>
      array.find((j) => getData(j, current) == i) === undefined
        ? returnKeyValue(i, current)
        : array.find((j) => getData(j, current) == i)
    );
    return sortedArray;
  }

  useEffect(() => {
    let subscribe = true;
    if (loader) {
      let request = api.create(token);
      let queryParams = {
        top_filter: topFilter,
        interval: current,
        user_id: filterState.user === "" ? null : filterState.user,
      };
      request.getTimeRecord(firmId, pmType, queryParams).then((res) => {
        if (subscribe) {
          let { billable, non_billable } = res.data;
          let billableHours = [];
          let billableValue = [];

          let nonBillableHours = [];
          let nonBillableValue = [];
          if (billable.length > 0 && non_billable.length > 0) {
            let fixedBilledData = billable.filter(
              (item) =>
                getData(item, current) !== null &&
                item.total_hours !== null &&
                item.total_value !== null
            );
            let fixedNonBilledData = non_billable.filter(
              (item) =>
                getData(item, current) !== null &&
                item.total_hours !== null &&
                item.total_value !== null
            );
            let fixedBilledYears = fixedBilledData.map((item) =>
              getData(item, current)
            );
            let fixedNonBilledYears = fixedNonBilledData.map((item) =>
              getData(item, current)
            );

            let commonLabels = _.uniq([
              ...fixedBilledYears,
              ...fixedNonBilledYears,
            ]);

            let refinedBillable = orderList(
              fixedBilledData,
              commonLabels.sort()
            );
            let refinedNonBillable = orderList(
              fixedNonBilledData,
              commonLabels.sort()
            );
            if (refinedBillable.length > 0) {
              billableHours = refinedBillable.map((item) => ({
                value: item.total_hours,
              }));
              billableValue = refinedBillable.map((item) => ({
                value: item.total_value,
              }));
            }
            if (refinedNonBillable.length > 0) {
              nonBillableHours = refinedNonBillable.map((item) => ({
                value: item.total_hours,
              }));
              nonBillableValue = refinedNonBillable.map((item) => ({
                value: item.total_value,
              }));
            }

            setYears(commonLabels.sort().map((item) => ({ label: `${item}` })));
            setData({
              billable: [billableHours, billableValue],
              non_billable: [nonBillableHours, nonBillableValue],
            });
          } else if (billable.length > 0 && non_billable.length === 0) {
            let fixedBilledData = billable.filter(
              (item) =>
                getData(item, current) !== null &&
                item.total_hours !== null &&
                item.total_value !== null
            );
            let commonYears = fixedBilledData.map((item) =>
              getData(item, current)
            );
            let fixedNonBilledData = commonYears.map((item) =>
              returnKeyValue(item, current)
            );
            billableHours = fixedBilledData.map((i) => ({
              value: i.total_hours,
            }));
            billableValue = fixedBilledData.map((i) => ({
              value: i.total_value,
            }));
            nonBillableHours = fixedNonBilledData.map((i) => ({
              value: i.total_hours,
            }));
            nonBillableValue = fixedNonBilledData.map((i) => ({
              value: i.total_value,
            }));
            setYears(commonYears.map((i) => ({ label: `${i}` })));
            setData({
              billable: [billableHours, billableValue],
              non_billable: [nonBillableHours, nonBillableValue],
            });
          } else if (billable.length === 0 && non_billable.length > 0) {
            let fixedNonBilledData = non_billable.filter(
              (item) =>
                getData(item, current) !== null &&
                item.total_hours !== null &&
                item.total_value !== null
            );
            let commonYears = fixedNonBilledData.map((item) =>
              getData(item, current)
            );
            let fixedBilledData = commonYears.map((item) =>
              returnKeyValue(item, current)
            );
            billableHours = fixedBilledData.map((i) => ({
              value: i.total_hours,
            }));
            billableValue = fixedBilledData.map((i) => ({
              value: i.total_value,
            }));
            nonBillableHours = fixedNonBilledData.map((i) => ({
              value: i.total_hours,
            }));
            nonBillableValue = fixedNonBilledData.map((i) => ({
              value: i.total_value,
            }));
            setYears(commonYears.map((i) => ({ label: `${i}` })));
            setData({
              billable: [billableHours, billableValue],
              non_billable: [nonBillableHours, nonBillableValue],
            });
          }

          setLoader(false);
        }
      });
    }
  }, [loader]);

  useEffect(() => {
    setLoader(true);
  }, [topFilter, current]);

  const seriesColumn1 = [
    {
      seriesname: "Billable",
      data: data.billable[parseInt(hourValue)],
    },
    {
      seriesname: "Non-Billable",
      data: data.non_billable[parseInt(hourValue)],
    },
  ];
  const configValue = {
    numberprefix: "$",
    scrollToEnd: 1,
  };
  const configHours = {
    numbersuffix: "hrs",
    scrollToEnd: 1,
  };
  const handleTabChange = (event, value) => {
    if (value !== null) {
      setTopFilter(value);
    }
  };
  const handleUser = (value: number | "") => {
    handleState.handleUser(value);
  };

  const handleFilterSubmit = () => {
    setLoader(true);
  };

  const handleFilterClear = () => {
    handleState.reset(initialFilterState);
    setLoader(true);
  };
  return (
    <Paper square className={classes.container}>
      <Grid
        container
        justifyContent={"space-between"}
        alignItems={"center"}
        spacing={4}
      >
        <Grid item>
          <StyledToggleButtonGroup
            onChange={(event, value) => value !== null && setHourValue(value)}
            exclusive
            value={hourValue}
            size="small"
          >
            <StyledToggleButton key={0} value={"0"}>
              Hours
            </StyledToggleButton>
            <StyledToggleButton key={1} value={"1"}>
              Value
            </StyledToggleButton>
          </StyledToggleButtonGroup>
        </Grid>
        <Grid item>
          <StyledToggleButtonGroup
            onChange={handleTabChange}
            exclusive
            value={topFilter}
            size="small"
          >
            <StyledToggleButton
              key={1}
              value={pmType == "clio" ? "hourly" : "Hourly"}
            >
              Hourly
            </StyledToggleButton>
            <StyledToggleButton
              key={2}
              value={pmType == "clio" ? "contingency" : "Contingency"}
            >
              Contingency
            </StyledToggleButton>
            <StyledToggleButton
              key={3}
              value={pmType == "clio" ? "flat" : "Flat Rate"}
            >
              Flat
            </StyledToggleButton>
          </StyledToggleButtonGroup>
        </Grid>
        <Grid item>
          <StyledToggleButtonGroup
            onChange={(event, value) => setCurrent(value)}
            exclusive
            value={current}
            size="small"
          >
            <StyledToggleButton key={1} value={"daily"}>
              Daily
            </StyledToggleButton>
            <StyledToggleButton key={2} value={"weekly"}>
              Weekly
            </StyledToggleButton>
            <StyledToggleButton key={3} value={"monthly"}>
              Monthly
            </StyledToggleButton>
            <StyledToggleButton key={4} value={"yearly"}>
              Yearly
            </StyledToggleButton>
          </StyledToggleButtonGroup>
        </Grid>
        <Grid item>
          <span>
            <IconButton onClick={() => history.push("/dashboard/productivity")}>
              <img src={images.linkIcon} />
            </IconButton>
            <InfoButton
              title={
                "Displays the total value per year of billable and non-billable time in the practice management system."
              }
            />
            <FilterUserTimePractice
              filterState={filterState}
              disabled={loader}
              handleFilterSubmit={handleFilterSubmit}
              handleFilterClear={handleFilterClear}
              handleUser={handleUser}
            />
          </span>
        </Grid>
      </Grid>
      {loader ? (
        <Skeleton variant="rect" height={300} />
      ) : data.billable.length > 0 && data.non_billable.length > 0 ? (
        <>
          <Chart
            series={seriesColumn1}
            labels={years}
            type={"scrollcombi2d"}
            height={300}
            config={hourValue == "0" ? configHours : configValue}
          />
        </>
      ) : (
        <EmptyPlaceholder small />
      )}
    </Paper>
  );
}
