import useAuth from "../../../../hooks/useAuth";
import { PlaceholderAcl } from "../../../commons/placeholderAcl";
import Trust from "./trust";
interface Props {}

const TrustContainer = (props: Props) => {
  const {
    generalStates: { acl },
  } = useAuth();
  return acl?.trust ? <Trust /> : <PlaceholderAcl />;
};
export default TrustContainer;
