import React, { useState, useEffect } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Grid, Button, Paper } from "@material-ui/core";
import _ from "lodash";
import images from "../../../images/index";
import { useHistory } from "react-router-dom";
import api from "../../../services";
import ErrorSnackBar from "../../dashboard/commons/SnackBar";
import Popup from "../../commons/popup";
import useAuth from "../../../hooks/useAuth";
export interface StripeConnectProps {
  current: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    height: 260,
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      height: 400,
    },
  },
  image: {
    maxWidth: "60%",
    height: "auto",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "80%",
      height: "auto",
    },
  },
  titleText: {
    display: "block",
    fontSize: "1.3rem",
    fontWeight: 600,
    color: theme.palette.text.primary,
    [theme.breakpoints.up("xl")]: {
      fontSize: "1.5rem",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "1.2rem",
      fontWeight: 600,
    },
  },
  subheadingText: {
    display: "block",
    fontSize: "1.1rem",
    fontWeight: 500,
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(1),
    [theme.breakpoints.up("xl")]: {
      fontSize: "1.2rem",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "0.9rem",
    },
  },
  textContainer: {
    paddingBottom: theme.spacing(2),
  },
  button: {
    backgroundColor: "#6772E5",
    "&:hover": {
      backgroundColor: "#7581ea",
    },
    color: "#fff",
    paddingRight: theme.spacing(8),
    paddingLeft: theme.spacing(8),
  },
  innerElementContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    border: "solid",
    borderWidth: 1,
    borderColor: "#dadaed",
    borderRadius: theme.spacing(1),
    backgroundColor: "#f4f4f9",
    fontSize: "1rem",
    fontWeight: 600,
    color: theme.palette.text.secondary,
    [theme.breakpoints.down("md")]: {
      fontSize: "0.8rem",
      fontWeight: 600,
    },
  },
  titleInputText: {
    display: "block",
    textAlign: "center",
    fontSize: "1rem",
    fontWeight: 600,
    color: theme.palette.text.secondary,
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      fontSize: "0.9rem",
      fontWeight: 600,
    },
  },
  imageWrapper: {
    display: "flex",
    alignItems: "center",
  },
}));

const StripeConnect: React.FC<StripeConnectProps> = ({ current }) => {
  const classes = useStyles();
  const history = useHistory();
  const {
    generalStates: {
      token,
      activeFirm: {
        firm: { _id, clio, xero },
      },
      paymentInfo: { subscribedClio, subscribedPP, subscribedXero },
    },
    currentAppState: {
      status: { clioStatus, ppStatus, xeroStatus },
    },
    generalActions: { setPaymentInfo, setUser },
    getStatus,
  } = useAuth();
  const [getSubscriptionUpdate, setSubscriptionUpdate] =
    useState<boolean>(false);
  const [getUser, setGetUser] = useState<boolean>(false);
  const [popup, setPopup] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [loader, setLoader] = useState<boolean>(false);
  const currentObj =
    current === "xero"
      ? subscribedXero
      : current === "clio"
      ? subscribedClio
      : subscribedPP;

  useEffect(() => {
    let subscribe = true;
    if (getUser) {
      let request = api.create(token);
      request
        .getUserProfile()
        .then((response) => {
          if (subscribe) {
            setUser(response.data);
            setGetUser(false);
          }
        })
        .catch((error) => {
          if (subscribe) {
            if (error.code === "ECONNABORTED") {
              setErrorMsg("The API request timed out. Please Refresh the page");
              setError(true);
            } else {
              setErrorMsg(error.response.data.message);
              setError(true);
            }
          }
        });
    }
    return () => {
      subscribe = false;
    };
  }, [getUser]);
  useEffect(() => {
    let subscribe = true;
    if (getSubscriptionUpdate) {
      let request = api.create(token);
      request
        .getStripeAllSubscription(_id)
        .then((res) => {
          if (subscribe) {
            const { CLIO, XERO, PP } = res.data;
            // if (CLIO && !CLIO.isBlocked && XERO && !XERO.isBlocked) {
            //   setClioXeroIntegration();
            // } else if (CLIO && !CLIO.isBlocked) {
            //   setClioIntegration();
            // } else if (XERO && !XERO.isBlocked) {
            //   setXeroIntegration();
            // } else if (!clio && !xero && !CLIO && !XERO) {
            //   setNoIntegration();
            // }
            const response = {
              subscribedClio: CLIO,
              subscribedXero: XERO,
              subscribedPP: PP,
            };
            getStatus();

            if (
              !_.isEqual(response, {
                subscribedClio: subscribedClio,
                subscribedXero: subscribedXero,
                subscribedPP: subscribedPP,
              })
            ) {
              setPaymentInfo(response);
            }
            setSubscriptionUpdate(false);
            setGetUser(true);
            history.replace("/dashboard/settings/payments");
          }
        })
        .catch((error) => {
          if (subscribe) {
            if (error.code === "ECONNABORTED") {
              setErrorMsg("The API request timed out. Please Refresh the page");
              setError(true);
            } else {
              setErrorMsg(error.response.data.message);
              setError(true);
            }
          }
        });
    }
    return () => {
      subscribe = false;
    };
  }, [getSubscriptionUpdate]);

  function subscriptionApi() {
    let request = api.create(token);
    request
      .postStripeSubscription(_id, current.toUpperCase(), {
        payment_method: "dummy",
        email: "dummy@dummy.com",
      })
      .then((res) => {
        setSubscriptionUpdate(true);
      })
      .catch((error) => {
        if (error.code === "ECONNABORTED") {
          setErrorMsg("The API request timed out. Please Refresh the page");
          setError(true);
        } else {
          setErrorMsg(error.response.data.message);
          setError(true);
        }
      });
  }

  const handleClick = () => {
    if (current == "clio" && clioStatus == 1) {
      setPopup(true);
    } else if (current == "xero" && (clioStatus == 1 || ppStatus == 1)) {
      setPopup(true);
    } else if (current == "pp" && xeroStatus == 1) {
      setPopup(true);
    } else {
      history.push("/dashboard/settings/payments/subscription");
    }
  };

  return (
    <React.Fragment>
      <Popup
        title={`Payment Confirmation`}
        warning={true}
        open={popup}
        buttonText={"Pay Now"}
        handleClick={subscriptionApi}
        disabled={getSubscriptionUpdate || getUser}
        handleClose={() => setPopup(false)}
        body={`Proceed to "Pay Now" with your existing payment information.`}
      />
      <Paper className={classes.paper}>
        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            className={classes.imageWrapper}
          >
            <img
              src={images.stripeLogo}
              alt="stripe-logo"
              className={classes.image}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            {currentObj !== null &&
            currentObj.hasOwnProperty("card") &&
            typeof currentObj.card !== "undefined" &&
            currentObj.isBlocked === false &&
            currentObj.billingRequired === false ? (
              //Render connected information once card is connected
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <span className={classes.titleInputText}>Card Brand:</span>
                  <div className={classes.innerElementContainer}>
                    {currentObj.card.brand}
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <span className={classes.titleInputText}>Card No:</span>
                  <div className={classes.innerElementContainer}>
                    XXXX XXXX XXXX {currentObj.card.last4}
                  </div>
                </Grid>
              </Grid>
            ) : (
              //Else display connection options
              <React.Fragment>
                <div className={classes.textContainer}>
                  <span className={classes.titleText}>Stripe Payment</span>
                  <span className={classes.subheadingText}>
                    Pay via Stripe's secure connection
                  </span>
                </div>
                <Button
                  variant="contained"
                  onClick={handleClick}
                  className={classes.button}
                >
                  Subscribe now
                </Button>
              </React.Fragment>
            )}
          </Grid>
        </Grid>
      </Paper>
      {error ? <ErrorSnackBar errorMsg={errorMsg} /> : null}
    </React.Fragment>
  );
};

export default StripeConnect;
