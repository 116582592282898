import React, { useState, useEffect, useCallback } from "react";
import useFilterState from "../../../filters/genericFilter/filterStateHook";
import FilterUserTimePractice from "../../../filters/genericFilter/filters";
import { ProductivityGTTypes } from "../../../filters/genericFilter/types";
import {
  compareFilterDates,
  getMonthsFusion,
  limitLengthToEighteen,
} from "../../utils";
import EmptyPlaceholder from "../../../commons/placeholders/emptyPlaceholder";
import { makeStyles } from "@material-ui/core/styles";
import ChartLoader from "../../../commons/loaders/chartLoader";
import Colors from "../../../../../theme/ChartTheme";
import ErrorSnackBar from "../../../commons/SnackBar";
import api from "../../../../../services";
import useDateFilterState, {
  DateStateType,
} from "../../../filters/genericFilter/dateFilterStateHook";
import {
  compareMonths,
  calcIterativePercentage,
  returnValueHours,
} from "./utils";
import _ from "lodash";
import { DateRange } from "materialui-daterange-picker";
import Chart from "../../../charts/fusionCharts";
import InfoButton from "../../../infoButton";
import { FormControlLabel, Paper, Grid, Checkbox } from "@material-ui/core";
import UserName from "../../../filters/commons/userName";
import {
  StyledToggleButtonGroup,
  StyledToggleButton,
} from "../../../tabs/styledTabs";
import useAuth from "../../../../../hooks/useAuth";
// *****
// Types
// *****

interface ChartTypes {
  utilizationRate: any[] | [];
  realizationRate: any[] | [];
  collectionRate: any[] | [];
}

interface ResponseArrayObject {
  total_hours: number;
  month: string;
}

interface ResponseType {
  available_time: ResponseArrayObject[];
  billed_time: ResponseArrayObject[];
  collected_time: ResponseArrayObject[];
  worked_time: ResponseArrayObject[];
}

export interface UtilizationProps {}

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "visible",
    flexDirection: "column",
    minHeight: 550,
    justifyContent: "center",
  },
  textTitle: {
    color: theme.palette.text.primary,
    fontSize: "1.2em",
    fontWeight: 600,
  },
  headingText: {
    fontSize: "1.2em",
    fontWeight: 600,
    color: theme.palette.text.secondary,
  },
  divider: {
    backgroundColor: "#EDEFF7",
    height: 1,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  radialLoader: {
    marginTop: theme.spacing(16),
  },
}));

// *****
// Initial Filter state
// *****

const initialFilterState: ProductivityGTTypes = {
  practiceArea: "",
  user: "",
  status: "",
};

const initialDateFilterState: DateStateType = {
  dateAfter: null,
  dateBefore: null,
};

const Utilization: React.FC<UtilizationProps> = () => {
  const classes = useStyles();
  const [loader, setLoader] = useState<boolean>(true);
  const [current, setCurrent] = useState<string>("0");
  const [topFilter, setTopFilter] = useState<string>("hourly");
  const [excludeNonBillable, setExcludeNonBillable] = useState<boolean>(false);

  const {
    generalStates: {
      token,
      activeFirm: {
        firm: { _id },
      },
    },
    integrationConfigState: { pmType },
  } = useAuth();

  const [error, setError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [months, setMonths] = useState<any[] | []>([]);
  const [rate, setRate] = useState<boolean>(false);
  const [chart, setChart] = useState<ChartTypes>({
    utilizationRate: [],
    realizationRate: [],
    collectionRate: [],
  });

  // *****
  // Filter States
  // *****

  const { filterRef, filterState, handleState } =
    useFilterState(initialFilterState);
  const { dateFilterRef, dateFilterState, handleDateState } =
    useDateFilterState(initialDateFilterState);

  const prevDateFilterState = dateFilterRef.current;
  const prevFilterState = filterRef.current;

  // *****
  // Series for the chart
  // *****

  const seriesColumn = [
    {
      seriesname: "Utilization",
      color: Colors.orange,
      data: chart.utilizationRate[parseInt(current)],
    },
    {
      seriesname: "Realization",
      color: Colors.green,
      data: chart.realizationRate[parseInt(current)],
    },
    {
      seriesname: "Collection",
      color: Colors.red,
      data: chart.collectionRate[parseInt(current)],
    },
  ];
  function queryParams() {
    let query = {
      user_id: filterState.user === "" ? null : filterState.user,
      practice_area_id:
        filterState.practiceArea === "" ? null : filterState.practiceArea,
      date_after: dateFilterState.dateAfter,
      date_before: dateFilterState.dateBefore,
      status: filterState.status === "" ? null : filterState.status,
      exclude_non_billable: excludeNonBillable,
    };
    if (pmType == "clio") {
      return { ...query, top_filter: topFilter };
    } else return { ...query, rate: rate };
  }

  useEffect(() => {
    let subscribe = true;
    if (loader) {
      let request = api.create(token);

      request
        .getProductivity(_id, pmType, queryParams())
        .then((response) => {
          if (subscribe) {
            let data: ResponseType = response.data;
            let billedArray = _.cloneDeep(data.billed_time);
            let availableArray = _.cloneDeep(data.available_time);
            let collectedArray = _.cloneDeep(data.collected_time);
            let workedArray = _.cloneDeep(data.worked_time);
            let getMonths = getMonthsFusion(availableArray, "month");
            let { hours: billedHours, value: billedValue } = returnValueHours(
              compareMonths(billedArray, availableArray)
            );
            let { hours: workedHours, value: workedValue } = returnValueHours(
              compareMonths(workedArray, availableArray)
            );
            let { hours: collectedHours, value: collectedValue } =
              returnValueHours(compareMonths(collectedArray, availableArray));
            let { hours: availableHours, value: availableValue } =
              returnValueHours(availableArray);

            let utilizationPercentagePerMonth = [
              calcIterativePercentage(workedHours, availableHours).map(
                (item) => ({ value: item })
              ),
              calcIterativePercentage(workedValue, availableValue).map(
                (item) => ({ value: item })
              ),
            ];
            let realizationPercentagePerMonth = [
              calcIterativePercentage(billedHours, workedHours).map((item) => ({
                value: item,
              })),
              calcIterativePercentage(billedValue, workedValue).map((item) => ({
                value: item,
              })),
            ];
            let collectionPercentagePerMonth = [
              calcIterativePercentage(collectedHours, billedHours).map(
                (item) => ({ value: item })
              ),
              calcIterativePercentage(collectedValue, billedValue).map(
                (item) => ({ value: item })
              ),
            ];
            //Remove limit 0f 18 when date filters applied
            if (
              dateFilterState.dateAfter !== null &&
              dateFilterState.dateBefore !== null
            ) {
              setChart({
                utilizationRate: utilizationPercentagePerMonth,
                realizationRate: realizationPercentagePerMonth,
                collectionRate: collectionPercentagePerMonth,
              });
              setMonths(getMonths);
            } else {
              let utilization = [
                limitLengthToEighteen(utilizationPercentagePerMonth[0]),
                limitLengthToEighteen(utilizationPercentagePerMonth[1]),
              ];
              let realization = [
                limitLengthToEighteen(realizationPercentagePerMonth[0]),
                limitLengthToEighteen(realizationPercentagePerMonth[1]),
              ];
              let collection = [
                limitLengthToEighteen(collectionPercentagePerMonth[0]),
                limitLengthToEighteen(collectionPercentagePerMonth[1]),
              ];
              let months = limitLengthToEighteen(getMonths);
              setChart({
                utilizationRate: utilization,
                realizationRate: realization,
                collectionRate: collection,
              });
              setMonths(months);
            }

            setTimeout(() => {
              setLoader(false);
            }, 500);
          }
        })
        .catch((error) => {
          if (subscribe) {
            if (error.code === "ECONNABORTED") {
              setErrorMsg("The API request timed out. Please Refresh the page");
              setError(true);
            } else {
              setErrorMsg(error.response.data.message);
              setError(true);
            }
          }
        });
    }
    return () => {
      subscribe = false;
    };
  }, [loader]);
  useEffect(() => {
    setLoader(true);
  }, [topFilter, excludeNonBillable, rate]);

  // *****
  // Filter Events
  // *****

  const handlePracticeAreas = (value: number | "") => {
    handleState.handlePracticeArea(value);
  };

  const handleUser = (value: number | "") => {
    handleState.handleUser(value);
  };
  const handleStatus = (value) => {
    handleState.handleStatus(value);
  };

  const handleDateFilterRange = (dateRange: DateRange): void => {
    handleDateState.handleDateUpdate(dateRange);
  };

  // *****
  // Filter Submit
  // Use Case 1: If dates have been applied, then check for date consistency.
  // Use Case 2: All filters have been applied and changed.
  // Use Case 3: Dates have been applied and changed.
  // *****

  const handleFilterSubmit = () => {
    //if filters or date Filters have changed
    if (
      compareFilterDates(
        filterState,
        prevFilterState,
        dateFilterState,
        prevDateFilterState,
        initialFilterState
      ) === true
    ) {
      setLoader(true);
    }
  };

  // *****
  // Clear Filter States
  // *****

  const handleFilterClear = useCallback(() => {
    handleState.reset(initialFilterState);
    handleDateState.resetDate(initialDateFilterState);
    setLoader(true);
  }, []);
  const config = {
    yAxisName: "Percentage",
    plottooltext: "<b>$dataValue %</b> $seriesName",
  };
  return (
    <Grid item lg={12} md={12} sm={12} xs={12} xl={12}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          {loader ? (
            <ChartLoader />
          ) : (
            <React.Fragment>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <span className={classes.textTitle}>
                  Utilization, Realization & Collection Ratios.
                </span>
                {!(filterState.user == "") && (
                  <UserName id={filterState.user} />
                )}

                <span>
                  <InfoButton
                    title={
                      <div>
                        Utilization Rate is Total Worked Time (Billed & Non
                        Billed) divided by Total Available Time.
                        <br />
                        <br />
                        Realization rate is Total Billed Time divided by Total
                        Worked Time.
                        <br />
                        <br />
                        Collection rate is Total Collected Time divided by Total
                        Billed Time.
                      </div>
                    }
                  />
                  <FilterUserTimePractice
                    filterState={filterState}
                    dateFilterState={dateFilterState}
                    disabled={loader}
                    handleUser={handleUser}
                    handleDateFilterRange={handleDateFilterRange}
                    handleFilterSubmit={handleFilterSubmit}
                    handleFilterClear={handleFilterClear}
                    handlePracticeAreas={handlePracticeAreas}
                    handleStatus={handleStatus}
                  />
                </span>
              </Grid>
              <Grid
                container
                justifyContent={"space-around"}
                alignItems={"center"}
                spacing={4}
              >
                {pmType == "clio" && (
                  <Grid item>
                    <StyledToggleButtonGroup
                      onChange={(event, value) =>
                        value !== null && setTopFilter(value)
                      }
                      exclusive
                      value={topFilter}
                      size="small"
                    >
                      <StyledToggleButton key={1} value={"hourly"}>
                        Hourly
                      </StyledToggleButton>
                      <StyledToggleButton key={2} value={"contingency"}>
                        Contingency
                      </StyledToggleButton>
                      <StyledToggleButton key={3} value={"flat"}>
                        Flat
                      </StyledToggleButton>
                    </StyledToggleButtonGroup>
                  </Grid>
                )}
                {pmType == "pp" && (
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={rate}
                          onChange={() => setRate(!rate)}
                          name="checkedA"
                        />
                      }
                      label="Zero Rate"
                    />
                  </Grid>
                )}
                <Grid item>
                  <StyledToggleButtonGroup
                    onChange={(event, value) => setCurrent(value)}
                    exclusive
                    value={current}
                    size="small"
                  >
                    <StyledToggleButton key={1} value={"0"}>
                      Hour
                    </StyledToggleButton>
                    <StyledToggleButton key={2} value={"1"}>
                      Value
                    </StyledToggleButton>
                  </StyledToggleButtonGroup>
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={excludeNonBillable}
                        onChange={() =>
                          setExcludeNonBillable(!excludeNonBillable)
                        }
                        name="checkedA"
                      />
                    }
                    label="Exclude Non Billable"
                  />
                </Grid>
              </Grid>
              {chart.utilizationRate[current].length > 0 ||
              chart.realizationRate[current].length > 0 ? (
                <Chart
                  type={"mscolumn2d"}
                  series={seriesColumn}
                  height={400}
                  labels={months}
                  config={config}
                />
              ) : (
                <EmptyPlaceholder />
              )}
            </React.Fragment>
          )}
        </Paper>
      </Grid>
      {error ? <ErrorSnackBar errorMsg={errorMsg} /> : null}
    </Grid>
  );
};

export default Utilization;
