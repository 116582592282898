import { makeStyles } from "@material-ui/core";
import React, { useContext, useState, useEffect } from "react";
import { FilterContext } from "../../../tabs";

interface IUserNameProps {
  id?: number;
}
const useStyles = makeStyles((theme) => ({
  textTitle: {
    color: theme.palette.text.primary,
    fontSize: "1.2em",
    fontWeight: 600,
  },
}));
const UserName: React.FunctionComponent<IUserNameProps> = ({ id }) => {
  const { users } = useContext(FilterContext);
  const [filteredName, setFilteredName] = useState<any>("");
  useEffect(() => {
    setFilteredName(users.find(item => item.id === id));
   
  }, [users]);
  const classes = useStyles();
  return <span className={classes.textTitle}>{filteredName.name}</span>;
};

export default UserName;
