import { FirmUpdatesType } from "../../../services/socket/socket";

// *****
// Evaluate Socket Integration and Socket Sync Progress, return true when firms have been synced succeccfully.
// *****

export const evalSocketState = (
  socketPM: FirmUpdatesType | null,
  socketXero: FirmUpdatesType | null
): boolean => {
  // 1a: Check if firm is integrated with both clio and xero

  if (socketPM !== null && socketXero !== null) {
    // 2: Then check if both have synced successfully

    if (
      (socketPM.sync_progress === 100 ||
        socketPM.sync_status === "completed") &&
      (socketXero.sync_progress === 100 ||
        socketXero.sync_status === "completed")
    ) {
      return true;
    } else {
      return false;
    }
    //Check  if only Clio is integrated
  } else if (socketPM !== null && socketXero == null) {
    //Check if clio progress has completed
    if (
      socketPM.sync_progress === 100 ||
      socketPM.sync_status === "completed"
    ) {
      return true;
    } else {
      return false;
    }
    //Check if xero is the only one integrated
  } else if (socketXero !== null && socketPM == null) {
    //Check if xero progress has completed
    if (
      socketXero.sync_progress === 100 ||
      socketXero.sync_status === "completed"
    ) {
      return true;
    } else {
      return false;
    }
  }

  return false;
};
