import { useState } from "react";
import ARBucket from "./arBucket";
import ARTab from "../../tabs/inner/arTab";
export interface IARProps {}

export default function AR(props: IARProps) {
  const [detail, setDetail] = useState<boolean>(false);
  const [id, setId] = useState([
    { name: "0-30 Days", id: 0, selected: false },
    { name: "31-60 Days", id: 1, selected: false },
    { name: "61-90 Days", id: 2, selected: false },
    { name: "91-120 Days", id: 3, selected: false },
    { name: "120+ Days", id: 4, selected: false },
  ]);

  const handleChange = (number) => {
    let temp = id;
    let temp2 = temp.map((item) => {
      if (item.selected && item.id === number) {
        return item;
      } else if (item.id === number) {
        return { ...item, selected: true };
      } else {
        return { ...item, selected: false };
      }
    });
    setId(temp2);
    setDetail(true);
  };

  return detail ? (
    <ARTab
      id={id}
      detail={detail}
      handleChange={handleChange}
      handleBack={() => setDetail(false)}
    />
  ) : (
    // <ARGrid
    //
    // />

    <ARBucket id={id} handleToggle={handleChange} />
  );
}
