export default function reducer(state, action) {
  switch (action.type) {
    case "SET_REQUEST":
      return {
        ...state,
        request: {
          status: true,
        },
        response: { status: false, data: [] },
        error: { status: false, message: "" },
      };
    case "SET_RESPONSE":
      return {
        ...state,
        request: {
          status: false,
        },
        response: { status: true, data: action.payload },
        error: { status: false, message: "" },
      };

    case "SET_ERROR":
      return {
        ...state,
        request: {
          status: false,
        },
        response: { status: false, data: [] },
        error: { status: true, message: action.payload },
      };
    default:
      return state;
  }
}
