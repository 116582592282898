const Colors = {
  blue: "#1488CC",
  lightBlue: "#3FA7D6",
  red: "#EE5253",
  yellow: "#FECA57",
  purple: "#9E5ABB",
  green: "#1DD1A1",
  grid: "#F7F9FC",
  labels: "#858796",
  labelDark: "#5a5c69",
  orange: "#FF9F43"
};
// const Colors = {
//   blue: "#008FFB",
//   red: "#FF4560",
//   yellow: "#FEB019",
//   purple: "#775DD0",
//   green: "#00E396",
//   grid: "#F7F9FC",
//   labels: "#858796",
//   labelDark: "#5a5c69"
// };

export default Colors;
