import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, CircularProgress } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import useAuth from "../hooks/useAuth";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: theme.spacing(8, 4),
  },
  wrapper: {
    paddingBottom: theme.spacing(1),
    display: "block",
    textAlign: "center",
  },
}));
export default function AuthRedirect() {
  const [initializer, setInitializer] = useState(true);
  const location = useLocation();
  const history = useHistory();
  const {
    generalStates: {
      user: { firmRoles },
    },
    onboardingState: { currentIntegration },
    authorizationCodeActions: { setClio, setPP, setXero },
  } = useAuth();
  const classes = useStyles();

  useEffect(() => {
    if (initializer) {
      const queries = queryString.parse(location.search);
      //Check if query code and state exist
      if (
        typeof queries.code != "undefined" &&
        typeof queries.state != "undefined"
      ) {
        // Integration Type = "XERO" || "CLIO" && update state accordingly
        if (currentIntegration === "XERO") {
          setXero({
            code: queries.code,
            state: queries.state,
          });
        } else if (currentIntegration === "PP") {
          setPP({
            code: queries.code,
            state: queries.state,
          });
        } else if (currentIntegration === "CLIO") {
          setClio({
            code: queries.code,
            state: queries.state,
          });
        }
        //Use case 1: No previous firms exist on firmtrak account
        if (firmRoles.length === 0) {
          // handleClio(queries.code as string, queries.state as string);
          history.replace("/dashboard/onboarding");
          setInitializer(false);
          //Use Case 2: firms exist and a new firm is being integrated
        } else if (firmRoles.length > 0) {
          if (queries.state != null) {
            // Use case 2A: If firm is integrating from settings/integration
            if (queries.state.includes("integration")) {
              //Clio code stored in app state
              // authorizationCodeActions.setClio({
              //   code: queries.code,
              //   state: queries.state
              // });
              setTimeout(() => {
                setInitializer(false);
                history.replace("/dashboard/settings/integration");
              }, 2000);
              // Use Case 2B: If firm is integrating from onboarding
            } else if (queries.state.includes("onboarding")) {
              // Clio code stored in context
              // handleClio(queries.code as string, queries.state as string);
              history.replace("/dashboard/onboarding");
              setInitializer(false);
            }
          }
        }
      }
    }
  }, [initializer]);

  if (initializer) {
    return (
      <div className={classes.root}>
        <CircularProgress />
      </div>
    );
  } else {
    return (
      <div className={classes.root}>
        <Typography
          variant="h3"
          color="textPrimary"
          align="center"
          className={classes.wrapper}
        ></Typography>
        <Typography variant="body2" color="textSecondary" align="center">
          {"Third Party Authorization Complete. Redirecting to Firmtrak..."}
        </Typography>
      </div>
    );
  }
}
