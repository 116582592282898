import React, { useState, useEffect } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import MatTable from "../../charts/table";
import api from "../../../../services";
import ProgressBar from "../../charts/progressBar";
import ErrorSnackBar from "../../commons/SnackBar";
import { PercentageRounded } from "../productivity/utils";
import useFilterState from "../../filters/genericFilter/filterStateHook";
import { MatterCountResponse, TableResponseItem } from "./types";
import { MatterTrackerTypes } from "../../filters/genericFilter/types";
import FilterUserTimePractice from "../../filters/genericFilter/filters";
import FilterListIcon from "@material-ui/icons/FilterList";
import InfoIcon from "@material-ui/icons/Info";
import useAuth from "../../../../hooks/useAuth";
import moment from "moment";
export interface MatterTrackerProps {
  type?: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  progressPaper: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(3),
    height: "100%",
  },
  innerWrapper: {
    marginBottom: theme.spacing(2),
  },
  buttonRoot: {
    display: "inline-block",
    margin: 0,
    border: "none",
    height: 0,
    minWidth: 0,
    padding: 0,
    "&:hover": {
      color: theme.palette.error.light,
      backgroundColor: "transparent",
    },
  },
  newButtonLabel: {
    display: "inline-block",
    textAlign: "start",
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: "25px",
    textTransform: "none",
    cursor: "pointer",
    padding: 0,
    margin: 0,
  },
  hoverTolerance: {
    "&:hover": {
      color: theme.palette.warning.light,
      backgroundColor: "transparent",
    },
  },
  hoverJeopardy: {
    "&:hover": {
      color: theme.palette.error.light,
      backgroundColor: "transparent",
    },
  },
  hoverPlanned: {
    "&:hover": {
      color: theme.palette.secondary.main,
      backgroundColor: "transparent",
    },
  },
  buttonLabel: {
    display: "inline-block",
    textAlign: "start",
    textTransform: "none",
    padding: 0,
    margin: 0,
    "&:hover": {
      color: theme.palette.error.light,
      backgroundColor: "transparent",
    },
  },
  headingText: {
    fontSize: "1.2em",
    display: "inline-block",
    fontWeight: 600,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(2),
  },
  divider: {
    backgroundColor: "#EDEFF7",
    height: 1,
    marginBottom: theme.spacing(1),
  },

  buttonLabelUnselected: {
    color: theme.palette.text.primary,
  },
  barJeopardy: {
    backgroundColor: theme.palette.error.main,
  },
  barTolerance: {
    backgroundColor: theme.palette.warning.main,
  },
  barPlanned: {
    backgroundColor: theme.palette.secondary.main,
  },
  percentJeopardy: {
    color: theme.palette.error.main,
  },
  percentTolerance: {
    color: theme.palette.warning.main,
  },
  percentPlanned: {
    color: theme.palette.secondary.main,
  },
  buttonWrapper: {
    display: "inline-block",
  },
  switch: {
    borderColor: theme.palette.primary.main,
    borderStyle: "solid",
    borderWidth: "2px",
    borderRadius: 5,
    overflow: "hidden",
    flexDirection: "row",
    display: "flex",
    width: 250,
    height: 40,
    justifyContent: "space-between",
    alignItems: "stretch",
    alignContent: "center",
  },
  selected: {
    backgroundColor: theme.palette.primary.main,
    color: "#ffffff",
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    fontSize: "16px",
  },
  unselected: {
    backgroundColor: "#ffffff",
    color: theme.palette.primary.main,
    display: "flex",
    flex: 1,
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "16px",
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  sticky: {
    height: "100%",
  },
}));

const initialFilterState: MatterTrackerTypes = {
  practiceArea: "",
  billingMethod: "",
};

const MatterTracker: React.FC<MatterTrackerProps> = () => {
  const {
    generalStates: {
      token,
      activeFirm: {
        firm: { _id },
      },
    },
    integrationConfigState: { pmType },
  } = useAuth();

  //Render States
  const [loading, setLoading] = useState<boolean>(true);
  const [open, setOpen] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [waitForCounts, setWaitForCounts] = useState<boolean>(false);
  const [tableLoading, setTableLoading] = useState<boolean>(true);
  const [jeopardySelected, setJeopardySelected] = useState<boolean>(true);
  const [toleranceSelected, setToleranceSelected] = useState<boolean>(false);
  const [plannedSelected, setPlannedSelected] = useState<boolean>(false);

  //Count States
  const [arCount, setArCount] = useState({
    jeopardyCount: 0,
    toleranceCount: 0,
    plannedCount: 0,
    total: 0,
  });
  const commonCellStyle = {
    color: "#858796",
  };
  const ppColumn = [
    {
      title: "Name",
      field: "name",
      cellStyle: { ...commonCellStyle, fontSize: 13 },
      type: "string",
    },
    {
      title: "Billing Method",
      field: "billing_method",
      type: "string",
      cellStyle: { ...commonCellStyle },
    },
    {
      title: "File open(days)",
      field: "days_file_open",
      cellStyle: { ...commonCellStyle, fontWeight: 600 },
      type: "numeric",
    },
    {
      title: "Open Date",
      field: "open_date",
      cellStyle: { ...commonCellStyle, fontSize: 12, fontWeight: 600 },
      render: (row) => (
        <span>
          {row["open_date"] !== null
            ? moment(row["open_date"])?.format("MM/DD/YY")
            : ""}
        </span>
      ),
      type: "string",
    },
  ];
  const clioColumn = [
    {
      title: "Name",
      field: "name",
      cellStyle: { ...commonCellStyle, fontSize: 13 },
      type: "string",
    },
    {
      title: "Billable",
      field: "billable",
      lookup: { 0: "No", 1: "Yes" },
      cellStyle: { ...commonCellStyle, fontWeight: 600 },
    },
    {
      title: "Billing Method",
      field: "billing_method",
      type: "string",
      cellStyle: { ...commonCellStyle },
    },
    {
      title: "File open(days)",
      field: "days_file_open",
      cellStyle: { ...commonCellStyle, fontWeight: 600 },
      type: "numeric",
    },
    {
      title: "Open Date",
      field: "open_date",
      cellStyle: { ...commonCellStyle, fontSize: 12, fontWeight: 600 },
      render: (row) => (
        <span>{moment(row["open_date"]).format("MM/DD/YY")}</span>
      ),
      type: "string",
    },
  ];

  //Table State

  const [table, setTable] = useState<TableResponseItem[] | []>([]);

  // *****
  // Current Values of Filters
  // *****

  const { filterRef, filterState, handleState } =
    useFilterState(initialFilterState);

  // *****
  // Previous Values of Filters
  // *****

  const prevFilterState = filterRef.current;

  // *****
  // Filter state updates
  // *****

  const handlePracticeAreas = (value: number | "") => {
    handleState.handlePracticeArea(value);
  };

  const handleBillingMethod = (value: string) => {
    handleState.handleBillingMethod(value);
  };

  // *****
  // Filter Submit
  // *****

  const handleFilterSubmit = (e: Event) => {
    if (
      filterState.practiceArea !== prevFilterState.practiceArea ||
      filterState.billingMethod !== prevFilterState.billingMethod
    ) {
      setLoading(true);
      setWaitForCounts(false);
      setTableLoading(true);
    }
  };

  // *****
  // Filter Clear
  // *****

  const handleFilterClear = () => {
    if (filterState.practiceArea === "" && filterState.billingMethod === "") {
      return;
    } else {
      handleState.reset(initialFilterState);
      setLoading(true);
      setWaitForCounts(false);
      setTableLoading(true);
    }
  };

  const classes = useStyles();

  //   *****
  //   Set Active Button Styles
  //   *****

  //   *****
  //   Button Selection Event Handlers
  //   *****

  const handleJeopardy = () => {
    if (jeopardySelected) return;
    setTableLoading(true);
    setJeopardySelected(true);
    setToleranceSelected(false);
    setPlannedSelected(false);
  };

  const handleTolerance = () => {
    if (toleranceSelected) {
      return;
    }
    setTableLoading(true);
    setToleranceSelected(true);
    setJeopardySelected(false);
    setPlannedSelected(false);
  };

  const handlePlanned = () => {
    if (plannedSelected) {
      return;
    }
    setTableLoading(true);
    setPlannedSelected(true);
    setToleranceSelected(false);
    setJeopardySelected(false);
  };

  // *****
  // Check for null values
  // *****

  function checkNull(value: number | null): number {
    if (value === null) {
      return 0;
    } else {
      return value;
    }
  }

  // *****
  // Set Table Header Active Color
  // *****

  function getTableHeader(): string {
    if (jeopardySelected) {
      return "Critical Matters";
    } else if (toleranceSelected) {
      return "Urgent Matters";
    } else if (plannedSelected) {
      return "Scheduled Matters";
    } else {
      return "";
    }
  }

  //   *****
  //   Matter Tracker Count
  //   *****

  useEffect(() => {
    let subscribe = true;
    if (loading) {
      let queryParams = {
        practice_area_id:
          filterState.practiceArea === "" ? null : filterState.practiceArea,
        billing_method:
          filterState.billingMethod === "" ? null : filterState.billingMethod,
      };
      let request = api.create(token);
      request
        .getMatterTrackerCountStaged(_id, pmType, queryParams)
        .then((response) => {
          if (subscribe) {
            let data: MatterCountResponse = response.data;
            setArCount({
              jeopardyCount: checkNull(data.jeopardyCount[0].count),
              toleranceCount: checkNull(data.toleranceCount[0].count),
              plannedCount: checkNull(data.plannedCount[0].count),
              total:
                checkNull(data.jeopardyCount[0].count) +
                checkNull(data.toleranceCount[0].count) +
                checkNull(data.plannedCount[0].count),
            });
            setWaitForCounts(true);
            setLoading(false);
          }
        })
        .catch((error) => {
          if (subscribe) {
            if (error.code === "ECONNABORTED") {
              setErrorMsg("The API request timed out. Please Refresh the page");
              setError(true);
            } else {
              setError(true);
              setErrorMsg(error.response.data.message);
            }
          }
        });
    }
    return () => {
      subscribe = false;
    };
  }, [loading]);

  // *****
  // Jeopardy Selected render
  // *****

  useEffect(() => {
    let subscribe = true;
    if (jeopardySelected && waitForCounts) {
      if (arCount.jeopardyCount > 0) {
        let request = api.create(token);
        let queryParams = {
          practice_area_id:
            filterState.practiceArea === "" ? null : filterState.practiceArea,
          billing_method:
            filterState.billingMethod === "" ? null : filterState.billingMethod,
          limit: arCount.jeopardyCount,
          offset: 0,
        };
        request
          .getMatterTrackerJeopardyDetailStaged(_id, pmType, queryParams)
          .then((response) => {
            if (subscribe) {
              let data: TableResponseItem[] | [] = response.data;
              setTable(data);
              setTableLoading(false);
            }
          })
          .catch((error) => {
            if (subscribe) {
              if (error.code === "ECONNABORTED") {
                setErrorMsg(
                  "The API request timed out. Please Refresh the page"
                );
                setError(true);
              } else {
                setError(true);
                setErrorMsg(error.response.data.message);
              }
            }
          });
      } else if (arCount.jeopardyCount === 0) {
        setTable([]);
        setTableLoading(false);
      }
    }
    return () => {
      subscribe = false;
    };
  }, [jeopardySelected, waitForCounts, arCount]);

  // *****
  // Tolerance Selected render
  // *****

  useEffect(() => {
    let subscribe = true;
    if (toleranceSelected && waitForCounts) {
      if (arCount.toleranceCount > 0) {
        let request = api.create(token);
        let queryParams = {
          practice_area_id:
            filterState.practiceArea === "" ? null : filterState.practiceArea,
          billing_method:
            filterState.billingMethod === "" ? null : filterState.billingMethod,
          limit: arCount.toleranceCount,
          offset: 0,
        };
        request
          .getMatterTrackerToleranceDetailStaged(_id, pmType, queryParams)
          .then((response) => {
            if (subscribe) {
              let data: TableResponseItem[] | [] = response.data;
              setTable(data);
              setTableLoading(false);
            }
          })
          .catch((error) => {
            if (subscribe) {
              if (error.code === "ECONNABORTED") {
                setErrorMsg(
                  "The API request timed out. Please Refresh the page"
                );
                setError(true);
              } else {
                setError(true);
                setErrorMsg(error.response.data.message);
              }
            }
          });
      } else if (arCount.toleranceCount === 0) {
        setTable([]);
        setTableLoading(false);
      }
    }
    return () => {
      subscribe = false;
    };
  }, [toleranceSelected, waitForCounts, arCount]);

  // *****
  // Planned Selected render
  // *****

  useEffect(() => {
    let subscribe = true;
    if (plannedSelected && waitForCounts) {
      if (arCount.plannedCount > 0) {
        let request = api.create(token);
        let queryParams = {
          practice_area_id:
            filterState.practiceArea === "" ? null : filterState.practiceArea,
          billing_method:
            filterState.billingMethod === "" ? null : filterState.billingMethod,
          limit: arCount.plannedCount,
          offset: 0,
        };
        request
          .getMatterTrackerPlannedDetailStaged(_id, pmType, queryParams)
          .then((response) => {
            if (subscribe) {
              let data: TableResponseItem[] | [] = response.data;
              setTable(data);
              setTableLoading(false);
            }
          })
          .catch((error) => {
            if (subscribe) {
              if (error.code === "ECONNABORTED") {
                setErrorMsg(
                  "The API request timed out. Please Refresh the page"
                );
                setError(true);
              } else {
                setError(true);
                setErrorMsg(error.response.data.message);
              }
            }
          });
      } else if (arCount.plannedCount === 0) {
        setTable([]);
        setTableLoading(false);
      }
    }
    return () => {
      subscribe = false;
    };
  }, [plannedSelected, waitForCounts, arCount]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
        <div className={classes.sticky}>
          <Paper className={classes.progressPaper}>
            <div className={classes.innerWrapper}>
              <span className={classes.buttonWrapper}>
                <a
                  onClick={handleJeopardy}
                  className={`${classes.newButtonLabel} ${
                    classes.hoverJeopardy
                  } ${jeopardySelected && classes.percentJeopardy}`}
                >
                  Critical Matters
                </a>
              </span>
              <ProgressBar
                value={PercentageRounded(arCount.jeopardyCount, arCount.total)}
                total={arCount.jeopardyCount}
                loading={loading}
                barColor={classes.barJeopardy}
                percentColor={classes.percentJeopardy}
              />
            </div>
            <div className={classes.innerWrapper}>
              <span className={classes.buttonWrapper}>
                <a
                  onClick={handleTolerance}
                  className={`${classes.newButtonLabel} ${
                    classes.hoverTolerance
                  } ${toleranceSelected && classes.percentTolerance}`}
                >
                  Urgent Matters
                </a>
              </span>
              <ProgressBar
                value={PercentageRounded(arCount.toleranceCount, arCount.total)}
                total={arCount.toleranceCount}
                barColor={classes.barTolerance}
                percentColor={classes.percentTolerance}
                loading={loading}
              />
            </div>
            <div className={classes.innerWrapper}>
              <span className={classes.buttonWrapper}>
                <a
                  onClick={handlePlanned}
                  className={`${classes.newButtonLabel} ${
                    classes.hoverPlanned
                  } ${plannedSelected && classes.percentPlanned}`}
                >
                  Scheduled Matters
                </a>
              </span>
              <ProgressBar
                value={PercentageRounded(arCount.plannedCount, arCount.total)}
                total={arCount.plannedCount}
                barColor={classes.barPlanned}
                percentColor={classes.percentPlanned}
                loading={loading}
              />
            </div>
          </Paper>
        </div>
      </Grid>
      <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
        <FilterUserTimePractice
          disabled={loading || tableLoading}
          filterState={filterState}
          dateFilterState={{}}
          handleBillingMethod={handleBillingMethod}
          handlePracticeAreas={handlePracticeAreas}
          handleFilterSubmit={handleFilterSubmit}
          handleFilterClear={handleFilterClear}
          handleFromComponent={open}
          setCloseFromComponent={() => setOpen(false)}
        />
        <MatTable
          data={table}
          column={pmType == "clio" ? clioColumn : ppColumn}
          isLoading={tableLoading || loading}
          search
          title={getTableHeader()}
          actions={[
            {
              icon: () => <InfoIcon />,
              isFreeAction: true,
              tooltip:
                "Track activity with a prioritized view of all open matters, monthly trends and easily identify which matters need immediate attention. ",
            },
            {
              icon: () => <FilterListIcon />,
              isFreeAction: true,
              onClick: (event, rowData) => {
                setOpen(!open);
              },
              tooltip: "Filters",
            },
          ]}
        />
      </Grid>
      {error ? <ErrorSnackBar errorMsg={errorMsg} /> : null}
    </Grid>
  );
};

export default MatterTracker;
