import { generalStates } from "../reducers/generalReducers";
import { onboardingState } from "../reducers/onboardingReducers";
import { authorizationCodeState } from "../reducers/authorizationCodeReducer";
import { currentAppState } from "../reducers/currentAppStateReducer";
import { integrationConfigState } from "../reducers/integrationConfigReducer";

export const initialState = {
  generalStates,
  onboardingState,
  authorizationCodeState,
  currentAppState,
  integrationConfigState
};
