import React, { useState, useEffect } from "react";
import { CssBaseline, Grid } from "@material-ui/core";
import images from "../images";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Carousel from "../components/commons/carousel";
import useAuth from "../hooks/useAuth";

interface ILayoutProps {
  children: React.ReactNode;
}
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: "100vh",
    background: theme.palette.background.default,
    alignItems: "center",
    overflow: "hidden",
    [theme.breakpoints.down("md")]: {
      height: "unset",
      padding: theme.spacing(4),
    },
  },
  gradient: {
    position: "relative",
    height: "100%",
    width: "100%",
    display: "flex",
    background: `linear-gradient(45deg, ${theme.palette.primary.main} , ${theme.palette.primary.light} )`,
    overflow: "hidden",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  hide: {
    display: "none",
  },
  circle: {
    position: "absolute",
    bottom: -100,
    left: -100,
  },
  pie: {
    position: "absolute",
    bottom: -50,
    right: -50,
  },
  outlined: {
    position: "absolute",
    top: -200,
    right: -200,
  },
  box: {
    position: "absolute",
    top: 50,
    left: 50,
  },
}));

const Layout: React.FunctionComponent<ILayoutProps> = ({ children }) => {
  const classes = useStyles();
  const [check, setCheck] = useState<boolean>(true);
  const {
    generalStates: { clientID },
    generalActions,
  } = useAuth();

  // *****
  // Set random client ID if none exists
  // *****

  useEffect(() => {
    if (check) {
      if (clientID === null || clientID === "" || clientID.length !== 8) {
        let random = "_" + Math.random().toString(36).substr(2, 7);
        generalActions.setClientID(random);
      }
      setCheck(false);
    }
  }, [check]);
  return (
    <Grid className={classes.root} container spacing={0}>
      <CssBaseline />
      <Grid className={classes.gradient} item lg={7} xl={7}>
        <Carousel />
        <img className={classes.pie} src={images.pie} />
        <img className={classes.box} src={images.boxesBox} />
        <img className={classes.outlined} src={images.outlinedCircle} />
        <img className={classes.circle} src={images.circle} />
      </Grid>
      {children}
    </Grid>
  );
};

export default Layout;
