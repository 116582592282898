import React, { useState, useEffect } from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import api from "../../../services";
import MatTable from "../../dashboard/charts/table";
import moment from "moment";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    accordianDetail: {
      display: "block",
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  })
);

export default function XeroErrorLog({ firmId, token }) {
  const classes = useStyles();
  const [loader, setLoader] = useState<boolean>(true);
  const [params, setParams] = useState<any>({
    limit: 10,
    offset: 0,
  });
  const [logData, setLogData] = useState<any[]>([]);
  useEffect(() => {
    let subscribe = true;
    let request = api.create(token);
    request
      .xeroErrorLogs(firmId)
      .then((res) => {
        if (subscribe) {
          setLogData(res.data);
          setLoader(false);
        }
      })
      .catch((res) => {
        if (subscribe) {
          setLoader(false);
        }
      });
    return () => {
      subscribe = false;
    };
  }, [loader]);
  return (
    <div className={classes.root}>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>Xero Logs</Typography>
        </AccordionSummary>
        <AccordionDetails classes={{ root: classes.accordianDetail }}>
          <MatTable
            isLoading={loader}
            search
            column={[
              {
                title: "Created At",
                field: "createdAt",

                render: (row) => (
                  <span>
                    {moment(row["created_at"]).format(
                      "MMMM Do YYYY, h:mm:ss a"
                    )}
                  </span>
                ),
                type: "date",
              },
              {
                title: "Integration Type",
                field: "integrationType",
              },
              {
                title: "Entity Name",
                field: "entityName",
              },
              {
                title: "Sync Error",
                field: "syncError",
              },
              {
                title: "Records Inserted",
                field: "recordsInserted",
                type: "numeric",
              },
            ]}
            data={logData}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
