import React, { useContext, useEffect, useState } from "react";
import MaterialTable from "material-table";
import api from "../../../services";
import ErrorSnackBar from "../../dashboard/commons/SnackBar";
import useAuth from "../../../hooks/useAuth";
export default function Users() {
  const {
    generalStates: { token, activeFirm },
  } = useAuth();
  const [loading, setLoading] = useState<boolean>(true);
  const [updated, setUpdated] = useState<boolean>(false);
  const [apiError, setApiError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [count, setCount] = useState<number>(0);

  let firm = activeFirm.firm._id;

  const [users, setUsers] = useState<any[]>([]);
  useEffect(() => {
    let subscribe = true;
    if (subscribe) {
      let request = api.create(token);
      request
        .getXeroAccounts(firm)
        .then((res) => {
          setUsers(res.data);
          let temp = res.data;
          let categorized = temp.reduce((acc, item) => {
            if (
              item.AccountCategorization !== "" ||
              item.AccountCategorization !== null
            ) {
              acc = acc + 1;
              return acc;
            } else {
              return acc;
            }
          }, 0);
          setCount(categorized);
          setLoading(false);
        })
        .catch((error) => {
          if (error.code === "ECONNABORTED") {
            setLoading(false);
            setErrorMsg("The API request timed out. Please Refresh the page");
            setApiError(true);
          } else {
            setLoading(false);
            setErrorMsg(error.response.data.message);
            setApiError(true);
          }
        });
    }

    return () => {
      subscribe = false;
    };
  }, [updated]);
  const columns: any = [
    { title: "Name", field: "Name", editable: "never" },
    {
      title: "Code",
      field: "Code",
      editable: "never",
      type: "numeric",
    },
    {
      title: "Status",
      field: "Status",
      editable: "never",
    },
    {
      title: "Type",
      field: "Type",
      editable: "never",
    },
    {
      title: "Class",
      field: "Class",
      editable: "never",
    },

    {
      title: "Account Category",
      field: "AccountCategorization",
      lookup: {
        "Operating Account": "Operating Account",
        "Trust Account": "Trust Account",
        "Client Expense": "Client Expense",
        "Marketing Expense": "Marketing Expense",
        "Wages and Salaries": "Wages and Salaries",
        Draw: "Draw",
        None: "None",
      },
    },
  ];
  return (
    <React.Fragment>
      <MaterialTable
        style={{ padding: 16 }}
        title={
          count === users.length
            ? "Accounts Setup"
            : `Accounts Setup (${count} / ${users.length} assigned)`
        }
        isLoading={loading}
        columns={columns}
        data={users}
        options={{
          pageSize: 20,
          headerStyle: { position: "sticky", top: 0 },
          maxBodyHeight: "650px",
        }}
        editable={{
          onRowUpdate:
            activeFirm.roleType !== "USER"
              ? (newData, oldData) =>
                  new Promise((resolve) => {
                    let request = api.create(token);
                    let queryParams = {
                      id: newData.AccountID,
                      AccountCategory:
                        newData.AccountCategorization === "None"
                          ? null
                          : newData.AccountCategorization,
                    };
                    request
                      .addAccountCategory(firm, queryParams)
                      .then((res) => {
                        setUpdated(!updated);
                        setLoading(true);
                      })
                      .catch((error) => {
                        if (error.code === "ECONNABORTED") {
                          setLoading(false);
                          setErrorMsg(
                            "The API request timed out. Please Refresh the page"
                          );
                          setApiError(true);
                        } else {
                          setLoading(false);
                          setErrorMsg(error.response.data.message);
                          setApiError(true);
                        }
                      });
                    // let options = [
                    //   "Bank",
                    //   "Client Expense",
                    //   "Marketing Expense",
                    //   "Wages and Salaries",
                    //   "Draw",
                    // ];
                    // setTimeout(() => {
                    //   users.map((user) =>
                    //     request
                    //       .addAccountCategory(
                    //         firm,
                    //         { id: user.AccountID },
                    //         {
                    //           AccountCategory: options[
                    //             Math.floor(Math.random() * options.length)
                    //           ].toString(),
                    //         }
                    //       )
                    //       .then((res) => {
                    //         // setUpdated(!updated);
                    //         // setLoading(true);
                    //       })
                    //       .catch((error) => {
                    //         // alert(error.response.message);
                    //       })
                    //   );
                    // }, 1000);

                    resolve(request);
                  })
              : undefined,
        }}
      />
      {apiError && <ErrorSnackBar errorMsg={errorMsg} />}
    </React.Fragment>
  );
}
