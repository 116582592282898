import { Grid, IconButton, makeStyles, Paper, Box } from "@material-ui/core";
import React, { useEffect, useState, useContext } from "react";
import api from "../../../../services";
import {
  StyledToggleButtonGroup,
  StyledToggleButton,
} from "../../tabs/styledTabs";
import { WindowContext } from "../../../../window/trackWindow";
import ErrorSnackBar from "../../commons/SnackBar";
import images from "../../../../images";
import InfoButton from "../../infoButton";
import { useHistory } from "react-router";

interface IBigWidgetProps {
  token: any;
  firmId: string;
  pmType: string;
}
const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3),
    height: 350,
    flex: 1,
  },
  value: {
    fontSize: theme.typography.pxToRem(26),
    fontWeight: "bold",
    color: theme.palette.secondary.dark,
    margin: 0,
  },
  title: {
    margin: 0,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.secondary.dark,
  },
  inner: {
    margin: theme.spacing(2),
    paddingTop: theme.spacing(1),
  },
}));
const ClientsMatters: React.FunctionComponent<IBigWidgetProps> = ({
  token,
  firmId,
  pmType,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [loader, setLoader] = useState<boolean>(true);
  const [active, setActive] = useState<number>(0);
  const [error, setError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [data, setData] = useState<any>([
    {
      YTD: 0,
      current_month: 0,
      pre_year: 0,
    },
    {
      YTD: 0,
      current_month: 0,
      pre_year: 0,
    },
  ]);
  const window = useContext(WindowContext);

  useEffect(() => {
    let subscribe = true;
    if (loader) {
      let request = api.create(token);
      Promise.all([
        request.getClientsDashboard(firmId, pmType),
        request.getMattersDashboard(firmId, pmType),
      ])
        .then((res) => {
          if (subscribe) {
            let [clients, matters] = [res[0].data, res[1].data];
            setData([
              {
                YTD: clients.YTD,
                current_month: clients.current_month,
                pre_year: clients.pre_year,
              },
              {
                YTD: matters.YTD,
                current_month: matters.current_month,
                pre_year: matters.pre_year,
              },
            ]);
            setLoader(false);
          }
        })
        .catch((error) => {
          if (subscribe) {
            if (error.code === "ECONNABORTED") {
              setErrorMsg("The API request timed out. Please Refresh the page");
              setError(true);
            } else {
              setErrorMsg(error.response.data.message);
              setError(true);
            }
          }
        });
    }
    return () => {
      subscribe = false;
    };
  }, [loader]);

  const handleTabChange = (event, value) => {
    if (value !== null) {
      setActive(value);
    }
  };

  return (
    <Grid item xs={12} md>
      <Paper square className={classes.container}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <StyledToggleButtonGroup
            onChange={handleTabChange}
            exclusive
            value={active}
            size="small"
            style={{ fontSize: window < 600 ? 11 : 13 }}
          >
            <StyledToggleButton key={1} value={0}>
              Clients
            </StyledToggleButton>
            <StyledToggleButton key={2} value={1}>
              Matters
            </StyledToggleButton>
          </StyledToggleButtonGroup>{" "}
          <span>
            <InfoButton
              title={
                "Toggle between clients and matters to show the followingvalues.  Displays the Clients/ Mattersadded year to date. Clients/ Matters added in thecurrent month. Clients/ Matters added in theprevious year."
              }
            />
            <IconButton
              onClick={() =>
                history.push(
                  active == 0 ? "/dashboard/clients" : "/dashboard/matters"
                )
              }
              style={{ padding: 0, margin: 0 }}
            >
              <img src={images.linkIcon} />
            </IconButton>
          </span>
        </div>

        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          className={classes.inner}
        >
          <span style={{ marginRight: 20 }}>
            <img src={images.ytd} alt="Year to Date" />
          </span>
          <span>
            <p className={classes.value}>{data[active].YTD}</p>
            <p className={classes.title}>YTD</p>
          </span>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          className={classes.inner}
        >
          <span style={{ marginRight: 20 }}>
            <img src={images.currentMonth} alt="Current Month" />
          </span>
          <span>
            <p className={classes.value}>{data[active].current_month}</p>
            <p className={classes.title}>Current Month</p>
          </span>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          className={classes.inner}
        >
          <span style={{ marginRight: 20 }}>
            <img src={images.previousYear} alt="Previous Year" />
          </span>
          <span>
            <p className={classes.value}>{data[active].pre_year}</p>
            <p className={classes.title}>Previous Year</p>
          </span>
        </Box>
      </Paper>
      {error ? <ErrorSnackBar errorMsg={errorMsg} /> : null}
    </Grid>
  );
};

export default ClientsMatters;
