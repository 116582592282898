import { MattersDistributionState, Action } from "./types";

const initialState: MattersDistributionState = {
  loading: false,
  response: {
    status: false,
    activeMatters: [],
    closedMatters: [],
    dormantMatters: [],
    months: []
  },
  error: {
    status: false,
    message: ""
  }
}

export default function reducer(
  state: MattersDistributionState = initialState,
  action: Action
) {
  switch (action.type) {
    case "SET_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "SET_RESPONSE":
      return {
        ...state,
        loading: false,
        response: {
          status: true,
          activeMatters: action.activeMatters,
          closedMatters: action.closedMatters,
          dormantMatters: action.dormantMatters,
          months: action.months,
        },
        error: { status: false, message: "" },
      };

    case "SET_ERROR":
      return {
        ...state,
        loading: true,
        error: { status: true, message: action.message },
      };
    default:
      return state;
  }
}
