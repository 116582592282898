import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import TimelineIcon from "@material-ui/icons/Timeline";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    justifySelf: "center",
    minHeight: 400,
  },
  textWrapper: {
    alignItems: "center",
    display: "block",
    marginTop: theme.spacing(1),
  },
  headingText: {
    color: theme.palette.primary.light,
    fontSize: "1.4em",
    fontWeight: 700,
    marginBottom: theme.spacing(1) / 2,
    display: "block",
    alignSelf: "center",
  },
  subheadingText: {
    color: theme.palette.primary.light,
    fontSize: "1em",
    fontWeight: 600,
    display: "block",
    alignSelf: "center",
  },
  icon: {
    color: theme.palette.primary.main,
  },
  height: {
    minHeight: 600,
  },
  small: {
    minHeight: 300,
  },
}));

export interface EmptyPlaceholderProps {
  text?: string;
  height?: boolean;
  small?: boolean;
}

const EmptyPlaceholder: React.FC<EmptyPlaceholderProps> = ({
  height,
  text,
  small,
}) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <div
        className={
          height
            ? `${classes.wrapper} ${classes.height}`
            : small
            ? `${classes.wrapper} ${classes.small}`
            : classes.wrapper
        }
      >
        <TimelineIcon fontSize="large" className={classes.icon} />
        <span className={classes.textWrapper}>
          <span className={classes.headingText}>{text ? text : "No Data"}</span>
          {!small && (
            <span className={classes.subheadingText}>
              Filtered result is empty.
            </span>
          )}
        </span>
      </div>
    </React.Fragment>
  );
};

export default EmptyPlaceholder;
