import React, { useEffect, useState } from "react";
import { makeStyles, Paper } from "@material-ui/core";
import Chart from "../../charts/fusionCharts";
import api from "../../../../services";
import EmptyPlaceholder from "../../commons/placeholders/emptyPlaceholder";
import { Skeleton } from "@material-ui/lab";
import InfoButton from "../../infoButton";
import _ from "lodash";
const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3),
  },
}));
export default function ChartGrid({ token, firmId, pmType }) {
  const classes = useStyles();
  const [loader, setLoader] = useState<boolean>(true);
  const [data, setData] = useState<any>({ AR: [], revenue: [], billing: [] });
  const [years, setYears] = useState<any>([]);

  function orderList(array, order) {
    let sortedArray = order.map((i) =>
      array.find((j) => j.year == i) === undefined
        ? { year: i, total: 0 }
        : array.find((j) => j.year == i)
    );
    return sortedArray;
  }
  useEffect(() => {
    let subscribe = true;
    if (loader) {
      let request = api.create(token);
      request.getBillingRevenueARDashboard(firmId, pmType).then((res) => {
        if (subscribe) {
          let { AR, billing, revenue } = res.data;
          let arYears = AR.length > 0 ? AR.map((item) => item.year) : [];
          let revenueYears =
            revenue.length > 0 ? revenue.map((item) => item.year) : [];
          let billingYears =
            billing.length > 0 ? billing.map((item) => item.year) : [];
          let chars = _.uniq([...arYears, ...revenueYears, ...billingYears]);

          let arData = orderList(AR, chars.sort()).map((item) => ({
            value: item.total,
          }));
          let billingData = orderList(billing, chars.sort()).map((item) => ({
            value: item.total,
          }));
          let revenueData = orderList(revenue, chars.sort()).map((item) => ({
            value: item.total,
          }));
          setData({ AR: arData, billing: billingData, revenue: revenueData });
          setYears(chars.sort().map((item) => ({ label: `${item}` })));
          setLoader(false);
        }
      });
    }
  }, [loader]);

  const seriesColumn1 = [
    {
      seriesname: "Invoices",
      data: data.billing,
    },
    {
      seriesname: "Revenue",
      data: data.revenue,
    },
    {
      seriesname: "AR",
      data: data.AR,
    },
  ];
  const config = {
    numberprefix: "$",
  };
  return (
    <Paper square className={classes.container}>
      {loader ? (
        <Skeleton variant="rect" height={300} />
      ) : data.AR.length > 0 ||
        data.revenue.lenght > 0 ||
        data.billing.length > 0 ? (
        <>
          <span style={{ display: "flex", justifyContent: "space-between" }}>
            <div />
            <InfoButton
              title={
                "Value of accounts receivable, collected time, andbilled time out of the practice management system."
              }
            />
          </span>
          <Chart
            series={seriesColumn1}
            labels={years}
            type={"scrollcombi2d"}
            height={250}
            config={config}
          />
        </>
      ) : (
        <EmptyPlaceholder small />
      )}
    </Paper>
  );
}
