import { useState } from "react";
import Steps from "./Steps";
import {
  Grid,
  Dialog,
  Paper,
  MobileStepper,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Popup from "../../commons/popup";
import { useHistory } from "react-router-dom";
import { data } from "./static";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import CloseIcon from "@material-ui/icons/Close";
import useAuth from "../../../hooks/useAuth";
const useStyles = makeStyles(theme => ({
  paper: {
    backgroundColor: theme.palette.background.default,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  tutorialContainer: {
    padding: theme.spacing(2),
  },
  stepper: {
    backgroundColor: "transparent",
    color: "#fff",
    padding: 0,
    marginTop: theme.spacing(8),
  },
  dot: {
    backgroundColor: theme.palette.primary.dark,
    margin: theme.spacing(1 / 2),
  },
  dotActive: {
    backgroundColor: theme.palette.primary.light,
  },
}));

export default function OnBoarding() {
  const classes = useStyles();
  const {
    generalStates: { activeFirm },
  } = useAuth();
  const history = useHistory();
  const [open, setOpen] = useState<boolean>(false);
  const [activeStep, setActiveStep] = useState<number>(0);
  const handleRedirect = () => {
    history.push("/dashboard/settings/integration");
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
  };
  const maxSteps = data.length;
  return (
    <Grid container spacing={2} className={classes.paper}>
      <Grid item xs={4} sm={4} lg={4}>
        {!activeFirm ? (
          <Popup
            title={`Do you want to create another firm?`}
            warning={true}
            open={open}
            buttonText={"Proceed to Integrations"}
            handleClick={handleRedirect}
            handleClose={handleClose}
            cancelText={`Create new firm`}
            body={`If you want to add an integration to your current firm, select "Proceed to Integrations otherwise if you want to add another firm, click "x" icon.`}
          />
        ) : (
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <Paper className={classes.tutorialContainer}>
              <IconButton
                onClick={() => setOpen(false)}
                style={{ position: "absolute", top: 0, right: 0 }}
              >
                <CloseIcon />
              </IconButton>
              <div>
                <h1>{data[activeStep].title}</h1>
                <ol>
                  {data[activeStep].points.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ol>
                <img
                  width={500}
                  height={500}
                  src={"https://i.ibb.co/JjvsvJ6/sc.png"}
                />
              </div>

              <MobileStepper
                steps={maxSteps}
                position="static"
                variant="dots"
                classes={{
                  root: classes.stepper,
                  dot: classes.dot,
                  dotActive: classes.dotActive,
                }}
                activeStep={activeStep}
                nextButton={
                  <IconButton
                    size="small"
                    onClick={() => setActiveStep(activeStep + 1)}
                    disabled={activeStep === maxSteps - 1}
                  >
                    <KeyboardArrowRight />
                  </IconButton>
                }
                backButton={
                  <IconButton
                    size="small"
                    onClick={() => {
                      activeStep > 0 && setActiveStep(activeStep - 1);
                    }}
                  >
                    <KeyboardArrowLeft />
                  </IconButton>
                }
              />
            </Paper>
          </Dialog>
        )}

        <Steps />
      </Grid>
    </Grid>
  );
}
