import { useState, useCallback, useRef, useEffect } from "react";
import { yearlyRanges } from "../commons/dateRangePicker/static";
import { FilterStateType } from "./types";

// *****
// Custom Hook to manage filter state - Possession of state with hook
// *****

const useFilterState = (initialState: FilterStateType) => {
  const [filterState, setFilterState] = useState(initialState);
  const filterRef = useRef(initialState);

  useEffect(() => {
    filterRef.current = filterState;
  }, [filterState]);

  // *****
  // Memoized Callback
  // *****

  const memoizedReducer = useCallback(
    (state, setState) => {
      return stateReducer(state, setState);
    },
    [setFilterState]
  );

  const handleState = memoizedReducer(filterState, setFilterState);
  return { filterRef, filterState, handleState };
};

// *****
// Reducer-esque implementation bypassing computationally expensive switch cases
// *****

const stateReducer = (state, setState) => {
  const handlePracticeArea = (value: number | "") => {
    if (typeof state.practiceArea === "undefined") return;
    setState({
      ...state,
      practiceArea: value,
    });
  };
  
  const handleBillingMethod = (value: string | "") => {
    if (typeof state.billingMethod === "undefined") return;
    setState({
      ...state,
      billingMethod: value,
    });
  };
  const handleUser = (value: number | "") => {
    if (typeof state.user === "undefined") return;
    setState({
      ...state,
      user: value,
    });
  };
  const handleOriginatingAtt = (value: number | "") => {
    if (typeof state.originatingAtt === "undefined") return;
    setState({
      ...state,
      originatingAtt: value,
    });
  };
  const handleResponsibleAtt = (value: number | "") => {
    if (typeof state.responsibleAtt === "undefined") return;
    setState({
      ...state,
      responsibleAtt: value,
    });
  };
  const handleDate = (value: Date | null) => {
    if (typeof state.date === "undefined") return;
    setState({
      ...state,
      date: value,
    });
  };
  const handleYearRange = (value: string) => {
    if (typeof state.year === "undefined") return;
    // console.log(yearlyRanges.find((x) => x.label === value));
    setState({
      ...state,
      year: yearlyRanges.find((x) => x.label === value),
    });
  };
  const handleStatus = (value: string | "") => {
    if (typeof state.status === "undefined") return;
    setState({
      ...state,
      status: value,
    });
  };
  const reset = (initialState: FilterStateType) => {
    setState(initialState);
  };
  return {
    handlePracticeArea,
    handleBillingMethod,
    handleOriginatingAtt,
    handleResponsibleAtt,
    handleUser,
    handleDate,
    handleStatus,
    handleYearRange,
    reset,
  };
};

export default useFilterState;
