import React, { useContext, useEffect, useState } from "react";
import ErrorSnackBar from "../../../dashboard/commons/SnackBar";
import { makeStyles, Theme } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Alert, AlertTitle } from "@material-ui/lab";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import api from "../../../../services/index";
import { InputLabel } from "@material-ui/core";
import { StyledInput } from "../stripe/styledInput";
import useAuth from "../../../../hooks/useAuth";
export interface AdminUpdatesProps {
  type: string;
  subscriptionObject: any;
}

interface UsersStateType {
  action: "UPDATE_FREE_USERS";
  freeUsers: number | string;
}

interface ForceStateType {
  action: "FORCE_BLOCK";
}
interface FreeStateType {
  action: "FREE_FIRM";
  freeFirm: boolean;
}

interface DaysStateType {
  action: "UPDATE_FREE_DAYS";
  freeDays: number | string;
}

interface EmptyStateType {
  action: "EMPTY";
}

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  titleText: {
    display: "block",
    fontSize: "1.3rem",
    fontWeight: 600,
    fontColor: theme.palette.text.primary,
    [theme.breakpoints.down("md")]: {
      fontSize: "1.2rem",
    },
  },
  subheadingText: {
    display: "block",
    fontSize: "1rem",
    fontWeight: 500,
    fontColor: theme.palette.text.secondary,
    marginTop: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      fontSize: "0.9rem",
    },
  },
  button: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.secondary.main,
    color: "#ffff",
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
    },
  },
  buttonForce: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(10),
    marginTop: theme.spacing(2),
    paddingRight: theme.spacing(10),
    backgroundColor: theme.palette.error.main,
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.palette.error.light,
    },
  },
  blockText: {
    display: "block",
    fontSize: "1rem",
    fontWeight: 500,
    color: theme.palette.text.secondary,
    [theme.breakpoints.down("md")]: {
      fontSize: "0.9rem",
    },
  },
  forceTitle: {
    display: "block",
    marginBottom: theme.spacing(1) / 2,
    fontSize: "1.1rem",
    fontWeight: 600,
    color: theme.palette.text.secondary,
    [theme.breakpoints.down("md")]: {
      fontSize: "1rem",
    },
  },
  alertGrid: {
    display: "flex",
    alignItems: "flex-end",
  },
  circularProgress: {
    color: "#ffff",
    backgroundColor: "#fff",
  },
}));

// Integration Type

const AdminUpdates: React.FC<AdminUpdatesProps> = ({
  type,
  subscriptionObject,
}) => {
  const classes = useStyles();
  const [error, setError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  //Set success message alert
  const [success, setSuccess] = useState<boolean>(false);
  const [successMsg, setSuccessMsg] = useState<string>("");
  // Track button loader according to type
  const [updateType, setUpdateType] = useState<number>(0);
  // invoke getSubscription API with this state
  const [updated, setUpdated] = useState<boolean>(false);
  // Set buttons disabled
  const [loading, setLoading] = useState<boolean>(false);
  const {
    generalStates: { token, activeFirm },
    generalActions,
  } = useAuth();
  const integrationType = type && type.toUpperCase();

  const defaultFreeUsers =
    subscriptionObject &&
    subscriptionObject.billingSubscriptionDetails.freeUsers
      ? subscriptionObject.billingSubscriptionDetails.freeUsers
      : 0;
  const defaultFreeDays = subscriptionObject && subscriptionObject.daysToExpire;
  const isFree =
    subscriptionObject && subscriptionObject.billingSubscriptionDetails.isFree;
  const firmId = activeFirm.firm._id;

  const [bodyObj, setBodyObj] = useState<
    | UsersStateType
    | DaysStateType
    | ForceStateType
    | EmptyStateType
    | FreeStateType
  >({ action: "EMPTY" });

  //   *****
  //   Input States
  //   *****

  const [freeDays, setFreeDays] = useState<number | string>(0);
  const [freeUsers, setFreeUsers] = useState<number | string>(0);

  //   *****
  //   Button Handles
  //   *****

  const handleUsersSubmit = (event) => {
    setLoading(true);
    setUpdateType(1);
    setBodyObj({
      action: "UPDATE_FREE_USERS",
      freeUsers: freeUsers,
    });
  };

  const handleDaysSubmit = (event) => {
    setLoading(true);
    setUpdateType(2);
    setBodyObj({
      action: "UPDATE_FREE_DAYS",
      freeDays: freeDays,
    });
  };

  const handleForceSubmit = (event) => {
    setLoading(true);
    setUpdateType(3);
    setBodyObj({
      action: "FORCE_BLOCK",
    });
  };
  const handleFreeSubmit = (event) => {
    setLoading(true);
    setUpdateType(3);
    setBodyObj({
      action: "FREE_FIRM",
      freeFirm: !isFree,
    });
  };

  //   *****
  //   Update App state with payment info after an admin action
  //   *****

  useEffect(() => {
    let subscribe = true;
    if (updated) {
      let request = api.create(token);
      request
        .getStripeAllSubscription(firmId)
        .then((response) => {
          if (subscribe) {
            const { XERO, CLIO, PP } = response.data;
            generalActions.setPaymentInfo({
              subscribedClio: CLIO,
              subscribedXero: XERO,
              subscribedPP: PP,
            });
            setUpdated(false);
          }
        })
        .catch((error) => {
          if (subscribe) {
            generalActions.setPaymentInfo({
              subscribedClio: null,
              subscribedXero: null,
              subscribedPP: null,
            });
            if (error.code === "ECONNABORTED") {
              setErrorMsg("The API request timed out. Please Refresh the page");
              setError(true);
            } else {
              setErrorMsg(error.response.data.message);
              setError(true);
            }
          }
        });
    }
    return () => {
      subscribe = false;
    };
  }, [updated]);

  //   *****
  //   Call API on an admin action
  //   *****

  useEffect(() => {
    let subscribe = true;
    if (!updated && bodyObj.action !== "EMPTY") {
      let request = api.create(token);
      request
        .postAdminActions(firmId, integrationType, bodyObj)
        .then((response) => {
          if (subscribe) {
            let data = response.data;
            setSuccess(true);
            setSuccessMsg(data.msg);
            //Set buttons: enabled
            setLoading(false);
            //Invoke getSubscription Api
            setUpdated(true);
          }
        })
        .catch((error) => {
          if (subscribe) {
            if (error.code === "ECONNABORTED") {
              setErrorMsg("The API request timed out. Please Refresh the page");
              setError(true);
            } else {
              setErrorMsg(error.response.data.message);
              setError(true);
            }
            setLoading(false);
            //Reset Input States
            if (bodyObj.action === "UPDATE_FREE_DAYS") {
              setFreeDays(0);
            } else if (bodyObj.action === "UPDATE_FREE_USERS") {
              setFreeUsers(0);
            }
          }
        });
    }
    return () => {
      subscribe = false;
    };
  }, [bodyObj]);

  return (
    <Paper className={classes.paper}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <span className={classes.titleText}>Admin Actions</span>
          <span className={classes.subheadingText}>
            Block a firm, update free users and update free trial days
          </span>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <InputLabel>Free Users</InputLabel>
          <StyledInput
            inputProps={{
              type: "number",
              min: 0,
            }}
            fullWidth
            defaultValue={defaultFreeUsers}
            onChange={(event) => {
              setFreeUsers(event.target.value);
            }}
          />
          <Button
            className={classes.button}
            fullWidth
            onClick={handleUsersSubmit}
            disabled={loading}
          >
            {updateType === 1 && loading ? (
              <CircularProgress
                size={24}
                classes={{ circle: classes.circularProgress }}
              />
            ) : (
              "Update Free Users"
            )}
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <InputLabel>Free Days</InputLabel>
          <StyledInput
            inputProps={{
              type: "number",
              min: 0,
            }}
            fullWidth
            defaultValue={defaultFreeDays}
            onChange={(event) => {
              setFreeDays(event.target.value);
            }}
          />
          <Button
            className={classes.button}
            fullWidth
            onClick={handleDaysSubmit}
            disabled={loading}
          >
            {updateType === 2 && loading ? (
              <CircularProgress
                size={24}
                classes={{ circle: classes.circularProgress }}
              />
            ) : (
              "Update Free Days"
            )}
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <span className={classes.forceTitle}>Force Block</span>
          <span className={classes.blockText}>
            Force block will permanently block the firm
          </span>
          <Button
            className={classes.buttonForce}
            fullWidth
            onClick={handleForceSubmit}
            disabled={loading}
          >
            {updateType === 3 && loading ? (
              <CircularProgress
                size={24}
                classes={{ circle: classes.circularProgress }}
              />
            ) : (
              "Force Block"
            )}
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <span className={classes.forceTitle}>
            {isFree ? "Remove Free Firm" : "Free Firm"}
          </span>
          <span className={classes.blockText}>
            {isFree
              ? "This will ask the user to pay for the firm"
              : "This will set the firm to be free of cost"}
          </span>
          <Button
            className={classes.button}
            fullWidth
            onClick={handleFreeSubmit}
            disabled={loading}
          >
            {updateType === 3 && loading ? (
              <CircularProgress
                size={24}
                classes={{ circle: classes.circularProgress }}
              />
            ) : isFree ? (
              "Remove Free Firm"
            ) : (
              "Free Firm"
            )}
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          className={classes.alertGrid}
        >
          {success && (
            <Alert
              severity="success"
              onClose={() => {
                setSuccess(false);
              }}
            >
              <AlertTitle>Success</AlertTitle>
              {successMsg}
            </Alert>
          )}
        </Grid>
        {error ? <ErrorSnackBar errorMsg={errorMsg} /> : null}
      </Grid>
    </Paper>
  );
};

export default AdminUpdates;
