import React, { ReactElement, useState, useEffect } from "react";
import MaterialTable from "material-table";
import api from "../../../services";
import useAuth from "../../../hooks/useAuth";
import ErrorSnackBar from "../../dashboard/commons/SnackBar";
import InfoIcon from "@material-ui/icons/Info";
import MatTable from "../../dashboard/charts/table";
import InfoButton from "../../dashboard/infoButton";

interface Props {}

export default function MatterTrackerSetup({}: Props): ReactElement {
  const {
    generalStates: {
      token,
      activeFirm: {
        firm: { _id: firm },
        roleType,
      },
    },
    integrationConfigState: { pmType },
  } = useAuth();
  const [loading, setLoading] = useState(true);
  const [apiError, setApiError] = useState<boolean>(false);
  const [updated, setUpdated] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [response, setRespone] = useState<any[]>([]);

  useEffect(() => {
    let subscribe = true;
    let request = api.create(token);
    request
      .getMatterTrackerSetup(firm, pmType)
      .then((res) => {
        if (subscribe) {
          setRespone(res.data);
          setLoading(false);
        }
      })
      .catch((error) => {
        if (subscribe) {
          if (error.code === "ECONNABORTED") {
            setLoading(false);
            setErrorMsg("The API request timed out. Please Refresh the page");
            setApiError(true);
          } else {
            setLoading(false);
            setErrorMsg(error.response.data.message);
            setApiError(true);
          }
        }
      });

    return () => {
      subscribe = false;
    };
  }, [updated]);

  function returnTruth(rowData) {
    return pmType == "clio"
      ? [
          rowData.calender_entry == 0 ? false : true,
          rowData.bills == 0 ? false : true,
          rowData.tasks == 0 ? false : true,
          rowData.activity == 0 ? false : true,
        ].filter(Boolean).length >= 2
      : [
          rowData.events == 0 ? false : true,
          rowData.invoices == 0 ? false : true,
          rowData.tasks == 0 ? false : true,
          rowData.timeEntries == 0 ? false : true,
        ].filter(Boolean).length >= 2;
  }

  const clioColumns: any = [
    {
      title: (
        <>
          <InfoButton
            title={
              <>
                Included - Select "Included" to include calendar events as an
                evaluation method in the matter tracker tab.
                <br />
                <br />
                Not Included - Select "Not Included" to exclude calendar events
                as an evaluation method in the matter tracker tab.
                <br />
                <br />
                Calendar events will be evaluated if created or not. Matters
                with future events on the calendar based on the current date
                will remain green. With no events, or no events in the future,
                matters will be considered red.
              </>
            }
          />
          Calendar Entry
        </>
      ),

      sorting: false,
      field: "calender_entry",
      lookup: { 0: "Not Included", 1: "Included" },
      validate: (rowData) => ({
        isValid: returnTruth(rowData),
        helperText: !returnTruth(rowData) && "Set atleast two categories",
      }),
    },
    {
      title: (
        <>
          <InfoButton
            title={
              <>
                Included - Select "Included" to include invoices as an
                evaluation method in the matter tracker tab
                <br />
                <br />
                Not Included - Select "Not Included" to exclude invoices as an
                evaluation method in the matter tracker tab
                <br />
                <br />
                Bills will be evaluated if created or not, and by their due
                dates.
              </>
            }
          />
          Bills
        </>
      ),

      sorting: false,
      field: "bills",
      lookup: { 0: "Not Included", 1: "Included" },
      validate: (rowData) => returnTruth(rowData),
    },
    {
      title: (
        <>
          <InfoButton
            title={
              <>
                Included - Select "Included" to include tasks as an evaluation
                method in the matter tracker tab
                <br />
                <br />
                Not Included - Select "Not Included" to exclude exclude tasks as
                an evaluation method in the matter tracker tab
                <br />
                <br />
                Tasks will be evaluated if created or not, and by their due
                dates.
              </>
            }
          />
          Tasks
        </>
      ),

      sorting: false,
      field: "tasks",
      lookup: { 0: "Not Included", 1: "Included" },
      validate: (rowData) => returnTruth(rowData),
    },
    {
      title: (
        <>
          <InfoButton
            title={
              <>
                Included - Select "Included" to include activities as an
                evaluation method in the matter tracker tab
                <br />
                <br />
                Not Included - Select "Not Included"to exclude activities as an
                evaluation method in the matter tracker tab
                <br />
                <br />
                Activities will be evaluated if created or not.
              </>
            }
          />
          Activities
        </>
      ),

      sorting: false,
      field: "activity",
      lookup: { 0: "Not Included", 1: "Included" },
      validate: (rowData) => returnTruth(rowData),
    },
    {
      title: (
        <>
          <InfoButton
            title={
              <>
                Set this value to establish the maximum number of days an
                invoice will remain yellow based on the due date and the max
                level setting of green invoices. Every invoice after this
                setting will be red.
              </>
            }
          />
          Bills Urgent Interval
        </>
      ),

      sorting: false,
      field: "bills_tolerance",
      type: "numeric",
      min: "0",
    },
    {
      title: (
        <>
          <InfoButton
            title={
              <>
                Set this value to establish the maximum number of days a task
                will remain yellow based on the due date and the max level
                setting of green tasks. Every task after this setting will be
                red.
              </>
            }
          />
          Tasks Urgent Interval
        </>
      ),

      sorting: false,
      field: "tasks_tolerance",
      type: "numeric",
      min: "0",
    },
    {
      title: (
        <>
          <InfoButton
            title={
              <>
                Set this value to establish the maximum number of days an
                invoice will remain green.
              </>
            }
          />
          Bills Scheduled Interval
        </>
      ),

      sorting: false,
      field: "bills_planned",
      type: "numeric",
      min: "0",
    },
    {
      title: (
        <>
          <InfoButton
            title={
              <>
                Set this value to establish the maximum number of days an
                invoice will remain green.
              </>
            }
          />
          Tasks Scheduled Interval
        </>
      ),
      sorting: false,
      field: "tasks_planned",
      type: "numeric",
      min: "0",
    },
  ];
  const ppColumns: any = [
    {
      title: (
        <>
          <InfoButton
            title={
              <>
                Included - Select "Included" to include calendar events as an
                evaluation method in the matter tracker tab.
                <br />
                <br />
                Not Included - Select "Not Included" to exclude calendar events
                as an evaluation method in the matter tracker tab.
                <br />
                <br />
                Events will be evaluated if created or not. Matters with future
                events on the calendar based on the current date will remain
                green. With no events, or no events in the future, matters will
                be considered red.
              </>
            }
          />
          Events
        </>
      ),

      sorting: false,
      field: "events",
      validate: (rowData) => ({
        isValid: returnTruth(rowData),
        helperText: !returnTruth(rowData) && "Set atleast two categories",
      }),
      lookup: { 0: "Not Included", 1: "Included" },
    },
    {
      title: (
        <>
          <InfoButton
            title={
              <>
                Included - Select "Included" to include invoices as an
                evaluation method in the matter tracker tab
                <br />
                <br />
                Not Included - Select "Not Included" to exclude invoices as an
                evaluation method in the matter tracker tab
                <br />
                <br />
                Invoices will be evaluated if created or not, and by their due
                dates.
              </>
            }
          />
          Invoices
        </>
      ),

      sorting: false,
      field: "invoices",
      validate: (rowData) => returnTruth(rowData),
      lookup: { 0: "Not Included", 1: "Included" },
    },
    {
      title: (
        <>
          <InfoButton
            title={
              <>
                Included - Select "Included" to include tasks as an evaluation
                method in the matter tracker tab
                <br />
                <br />
                Not Included - Select "Not Included" to exclude exclude tasks as
                an evaluation method in the matter tracker tab
                <br />
                <br />
                Tasks will be evaluated if created or not, and by their due
                dates.
              </>
            }
          />
          Tasks
        </>
      ),

      sorting: false,
      field: "tasks",
      validate: (rowData) => returnTruth(rowData),

      lookup: { 0: "Not Included", 1: "Included" },
    },
    {
      title: (
        <>
          <InfoButton
            title={
              <>
                Included - Select "Included" to include time entries as an
                evaluation method in the matter tracker tab
                <br />
                <br />
                Not Included - Select "Not Included"to exclude time entries as
                an evaluation method in the matter tracker tab
                <br />
                <br />
                Time Entries will be evaluated if created or not.
              </>
            }
          />
          Time Entries
        </>
      ),

      sorting: false,
      field: "timeEntries",
      validate: (rowData) => returnTruth(rowData),

      lookup: { 0: "Not Included", 1: "Included" },
    },
    {
      title: (
        <>
          <InfoButton
            title={
              <>
                Set this value to establish the maximum number of days an
                invoice will remain yellow based on the due date and the max
                level setting of green invoices. Every invoice after this
                setting will be red.
              </>
            }
          />
          Invoices Urgent Interval
        </>
      ),
      sorting: false,
      field: "invoices_tolerance",
      type: "numeric",
      min: "0",
    },
    {
      title: (
        <>
          <InfoButton
            title={
              <>
                Set this value to establish the maximum number of days a task
                will remain yellow based on the due date and the max level
                setting of green tasks. Every task after this setting will be
                red.
              </>
            }
          />
          Tasks Urgent Interval
        </>
      ),

      sorting: false,
      field: "tasks_tolerance",
      type: "numeric",
      min: "0",
    },
    {
      title: (
        <>
          <InfoButton
            title={
              <>
                Set this value to establish the maximum number of days an
                invoice will remain green.
              </>
            }
          />
          Invoices Scheduled Interval
        </>
      ),
      sorting: false,
      field: "invoices_planned",
      type: "numeric",
      min: "0",
    },
    {
      title: (
        <>
          <InfoButton
            title={
              <>
                Set this value to establish the maximum number of days an
                invoice will remain green.
              </>
            }
          />
          Tasks Scheduled Interval
        </>
      ),
      sorting: false,
      field: "tasks_planned",
      type: "numeric",
      min: "0",
    },
  ];
  return (
    <>
      <MatTable
        data={response}
        column={pmType == "pp" ? ppColumns : clioColumns}
        title="Matter Tracker Setup"
        isLoading={loading}
        style={{ padding: 15 }}
        options={{
          pageSize: 5,
          draggable: false,
        }}
        editable={{
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve) => {
              let request = api.create(token);
              let id = newData.id;
              let clioObj = {
                tasks: newData.tasks == 0 ? false : true,
                bills: newData.bills == 0 ? false : true,
                activity: newData.activity == 0 ? false : true,
                calender_entry: newData.calender_entry == 0 ? false : true,
                bills_tolerance: newData.bills_tolerance,
                tasks_tolerance: newData.tasks_tolerance,
                bills_planned: newData.bills_planned,
                tasks_planned: newData.tasks_planned,
              };
              let ppObj = {
                tasks: newData.tasks == 0 ? false : true,
                invoices: newData.invoices == 0 ? false : true,
                timeEntries: newData.timeEntries == 0 ? false : true,
                events: newData.events == 0 ? false : true,
                invoices_tolerance: newData.invoices_tolerance,
                tasks_tolerance: newData.tasks_tolerance,
                invoices_planned: newData.invoices_planned,
                tasks_planned: newData.tasks_planned,
              };
              request
                .updateMatterTrackerSetup(
                  firm,
                  pmType,
                  pmType == "pp" ? ppObj : clioObj
                )
                .then((res) => {
                  setUpdated(!updated);
                  setLoading(true);
                })
                .catch((error) => {
                  if (error.code === "ECONNABORTED") {
                    setLoading(false);
                    setErrorMsg(
                      "The API request timed out. Please Refresh the page"
                    );
                    setApiError(true);
                  } else {
                    setLoading(false);
                    setErrorMsg(error.response.data.message);
                    setApiError(true);
                  }
                });
              resolve(request);
            }),
        }}
      />
      {apiError && <ErrorSnackBar errorMsg={errorMsg} />}
    </>
  );
}
