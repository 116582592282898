import moment from "moment";

interface DefinedRange {
  label: string;
  startDate: Date;
  endDate: Date;
}

// *****
// Static Defined Date Ranges
// *****

// console.log(‘last quarter start and end’)
// console.log()
// console.log(moment().subtract(1, ‘quarter’).endOf(‘quarter’).format(‘yyyy-DD-MM’))
// console.log(‘Current quarter start and end’)
// console.log(moment().startOf(‘quarter’).format(‘yyyy-DD-MM’));
// console.log(moment().endOf(‘quarter’).format(‘yyyy-DD-MM’));

export const definedRanges: DefinedRange[] = [
  {
    label: "Last 30 days",
    startDate: moment().subtract(30, "d").toDate(),
    endDate: new Date(),
  },
  {
    label: "Last Month",
    startDate: moment().startOf("month").subtract(1, "M").toDate(),
    endDate: moment().subtract(1, "M").endOf("month").toDate(),
  },
  {
    label: "This Month",
    startDate: moment().startOf("month").toDate(),
    endDate: moment().endOf("month").toDate(),
  },
  {
    label: "Current Quarter",
    startDate: moment().startOf("quarter").toDate(),
    endDate: moment().endOf("quarter").toDate(),
  },
  {
    label: "Last Quarter",
    startDate: moment().subtract(1, "quarter").startOf("quarter").toDate(),
    endDate: moment().subtract(1, "M").endOf("month").toDate(),
  },
  {
    label: "This Year",
    startDate: moment().startOf("year").toDate(),
    endDate: new Date(),
  },
  {
    label: "Last Year",
    startDate: moment().startOf("year").subtract(1, "y").toDate(),
    endDate: moment().endOf("year").subtract(1, "y").toDate(),
  },
];
export const thisYear = {
  startDate: moment().startOf("year").toDate(),
  endDate: new Date(),
};
export const yearlyRanges: DefinedRange[] = [
  {
    label: "Current Year",
    startDate: moment().utc().startOf("year").toDate(),
    endDate: new Date(),
  },
  {
    label: "Previous Year",
    startDate: moment().utc().startOf("year").subtract(1, "y").toDate(),
    endDate: moment().endOf("year").subtract(1, "y").toDate(),
  },
  {
    label: `${moment()
      .startOf("year")
      .subtract(2, "y")
      .toDate()
      .getFullYear()}`,
    startDate: moment().utc().startOf("year").subtract(2, "y").toDate(),
    endDate: moment().endOf("year").subtract(2, "y").toDate(),
  },
  {
    label: `${moment()
      .startOf("year")
      .subtract(3, "y")
      .toDate()
      .getFullYear()}`,
    startDate: moment().utc().startOf("year").subtract(3, "y").toDate(),
    endDate: moment().endOf("year").subtract(3, "y").toDate(),
  },
  {
    label: `${moment()
      .startOf("year")
      .subtract(4, "y")
      .toDate()
      .getFullYear()}`,
    startDate: moment().utc().startOf("year").subtract(4, "y").toDate(),
    endDate: moment().endOf("year").subtract(4, "y").toDate(),
  },
  {
    label: `${moment()
      .startOf("year")
      .subtract(5, "y")
      .toDate()
      .getFullYear()}`,
    startDate: moment().utc().startOf("year").subtract(5, "y").toDate(),
    endDate: moment().endOf("year").subtract(5, "y").toDate(),
  },
];
