import { Paper, Grid, Typography } from "@material-ui/core";
import BlockIcon from "@material-ui/icons/Block";
interface Props {}

export const PlaceholderAcl = (props: Props) => {
  return (
    <Grid container>
      <Grid item xs={12} direction="column">
        <Paper
          elevation={3}
          variant="outlined"
          square
          style={{
            display: "flex",
            alignItems: "center",
            padding: 40,
          }}
        >
          <BlockIcon fontSize="large" color="error" />
          <Typography variant="h5">
            You dont have the permissions to access these charts.
          </Typography>
        </Paper>
      </Grid>
    </Grid>
  );
};
