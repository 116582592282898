import React, { useEffect, useState, createContext } from 'react';

export interface TrackWindowProps {
    children: React.ReactNode
}

const WindowContext = createContext(0);

const TrackWindow: React.FC<TrackWindowProps> = ({ children }) => {
    const [ width, setWidth ] = useState(0);

    const updateDimensions = () => {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        updateDimensions();
        window.addEventListener("resize", updateDimensions )
    
        return () => { window.removeEventListener("resize", updateDimensions) }
    })

    return ( 
        <WindowContext.Provider value = {width}>
            { children }
        </WindowContext.Provider>
     );
}
 
export { TrackWindow, WindowContext };
