import { Grid, Button, Fade } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import React, { useState } from "react";
import clsx from "clsx";
import { useHistory } from "react-router-dom";

interface ITiersProps {
  obj: any;
  showExpiry?: boolean;
  current?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  textWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    [theme.breakpoints.down("md")]: {
      paddingBottom: theme.spacing(2),
    },
  },
  imageWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      justifyContent: "flex-start",
    },
  },
  title: {
    display: "inline-block",
    paddingBottom: theme.spacing(2),
    color: theme.palette.text.primary,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipses",
    [theme.breakpoints.up("md")]: {
      fontSize: "1.5em",
      fontWeight: theme.typography.fontWeightMedium,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "1.3em",
      fontWeight: 600,
    },
    fontSize: "1.3em",
    fontWeight: 500,
  },
  subHeading: {
    display: "block",
    whiteSpace: "normal",
    fontSize: "0.9em",
    fontWeight: 200,
    color: theme.palette.text.primary,
    [theme.breakpoints.up("md")]: {
      fontSize: "1.1em",
      fontWeight: 500,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "1.3em",
      fontWeight: 500,
    },
  },
  price: {
    display: "block",
    whiteSpace: "normal",
    fontSize: "2.4em",
    fontWeight: "bold",
    color: theme.palette.primary.light,
    paddingBottom: theme.spacing(4),
  },
  month: {
    fontSize: "0.5em !important",
    color: theme.palette.text.secondary,
    fontWeight: "unset",
    paddingBottom: theme.spacing(1),
  },
  image: {
    maxHeight: "100%",
    width: "auto",
  },
  button: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(2),
    },
    textTransform: "none",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    color: theme.palette.background.paper,
  },
  plansDiv: {
    borderRadius: theme.spacing(2),
    overflow: "hidden",
    padding: theme.spacing(4),
    margin: theme.spacing(4),
  },
  selectedPlanDiv: {
    background: `linear-gradient(0deg, ${theme.palette.primary.main} , ${theme.palette.primary.light} )`,
  },
  white: {
    color: theme.palette.background.paper,
  },
  itemTitle: {
    display: "inline-block",
    paddingBottom: theme.spacing(2),
    fontFamily: "Open Sans",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipses",
    [theme.breakpoints.up("md")]: {
      fontSize: "1.2em",
      fontWeight: theme.typography.fontWeightMedium,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "1.1em",
    },
    fontSize: "1.2em",
  },
}));
// function ValueLabelComponent(props) {
//   const { children, open, value } = props;

//   return (
//     <Tooltip
//       open={open}
//       enterTouchDelay={0}
//       placement="top"
//       title={`${value} ${value === 1 ? "User" : "Users"}`}
//     >
//       {children}
//     </Tooltip>
//   );
// }

function Fader(props) {
  const { children, value } = props;
  return value ? <Fade in>{children}</Fade> : <>{children}</>;
}

const Tiers: React.FunctionComponent<ITiersProps> = ({
  obj,
  showExpiry,
  current,
}) => {
  const [value, setValue] = useState<number>(10);
  const classes = useStyles();
  const history = useHistory();
  const handleRedirect = () => {
    history.push("/dashboard/settings/payments");
  };
  const plans = ["Intro", "Basic", "Professional", "Customized Pricing"];


  const returnTruth = (from, upto) => {
    let newUpto = () => {
      return upto === "inf" ? 25 : upto;
    };

    if (value >= from && value <= newUpto()) return true;
  };
  console.log(obj);
  return (
    <>
      {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}> */}
      {/* <Sliders
          valueLabelDisplay="auto"
          defaultValue={value}
          onChange={handleChange}
          ValueLabelComponent={ValueLabelComponent}
          min={1}
          max={25}
        /> */}
      {/* </Grid> */}
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Grid container spacing={2}>
          {obj &&
            obj.map((item, index) => (
              <Grid item xs={12} sm={12} md key={index}>
                <Fader value={returnTruth(item.from, item.upto)}>
                  <div
                    className={clsx(
                      classes.plansDiv,
                      item.isActive && classes.selectedPlanDiv
                    )}
                  >
                    <span
                      className={clsx(
                        classes.itemTitle,
                        item.isActive && classes.white
                      )}
                    >
                      {plans[index]}
                    </span>
                    <span
                      className={clsx(
                        classes.price,
                        item.isActive && classes.white
                      )}
                    >
                      {index !== 3 && `$${item.price}`}
                      <span className={classes.month}>
                        {index === 3 && (
                          <a
                            style={
                              item.isActive
                                ? { color: "#fff" }
                                : { color: "#000" }
                            }
                            href="support@firmtraksolutions.zendesk.com"
                          >
                            Email: support@firmtraksolutions.zendesk.com
                          </a>
                        )}
                      </span>
                    </span>
                    <span
                      className={clsx(
                        classes.subHeading,
                        item.isActive && classes.white
                      )}
                    >
                      {item.des}
                    </span>
                    {item.isActive && showExpiry && (
                      <div className={classes.textWrapper}>
                        <Button
                          onClick={handleRedirect}
                          fullWidth
                          variant="contained"
                          color="secondary"
                          className={classes.button}
                        >
                          Pay Now
                        </Button>
                      </div>
                    )}
                  </div>
                </Fader>
              </Grid>
            ))}
        </Grid>
      </Grid>
    </>
  );
};

export default Tiers;
