import React, { useState, useContext } from "react";

import { StyledToggleButtonGroup, StyledToggleButton } from "../styledTabs";
import { makeStyles } from "@material-ui/core/styles";
import { WindowContext } from "../../../../window/trackWindow";
import Grid from "@material-ui/core/Grid";
import ARGrid from "../../grids/AR/arGrid";

const useStyles = makeStyles(theme => ({
  headingText: {
    fontSize: "1.5em",
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.text.primary,
  },
  divider: {
    marginBottom: theme.spacing(1),
  },
}));

export interface MattersTabProps {
  id: any;
  detail: any;
  handleChange: any;
  handleBack: any;
}

const ARTab: React.FC<MattersTabProps> = ({
  id,
  detail,
  handleChange,
  handleBack,
}) => {
  const window = useContext(WindowContext);
  const [active, setActive] = useState(0);
  const classes = useStyles();

  const handleTabChange = (event, value) => {
    if (value !== null) {
      setActive(value);
    }
  };

  return (
    <Grid container spacing={4} justifyContent="space-between">
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <span className={classes.headingText}>AR Aging</span>
      </Grid>
      <StyledToggleButtonGroup
        onChange={handleTabChange}
        exclusive
        value={active}
        size="small"
        style={{ fontSize: window < 600 ? 11 : 13 }}
      >
        <StyledToggleButton key={1} value={0}>
          Predefined
        </StyledToggleButton>
        <StyledToggleButton key={2} value={1}>
          Custom
        </StyledToggleButton>
      </StyledToggleButtonGroup>
      <Grid
        item
        xs={12}
        // sm={12}
        // md={9}
        // lg={9}
        // xl={9}
      >
        <ARGrid
          id={id}
          detail={detail}
          handleChange={handleChange}
          handleBack={handleBack}
          type={active}
        />
      </Grid>
    </Grid>
  );
};

export default ARTab;
