import images from "../../../images";

const tutorialStepsClioXero = [
  {
    icon: images.settings,
    data: [
      {
        title: "Go to settings",
        description: `firmTRAK requires some initial settings in order to get the most informative KPI’s. Failure to complete set up will impact the performance of the application.`,
        last: true,
      },
    ],
    buttonText: "NEXT",
    subSteps: false,
    navigateTo: "/settings/employee-setup",
    last: false,
  },
  {
    icon: images.integrationIcon,
    data: [
      {
        title: "Employee Setup",
        description: `Please review the following Employee setup fields.`,
        last: false,
      },
      {
        title: "Rate per Hour",
        description:
          "This field is imported from the legal practice manager. If this field should not be 0 please enter a rate in the practice manager",
        last: false,
      },
      {
        title: "Hours Per Month",
        description:
          "This field is set at 160 hours, the default rate for a full time employee. Review the list of users imported from the practice manager for part time employees.",
        last: false,
      },
      {
        title: "Monthly Billable Target(hrs)",
        description:
          "This field is set at 160 hours, the default target rate for a full time employee. Review the list of users imported from the practice manager for part time employees.",
        last: false,
      },
      {
        title: "Included In Calculations",
        description:
          "This field is set by the practice manager classification of active or inactive.  Review to exclude any active users that aren’t necessary for KPI’s.",
        last: false,
      },
      {
        title: "Actions",
        description:
          "Click the pencil icon to edit any employee revisions that need to be made.",
        last: true,
      },
    ],
    subSteps: true,
    buttonText: "NEXT",
    navigateTo: "/settings/account-setup",
    last: false,
  },
  {
    icon: images.integrationIcon,
    subSteps: true,

    data: [
      {
        title: "Accounts Setup",
        description: `Account Names, Code, Status, Type, and Class are imported from Xero. Please review to assign the proper Account Category for proper KPI tracking.  Failure to do so will impact the performance of the application.`,
        last: false,
      },
      {
        title: "Operating Account",
        description:
          "The Operating Account refers to any bank accounts used in the operations of the business. Choose this category for the relevant operating bank accounts.",
        last: false,
      },
      {
        title: "Trust Account",
        description:
          "The Trust Account refers to any IOLTA bank accounts used to deposit client trust monies of the business. Choose this category for the relevant Trust bank accounts.",
        last: false,
      },
      {
        title: "Client Expense",
        description:
          "Client Expense refers to any chart of account used to spend money on behalf of client related expenses whether or not the firm seeks reimbursement from their clients.",
        last: false,
      },
      {
        title: "Marketing Expense",
        description:
          "Marketing Expense refers to any expense type chart of account used to spend money on behalf of expenses related to obtaining new clients. ",
        last: false,
      },
      {
        title: "Wages and Salaries",
        description:
          "Wages and Salaries refers to any expense type chart of account used to spend money on behalf of expenses related to employees of the law firm.",
        last: true,
      },
      {
        title: "Draw",
        description:
          "Draw refers to any equity chart of account used to spend or distribute money to the owners of the law firm.",
        last: true,
      },
    ],
    buttonText: "NEXT",
    navigateTo: "/settings/integration",
    last: false,
  },
  {
    icon: images.integrationIcon,
    data: [
      {
        title: "Congratulations! You have reached to the end of tutorial.",
        description: `You can integrate/sync/disconnect Clio/Xero here. To access the tutorial at any time click your name menu in the upper right corner. Click on Tutorial.`,
        last: true,
      },
    ],
    buttonText: "NEXT",
    navigateTo: "/settings/integration",
    last: true,
  },
];
const tutorialStepsClio = [
  {
    icon: images.settings,
    data: [
      {
        title: "Go to settings",
        description: `firmTRAK requires some initial settings in order to get the most informative KPI’s. Failure to complete set up will impact the performance of the application.`,
        last: true,
      },
    ],
    buttonText: "NEXT",
    subSteps: false,
    navigateTo: "/settings/employee-setup",
    last: false,
  },
  {
    icon: images.integrationIcon,
    data: [
      {
        title: "Employee Setup",
        description: `Please review the following Employee setup fields.`,
        last: false,
      },
      {
        title: "Rate per Hour",
        description:
          "This field is imported from the legal practice manager. If this field should not be 0 please enter a rate in the practice manager",
        last: false,
      },
      {
        title: "Hours Per Month",
        description:
          "This field is set at 160 hours, the default rate for a full time employee. Review the list of users imported from the practice manager for part time employees.",
        last: false,
      },
      {
        title: "Monthly Billable Target(hrs)",
        description:
          "This field is set at 160 hours, the default target rate for a full time employee. Review the list of users imported from the practice manager for part time employees.",
        last: false,
      },
      {
        title: "Included In Calculations",
        description:
          "This field is set by the practice manager classification of active or inactive.  Review to exclude any active users that aren’t necessary for KPI’s.",
        last: false,
      },
      {
        title: "Actions",
        description:
          "Click the pencil icon to edit any employee revisions that need to be made.",
        last: true,
      },
    ],
    subSteps: true,
    buttonText: "NEXT",
    navigateTo: "/settings/integration",
    last: false,
  },
  {
    icon: images.integrationIcon,
    data: [
      {
        title: "Congratulations! You have reached to the end of tutorial.",
        description: `You can integrate/sync/disconnect Clio/Xero here. To access the tutorial at any time click your name menu in the upper right corner. Click on Tutorial.`,
        last: true,
      },
    ],
    buttonText: "NEXT",
    navigateTo: "/settings/integration",
    last: true,
  },
];
const tutorialStepsXero = [
  {
    icon: images.settings,
    data: [
      {
        title: "Go to settings",
        description: `firmTRAK requires some initial settings in order to get the most informative KPI’s. Failure to complete set up will impact the performance of the application.`,
        last: true,
      },
    ],
    buttonText: "NEXT",
    subSteps: false,
    navigateTo: "/settings/account-setup",
    last: false,
  },
  {
    icon: images.integrationIcon,
    subSteps: true,
    data: [
      {
        title: "Accounts Setup",
        description: `Account Names, Code, Status, Type, and Class are imported from Xero. Please review to assign the proper Account Category for proper KPI tracking.  Failure to do so will impact the performance of the application.`,
        last: false,
      },
      {
        title: "Operating Account",
        description:
          "The Operating Account refers to any bank accounts used in the operations of the business. Choose this category for the relevant operating bank accounts.",
        last: false,
      },
      {
        title: "Trust Account",
        description:
          "The Trust Account refers to any IOLTA bank accounts used to deposit client trust monies of the business. Choose this category for the relevant Trust bank accounts.",
        last: false,
      },
      {
        title: "Client Expense",
        description:
          "Client Expense refers to any chart of account used to spend money on behalf of client related expenses whether or not the firm seeks reimbursement from their clients.",
        last: false,
      },
      {
        title: "Marketing Expense",
        description:
          "Marketing Expense refers to any expense type chart of account used to spend money on behalf of expenses related to obtaining new clients. ",
        last: false,
      },
      {
        title: "Wages and Salaries",
        description:
          "Wages and Salaries refers to any expense type chart of account used to spend money on behalf of expenses related to employees of the law firm.",
        last: true,
      },
      {
        title: "Draw",
        description:
          "Draw refers to any equity chart of account used to spend or distribute money to the owners of the law firm.",
        last: true,
      },
    ],
    buttonText: "NEXT",
    navigateTo: "/settings/integration",
    last: false,
  },
  {
    icon: images.integrationIcon,
    data: [
      {
        title: "Congratulations! You have reached to the end of tutorial.",
        description: `You can integrate/sync/disconnect Clio/Xero here. To access the tutorial at any time click your name menu in the upper right corner. Click on Tutorial.`,
        last: true,
      },
    ],
    buttonText: "NEXT",
    navigateTo: "/settings/integration",
    last: true,
  },
];
export { tutorialStepsClio, tutorialStepsClioXero, tutorialStepsXero };
