import React, { useEffect, useState } from "react";
import { makeStyles, Paper } from "@material-ui/core";
import Chart from "../../charts/fusionCharts";
import api from "../../../../services";
import EmptyPlaceholder from "../../commons/placeholders/emptyPlaceholder";
import { Skeleton } from "@material-ui/lab";
import _ from "lodash";
import ErrorSnackBar from "../../commons/SnackBar";
import InfoButton from "../../infoButton";
const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3),
  },
}));
export default function ChartGrid({ token, firmId, pmType }) {
  const classes = useStyles();
  const [loader, setLoader] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [data, setData] = useState<any>({
    clients: [],
    matters: [],
    years: [],
  });
  function returnKeyValue(item, type) {
    return { [type]: 0, year: item };
  }
  function orderList(array, order, type) {
    let sortedArray = order.map((i) =>
      array.find((j) => j.year == i) === undefined
        ? returnKeyValue(i, type)
        : array.find((j) => j.year == i)
    );
    return sortedArray;
  }

  useEffect(() => {
    let subscribe = true;
    if (loader) {
      let request = api.create(token);
      request
        .getClientsMattersAR(firmId, pmType)
        .then((res) => {
          if (subscribe) {
            let { clients, matters } = res.data;
            let fixedClientsYears =
              clients.length > 0 ? clients.map((i) => i.year) : [];
            let fixedMattersYears =
              matters.length > 0 ? matters.map((i) => i.year) : [];
            let commonLabels = _.uniq([
              ...fixedClientsYears,
              ...fixedMattersYears,
            ]);
            let orderedClients = orderList(
              clients,
              commonLabels.sort(),
              "total_clients"
            ).map((i) => ({ value: i.total_clients }));
            let orderedMatters = orderList(
              matters,
              commonLabels.sort(),
              "total_matters"
            ).map((i) => ({ value: i.total_matters }));
            setData({
              clients: orderedClients,
              matters: orderedMatters,
              years: commonLabels.sort().map((i) => ({ label: `${i}` })),
            });

            setLoader(false);
          }
        })
        .catch((error) => {
          if (subscribe) {
            if (error.code === "ECONNABORTED") {
              setErrorMsg("The API request timed out. Please Refresh the page");
              setError(true);
            } else {
              setErrorMsg(error.response.data.message);
              setError(true);
            }
          }
        });
    }
  }, [loader]);
  const seriesColumn1 = [
    {
      seriesname: "Clients",
      data: data.clients,
    },
    {
      seriesname: "Matters",
      data: data.matters,
    },
  ];
  const chartConfig = {
    plottooltext: "<b>$dataValue</b> $seriesName",
    showsum: "0",
  };
  return (
    <Paper square className={classes.container}>
      {loader ? (
        <Skeleton variant="rect" height={300} />
      ) : data.clients.length > 0 || data.matters.length > 0 ? (
        <>
          <span style={{ display: "flex", justifyContent: "space-between" }}>
            <div />
            <InfoButton title={"Number of clients and matters per year."} />
          </span>
          <Chart
            type={"stackedcolumn2d"}
            config={chartConfig}
            series={seriesColumn1}
            labels={data.years}
            height={250}
          />
        </>
      ) : (
        <EmptyPlaceholder small />
      )}
      {error ? <ErrorSnackBar errorMsg={errorMsg} /> : null}
    </Paper>
  );
}
