export interface Location {
  latitude: number;
  longitude: number;
}

const globalServer: Location = {
  latitude: 39.96,
  longitude: -83,
};

const canadaServer: Location = {
  latitude: 45.5,
  longitude: -73.6,
};

const euServer: Location = {
  latitude: 51,
  longitude: -0.1,
};

export { globalServer, canadaServer, euServer };
