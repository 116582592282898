import axios from "axios";
import _ from "lodash";

const create = (token, baseURL = process.env.REACT_APP_BASE_URL) => {
  // console.log(baseURL);
  const api = axios.create({
    baseURL,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    timeout: process.env.REACT_APP_API_TIMEOUT,
  });
  // api.interceptors.response.use(
  //   function (response) {
  //     return response;
  //   },
  //   function (error) {
  //     // alert('error')
  //     if (error.response.status !== 401) {
  //       return new Promise((resolve, reject) => {
  //         reject(error);
  //       });
  //     }

  //     let user = localStorage.getItem("user");
  //     let userID = JSON.parse(user);
  //     let refreshToken = localStorage.getItem("refresh-token");
  //     let clientID = localStorage.getItem("clientID");
  //     //     console.log("refreshToken", refreshToken);
  //     //     if (error.response.status == 401) {
  //     //       console.log("services", error.response.status);
  //     return axios
  //       .post(`${env.BASE_URL}/public/users/refresh-token`, {
  //         refreshToken: refreshToken,
  //         userID: userID._id,
  //         clientID: clientID,
  //       })
  //       .then((res) => {
  //         localStorage.setItem("user", JSON.stringify(res.data.user));
  //         localStorage.setItem("token", res.data.token);
  //         localStorage.setItem("refresh-token", res.data.refreshToken);
  //       })
  //       .catch((error) => {
  //         console.log(error.response);
  //       });
  //   }
  // );

  const login = (obj) => api.post("/public/users/login", obj);
  const register = (obj) => api.post("/public/users/register", obj);
  const signupWithXero = (obj) => api.post("/public/users/sign-up-xero", obj);
  const acceptInvitation = (obj) =>
    api.post("/public/users/accept-invitation", obj);
  const emailAvailability = (obj) => {
    return api.post(`/public/users/email-availability`, obj);
  };
  const verifyEmail = (obj) => api.post("/public/users/verify/email", obj);
  const forgotPassword = (obj) =>
    api.post("/public/users/forgot-password/email", obj);
  const resetPassword = (obj) =>
    api.post("/public/users/reset-password/email", obj);
  const refreshToken = (obj) => api.post("/public/users/refresh-token", obj);
  const emailVerification = (obj) =>
    api.get("/public/users/verify/email/check", obj);
  const sessionLogout = (clientID) =>
    api.delete(`/private/users/logout?${clientID}`);
  const allSessionsLogout = (obj) => api.delete(`/private/users/logout/`);
  const getAllUsers = () => api.get(`/private/users/`);
  const changePassword = (obj) =>
    api.post(`/private/users/change-password`, obj);
  const createFirm = (obj) => api.post("/private/firms", obj);
  const getAllFirms = () => api.get("/private/firms/");
  const deleteIntegration = (params) =>
    api.delete(`/private/firms/delete-firm`, { params: params });
  const firmIntegration = (firmId, integrationType, obj) =>
    api.post(`/private/firms/${firmId}/${integrationType}/integrations`, obj);
  const getFirmByID = (firmId) => api.get(`/private/firms/${firmId}`);

  const getUserPermissions = (firmId, params) =>
    api.get(`private/firms/${firmId}/get-user-permissions`, {
      params: params,
    });

  const setUserPermissions = (firmId, obj, params) =>
    api.post(`private/firms/${firmId}/set-user-permissions`, obj, {
      params: params,
    });

  // *********************
  // Firm Endpoints
  // *********************

  const getClioUsers = (firmId, pmtype) => {
    return api.get(`/private/firms/${firmId}/${pmtype}/users`);
  };
  const updateClioUser = (firmId, pmType, userId, obj) => {
    return api.put(`/private/firms/${firmId}/${pmType}/users/${userId}`, obj);
  };

  const getRoles = (firmId) => {
    return api.get(`/private/firms/${firmId}/roles`);
  };

  const getFirmState = (firmId) => {
    return api.get(`/private/firms/${firmId}/firm-state`);
  };

  const getPracticeAreas = (firmId, pmType) => {
    return api.get(`/private/firms/${firmId}/${pmType}/practice-areas`);
  };
  const getUsers = (firmId, pmType) => {
    return api.get(`/private/firms/${firmId}/${pmType}/users`);
  };

  const diconnectFirm = (firmId, type) => {
    return api.post(`/private/firms/${firmId}/disconnect-integration/${type}`);
  };
  // *****
  // Charts: Clients Endpoints
  // *****

  const getNewClients = (firmId, type, queryParams) => {
    return api.get(`/private/firms/${firmId}/${type}/new-clients-count`, {
      params: _.pickBy(queryParams, (value, key) => {
        return !!value;
      }),
    });
  };

  const getClientsByType = (firmId, pmType, queryParams) => {
    return api.get(`/private/firms/${firmId}/${pmType}/clients-by-type`, {
      params: _.pickBy(queryParams, (value, key) => {
        return !!value;
      }),
    });
  };

  const getActiveDormantClients = (firmId, pmType, queryParams) => {
    return api.get(
      `/private/firms/${firmId}/${pmType}/clients-active-dormant`,
      {
        params: queryParams,
      }
    );
  };

  const getTopWorst = (type, pmType, firmId, queryParams) => {
    return api.get(`/private/firms/${firmId}/${pmType}/${type}`, {
      params: queryParams,
    });
  };

  const getClientDistribution = (firmId, pmType, queryParams) => {
    return api.get(`/private/firms/${firmId}/${pmType}/clients-distribution`, {
      params: queryParams,
    });
  };

  // *****
  // Charts: Matters Endpoints
  // *****

  const getMattersByType = (firmId, pmType, queryParams) => {
    return api.get(`/private/firms/${firmId}/${pmType}/matters-by-type`, {
      params: _.pickBy(queryParams, (value, key) => {
        return !!value;
      }),
    });
  };

  const getNewMatters = (firmId, pmType, queryParams) => {
    return api.get(`/private/firms/${firmId}/${pmType}/new-matters-count`, {
      params: _.pickBy(queryParams, (value, key) => {
        return !!value;
      }),
    });
  };

  const getActiveDormantMatters = (firmId, pmType, queryParams) => {
    return api.get(
      `/private/firms/${firmId}/${pmType}/matters-active-dormant`,
      {
        params: queryParams,
      }
    );
  };

  const getMatterDistribution = (firmId, pmType, queryParams) => {
    return api.get(`/private/firms/${firmId}/${pmType}/matters-distribution`, {
      params: queryParams,
    });
  };

  const getUsersByActualBilled = (firmId, pmType, queryParams) => {
    return api.get(
      `/private/firms/${firmId}/${pmType}/all-users-by-actual-billed`,
      {
        params: _.pickBy(queryParams, (value, key) => {
          return !!value;
        }),
      }
    );
  };

  const getCollectedTime = (firmId, pmType, queryParams) => {
    return api.get(`/private/firms/${firmId}/${pmType}/collected-time`, {
      params: _.pickBy(queryParams, (value, key) => {
        return !!value;
      }),
    });
  };

  const getBilledTime = (firmId, pmType, queryParams) => {
    return api.get(`/private/firms/${firmId}/${pmType}/billed-time`, {
      params: _.pickBy(queryParams, (value, key) => {
        return !!value;
      }),
    });
  };

  const getWorkedTime = (firmId, pmType, queryParams) => {
    return api.get(`/private/firms/${firmId}/${pmType}/worked-time`, {
      params: _.pickBy(queryParams, (value, key) => {
        return !!value;
      }),
    });
  };

  const getAvailableTime = (firmId, pmType, queryParams) => {
    return api.get(`/private/firms/${firmId}/${pmType}/available-time`, {
      params: _.pickBy(queryParams, (value, key) => {
        return !!value;
      }),
    });
  };

  const getTopTenUsersByCollectedTime = (firmId, pmType, queryParams) => {
    return api.get(
      `/private/firms/${firmId}/${pmType}/top10-users-by-collected-time`,
      {
        params: queryParams,
      }
    );
  };

  const getTopTenUsersByBilledTime = (firmId, pmType, queryParams) => {
    return api.get(
      `/private/firms/${firmId}/${pmType}/top10-users-by-billed-time`,
      {
        params: queryParams,
      }
    );
  };

  const getTopTenUsersByWorkedTime = (firmId, pmType, queryParams) => {
    return api.get(
      `/private/firms/${firmId}/${pmType}/top10-users-by-worked-time`,
      {
        params: queryParams,
      }
    );
  };

  const getTopTenUsersByAvailableTime = (firmId, pmType, queryParams) => {
    return api.get(
      `/private/firms/${firmId}/${pmType}/top10-users-by-available-time`,
      {
        params: queryParams,
      }
    );
  };

  const getTopTenUsersByCollectedTimeGrowth = (firmId, pmType, queryParams) => {
    return api.get(
      `/private/firms/${firmId}/${pmType}/top10-users-by-collected-time-growth`,
      {
        params: queryParams,
      }
    );
  };

  const getTopTenUsersByBilledTimeGrowth = (firmId, pmType, queryParams) => {
    return api.get(
      `/private/firms/${firmId}/${pmType}/top10-users-by-billed-time-growth`,
      {
        params: queryParams,
      }
    );
  };

  const getTopTenUsersByWorkedTimeGrowth = (firmId, pmType, queryParams) => {
    return api.get(
      `/private/firms/${firmId}/${pmType}/top10-users-by-worked-time-growth`,
      {
        params: queryParams,
      }
    );
  };

  const getTopTenUsersByAvailableTimeGrowth = (firmId, pmType, queryParams) => {
    return api.get(
      `/private/firms/${firmId}/${pmType}/top10-users-by-available-time-growth`,
      {
        params: queryParams,
      }
    );
  };

  const getWorstTenUsersByCollectedTime = (firmId, pmType, queryParams) => {
    return api.get(
      `/private/firms/${firmId}/${pmType}/worst10-users-by-collected-time`,
      {
        params: queryParams,
      }
    );
  };

  const getWorstTenUsersByBilledTime = (firmId, pmType, queryParams) => {
    return api.get(
      `/private/firms/${firmId}/${pmType}/worst10-users-by-billed-time`,
      {
        params: queryParams,
      }
    );
  };

  const getWorstTenUsersByWorkedTime = (firmId, pmType, queryParams) => {
    return api.get(
      `/private/firms/${firmId}/${pmType}/worst10-users-by-worked-time`,
      {
        params: queryParams,
      }
    );
  };

  const getWorstTenUsersByAvailableTime = (firmId, pmType, queryParams) => {
    return api.get(
      `/private/firms/${firmId}/${pmType}/worst10-users-by-available-time`,
      {
        params: queryParams,
      }
    );
  };
  const getBillableVsNonBillableMatters = (firmId, pmType, queryParams) => {
    return api.get(
      `/private/firms/${firmId}/${pmType}/billabale-vs-nonbillable-matters`,
      {
        params: queryParams,
      }
    );
  };

  // *****
  // Charts: Productivity Endpoints
  // *****

  const getTotalTargetedBill = (firmId, pmType, queryParams) => {
    return api.get(`/private/firms/${firmId}/${pmType}/total-targeted-bill`, {
      params: _.pickBy(queryParams, (value, key) => {
        return !!value;
      }),
    });
  };

  const getFeeBilled = (firmId, pmType, queryParams) => {
    return api.get(`/private/firms/${firmId}/${pmType}/fee-billed`, {
      params: _.pickBy(queryParams, (value, key) => {
        return !!value;
      }),
    });
  };

  const getAllUsersByActualBilled = (firmId, pmType, queryParams) => {
    return api.get(
      `/private/firms/${firmId}/${pmType}/all-users-by-actual-billed`,
      {
        params: _.pickBy(queryParams, (value, key) => {
          return !!value;
        }),
      }
    );
  };

  const getTotalWorkedTimeByAOP = (firmId, pmType, queryParams) => {
    return api.get(
      `/private/firms/${firmId}/${pmType}/total-workedTime-by-aop`,
      {
        params: _.pickBy(queryParams, (value, key) => {
          return !!value;
        }),
      }
    );
  };
  const getTotalBilledTimeByAOP = (firmId, pmType, queryParams) => {
    return api.get(
      `/private/firms/${firmId}/${pmType}/total-billedTime-by-aop`,
      {
        params: _.pickBy(queryParams, (value, key) => {
          return !!value;
        }),
      }
    );
  };
  const getTotalCollectedTimeByAOP = (firmId, pmType, queryParams) => {
    return api.get(
      `/private/firms/${firmId}/${pmType}/total-collectedTime-by-aop`,
      {
        params: _.pickBy(queryParams, (value, key) => {
          return !!value;
        }),
      }
    );
  };

  const getFeeOutstanding = (firmId, pmType, queryParams) => {
    return api.get(`/private/firms/${firmId}/${pmType}/fee-outstanding`, {
      params: _.pickBy(queryParams, (value, key) => {
        return !!value;
      }),
    });
  };
  const getFeeCollected = (firmId, pmType, queryParams) => {
    return api.get(`/private/firms/${firmId}/${pmType}/fee-collected`, {
      params: _.pickBy(queryParams, (value, key) => {
        return !!value;
      }),
    });
  };

  const getProductivity = (firmId, pmType, queryParams) => {
    return api.get(`/private/firms/${firmId}/${pmType}/productivity`, {
      params: queryParams,
    });
  };

  // *****
  // Charts: Matter Tracker Endpoints
  // *****

  const getMatterTrackerCountStaged = (firmId, pmType, queryParams) => {
    return api.get(
      `/private/firms/${firmId}/${pmType}/mattertracker/matter-count`,
      {
        params: queryParams,
      }
    );
  };

  const getMatterTrackerSetup = (firmId, pmType) => {
    return api.get(`/private/firms/${firmId}/${pmType}/matter-tracker/setup`);
  };
  const updateMatterTrackerSetup = (firmId, pmType, obj) => {
    return api.put(
      `/private/firms/${firmId}/${pmType}/matter-tracker/setup`,
      obj
    );
  };

  const getMatterTrackerJeopardyDetailStaged = (
    firmId,
    pmType,
    queryParams
  ) => {
    return api.get(
      `/private/firms/${firmId}/${pmType}/mattertracker/jeopardy-detail`,
      {
        params: queryParams,
      }
    );
  };

  const getMatterTrackerToleranceDetailStaged = (
    firmId,
    pmType,
    queryParams
  ) => {
    return api.get(
      `/private/firms/${firmId}/${pmType}/mattertracker/tolerance-detail`,
      {
        params: queryParams,
      }
    );
  };

  const getMatterTrackerPlannedDetailStaged = (firmId, pmType, queryParams) => {
    return api.get(
      `/private/firms/${firmId}/${pmType}/mattertracker/planned-detail`,
      {
        params: queryParams,
      }
    );
  };

  // *****
  // Charts: Trust Endpoints
  // *****

  const getTrustReport = (firmId, pmType, queryParams) => {
    return api.get(`/private/firms/${firmId}/${pmType}/trust-report`, {
      params: queryParams,
    });
  };

  const getTrustDetail = (firmId, pmType, obj) => {
    return api.get(`/private/firms/${firmId}/${pmType}/trust-detail`, {
      params: obj,
    });
  };

  const addThresholdValue = (firmId, pmType, obj) => {
    return api.put(
      `/private/firms/${firmId}/${pmType}/matter-guaranteed-collection-threshold`,
      null,
      {
        params: obj,
      }
    );
  };

  const addThresholdIncluded = (firmId, pmType, obj) => {
    return api.put(
      `/private/firms/${firmId}/${pmType}/matter-is-guaranteed-collection-threshold-included`,
      null,
      {
        params: obj,
      }
    );
  };
  // *****
  // Charts: AR Endpoints
  // *****

  const getARDateFilterCount = (firmId, pmType, queryParams) => {
    return api.get(`/private/firms/${firmId}/${pmType}/ar/date-filter-count`, {
      params: queryParams,
    });
  };

  const getARDateFilterDetail = (firmId, pmType, queryParams) => {
    return api.get(`/private/firms/${firmId}/${pmType}/ar/date-filter-detail`, {
      params: queryParams,
    });
  };

  const getARCount = (firmId, pmType, queryParams) => {
    return api.get(`/private/firms/${firmId}/${pmType}/ar/ar-count`, {
      params: _.pickBy(queryParams, (value, key) => {
        return !!value;
      }),
    });
  };
  const getARDetail0to30 = (firmId, pmType, queryParams) => {
    return api.get(`/private/firms/${firmId}/${pmType}/ar/0-30-days-detail`, {
      params: queryParams,
    });
  };
  const getARDetail31to60 = (firmId, pmType, queryParams) => {
    return api.get(`/private/firms/${firmId}/${pmType}/ar/31-60-days-detail`, {
      params: queryParams,
    });
  };
  const getARDetail61to90 = (firmId, pmType, queryParams) => {
    return api.get(`/private/firms/${firmId}/${pmType}/ar/61-90-days-detail`, {
      params: queryParams,
    });
  };
  const getARDetail91to120 = (firmId, pmType, queryParams) => {
    return api.get(`/private/firms/${firmId}/${pmType}/ar/91-120-days-detail`, {
      params: queryParams,
    });
  };
  const getARDetail120plus = (firmId, pmType, queryParams) => {
    return api.get(`/private/firms/${firmId}/${pmType}/ar/120+days-detail`, {
      params: queryParams,
    });
  };
  const getARBucket = (firmId, pmType, queryParams) => {
    return api.get(`/private/firms/${firmId}/${pmType}/ar/buckets`, {
      params: queryParams,
    });
  };

  const getARConcentrationRatio = (firmId, pmType, queryParams) => {
    return api.get(
      `/private/firms/${firmId}/${pmType}/ar/concentration-ratio`,
      {
        params: queryParams,
      }
    );
  };

  // *****
  // Profile Endpoints
  // *****

  const addRole = (firmId, obj) => {
    return api.post(`/private/firms/${firmId}/roles`, obj);
  };

  const deleteRole = (firmId, roleId) => {
    return api.delete(`/private/firms/${firmId}/roles/${roleId}`);
  };

  const updateUserProfile = (obj) => {
    return api.put(`/private/users/profile`, obj);
  };
  const getUserProfile = () => {
    return api.get(`/private/users/profile`);
  };

  const forceSync = (firmId, type) => {
    return api.post(`/private/firms/${firmId}/force-sync/${type}`);
  };

  // *****
  // Stripe Enpoints
  // *****

  const getStripeSubscription = (firmId, integrationType) => {
    return api.get(
      `/private/stripe/firms/${firmId}/integration/${integrationType}/subscription`
    );
  };
  const getStripeAllSubscription = (firmId) => {
    return api.get(`/private/stripe/firms/${firmId}/subscription`);
  };
  const postStripeSubscription = (firmId, integrationType, obj) => {
    return api.post(
      `/private/stripe/firms/${firmId}/integration/${integrationType}/subscription`,
      obj
    );
  };

  // *****
  // Super Admin Endpoints
  // *****

  const postAdminActions = (firmId, integrationType, obj) => {
    return api.post(
      `/private/stripe/firms/${firmId}/integration/${integrationType}/actions`,
      obj
    );
  };

  const apiLogs = (queryParams) => {
    return api.get(`/private/logs/api`, {
      params: queryParams,
    });
  };

  // *****
  // Xero Accounts Endpoints
  // *****
  const getXeroAccounts = (firmId) => {
    return api.get(`/private/firms/${firmId}/xero/accounts`);
  };

  const addAccountCategory = (firmId, queryParams) => {
    return api.put(
      `/private/firms/${firmId}/xero/add-account-category-v2`,
      null,
      {
        params: queryParams,
      }
    );
  };
  const xeroErrorLogs = (firmId) => {
    return api.get(`/private/firms/${firmId}/xero/sync-logs`);
  };
  const checkMinAccount = (firmId) => {
    return api.get(
      `/private/firms/${firmId}/xero/is-min-account-categories-added`
    );
  };
  // *****
  // Xero Financials Endpoints
  // *****
  const getTotalRevenue = (firmId, queryParams) => {
    return api.get(`/private/firms/${firmId}/xero/total-revenue`, {
      params: queryParams,
    });
  };

  const getNetCashFlow = (firmId, queryParams) => {
    return api.get(`/private/firms/${firmId}/xero/net-cash-flow`, {
      params: queryParams,
    });
  };

  const getNetIncome = (firmId, queryParams) => {
    return api.get(`/private/firms/${firmId}/xero/net-income`, {
      params: queryParams,
    });
  };

  const getOperatingExpense = (firmId, queryParams) => {
    return api.get(`/private/firms/${firmId}/xero/operating-expense`, {
      params: queryParams,
    });
  };

  const getDraw = (firmId, queryParams) => {
    return api.get(`/private/firms/${firmId}/xero/draw`, {
      params: queryParams,
    });
  };
  const getClientExpenses = (firmId, queryParams) => {
    return api.get(`/private/firms/${firmId}/xero/client-expenses`, {
      params: queryParams,
    });
  };
  const getClientExpensesPNL = (firmId, queryParams) => {
    return api.get(`/private/firms/${firmId}/xero/client-expenses-by-PNL`, {
      params: queryParams,
    });
  };
  const getWagesSalaries = (firmId, queryParams) => {
    return api.get(`/private/firms/${firmId}/xero/wages-and-salaries`, {
      params: queryParams,
    });
  };
  const getMarketingExpense = (firmId, queryParams) => {
    return api.get(`/private/firms/${firmId}/xero/marketing-expense`, {
      params: queryParams,
    });
  };

  // *****
  // Dashboard  Endpoints
  // *****

  const getTrustReportDashboard = (firmId, pmType) => {
    return api.get(
      `/private/firms/${firmId}/dashboard/${pmType}/trust-balance`
    );
  };
  const getDashboardConcentrationRatio = (firmId, pmType, queryParams) => {
    return api.get(
      `/private/firms/${firmId}/dashboard/${pmType}/concentration-diversity`,
      {
        params: queryParams,
      }
    );
  };

  const getInvoicesDashboard = (firmId, pmType) => {
    return api.get(`/private/firms/${firmId}/dashboard/${pmType}/invoices`);
  };

  const getRevenueDashboard = (firmId, pmType) => {
    return api.get(`/private/firms/${firmId}/dashboard/${pmType}/revenue`);
  };
  const getARDashboard = (firmId, pmType) => {
    return api.get(`/private/firms/${firmId}/dashboard/${pmType}/ar`);
  };

  const getBillingRevenueARDashboard = (firmId, pmType) => {
    return api.get(
      `/private/firms/${firmId}/dashboard/${pmType}/billing-revenue-ar`
    );
  };

  const getClientsDashboard = (firmId, pmType) => {
    return api.get(`/private/firms/${firmId}/dashboard/${pmType}/clients`);
  };

  const getMattersDashboard = (firmId, pmType) => {
    return api.get(`/private/firms/${firmId}/dashboard/${pmType}/matters`);
  };

  const getClientsMattersAR = (firmId, pmType) => {
    return api.get(
      `/private/firms/${firmId}/dashboard/${pmType}/clients-matters-ar`
    );
  };
  const getOutstandingWip = (firmId, pmType, queryParams) => {
    return api.get(
      `/private/firms/${firmId}/dashboard/${pmType}/outstanding-wip`,
      {
        params: queryParams,
      }
    );
  };

  const getDailySalesOutstanding = (firmId, pmType, queryParams) => {
    return api.get(
      `/private/firms/${firmId}/dashboard/${pmType}/daily-sales-outstanding`,
      {
        params: queryParams,
      }
    );
  };
  const getTimeRecord = (firmId, pmType, queryParams) => {
    return api.get(`/private/firms/${firmId}/dashboard/${pmType}/time-record`, {
      params: queryParams,
    });
  };
  const getLawmatics = (firmId) => {
    return api.get(`/private/firms/${firmId}/firm-images`);
  };

  const getCustomReportPermission = (firmId) => {
    return api.get(
      `/private/firms/${firmId}/clio/check-custom-report-permission`
    );
  };

  const getOpenFilesvsSecondChair = (firmId) => {
    return api.get(
      `/private/firms/${firmId}/clio/open-files-vs-second-chair`
    );
  };

  const getEstimatedTrustTransfer = (firmId, queryParams) => {
    return api.get(
      `/private/firms/${firmId}/clio/estimated-trust-transfer`, {
        params: queryParams
      }
    );
  };

  const getOpenFilesWithParalegals = (firmId) => {
    return api.get(`/private/firms/${firmId}/clio/open-files-with-paralegals
    `);
  };

  return {
    updateMatterTrackerSetup,
    getMatterTrackerSetup,
    deleteIntegration,
    getTopTenUsersByAvailableTimeGrowth,
    getTopTenUsersByWorkedTimeGrowth,
    getTopTenUsersByBilledTimeGrowth,
    getTopTenUsersByCollectedTimeGrowth,
    getTimeRecord,
    getDashboardConcentrationRatio,
    getOutstandingWip,
    getDailySalesOutstanding,
    getClientsMattersAR,
    getClientsDashboard,
    getMattersDashboard,
    getBillingRevenueARDashboard,
    getRevenueDashboard,
    getARDashboard,
    getInvoicesDashboard,
    getBillableVsNonBillableMatters,
    getARBucket,
    checkMinAccount,
    getActiveDormantMatters,
    getWagesSalaries,
    getMarketingExpense,
    getClientExpensesPNL,
    getClientExpenses,
    getClientDistribution,
    getDraw,
    getOperatingExpense,
    getNetIncome,
    getNetCashFlow,
    getTotalRevenue,
    addAccountCategory,
    getXeroAccounts,
    forceSync,
    getUserProfile,
    updateUserProfile,
    updateClioUser,
    deleteRole,
    addRole,
    getARDateFilterCount,
    getARDateFilterDetail,
    login,
    register,
    verifyEmail,
    forgotPassword,
    resetPassword,
    refreshToken,
    emailVerification,
    sessionLogout,
    allSessionsLogout,
    getAllUsers,
    changePassword,
    createFirm,
    getAllFirms,
    firmIntegration,
    getNewClients,
    getActiveDormantClients,
    getClientsByType,
    getPracticeAreas,
    getUsers,
    getNewMatters,
    getMattersByType,
    getMatterDistribution,
    getTopWorst,
    getUsersByActualBilled,
    getCollectedTime,
    getBilledTime,
    getWorkedTime,
    getAvailableTime,
    getClioUsers,
    getTopTenUsersByAvailableTime,
    getTopTenUsersByWorkedTime,
    getTopTenUsersByBilledTime,
    getTopTenUsersByCollectedTime,
    getWorstTenUsersByAvailableTime,
    getWorstTenUsersByWorkedTime,
    getWorstTenUsersByBilledTime,
    getWorstTenUsersByCollectedTime,
    getTotalTargetedBill,
    getFeeBilled,
    getAllUsersByActualBilled,
    getTotalWorkedTimeByAOP,
    getTotalBilledTimeByAOP,
    getTotalCollectedTimeByAOP,
    getFeeOutstanding,
    getFeeCollected,
    getARCount,
    getARDetail0to30,
    getARDetail61to90,
    getARDetail91to120,
    getARDetail31to60,
    getARDetail120plus,
    getRoles,
    getFirmByID,
    getProductivity,
    acceptInvitation,
    getStripeSubscription,
    postStripeSubscription,
    postAdminActions,
    getMatterTrackerCountStaged,
    getMatterTrackerJeopardyDetailStaged,
    getMatterTrackerToleranceDetailStaged,
    getMatterTrackerPlannedDetailStaged,
    apiLogs,
    getARConcentrationRatio,
    getTrustReport,
    getTrustDetail,
    addThresholdValue,
    addThresholdIncluded,
    emailAvailability,
    diconnectFirm,
    xeroErrorLogs,
    signupWithXero,
    getTrustReportDashboard,
    getStripeAllSubscription,
    getFirmState,
    getUserPermissions,
    setUserPermissions,
    getLawmatics,
    getCustomReportPermission,
    getOpenFilesWithParalegals,
    getOpenFilesvsSecondChair,
    getEstimatedTrustTransfer,
  };
};
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  create,
};
