import { useRef, useEffect } from "react";
import _ from "lodash";
import { DateStateType } from "../filters/genericFilter/dateFilterStateHook";
import moment from "moment";

// *****
// Hook - Returns the Previous value of filters using Ref
// *****

export default function usePreviousState(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

// *****************************
// Matters and Client Growth & Trends
// *****************************

interface MatterListItem {
  total_matters: number;
  month: string;
}

interface ClientListItem {
  total_clients: number;
  month: string;
}

interface FinancialsListItem {
  total: number;
  month: string;
}

interface Response {
  total: number;
  month: string;
}
// *****
// Calculates the gaining cumulative clients next = cumPrev + next
// *****

function handleCommClients(array: any[]): any[] {
  let sum;
  array = array.map((elem) => (sum = (sum || 0) + elem.value));
  return array;
}

// *****
// Flatten array and return by months
// *****
function compareMonths(
  comparisonList: Response[],
  benchmarkList: Response[],
  partialObject = {}
) {
  // Add new missing elements
  benchmarkList.map((item, i) => {
    if (!_.find(comparisonList, (item2) => item2.month.includes(item.month))) {
      comparisonList.splice(i, 0, {
        total: 0,
        month: item.month,
        ...partialObject,
      });
    }
  });
  let sortedArray = _.sortBy(comparisonList, (item) => item.month);
  //Remove extra elements from comparison list
  let compareList = _.intersectionWith(
    sortedArray,
    benchmarkList,
    (arrVal, otherVal) =>
      arrVal.month.includes(otherVal.month) &&
      arrVal.month !== null &&
      otherVal.month !== null
  );
  return compareList;
}

const returnApi = (current: number, topTen: string, bottomTen: string) => {
  if (current === 0) {
    return topTen;
  } else {
    return bottomTen;
  }
};
// *****************************
// Matters and Client Top & Worst Tens
// *****************************

interface ClientTensItem {
  total: number | string;
  name: string;
}

interface MattersTensItem {
  total: number | string;
  matter_name: string;
}

interface Productivity {
  total_hours: number | string;
  month: string;
}

// *****
// Flatten array and return nested list by property
// *****

function getNestedListByProperty(
  list:
    | MatterListItem[]
    | ClientListItem[]
    | FinancialsListItem[]
    | MattersTensItem[],
  property: string
): any[] {
  return _.flatMap(list, (item) => item[property]);
}

function getNestedListByPropertyFusion(
  list:
    | MatterListItem[]
    | ClientListItem[]
    | FinancialsListItem[]
    | Productivity[]
    | MattersTensItem[],
  property: string
): obj[] {
  return _.flatMap(list, (item) => ({ value: item[property] }));
}

function getNestedListByPropertyFusionClosed(
  list:
    | MatterListItem[]
    | ClientListItem[]
    | FinancialsListItem[]
    | Productivity[]
    | MattersTensItem[],
  property: string
): obj[] {
  return _.flatMap(list, (item, index) => ({
    value:
      item[property] == 0 && index != 0
        ? list[index - 1][property]
        : item[property],
  }));
}
function getNestedListByPropertyLabelFusion(
  list:
    | MatterListItem[]
    | ClientListItem[]
    | FinancialsListItem[]
    | MattersTensItem[],
  property: string
): obj[] {
  return _.flatMap(list, (item) => ({ label: item[property] }));
}
function getMonthsFusion(
  list:
    | MatterListItem[]
    | ClientListItem[]
    | FinancialsListItem[]
    | MattersTensItem[]
    | Productivity[],
  property: string
): obj[] {
  return _.flatMap(list, (item) => ({
    label: moment(item[property], "YYYY-MM").format("MMM YY"),
  }));
}

// *****
// Returns the flattened total amount array
// *****

function getAmount(
  data: MattersTensItem[] | ClientTensItem[] | FinancialsListItem[]
): number[] {
  return _.flatMap(data, (item, i) =>
    isFinite(item.total) ? `${item.total}` : `0`
  );
}

function addValue(
  data: MattersTensItem[] | ClientTensItem[] | FinancialsListItem[] | any[]
): any[] {
  return _.flatMap(data, (item, i) => ({ value: item }));
}

function getAmountFusion(
  data: MattersTensItem[] | ClientTensItem[] | FinancialsListItem[]
): number[] {
  return _.flatMap(data, (item, i) => ({
    value: isFinite(item.total) ? item.total : 0,
  }));
}

// *****
// Filter - Check start and end date
// *****

const checkDate = (startDate: Date | null, endDate: Date | null): boolean => {
  if (startDate && endDate) {
    if (startDate.getTime() <= endDate.getTime()) {
      //start is less than end
      return true;
    } else {
      //Start is greater than end
      return false;
    }
  } else {
    //Date: one or both is null
    return false;
  }
};

// *****
// Date and Filter Comparison for Filtered Results, Returns true when both dates are selected or filters are selected
// *****

const compareFilterDates = (
  filterState,
  prevFilterState,
  dateFilterState: DateStateType,
  prevDateFilterState: DateStateType,
  initialFilterState
) => {
  const initialDateFilterState: DateStateType = {
    dateAfter: null,
    dateBefore: null,
  };
  if (
    _.isEqual(filterState, prevFilterState) === false ||
    _.isEqual(dateFilterState, prevDateFilterState) === false
  ) {
    //nested - then check if both date parameters are applied with second greater than the first
    if (
      checkDate(dateFilterState.dateAfter, dateFilterState.dateBefore) === true
    ) {
      return true;
      //nested - or if none of them are applied
    } else if (
      dateFilterState.dateAfter === null &&
      dateFilterState.dateBefore === null
    ) {
      return true;
      //nested - or if both(filters and date filters) have been 'cleared'
    } else if (
      _.isEqual(filterState, initialFilterState) === true &&
      _.isEqual(dateFilterState, initialDateFilterState) === true
    ) {
      return true;
    }
    // else check if only moving averages have been applied
  } else {
    return false;
  }
};

// *****
// Return sliced array
// *****

const limitLengthToEighteen = (array) => {
  if (array.length > 18) {
    let slicedArray = array.slice(array.length - 18);
    return slicedArray;
  } else return array;
};

// *****
// Returns a rounded percentage
// *****

function PercentageRounded(numerator: number, denominator: number): number {
  if (numerator === 0 || denominator === 0) return 0;
  return (
    Math.round(
      ((Math.ceil(numerator) / denominator) * 100 + Number.EPSILON) * 100
    ) / 100
  );
}

// *****
// Compares two arrays by a certain element property returns the comparison list
// *****

type ResponseObject = {
  month: string;
  total: number | string;
};

type obj = {
  value: number;
};

function getCommonElementsFromArrays(
  comparisonList: ResponseObject[],
  benchmarkList: ResponseObject[],
  property: string
): ResponseObject[] | any[] {
  return _.intersectionWith(
    comparisonList,
    benchmarkList,
    (item: ResponseObject, item2: ResponseObject) =>
      item[`${property}`] === item2[`${property}`]
  );
}

// *****
// Returns substraction of elements secondArray - firstArray
// *****

function subtractElementfromArray(
  comparisonList: any[],
  benchmarkList: any[]
): any[] {
  let tempArray: any = [];
  if (comparisonList.length > 0 && benchmarkList.length > 0) {
    benchmarkList.forEach((item, index) => {
      // TEMPORARY PATCH - number to string conversion
      let val = comparisonList[index].value;
      let temp = item.value - -(-`${val}`);
      tempArray.push({ value: temp });
    });
  }

  return tempArray;
}

// *****
// Returns addition of elements at index in two arrays
// *****

function addElementsAtIndexInArrays(
  list1: any[] | [],
  list2: any[] | []
): any[] | [] {
  let tempArr: any[] = [];
  list1.forEach((item, index) => {
    tempArr.push({ value: item.value + list2[index].value });
  });
  return tempArr;
}

export {
  handleCommClients,
  getNestedListByProperty,
  getAmount,
  checkDate,
  limitLengthToEighteen,
  compareFilterDates,
  PercentageRounded,
  compareMonths,
  getCommonElementsFromArrays,
  subtractElementfromArray,
  addElementsAtIndexInArrays,
  returnApi,
  getNestedListByPropertyFusion,
  getMonthsFusion,
  getAmountFusion,
  getNestedListByPropertyLabelFusion,
  addValue,
  getNestedListByPropertyFusionClosed,
};
