import React, { useEffect, useState } from 'react'
import api from "../../../../services";
import { Grid, Paper, makeStyles } from '@material-ui/core';
import Chart from '../../charts/fusionCharts';
import EmptyPlaceholder from '../../commons/placeholders/emptyPlaceholder';
import ChartLoader from '../../commons/loaders/chartLoader';

type Props = {
    firmId: string;
    isAllowed: boolean;
    token: string;
}


const useStyles = makeStyles((theme) => ({
    headingText: {
        fontSize: "1.5em",
        fontWeight: theme.typography.fontWeightMedium,
        color: theme.palette.text.primary,
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "visible",
        flexDirection: "column",
        justifyContent: "center",
        height: 500,
    },
    textTitle: {
        color: theme.palette.text.primary,
        fontSize: "1.2em",
        fontWeight: 600,
    },
}));


function sortArrayBySequence(arrayToSort, sequenceArray) {
    const sortedArray: any = [];
    for (const name of sequenceArray) {
        const objectsWithName = arrayToSort.filter(obj => obj.name === name);
        sortedArray.push(...objectsWithName);
    }
    // Remove objects with names that are not in the sequence array
    for (const obj of arrayToSort) {
        if (!sequenceArray.includes(obj.name)) {
            const index = sortedArray.indexOf(obj);
            if (index !== -1) {
                sortedArray.splice(index, 1);
            }
        }
    }
    return sortedArray;
}

function sumSeriesData(arr) {
    const sums = {};
    for (const item of arr) {
        const seriesname = item.seriesname;
        const data = item.data;
        if (!(seriesname in sums)) {
            sums[seriesname] = data;
        } else {
            for (let i = 0; i < data.length; i++) {
                const datapoint = data[i];
                for (const [key, value] of Object.entries(datapoint)) {
                    sums[seriesname][i][key] += value;
                }
            }
        }
    }
    return Object.entries(sums).map(([seriesname, data]) => ({ seriesname, data }));
}

function removeZeroValueSeries(arr) {
    return arr.filter((item) => {
        const data = item.data;
        return !data.every((datapoint) => datapoint.value === 0);
    });
}

const CustomChartsContainer = ({ firmId, isAllowed, token }: Props) => {
    const [loader, setLoader] = useState(true);
    const [attorneyList, setAttorneyList] = useState<any>([])
    const [data, setData] = useState<any>(null)

    const classes = useStyles();

    useEffect(() => {
        let subscribe = true;
        if (loader && firmId && token && isAllowed) {
            let request = api.create(token);
            request
                .getOpenFilesvsSecondChair(firmId)
                .then((res) => {
                    const { practice_area_labels, attorney_list, matters_count_by_second_chair, matters_count_by_RA } = res.data;
                    if (subscribe) {
                        const labels = practice_area_labels.map(i => i.name)
                        const attorney = attorney_list.map(i => i.name)
                        const sortedSortedChair = sortArrayBySequence(matters_count_by_second_chair, attorney)
                        const sortedRA = sortArrayBySequence(matters_count_by_RA, attorney)

                        setAttorneyList(attorney_list.map(i => ({ label: i.name })))
                        const column1 = sumSeriesData(sortedSortedChair.map(i => ({
                            seriesname: i.practice_area_name, legendCaption: i.practice_area_name,
                            data: attorney.map(k => ({ value: k === i.name ? i.total : 0 }))
                        })))
                        const column2 = sumSeriesData(sortedRA.map(i => ({ seriesname: i.practice_area_name, legendCaption: i.practice_area_name, data: attorney.map(k => ({ value: k === i.name ? i.total : 0 })) })))
                        setData([{ dataset: column2 }, { dataset: column1 }])
                        console.log(column1)
                        // console.log([{ dataset: column1 }, { dataset: column2 }])

                        setLoader(false);
                    }

                })
                .catch((e) => {
                    setLoader(false);
                });
        }
        return () => {
            subscribe = false;
        };
    }, [loader, isAllowed]);


    const chartConfig = {
        xAxisName: "Attorney Name",
        yAxisName: "Total Matters",
        showValues: "1",
        decimals: "0",
        showLegend: '0',
        showSum: "1",
        formatNumberScale: "0",
        placevaluesinside: "1",
        showvalues: "0",
        plottooltext: "<b>$dataValue</b> $seriesName",
        theme: "fusion",
    };
    return (
        <>
            <Grid item lg={12} md={12} sm={12} xs={12} xl={12}>
                <Paper className={classes.paper}>
                    {loader ? (
                        <ChartLoader />
                    ) :
                        <>
                            <Grid container justifyContent="space-between" alignItems="center" style={{ marginBottom: 8 }}>
                                <span className={classes.textTitle}>Open Files by Responsible Attorney vs Second Chair</span>
                                <span />
                            </Grid>
                            {data.length > 0 ? (
                                <Chart
                                    dataSource={{
                                        chart: chartConfig,
                                        categories: [
                                            {
                                                category: attorneyList,
                                            },
                                        ],
                                        dataset: data

                                    }}
                                    type={"msstackedcolumn2d"}
                                    height={400}
                                />
                            ) : (
                                <EmptyPlaceholder />
                            )}
                        </>
                    }
                </Paper>
            </Grid></>
    );
}

export default CustomChartsContainer