import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Button, Grid, Paper } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import images from "../../../../images";
import useAuth from "../../../../hooks/useAuth";
export interface IntegrationPlaceholderProps {}

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  textWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "flex-start",
    paddingLeft: theme.spacing(6),
  },
  imageWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      justifyContent: "flex-start",
    },
  },
  title: {
    display: "inline-block",
    paddingBottom: theme.spacing(2),
    color: theme.palette.text.primary,
    fontFamily: "Open Sans",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipses",
    [theme.breakpoints.up("md")]: {
      fontSize: "1.6em",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "1.3em",
    },
    fontSize: "1.3em",
  },
  subHeading: {
    display: "block",
    whiteSpace: "normal",
    fontSize: "1.2em",
    fontWeight: 200,
    color: theme.palette.text.primary,
    [theme.breakpoints.up("md")]: {
      fontSize: "1.5em",
      fontWeight: 500,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "1.3em",
      fontWeight: 500,
    },
  },
  exclaimation: {
    backgroundColor: " #F25767",
    color: theme.palette.background.paper,
    height: theme.spacing(3),
    width: theme.spacing(3),
    borderRadius: theme.spacing(1.5),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "1.2em",
    marginTop: theme.spacing(1) / 2,
    marginRight: theme.spacing(2),
  },
  image: {
    height: 50,
  },
  button: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(2),
    },
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    backgroundColor: theme.palette.primary.light,
    marginRight: theme.spacing(4),
  },
  plansDiv: {
    border: "solid",
    borderColor: theme.palette.primary.main,
    borderWidth: 2,
    borderRadius: theme.spacing(1),
    overflow: "hidden",
    padding: theme.spacing(2),
    backgroundColor: "rgb(39,80,195,0.2)",
  },
  selectedPlanDiv: {
    borderColor: theme.palette.secondary.main,
    backgroundColor: "rgb(28,200,138, 0.2)",
  },
}));

const IntegrationPlaceholder: React.FC<IntegrationPlaceholderProps> = () => {
  const {
    generalStates: { activeFirm },
    integrationConfigState: { pmType },
  } = useAuth();
  const log =
    activeFirm.firm[pmType == "pp" ? "practicePanther" : "clio"].error_log;
  const classes = useStyles();
  const history = useHistory();

  const handleRedirect = () => {
    history.push("/dashboard/settings/integration");
  };

  return (
    <React.Fragment>
      <span className={classes.title}>Sync Firm</span>

      <Grid
        container
        spacing={2}
        justifyContent={"space-between"}
        alignItems={"center"}
        component={Paper}
      >
        <Grid item className={classes.imageWrapper}>
          <img
            src={images[pmType.toUpperCase()]}
            className={classes.image}
            height={200}
            alt=""
          />
          <div className={classes.textWrapper}>
            <span className={classes.exclaimation}>!</span>
            <span className={classes.subHeading}>{log}</span>
          </div>
        </Grid>

        <Grid item>
          <Button
            onClick={handleRedirect}
            variant="contained"
            color="primary"
            className={classes.button}
          >
            Sync Now
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default IntegrationPlaceholder;
