import React, { useState, useEffect, useRef, useContext } from "react";
import { InputAdornment } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import DateDropdownRange from "./dateRange";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import { WindowContext } from "../../../../../window/trackWindow";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { DateStateType } from "../../genericFilter/dateFilterStateHook";
import { DateRange } from "materialui-daterange-picker";
import _ from "lodash";
import moment from "moment";

export interface DateTextFieldProps {
  dateFilterState: DateStateType;
  handleDateFilterRange: (dateRange: DateRange) => void | any;
}

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    background: theme.palette.secondary.light,
    color: theme.palette.text.secondary,
    fontWeight: 500,
    fontSize: "0.8em",
    borderStyle: "solid",
    borderWidth: 0.5,
    borderRadius: theme.spacing(1) / 2,
    borderColor: "#EDEFF7",
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(1) / 2 + 2,
    paddingBottom: theme.spacing(1) / 2 + 2,
  },
  icon: {
    color: theme.palette.secondary.dark,
    "&:hover": {
      background: "0px 5px 5px -3px rgba(0,0,0,0.1)",
    },
  },
}));

// *****
// Parse Date Range and concatenate to string
// *****

function parseDateToString(dateFilterState: DateStateType): string {
  if (
    typeof dateFilterState.dateAfter === "undefined" ||
    typeof dateFilterState.dateBefore === "undefined"
  )
    return "";

  if (
    dateFilterState.dateBefore !== null &&
    dateFilterState.dateAfter !== null
  ) {
    const replicate = _.cloneDeep(dateFilterState);
    const concatDate: string = `${moment(replicate.dateAfter).format(
      "L"
    )} - ${moment(replicate.dateBefore).format("L")}`;
    return concatDate;
  }

  return "date from - date to";
}

const DateTextField: React.FC<DateTextFieldProps> = ({
  dateFilterState,
  handleDateFilterRange,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [currentPosition, setCurrentPosition] = useState<string>("GENERIC");
  const window = useContext(WindowContext);
  const classes = useStyles();
  const elRef = useRef<any>(null);

  // *****
  // Used to pass DOM element location information to DateRange
  // *****

  useEffect(() => {
    if (elRef !== null && elRef.current !== null) {
      const DOMBoundingBox = elRef.current.getBoundingClientRect();
      const calcRelativePosition = () => {
        // ||[element]||||||||
        if (DOMBoundingBox.left / window < 0.3) {
          setCurrentPosition("SHIFT_LEFT");
        }
        // ||||||||[element]||
        else if (DOMBoundingBox.left / window > 0.5) {
          setCurrentPosition("SHIFT_RIGHT");
        }
      };
      calcRelativePosition();
    }
  }, []);

  const handleOpen = (): void => {
    setOpen(!open);
  };

  return (
    <>
      <TextField
        ref={elRef}
        type="text"
        placeholder="date from - date to"
        value={parseDateToString(dateFilterState)}
        fullWidth
        InputProps={{
          classes: {
            root: classes.input,
          },
          disableUnderline: true,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton size={"small"} onClick={handleOpen}>
                <CalendarTodayIcon className={classes.icon} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <DateDropdownRange
        open={open}
        handleOpen={handleOpen}
        handleDateRange={handleDateFilterRange}
        currentPosition={currentPosition}
      />
    </>
  );
};

export default DateTextField;
