import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { DateRange } from "materialui-daterange-picker";
import api from "../../../../../services";
import ChartLoader from "../../../commons/loaders/chartLoader";
import Colors from "../../../../../theme/ChartTheme";
import ErrorSnackBar from "../../../commons/SnackBar";
import {
  getAmountFusion,
  getNestedListByPropertyLabelFusion,
  returnApi,
  compareFilterDates,
} from "../../utils";
import EmptyPlaceholder from "../../../commons/placeholders/emptyPlaceholder";
import FilterUserTimePractice from "../../../filters/genericFilter/filters";
import useFilterState from "../../../filters/genericFilter/filterStateHook";
import { FilterClientsMattersTypes } from "../../../filters/genericFilter/types";
import useDateFilterState, {
  DateStateType,
} from "../../../filters/genericFilter/dateFilterStateHook";
import BackForwardArrows from "../../../commons/backForwardArrows";
import TopBottomSelect from "../../../commons/topBottomSelect";
import useChartLimits from "../../../commons/chartLimitsHook";
import Chart from "../../../charts/fusionCharts";
import InfoButton from "../../../infoButton";
import useAuth from "../../../../../hooks/useAuth";

export interface ClientTopTensDuesProps {}

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "visible",
    flexDirection: "column",
    height: 650,
    justifyContent: "center",
  },
  textTitle: {
    color: theme.palette.text.primary,
    fontSize: "1.2em",
    fontWeight: 650,
  },
  headingText: {
    fontSize: "1.2em",
    fontWeight: 600,
    color: theme.palette.text.secondary,
  },
  divider: {
    backgroundColor: "#EDEFF7",
    height: 1,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  titleWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

// *****
// Initial Filter State
// *****

const initialFilterState: FilterClientsMattersTypes = {
  practiceArea: "",
  billingMethod: "",
  originatingAtt: "",
  responsibleAtt: "",
};

const initialDateFilterState: DateStateType = {
  dateAfter: null,
  dateBefore: null,
};

const ClientTopTensDues: React.FC<ClientTopTensDuesProps> = () => {
  const [loader, setLoader] = useState<boolean>(true);
  const [byOutstandingDues, setByOutstandingDues] = useState<number[]>([]);
  const [error, setError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [byOutstandingDuesNames, setByOutstandingDuesNames] = useState<any[]>(
    []
  );
  const { limitParams, handleLimitState } = useChartLimits();

  const [current, setCurrent] = useState<number>(0);

  const classes = useStyles();

  // *****
  // Current Values of Filters
  // *****

  const { filterRef, filterState, handleState } =
    useFilterState(initialFilterState);

  // *****
  // Previous Values of Filters
  // *****

  const prevFilterState = filterRef.current;

  // *****
  // Current Values of Date Filters
  // *****

  const { dateFilterRef, dateFilterState, handleDateState } =
    useDateFilterState(initialDateFilterState);

  // *****
  // Previous Values of Date Filters
  // *****

  const prevDateFilterState = dateFilterRef.current;

  const {
    generalStates: {
      token,
      activeFirm: {
        firm: { _id },
      },
    },
    integrationConfigState: { pmType },
  } = useAuth();

  // *****
  // Chart Series
  // *****

  const seriesByOutstandingDues = [
    {
      name: "By Outstanding Dues",
      color: Colors.red,
      data: byOutstandingDues,
    },
  ];

  // *****
  // Filter state updates
  // *****

  const handlePracticeAreas = (value: number | "") => {
    handleState.handlePracticeArea(value);
  };

  const handleBillingMethod = (value: string) => {
    handleState.handleBillingMethod(value);
  };

  const handleOriginatingAtt = (value: number | "") => {
    handleState.handleOriginatingAtt(value);
  };

  const handleResponsibleAtt = (value: number | "") => {
    handleState.handleResponsibleAtt(value);
  };

  const handleDateFilterRange = (dateRange: DateRange): void => {
    handleDateState.handleDateUpdate(dateRange);
  };

  // *****
  // Filter Submit - Leveraging Closure and invoking utility function compareFilterDates
  // Use Case: If any of the filter state has changed and date has not been applied, invoke action.
  // Use Case: both date values have changed with second greater than the first, invoke action.
  // *****

  const handleFilterSubmit = () => {
    handleLimitState.limitReset();
    if (
      compareFilterDates(
        filterState,
        prevFilterState,
        dateFilterState,
        prevDateFilterState,
        initialFilterState
      ) === true
    ) {
      setLoader(true);
    }
  };

  // *****
  // Clear Filter States
  // *****

  const handleFilterClear = () => {
    handleState.reset(initialFilterState);
    handleDateState.resetDate(initialDateFilterState);
    handleLimitState.limitReset();
    setLoader(true);
  };

  // *****
  // Filter
  // Conditionally render and request by Outstanding Dues API when 'filteredDues' state has changed.
  // *****

  useEffect(() => {
    let subscribe = true;
    if (loader) {
      let request = api.create(token);
      let queryParams = {
        practice_area_id:
          filterState.practiceArea === "" ? null : filterState.practiceArea,
        billing_method:
          filterState.billingMethod === "" ? null : filterState.billingMethod,
        originating_attorney_id:
          filterState.originatingAtt === "" ? null : filterState.originatingAtt,
        responsible_attorney_id:
          filterState.responsibleAtt === "" ? null : filterState.responsibleAtt,
        issued_after: dateFilterState.dateAfter,
        issued_before: dateFilterState.dateBefore,
        limit: limitParams.limit,
        offset: limitParams.offset,
      };
      const type = returnApi(
        current,
        "top10-clients-by-OD",
        "worst10-clients-by-OD"
      );
      request
        .getTopWorst(type, pmType, _id, queryParams)
        .then((response) => {
          if (subscribe) {
            let data = response.data;

            let byOutstandingDues = getAmountFusion(data);
            let byOutstandingDuesNames = getNestedListByPropertyLabelFusion(
              data,
              "name"
            );
            setByOutstandingDues(byOutstandingDues);
            setByOutstandingDuesNames(byOutstandingDuesNames);
            setLoader(false);
          }
        })
        .catch((error) => {
          if (subscribe) {
            if (error.code === "ECONNABORTED") {
              setErrorMsg("The API request timed out. Please Refresh the page");
              setError(true);
            } else {
              setErrorMsg(error.response.data.message);
              setError(true);
            }
          }
        });
    }
    return () => {
      subscribe = false;
    };
  }, [loader]);

  useEffect(() => {
    setLoader(true);
  }, [limitParams]);

  const handleForward = () => {
    handleLimitState.handleForward();
  };
  const handleBack = () => {
    handleLimitState.handleBack();
  };
  useEffect(() => {
    setLoader(true);
  }, [current]);

  const handleSelect = (val: number) => {
    if (val === 0) {
      setCurrent(1);
    } else {
      setCurrent(0);
    }
  };
  const chartConfig = {
    plottooltext: "<b>$$dataValue</b> $seriesName",
  };

  return (
    <Grid item lg={12} md={12} sm={12} xs={12} xl={12}>
      <Paper className={classes.paper}>
        {loader ? (
          <ChartLoader />
        ) : (
          <React.Fragment>
            <div className={classes.titleWrapper}>
              <TopBottomSelect
                title={"Clients By AR"}
                current={current}
                handleSelect={handleSelect}
              />
              <span>
                <InfoButton title={"Assess clients by AR."} />
                <FilterUserTimePractice
                  disabled={loader}
                  filterState={filterState}
                  dateFilterState={dateFilterState}
                  handleFilterSubmit={handleFilterSubmit}
                  handleFilterClear={handleFilterClear}
                  handlePracticeAreas={handlePracticeAreas}
                  handleBillingMethod={handleBillingMethod}
                  handleOriginatingAtt={handleOriginatingAtt}
                  handleResponsibleAtt={handleResponsibleAtt}
                  handleDateFilterRange={handleDateFilterRange}
                />
                <BackForwardArrows
                  handleBack={handleBack}
                  handleForward={handleForward}
                  disableBack={limitParams.offset < 10 ? true : false}
                />
              </span>
            </div>
            {byOutstandingDues.length > 0 ? (
              <Chart
                type={"msbar2d"}
                series={seriesByOutstandingDues}
                labels={byOutstandingDuesNames}
                height={600}
                config={chartConfig}
              />
            ) : (
              <EmptyPlaceholder height />
            )}
          </React.Fragment>
        )}
      </Paper>
      {error ? <ErrorSnackBar errorMsg={errorMsg} /> : null}
    </Grid>
  );
};

export default ClientTopTensDues;
