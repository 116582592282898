import {
  Popover,
  Backdrop,
  ButtonBase,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import React, { useContext, useState, useEffect } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import {
  tutorialStepsClioXero,
  tutorialStepsXero,
  tutorialStepsClio,
} from "./static";
import useAuth from "../../../hooks/useAuth";

interface ITutorialPopupProps {}

const useStyles = makeStyles((theme) => ({
  settingIcon: {
    marginLeft: theme.spacing(1),
  },
  next: {
    width: "100%",
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.background.paper,
    padding: theme.spacing(1.5),
  },
}));

const TutorialPopup: React.FunctionComponent<ITutorialPopupProps> = ({}) => {
  const [subStep, setSubStep] = useState<number>(0);
  const [currentTutorial, setCurrentTutorial] = useState<any>(null);
  const classes = useStyles();
  const history = useHistory();
  const { url } = useRouteMatch();

  const {
    generalStates: { tutorial, tutorialStep, tutorialPosition },
    integrationConfigState: { integrationConfig },
    generalActions: { setTutorial, setTutorialStep, resetTutorialStep },
  } = useAuth();
  useEffect(() => {
    switch (integrationConfig) {
      case "XERO":
        return setCurrentTutorial(tutorialStepsXero);
      case "CLIO":
        return setCurrentTutorial(tutorialStepsClio);
      default:
        return setCurrentTutorial(tutorialStepsClioXero);
    }
  }, [integrationConfig]);
  useEffect(() => {
    setSubStep(0);
    resetTutorialStep(0);
  }, [tutorial]);
  // set the tutorial popup open={tutorial}
  const handleClick = () => {
    if (
      currentTutorial[tutorialStep].subSteps &&
      currentTutorial[tutorialStep].data[subStep].last == false
    ) {
      setSubStep(subStep + 1);
    } else {
      setSubStep(0);
      history.push(`${url}${currentTutorial[tutorialStep].navigateTo}`);
      setTutorialStep();
    }
  };

  return (
    <Popover
      anchorReference="anchorPosition"
      open={tutorial}
      anchorPosition={{
        top: tutorialPosition.top + 20,
        left: tutorialPosition.width + 20,
      }}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      {currentTutorial !== null && (
        <div style={{ width: 300, overflow: "hidden" }}>
          <div style={{ padding: 20 }}>
            <img
              className={classes.settingIcon}
              height="40px"
              width="40px"
              src={currentTutorial[tutorialStep].icon}
            />
            <>
              <h2>{currentTutorial[tutorialStep].data[subStep].title}</h2>
              <div>
                {currentTutorial[tutorialStep].data[subStep].description}
              </div>
            </>
          </div>
          {!currentTutorial[tutorialStep].last && (
            <ButtonBase onClick={handleClick} className={classes.next}>
              {currentTutorial[tutorialStep].buttonText}
            </ButtonBase>
          )}
          <IconButton
            onClick={() => {
              setTutorial(false);
            }}
            style={{ position: "absolute", top: 0, right: 0 }}
          >
            <CloseIcon />
          </IconButton>
        </div>
      )}
    </Popover>
  );
};

export default TutorialPopup;
