import React, { useState, useContext } from "react";
import FinancialsGrowthTrends from "../../grids/financials/growthTrends";
import { StyledToggleButtonGroup, StyledToggleButton } from "../styledTabs";
import { makeStyles } from "@material-ui/core/styles";
import { WindowContext } from "../../../../window/trackWindow";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  headingText: {
    fontSize: "1.5em",
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.text.primary,
  },
  divider: {
    marginBottom: theme.spacing(1),
  },
}));

export interface FinancialsTabProps {}

const FinancialsTab: React.FC<FinancialsTabProps> = () => {
  const window = useContext(WindowContext);
  const [active, setActive] = useState(1);
  const classes = useStyles();

  const handleChange = (event, value) => {
    if (value !== null) {
      setActive(value);
    }
  };

  function switchTabs() {
    switch (active) {
      case 1:
        return <FinancialsGrowthTrends />;
    
    }
  }

  return (
    <Grid container spacing={4} justifyContent="space-between">
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <span className={classes.headingText}>Financials</span>
      </Grid>
      <StyledToggleButtonGroup
        onChange={handleChange}
        exclusive
        value={active}
        size="small"
        style={{ fontSize: window < 600 ? 11 : 13 }}
      >
        <StyledToggleButton key={1} value={1}>
          Growth & Trend
        </StyledToggleButton>
        {/* <StyledToggleButton key = {2} value = {2}>
                        Top Ten
                    </StyledToggleButton>,
                    <StyledToggleButton key = {3} value = {3} selected = { active == 3 ? true : false }>
                        Bottom Ten
                    </StyledToggleButton>, */}
        {/* <StyledToggleButton key = {4} value = {4} selected = { active === 4 }>
                        Key KPIs
                    </StyledToggleButton> */}
      </StyledToggleButtonGroup>
      <Grid
        item
        xs={12}
        // sm={12}
        // md={9}
        // lg={9}
        // xl={9}
      >
        {switchTabs()}
      </Grid>
    </Grid>
  );
};

export default FinancialsTab;
