import React, { useContext, Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import ProductivityTopTensUsers from "./topTenByUsers";
import useAuth from "../../../../../hooks/useAuth";
import { PlaceholderAcl } from "../../../../commons/placeholderAcl";
export interface ProductivityWorstTensProps {}

const ProductivityWorstTens: React.FC<ProductivityWorstTensProps> = () => {
  const {
    integrationConfigState: {
      integrationObject: {
        productivity: { users },
      },
    },
    generalStates: { acl },
  } = useAuth();
  return (
    <Grid container spacing={2}>
      {!acl?.productivity_user && <PlaceholderAcl />}
      {acl?.productivity_user &&
        users.map((item, index) => <Fragment key={index}>{item}</Fragment>)}
    </Grid>
  );
};

export default ProductivityWorstTens;
export { ProductivityTopTensUsers };
