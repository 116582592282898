import React from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import NavigateNextTwoToneIcon from "@material-ui/icons/NavigateNextTwoTone";
import NavigateBeforeTwoToneIcon from "@material-ui/icons/NavigateBeforeTwoTone";

export interface BackForwardArrowsProps {
  disableBack: boolean;
  handleBack: any;
  handleForward: any;
}

const useStyles = makeStyles((theme: Theme) => ({
  outerWrapper: {
    display: "inline-block",
  },
  button: {},
}));

const BackForwardArrows: React.FC<BackForwardArrowsProps> = ({
  handleBack,
  handleForward,
  disableBack = false,
}) => {
  const classes = useStyles();

  return (
    <span className={classes.outerWrapper}>
      <IconButton
        className={classes.button}
        aria-label="Previous Twenty"
        onClick={handleBack}
        disabled={disableBack}
      >
        <Tooltip title="Previous Twenty">
          <NavigateBeforeTwoToneIcon />
        </Tooltip>
      </IconButton>
      <IconButton
        aria-label="Next Twenty"
        className={classes.button}
        onClick={handleForward}
      >
        <Tooltip title="Next Twenty">
          <NavigateNextTwoToneIcon />
        </Tooltip>
      </IconButton>
    </span>
  );
};

export default BackForwardArrows;
