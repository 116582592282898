import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import api from "../../../services";
import ErrorSnackBar from "../../dashboard/commons/SnackBar";
import useAuth from "../../../hooks/useAuth";
import moment from "moment";
export default function Users() {
  const {
    generalStates: {
      token,
      activeFirm: {
        firm: { _id: firm },
        roleType,
      },
    },
    integrationConfigState: { pmType },
  } = useAuth();
  const [loading, setLoading] = useState(true);
  const [updated, setUpdated] = useState(false);
  const [apiError, setApiError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [users, setUsers] = useState<any[]>([]);

  useEffect(() => {
    let subscribe = true;
    if (subscribe) {
      let request = api.create(token);
      request
        .getClioUsers(firm, pmType)
        .then((res) => {
          setUsers(res.data);
          console.log(res.data);
          setLoading(false);
        })
        .catch((error) => {
          if (error.code === "ECONNABORTED") {
            setLoading(false);
            setErrorMsg("The API request timed out. Please Refresh the page");
            setApiError(true);
          } else {
            setLoading(false);
            setErrorMsg(error.response.data.message);
            setApiError(true);
          }
        });
    }

    return () => {
      subscribe = false;
    };
  }, [updated]);

  const clioColumns: any = [
    { title: "Name", field: "name", editable: "never" },
    {
      title: "Type",
      field: "subscription_type",
      lookup: {
        Attorney: "Attorney",
        ParalegalStaff: "Paralegal Staff",
        AdminStaff: "Admin Staff",
        NonAttorney: "Others",
      },
      editable: "never",
    },
    {
      title: "Date of Joining",
      field: "created_at",
      render: (row) => (
        <span>{moment(row["created_at"]).format("MM/DD/YY")}</span>
      ),
      editable: "never",
    },
    {
      title: "Weekly Notification",
      field: "weekly_notification",
      lookup: { 0: "No", 1: "Yes" }
    },
    {
      title: "Monthly Notification",
      field: "monthly_notification",
      lookup: { 0: "No", 1: "Yes" }
    },
    {
      title: "Rate per Hour",
      field: "rate",
      editable: "never",
      type: "numeric",
    },

    { title: "Hours Per Month", field: "hours_per_month", type: "numeric" },
    {
      title: "Monthly Billable Target(hrs)",
      field: "target_hours_per_month",
      type: "numeric",
    },
    {
      title: "Included In Calculations",
      field: "is_calculated",
      lookup: { 0: "No", 1: "Yes" },
    },
    {
      title: "Category",
      field: "category",
      lookup: {
        Owner: "Owner",
        Partner: "Partner",
        Attorney: "Attorney",
        Associate: "Associate",
        Paralegal: "Paralegal",
        "Admin staff": "Admin staff",
        Other: "Other",
      },
    },
  ];

  const ppColumns: any = [
    { title: "Name", field: "display_name", editable: "never" },
    {
      title: "Type",
      field: "subscription_type",
      lookup: {
        Owner: "Owner",
        Partner: "Partner",
        Attorney: "Attorney",
        Associate: "Associate",
        Paralegal: "Paralegal",
        "Admin staff": "Admin staff",
        Other: "Other",
      },
      editable: "never",
    },
    {
      title: "Date of Joining",
      field: "created_at",
      render: (row) => (
        <span>{moment(row["created_at"]).format("MM/DD/YY")}</span>
      ),
      editable: "never",
    },
    {
      title: "Rate per Hour",
      field: "rate",
      type: "numeric",
    },

    { title: "Hours Per Month", field: "hours_per_month", type: "numeric" },
    {
      title: "Monthly Billable Target(hrs)",
      field: "target_hours_per_month",
      type: "numeric",
    },
    {
      title: "Included In Calculations",
      field: "is_calculated",
      lookup: { 0: "No", 1: "Yes" },
    },
    {
      title: "Category",
      field: "category",
      lookup: {
        Owner: "Owner",
        Partner: "Partner",
        Attorney: "Attorney",
        Associate: "Associate",
        Paralegal: "Paralegal",
        "Admin staff": "Admin staff",
        Other: "Other",
      },
    },
  ];

  return (
    <React.Fragment>
      <MaterialTable
        style={{ padding: 16 }}
        title="Employee Setup"
        isLoading={loading}
        columns={pmType == "clio" ? clioColumns : ppColumns}
        data={users}
        options={{
          pageSize: 20,
          headerStyle: { position: "sticky", top: 0 },
          maxBodyHeight: "650px",
        }}
        editable={{
          onRowUpdate:
            roleType !== "USER"
              ? (newData, oldData) =>
                  new Promise((resolve) => {
                    let request = api.create(token);
                    let id = newData.id;
                    let obj = {
                      is_calculated: newData.is_calculated == 0 ? false : true,
                      target_hours_per_month: newData.target_hours_per_month,
                      hours_per_month: newData.hours_per_month,
                      category: newData.category,
                      weekly_notification: newData.weekly_notification == 0 ? false : true,
                      monthly_notification: newData.monthly_notification == 0 ? false : true
                    };
                    request
                      .updateClioUser(
                        firm,
                        pmType,
                        id,
                        pmType == "clio" ? obj : { ...obj, rate: newData.rate }
                      )
                      .then((res) => {
                        setUpdated(!updated);
                        setLoading(true);
                      })
                      .catch((error) => {
                        if (error.code === "ECONNABORTED") {
                          setLoading(false);
                          setErrorMsg(
                            "The API request timed out. Please Refresh the page"
                          );
                          setApiError(true);
                        } else {
                          setLoading(false);
                          setErrorMsg(error.response.data.message);
                          setApiError(true);
                        }
                      });
                    resolve(request);
                  })
              : undefined,
        }}
      />
      {apiError && <ErrorSnackBar errorMsg={errorMsg} />}
    </React.Fragment>
  );
}
