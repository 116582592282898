import React, { useContext, useEffect } from "react";
import { Link, useLocation, useRouteMatch } from "react-router-dom";
import { StyledTabs, StyledTab } from "./styledTabs";
import { WindowContext } from "../../../window/trackWindow";
import useAuth from "../../../hooks/useAuth";
interface ICustomTabPanelProps { }
function a11yProps(index: any) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}
const CustomTabPanel: React.FunctionComponent<ICustomTabPanelProps> = (
  props
) => {
  const [value, setValue] = React.useState(0);
  const { url, path } = useRouteMatch();
  const { pathname } = useLocation();

  const {
    integrationConfigState: {
      integrationObject: {
        clients,
        matters,
        productivity,
        financials,
        matterTracker,
        AR,
        trust,
        dashboard,
        lawmatics,
        customReports
      },
      pmType,
    },
    currentAppState: { clioHasDuplicate },
  } = useAuth();
  const window = useContext(WindowContext);

  useEffect(() => {
    if (pathname.includes("home")) {
      setValue(0);
    } else if (pathname.includes("clients")) {
      setValue(1);
    } else if (pathname.includes("matters")) {
      setValue(2);
    } else if (pathname.includes("financials")) {
      setValue(3);
    } else if (pathname.includes("productivity")) {
      setValue(4);
    } else if (pathname.includes("matter-tracker")) {
      setValue(5);
    } else if (pathname.includes("trust")) {
      setValue(6);
    } else if (pathname.includes("a-r")) {
      setValue(7);
    } else if (pathname.includes("lawmatics")) {
      setValue(8);
    } else if (pathname.includes("custom-reports")) {
      setValue(9);
    }
  }, [pathname]);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(() => newValue);
  };
  
  return (
    <StyledTabs
      value={value}
      onChange={handleChange}
      scrollButtons="on"
      centered={window > 1100 ? true : false}
      variant={window > 1100 ? "standard" : "scrollable"}
    >
      <StyledTab
        label="Home"
        {...a11yProps("home")}
        component={Link}
        disabled={!dashboard.state}
        to={`${url}`}
      />
      <StyledTab
        label="Clients"
        {...a11yProps("clients")}
        component={Link}
        disabled={!clients.state}
        to={`${url}/clients`}
      />
      <StyledTab
        label="Matters"
        {...a11yProps("matters")}
        component={Link}
        disabled={!matters.state}
        to={`${url}/matters`}
      />
      <StyledTab
        label="Financials"
        {...a11yProps("financials")}
        component={Link}
        disabled={!financials.state}
        to={`${url}/financials`}
      />
      <StyledTab
        label="Productivity"
        {...a11yProps("productivity")}
        component={Link}
        disabled={!productivity.state}
        to={`${url}/productivity`}
      />
      <StyledTab
        label="Matter Tracker"
        {...a11yProps("matter-tracker")}
        component={Link}
        disabled={!matterTracker.state}
        to={`${url}/matter-tracker`}
      />
      <StyledTab
        label="Trust"
        {...a11yProps("trust")}
        disabled={!trust.state}
        component={Link}
        to={`${url}/trust`}
      />
      <StyledTab
        label="AR"
        {...a11yProps("accounts receivable")}
        disabled={!AR.state}
        component={Link}
        to={`${url}/a-r`}
      />
      <StyledTab
        label="Lawmatics"
        {...a11yProps("lawmatics")}
        disabled={!lawmatics.state}
        component={Link}
        to={`${url}/lawmatics`}
      />
      <StyledTab
        label="Custom Reports"
        {...a11yProps("custom-reports")}
        disabled={!lawmatics.state}
        component={Link}
        to={`${url}/custom-reports`}
      />

      {/* <StyledTab
    label="Reconciliations"
    icon={<AllInboxIcon />}
    {...a11yProps(6)}
    component={Link}
    to={`${url}/reconciliations`}
  /> */}
    </StyledTabs>
  );
};

export default CustomTabPanel;
