import React from "react";
import { useLocation } from "react-router-dom";
import Signup from "../components/signUp/Signup";
import SignupWithXero from "../components/signUp/SignupWithXero";

import Layout from "./Layout";

export default function SignInSide(props) {
  const { pathname } = useLocation();

  return (
    <Layout>
      {pathname.endsWith("xero") ? (
        <SignupWithXero />
      ) : (
        <Signup location={props.location} />
      )}
    </Layout>
  );
}
