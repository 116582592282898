import React from "react";
import { MenuItem, Typography, Select } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  select: {
    background: theme.palette.secondary.light,
    color: theme.palette.text.secondary,
    minWidth: 150,
    maxWidth: 150,

    fontWeight: 500,
    fontSize: "0.8em",
    borderStyle: "solid",
    borderWidth: 0.5,
    borderRadius: theme.spacing(1) / 2,
    borderColor: "#EDEFF7",
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(1) + 1,
    paddingBottom: theme.spacing(1) + 1,
    "&:focus": {
      borderRadius: theme.spacing(1) / 2,
      background: theme.palette.secondary.light,
      borderColor: "#EDEFF7",
    },
  },
  icon: {
    color: theme.palette.text.primary,
    right: theme.spacing(1) / 2,
    position: "absolute",
    userSelect: "none",
    pointerEvents: "none",
  },
  paper: {
    borderRadius: theme.spacing(1) / 2,
    marginTop: theme.spacing(1),
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    background: "white",
    maxWidth: 200,
    minWidth: 200,
    "& li": {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      color: theme.palette.text.secondary,
      fontWeight: 500,
      fontSize: "0.9em",
      overflowWrap: "break-word",
      wordWrap: "break-word",
    },
    "& li:hover": {
      background: "0px 5px 5px -3px rgba(0,0,0,0.1)",
    },
    "& li.Mui-selected": {
      color: "white",
      background: theme.palette.primary.light,
    },
    "& li.Mui-selected:hover": {
      background: theme.palette.primary.light,
    },
  },
  text: {
    fontSize: "1em",
    fontWeight: 700,
    color: theme.palette.primary.light,
  },
  placeholderText: {
    fontSize: "0.8em",
    fontWeight: 700,
    "&:disabled": {
      color: "#fff",
    },
  },
}));

export interface DropdownProps {
  listItems: any[];
  name: string;
  value: any;
  handleChange: any;
  movingAvg?: boolean;
  yearly?: boolean;
}

const Dropdown: React.FC<DropdownProps> = ({
  listItems,
  name,
  value,
  handleChange,
  movingAvg,
  yearly,
}) => {
  const classes = useStyles();

  const iconComponent = (props) => {
    return <ExpandMoreIcon className={props.className + " " + classes.icon} />;
  };

  const menuProps = {
    classes: {
      paper: classes.paper,
      list: classes.list,
    },
    getContentAnchorEl: null,
  };

  return (
    <Select
      classes={{ root: classes.select }}
      MenuProps={menuProps}
      IconComponent={iconComponent}
      value={yearly ? value.label : value}
      onChange={(e) => handleChange(e.target.value)}
      labelId={"input-label"}
      disableUnderline
      displayEmpty
      fullWidth
    >
      <MenuItem value={""} disabled={movingAvg || yearly ? true : false}>
        <span className={classes.placeholderText}>{name}</span>
      </MenuItem>
      {typeof listItems !== "undefined"
        ? listItems.map((item, i) => {
            return (
              <MenuItem
                key={yearly ? item.label : item.id}
                value={yearly ? item.label : item.id}
              >
                <Typography variant="inherit" noWrap>
                  {yearly ? item.label : item.name}
                </Typography>
              </MenuItem>
            );
          })
        : null}
    </Select>
  );
};

export default Dropdown;
