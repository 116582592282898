import * as React from "react";
import { LinearProgress } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";

export interface ProgressBarProps {
  value: number | any;
  barColor?: any;
  percentColor?: any;
  total: number | any;
  loading?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: theme.spacing(1),
    borderRadius: theme.spacing(1) / 2,
    backgroundColor: "#EDEFF7"
  },
  bar: {
    borderRadius: theme.spacing(1) / 2
  },
  barDefaultColor: {
    backgroundColor: theme.palette.secondary.main
  },
  commonText: {
    textAlign: "end",
    display: "block",
    fontSize: "0.7em",
    fontWeight: 600,
    marginBottom: theme.spacing(1) / 2,
    marginTop: theme.spacing(1) / 2,
    minHeight: 16
  },
  animate: {
    animation: `$animateIn 1000ms ${theme.transitions.easing.easeIn}`
  },
  "@keyframes animateIn": {
    "0%": {
      opacity: 0
    },
    "100%": {
      opacity: 1
    }
  },
  totalText: {
    color: theme.palette.text.secondary
  },
  defaultPercentText: {
    color: theme.palette.primary.light
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "100%"
  }
}));

const ProgressBar: React.FC<ProgressBarProps> = ({
  value,
  barColor,
  percentColor,
  total,
  loading
}) => {
  const styledClass = useStyles();
  const totalStyle = clsx(
    styledClass.totalText,
    styledClass.commonText,
    styledClass.animate
  );

  //   *****
  //   Bar & Text Color defaults to theme light Primary
  //   *****

  const percentStyle = percentColor
    ? clsx(styledClass.commonText, percentColor, styledClass.animate)
    : clsx(
        styledClass.commonText,
        styledClass.defaultPercentText,
        styledClass.animate
      );

  const barStyle = barColor
    ? clsx(styledClass.bar, barColor)
    : clsx(styledClass.bar, styledClass.barDefaultColor);

  return (
    <div className={styledClass.wrapper}>
      <span className={!loading ? totalStyle : styledClass.commonText}>
        {!loading ? `${total}` : null}
      </span>
      <LinearProgress
        variant={loading ? "indeterminate" : "determinate"}
        color="primary"
        value={value}
        classes={{
          root: styledClass.root,
          bar: barStyle
        }}
      />
      <div style={{ maxWidth: `${value}%` }}>
        <span className={loading ? styledClass.commonText : percentStyle}>
          {!loading ? (isNaN(value) ? "error" : `${value}%`) : null}
        </span>
      </div>
    </div>
  );
};

export default ProgressBar;
