import {
  Checkbox,
  FormControlLabel,
  Grid,
  makeStyles,
  Paper,
} from "@material-ui/core";
import { DateRange } from "materialui-daterange-picker";
import React, { useContext, useState, useCallback, useEffect } from "react";
import Chart from "../../../charts/fusionCharts";
import ChartLoader from "../../../commons/loaders/chartLoader";
import EmptyPlaceholder from "../../../commons/placeholders/emptyPlaceholder";
import ErrorSnackBar from "../../../commons/SnackBar";
import useDateFilterState, {
  DateStateType,
} from "../../../filters/genericFilter/dateFilterStateHook";
import FilterUserTimePractice from "../../../filters/genericFilter/filters";
import useFilterState from "../../../filters/genericFilter/filterStateHook";
import InfoButton from "../../../infoButton";
import { compareFilterDates } from "../../utils";
import api from "../../../../../services";
import { compareNames, orderList, returnNames } from "../utils";
import _ from "lodash";
import { calcIterativePercentageHeat } from "./utils";
import { thisYear } from "../../../filters/commons/dateRangePicker/static";
import {
  StyledToggleButton,
  StyledToggleButtonGroup,
} from "../../../tabs/styledTabs";
import useAuth from "../../../../../hooks/useAuth";
interface IAppProps {}
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "visible",
    flexDirection: "column",
    minHeight: 550,
    justifyContent: "center",
  },
  textTitle: {
    color: theme.palette.text.primary,
    fontSize: "1.2em",
    fontWeight: 600,
  },
  headingText: {
    fontSize: "1.2em",
    fontWeight: 600,
    color: theme.palette.text.secondary,
  },
  divider: {
    backgroundColor: "#EDEFF7",
    height: 1,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const initialFilterState: any = {
  practiceArea: "",
  billingMethod: "",
  status: "",
};

const initialDateFilterState: DateStateType = {
  dateAfter: thisYear.startDate,
  dateBefore: thisYear.endDate,
};

const App: React.FunctionComponent<IAppProps> = (props) => {
  const classes = useStyles();
  const [loader, setLoader] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [dataSource, setDataSource] = useState({});
  const [topFilter, setTopFilter] = useState<string>("hourly");
  const [excludeNonBillable, setExcludeNonBillable] = useState<boolean>(false);
  const [current, setCurrent] = useState<string>("0");
  const [rate, setRate] = useState<boolean>(false);
  const [responses, setResponses] = useState<any>({
    available: [],
    worked: [],
    billed: [],
    collected: [],
  });
  const {
    generalStates: {
      token,
      activeFirm: {
        firm: { _id },
      },
    },
    integrationConfigState: { pmType },
  } = useAuth();

  const { filterRef, filterState, handleState } =
    useFilterState(initialFilterState);
  const { dateFilterRef, dateFilterState, handleDateState } =
    useDateFilterState(initialDateFilterState);

  const prevDateFilterState = dateFilterRef.current;
  const prevFilterState = filterRef.current;
  const handleDateFilterRange = (dateRange: DateRange): void => {
    handleDateState.handleDateUpdate(dateRange);
  };

  const handlePracticeAreas = (value: number | "") => {
    handleState.handlePracticeArea(value);
  };

  const handleStatus = (value) => {
    handleState.handleStatus(value);
  };

  const handleBillingMethod = (value) => {
    handleState.handleBillingMethod(value);
  };

  function queryParamsWorkedTime() {
    let query = {
      date_after: dateFilterState.dateAfter,
      date_before: dateFilterState.dateBefore,
      billing_method:
        filterState.billingMethod === "" ? null : filterState.billingMethod,
      status: filterState.status === "" ? null : filterState.status,
      exclude_non_billable: excludeNonBillable,
      practice_area_id:
        filterState.practiceArea === "" ? null : filterState.practiceArea,
      limit: 1000,
      offset: 0,
    };
    if (pmType == "clio") {
      return {
        ...query,
        top_filter: topFilter,
      };
    } else {
      return { ...query, rate: rate };
    }
  }
  useEffect(() => {
    let subscribe = true;
    if (loader) {
      let request = api.create(token);

      let queryParams = {
        date_after: dateFilterState.dateAfter,
        date_before: dateFilterState.dateBefore,
        practice_area_id:
          filterState.practiceArea === "" ? null : filterState.practiceArea,
        billing_method:
          filterState.billingMethod === "" ? null : filterState.billingMethod,

        status: filterState.status === "" ? null : filterState.status,
        limit: 1000,
        offset: 0,
      };

      let queryParamsAvailableTime = {
        created_after: dateFilterState.dateAfter,
        created_before: dateFilterState.dateBefore,
        limit: 1000,
        offset: 0,
      };

      Promise.all([
        request.getTopTenUsersByAvailableTimeGrowth(
          _id,
          pmType,
          queryParamsAvailableTime
        ),
        request.getTopTenUsersByBilledTimeGrowth(_id, pmType, queryParams),
        request.getTopTenUsersByCollectedTimeGrowth(_id, pmType, queryParams),
        request.getTopTenUsersByWorkedTimeGrowth(
          _id,
          pmType,
          queryParamsWorkedTime()
        ),
      ])
        .then((responses) => {
          if (subscribe) {
            let availableTimeData = responses[0].data;
            let billedTimeData = responses[1].data;
            let collectedTimeData = responses[2].data;
            let workedTimeData = responses[3].data;
            let fixedCollectedTimeData,
              fixedBilledTimeData,
              fixedAvailableTimeData;

            if (workedTimeData.length > billedTimeData.length) {
              fixedBilledTimeData = compareNames(
                billedTimeData,
                workedTimeData
              );
            } else {
              fixedBilledTimeData = billedTimeData;
            }
            if (workedTimeData.length > collectedTimeData.length) {
              fixedCollectedTimeData = compareNames(
                collectedTimeData,
                workedTimeData
              );
            } else {
              fixedCollectedTimeData = collectedTimeData;
            }
            if (workedTimeData.length > availableTimeData.length) {
              fixedAvailableTimeData = compareNames(
                availableTimeData,
                workedTimeData
              );
            } else {
              fixedAvailableTimeData = availableTimeData;
            }
            setResponses({
              available: [...fixedAvailableTimeData],
              billed: [...fixedBilledTimeData],
              collected: [...fixedCollectedTimeData],
              worked: [...workedTimeData],
            });
            setLoader(false);
          }
        })
        .catch((error) => {
          if (subscribe) {
            if (error.code === "ECONNABORTED") {
              setErrorMsg("The API request timed out. Please Refresh the page");
              setError(true);
            } else {
              // setErrorMsg(error.response.data.message);
              setError(true);
            }
          }
        });
    }
    return () => {
      subscribe = false;
    };
  }, [loader]);

  const getTime = (arr, type, label) => {
    return arr.map((item) => ({
      columnid: item.name.toLowerCase().replace(/\s/g, ""),
      rowid: label,
      value: item[type] ? item[type] : 0,
    }));
  };

  useEffect(() => {
    const { worked, collected, billed, available } = responses;
    if (
      worked.length > 0 &&
      collected.length > 0 &&
      billed.length > 0 &&
      available.length > 0
    ) {
      if (current === "0") {
        let workedTimeCorrected = getTime(worked, "total_hours", "worked_time");
        let billedTimeCorrected = getTime(billed, "total_hours", "billed_time");
        let collectedTimeCorrected = getTime(
          collected,
          "total_hours",
          "collected_time"
        );
        let availableTimeCorrected = getTime(
          available,
          "total_hours",
          "available_time"
        );
        let names = returnNames(responses.worked);
        let column = names.map((item) => ({
          id: item.toLowerCase().replace(/\s/g, ""),
          label: item,
        }));
        let values = [
          ...availableTimeCorrected,
          ...workedTimeCorrected,
          ...billedTimeCorrected,
          ...collectedTimeCorrected,
        ].map((item) => item.value);
        let mv = Math.max(...(values as any));
        let row = [
          {
            id: "available_time",
            label: "Available Time",
          },
          {
            id: "worked_time",
            label: "Worked Time",
          },
          {
            id: "billed_time",
            label: "Billed Time",
          },
          {
            id: "collected_time",
            label: "Collected Time ",
          },
        ];
        let configuration = {
          chart: {
            theme: "fusion",
            showvalues: "1",
            valuefontcolor: "#ffffff",
            plottooltext:
              "<div><b>$rowLabel</b><br/>$columnLabel <b>$datavalue hrs</b></div>",
          },
          rows: {
            row: row,
          },
          columns: {
            column: column,
          },
          dataset: [
            {
              data: [
                ...availableTimeCorrected,
                ...workedTimeCorrected,
                ...billedTimeCorrected,
                ...collectedTimeCorrected,
              ],
            },
          ],
          colorrange: {
            gradient: "1",
            minvalue: "0",
            maxvalue: mv.toString(),
            mapbypercent: "0",
            code: "#1488CC",
            startlabel: "Minimum",
            endlabel: "Maximum",
          },
        };
        setDataSource(configuration);
      }
      if (current === "1") {
        let workedTimeCorrected = getTime(
          worked,
          "total_value",
          "worked_value"
        );
        let billedTimeCorrected = getTime(
          billed,
          "total_value",
          "billed_value"
        );
        let collectedTimeCorrected = getTime(
          collected,
          "total_value",
          "collected_value"
        );
        let availableTimeCorrected = getTime(
          available,
          "total_value",
          "available_value"
        );
        let names = returnNames(worked);
        let column = names.map((item) => ({
          id: item.toLowerCase().replace(/\s/g, ""),
          label: item,
        }));
        let values = [
          ...availableTimeCorrected,
          ...workedTimeCorrected,
          ...billedTimeCorrected,
          ...collectedTimeCorrected,
        ].map((item) => item.value);

        let mv = Math.max(...(values as any));
        let row = [
          {
            id: "available_value",
            label: "Available Value",
          },
          {
            id: "worked_value",
            label: "Worked Value",
          },
          {
            id: "billed_value",
            label: "Billed Value",
          },
          {
            id: "collected_value",
            label: "Collected Value ",
          },
        ];
        let configuration = {
          chart: {
            theme: "fusion",
            showvalues: "1",
            valuefontcolor: "#ffffff",
            plottooltext:
              "<div><b>$rowLabel</b><br/>$columnLabel <b>$ $datavalue</b></div>",
          },
          rows: {
            row: row,
          },
          columns: {
            column: column,
          },
          dataset: [
            {
              data: [
                ...availableTimeCorrected,
                ...workedTimeCorrected,
                ...billedTimeCorrected,
                ...collectedTimeCorrected,
              ],
            },
          ],
          colorrange: {
            gradient: "1",
            minvalue: "0",
            maxvalue: mv.toString(),
            mapbypercent: "0",
            code: "#1488CC",
            startlabel: "Minimum",
            endlabel: "Maximum",
          },
        };
        setDataSource(configuration);
      }
      if (current === "2") {
        let names = returnNames(worked);
        let workedTimeCorrected = getTime(
          calcIterativePercentageHeat(worked, orderList(available, names)),
          "total_hours",
          "worked_percentage"
        );
        let billedTimeCorrected = getTime(
          calcIterativePercentageHeat(orderList(billed, names), worked),
          "total_hours",
          "billed_percentage"
        );
        let collectedTimeCorrected = getTime(
          calcIterativePercentageHeat(
            orderList(collected, names),
            orderList(billed, names)
          ),
          "total_hours",
          "collected_percentage"
        );
        let newNames = names.map((item) => ({ name: item, total_hours: 100 }));
        let availableTimeCorrected = getTime(
          newNames,
          "total_hours",
          "available_percentage"
        );
        let column = names.map((item) => ({
          id: item.toLowerCase().replace(/\s/g, ""),
          label: item,
        }));
        let values = [
          ...availableTimeCorrected,
          ...workedTimeCorrected,
          ...billedTimeCorrected,
          ...collectedTimeCorrected,
        ].map((item) => item.value);
        let mv = Math.max(...(values as any));
        let row = [
          {
            id: "available_percentage",
            label: "Available Percentage",
          },
          {
            id: "worked_percentage",
            label: "Worked Percentage",
          },
          {
            id: "billed_percentage",
            label: "Billed Percentage",
          },
          {
            id: "collected_percentage",
            label: "Collected Percentage ",
          },
        ];
        let configuration = {
          chart: {
            theme: "fusion",
            showvalues: "1",
            valuefontcolor: "#ffffff",
            plottooltext:
              "<div><b>$rowLabel</b><br/>$columnLabel <b>$datavalue %</b></div>",
          },
          rows: {
            row: row,
          },
          columns: {
            column: column,
          },
          dataset: [
            {
              data: [
                ...availableTimeCorrected,
                ...workedTimeCorrected,
                ...billedTimeCorrected,
                ...collectedTimeCorrected,
              ],
            },
          ],
          colorrange: {
            gradient: "1",
            minvalue: "0",
            maxvalue: mv.toString(),
            mapbypercent: "0",
            code: "#1488CC",
            startlabel: "Minimum",
            endlabel: "Maximum",
          },
        };
        setDataSource(configuration);
      }
    }
  }, [current, responses]);

  useEffect(() => {
    setLoader(true);
  }, [topFilter, excludeNonBillable, rate]);

  // *****
  // Filter Submit
  // Use Case 1: If dates have been applied, then check for date consistency.
  // Use Case 2: All filters have been applied and changed.
  // Use Case 3: Dates have been applied and changed.
  // *****

  const handleFilterSubmit = () => {
    //if filters or date Filters have changed
    if (
      compareFilterDates(
        filterState,
        prevFilterState,
        dateFilterState,
        prevDateFilterState,
        initialFilterState
      ) === true
    ) {
      setLoader(true);
    }
  };

  // *****
  // Clear Filter States
  // *****

  const handleFilterClear = useCallback(() => {
    handleState.reset(initialFilterState);
    handleDateState.resetDate(initialDateFilterState);
    setLoader(true);
  }, []);

  return (
    <Grid item lg={12} md={12} sm={12} xs={12} xl={12}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          {loader ? (
            <ChartLoader />
          ) : (
            <React.Fragment>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <span className={classes.textTitle}>
                  Available, Worked, Billed & Collected Time
                </span>
                <span>
                  <InfoButton
                    title={
                      "Monitor or track employee billed hours based on set goals."
                    }
                  />
                  
                  <FilterUserTimePractice
                    filterState={filterState}
                    dateFilterState={dateFilterState}
                    disabled={loader}
                    handleDateFilterRange={handleDateFilterRange}
                    handleFilterSubmit={handleFilterSubmit}
                    handleFilterClear={handleFilterClear}
                    handlePracticeAreas={handlePracticeAreas}
                    handleStatus={handleStatus}
                    handleBillingMethod={handleBillingMethod}
                  />
                </span>
              </Grid>
              <Grid
                container
                justifyContent={"space-around"}
                alignItems={"center"}
                spacing={4}
              >
                {pmType == "clio" && (
                  <Grid item>
                    <StyledToggleButtonGroup
                      onChange={(event, value) =>
                        value !== null && setTopFilter(value)
                      }
                      exclusive
                      value={topFilter}
                      size="small"
                    >
                      <StyledToggleButton key={1} value={"hourly"}>
                        Hourly
                      </StyledToggleButton>
                      <StyledToggleButton key={2} value={"contingency"}>
                        Contingency
                      </StyledToggleButton>
                      <StyledToggleButton key={3} value={"flat"}>
                        Flat
                      </StyledToggleButton>
                    </StyledToggleButtonGroup>
                  </Grid>
                )}
                {pmType == "pp" && (
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={rate}
                          onChange={() => setRate(!rate)}
                          name="checkedA"
                        />
                      }
                      label="Zero Rate"
                    />
                  </Grid>
                )}
                <Grid item>
                  <StyledToggleButtonGroup
                    onChange={(event, value) => setCurrent(value)}
                    exclusive
                    value={current}
                    size="small"
                  >
                    <StyledToggleButton key={1} value={"0"}>
                      Hour
                    </StyledToggleButton>
                    <StyledToggleButton key={2} value={"1"}>
                      Value
                    </StyledToggleButton>
                    <StyledToggleButton key={3} value={"2"}>
                      Percentage
                    </StyledToggleButton>
                  </StyledToggleButtonGroup>
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={excludeNonBillable}
                        onChange={() =>
                          setExcludeNonBillable(!excludeNonBillable)
                        }
                        name="checkedA"
                      />
                    }
                    label="Exclude Non Billable"
                  />
                </Grid>
              </Grid>
              {responses.worked.length > 0 &&
              responses.collected.length > 0 &&
              responses.available.length > 0 &&
              responses.billed.length > 0 ? (
                <Chart type="heatmap" height={400} dataSource={dataSource} />
              ) : (
                <EmptyPlaceholder />
              )}
            </React.Fragment>
          )}
        </Paper>
      </Grid>
      {error ? <ErrorSnackBar errorMsg={errorMsg} /> : null}
    </Grid>
  );
};

export default App;
