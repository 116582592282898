import React from "react";
import { useRouteMatch, Switch } from "react-router-dom";
import Settings from "../components/settings/settings";
import OnBoarding from "../components/dashboard/onboarding/OnBoarding";
import PrivateRoute from "../router/PrivateRoute";
import PrivateOnboardingRoute from "../router/PrivateOnboardingRoute";
import AuthRedirect from "../containers/AuthRedirect";
import { TabPanel } from "../components/dashboard/tabs";
import Support from "../components/dashboard/support";
import AdminLogs from "../components/dashboard/adminLogs";

export default function PrivateDashBoardRoutes({ userType }) {
  const { path } = useRouteMatch();
  return (
    <React.Fragment>
      {/* Add the private route for support before the tab panel component, switch returns true on the first path match */}
      <Switch>
        <PrivateRoute
          exact
          path={`${path}/onboarding`}
          component={OnBoarding}
        />
        <PrivateRoute
          exact
          path={`${path}/auth-redirect`}
          component={AuthRedirect}
        />
        <PrivateRoute
          path={`${path}/auth-redirect-xero`}
          component={AuthRedirect}
        />
        <PrivateRoute
          path={`${path}/auth-redirect-pp`}
          component={AuthRedirect}
        />
        <PrivateOnboardingRoute
          path={`${path}/settings`}
          component={Settings}
        />
        <PrivateOnboardingRoute path={`${path}/support`} component={Support} />
        {userType === "SUPER_ADMIN" && (
          <PrivateOnboardingRoute path={`${path}/logs`} component={AdminLogs} />
        )}
        <PrivateOnboardingRoute path={`${path}`} component={TabPanel} />
      </Switch>
    </React.Fragment>
  );
}

// integrationConfig !== null &&
//             (integrationConfig === "XERO" ? FinancialsGrowthTrends : TabPanel)
