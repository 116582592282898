import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import ChartLoader from "../../../commons/loaders/chartLoader";
import api from "../../../../../services";
import Colors from "../../../../../theme/ChartTheme";
import ErrorSnackBar from "../../../commons/SnackBar";
import FilterUserTimePractice from "../../../filters/genericFilter/filters";
import { compareNames, returnNames, checkDate, orderList } from "../utils";
import EmptyPlaceholder from "../../../commons/placeholders/emptyPlaceholder";
import useDateFilterState, {
  DateStateType,
} from "../../../filters/genericFilter/dateFilterStateHook";
import { DateRange } from "materialui-daterange-picker";
import useChartLimits from "../../../commons/chartLimitsHook";
import BackForwardArrows from "../../../commons/backForwardArrows";
import Chart from "../../../charts/fusionCharts";
import InfoButton from "../../../infoButton";
import TopBottomSelect from "../../../commons/topBottomSelect";
import { returnValueHours } from "../growthTrends/utils";
import { FormControlLabel, Checkbox, Grid, Paper } from "@material-ui/core";
import useFilterState from "../../../filters/genericFilter/filterStateHook";
import { thisYear } from "../../../filters/commons/dateRangePicker/static";
import {
  StyledToggleButtonGroup,
  StyledToggleButton,
} from "../../../tabs/styledTabs";
import useAuth from "../../../../../hooks/useAuth";
export interface ProductivityTopTensUsersProps {}

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "visible",
    flexDirection: "column",
    justifyContent: "center",
  },
  paperHeight: {
    minHeight: 550,
  },
  textTitle: {
    color: theme.palette.text.primary,
    fontSize: "1.2em",
    fontWeight: 600,
  },
  textSubtitle: {
    color: theme.palette.text.secondary,
    fontSize: "0.8em",
  },
  headingText: {
    fontSize: "1.2em",
    fontWeight: 600,
    color: theme.palette.text.secondary,
  },
  divider: {
    backgroundColor: "#EDEFF7",
    height: 1,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  titleWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

// *****
// Initial Filter State
// *****

const initialFilterState: any = {
  practiceArea: "",
  billingMethod: "",
  status: "",
};

const initialDateFilterState: DateStateType = {
  dateAfter: thisYear.startDate,
  dateBefore: thisYear.endDate,
};

interface chartType {
  worked: any[];
  collected: any[];
  billed: any[];
  available: any[];
}

const ProductivityTopTensUsers: React.FC<ProductivityTopTensUsersProps> =
  () => {
    const [error, setError] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string>("");
    const [loading2, setLoading2] = useState<boolean>(true);
    const [current, setCurrent] = useState<number>(0);
    const [hourValue, setHourValue] = useState<string>("0");
    const [topFilter, setTopFilter] = useState<string>("hourly");
    const [rate, setRate] = useState<boolean>(false);
    const [excludeNonBillable, setExcludeNonBillable] =
      useState<boolean>(false);
    const [chart, setChart] = useState<chartType>({
      worked: [],
      collected: [],
      billed: [],
      available: [],
    });
    const [names, setNames] = useState<string[]>([]);
    const { limitParams, handleLimitState } = useChartLimits();

    const {
      generalStates: {
        token,
        activeFirm: {
          firm: { _id },
        },
      },
      integrationConfigState: { pmType },
    } = useAuth();
    // *****
    // Current Values of Date Filters
    // *****
    const { filterRef, filterState, handleState } =
      useFilterState(initialFilterState);
    const { dateFilterRef, dateFilterState, handleDateState } =
      useDateFilterState(initialDateFilterState);

    const classes = useStyles();
    const fixedHeightPaper = clsx(classes.paper, classes.paperHeight);

    const seriesColumn = [
      {
        seriesname: "Available",
        color: Colors.blue,
        data: chart.available[parseInt(hourValue)],
      },
      {
        seriesname: "Worked",
        color: Colors.orange,
        data: chart.worked[parseInt(hourValue)],
      },
      {
        seriesname: "Billed",
        color: Colors.red,
        data: chart.billed[parseInt(hourValue)],
      },
      {
        seriesname: "Collected",
        color: Colors.green,
        data: chart.collected[parseInt(hourValue)],
      },
    ];

    // *****
    // Filter state updates
    // *****

    const handleDateFilterRange = (dateRange: DateRange): void => {
      handleDateState.handleDateUpdate(dateRange);
    };
    const handlePracticeAreas = (value: number | "") => {
      handleState.handlePracticeArea(value);
    };

    const handleStatus = (value) => {
      handleState.handleStatus(value);
    };

    const handleBillingMethod = (value) => {
      handleState.handleBillingMethod(value);
    };
    // *****
    // Filter Submit
    // *****

    const handleFilterSubmit = () => {
      handleLimitState.limitReset();
      if (checkDate(dateFilterState.dateAfter, dateFilterState.dateBefore)) {
        setLoading2(true);
      } else if (
        dateFilterState.dateAfter === null &&
        dateFilterState.dateBefore === null
      ) {
        setLoading2(true);
      }
    };
    const handleSelect = (val: number) => {
      if (val === 0) {
        setCurrent(1);
      } else {
        setCurrent(0);
      }
    };

    // *****
    // Clear Filter States
    // *****

    const handleFilterClear = () => {
      handleDateState.resetDate(initialDateFilterState);
      handleState.reset(initialFilterState);
      handleLimitState.limitReset();
      setLoading2(true);
    };

    function queryParamsWorkedTime() {
      let query = {
        date_after: dateFilterState.dateAfter,
        date_before: dateFilterState.dateBefore,
        billing_method:
          filterState.billingMethod === "" ? null : filterState.billingMethod,
        status: filterState.status === "" ? null : filterState.status,
        exclude_non_billable: excludeNonBillable,
        practice_area_id:
          filterState.practiceArea === "" ? null : filterState.practiceArea,
        limit: 1000,
        offset: 0,
      };
      if (pmType == "clio") {
        return {
          ...query,
          top_filter: topFilter,
        };
      } else return { ...query, rate: rate };
    }

    useEffect(() => {
      let subscribe = true;
      if (loading2) {
        let request = api.create(token);

        let queryParamsAvailableTime = {
          user_id: null,
          created_after: dateFilterState.dateAfter,
          created_before: dateFilterState.dateBefore,
          limit: limitParams.limit,
          offset: limitParams.offset,
        };
        let queryParams = {
          user_id: null,
          date_after: dateFilterState.dateAfter,
          date_before: dateFilterState.dateBefore,
          billing_method:
            filterState.billingMethod === "" ? null : filterState.billingMethod,
          status: filterState.status === "" ? null : filterState.status,
          practice_area_id:
            filterState.practiceArea === "" ? null : filterState.practiceArea,
          limit: limitParams.limit,
          offset: limitParams.offset,
        };
        Promise.all(
          current == 0
            ? [
                request.getTopTenUsersByAvailableTime(
                  _id,
                  pmType,
                  queryParamsAvailableTime
                ),
                request.getTopTenUsersByBilledTime(_id, pmType, queryParams),
                request.getTopTenUsersByCollectedTime(_id, pmType, queryParams),
                request.getTopTenUsersByWorkedTime(
                  _id,
                  pmType,
                  queryParamsWorkedTime()
                ),
              ]
            : [
                request.getWorstTenUsersByAvailableTime(
                  _id,
                  pmType,
                  queryParamsAvailableTime
                ),
                request.getWorstTenUsersByBilledTime(_id, pmType, queryParams),
                request.getWorstTenUsersByCollectedTime(
                  _id,
                  pmType,
                  queryParams
                ),
                request.getWorstTenUsersByWorkedTime(
                  _id,
                  pmType,
                  queryParamsWorkedTime()
                ),
              ]
        )
          .then((responses) => {
            if (subscribe) {
              let availableTimeData = responses[0].data;
              let billedTimeData = responses[1].data;
              let collectedTimeData = responses[2].data;
              let workedTimeData = responses[3].data;
              let fixedCollectedTimeData,
                fixedBilledTimeData,
                fixedAvailableTimeData;

              if (workedTimeData.length > billedTimeData.length) {
                fixedBilledTimeData = compareNames(
                  billedTimeData,
                  workedTimeData
                );
              } else {
                fixedBilledTimeData = billedTimeData;
              }
              if (workedTimeData.length > collectedTimeData.length) {
                fixedCollectedTimeData = compareNames(
                  collectedTimeData,
                  workedTimeData
                );
              } else {
                fixedCollectedTimeData = collectedTimeData;
              }
              if (workedTimeData.length > availableTimeData.length) {
                fixedAvailableTimeData = compareNames(
                  availableTimeData,
                  workedTimeData
                );
              } else {
                fixedAvailableTimeData = availableTimeData;
              }
              let names = returnNames(workedTimeData);

              let { hours: billedHours, value: billedValue } = returnValueHours(
                orderList(fixedBilledTimeData, names)
              );
              let { hours: workedHours, value: workedValue } =
                returnValueHours(workedTimeData);
              let { hours: collectedHours, value: collectedValue } =
                returnValueHours(orderList(fixedCollectedTimeData, names));
              let { hours: availableHours, value: availableValue } =
                returnValueHours(orderList(fixedAvailableTimeData, names));

              setNames(
                names.map((item) => ({
                  label: item,
                }))
              );
              setChart({
                billed: [
                  billedHours.map((item) => ({ value: item })),
                  billedValue.map((item) => ({ value: item })),
                ],
                worked: [
                  workedHours.map((item) => ({ value: item })),
                  workedValue.map((item) => ({ value: item })),
                ],
                collected: [
                  collectedHours.map((item) => ({ value: item })),
                  collectedValue.map((item) => ({ value: item })),
                ],
                available: [
                  availableHours.map((item) => ({ value: item })),
                  availableValue.map((item) => ({ value: item })),
                ],
              });

              setTimeout(() => {
                setLoading2(false);
              }, 1200);
            }
          })
          .catch((error) => {
            if (subscribe) {
              if (error.code === "ECONNABORTED") {
                setErrorMsg(
                  "The API request timed out. Please Refresh the page"
                );
                setError(true);
              } else {
                setErrorMsg(error.response.data.message);
                setError(true);
              }
            }
          });
      }
      return () => {
        subscribe = false;
      };
    }, [loading2]);

    useEffect(() => {
      setLoading2(true);
    }, [limitParams, current, topFilter, excludeNonBillable, rate]);

    const handleForward = () => {
      handleLimitState.handleForward();
    };
    const handleBack = () => {
      handleLimitState.handleBack();
    };
    const hoursconfig = {
      yAxisName: "Hours",
      numberSuffix: " hrs",
    };
    const valueConfig = {
      yAxisName: "Amount",
      numberPrefix: "$",
    };
    return (
      <Grid item lg={12} md={12} sm={12} xs={12} xl={12}>
        <Paper className={fixedHeightPaper}>
          {loading2 ? (
            <ChartLoader />
          ) : (
            <React.Fragment>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <span className={classes.textTitle}>
                  <TopBottomSelect
                    title={"Users by Productivity"}
                    current={current}
                    handleSelect={handleSelect}
                  />
                </span>
                <span>
                  <InfoButton title={"Users by Productivity"} />
                  <FilterUserTimePractice
                    dateFilterState={dateFilterState}
                    filterState={filterState}
                    disabled={loading2}
                    handleDateFilterRange={handleDateFilterRange}
                    handleFilterClear={handleFilterClear}
                    handleFilterSubmit={handleFilterSubmit}
                    handlePracticeAreas={handlePracticeAreas}
                    handleStatus={handleStatus}
                    handleBillingMethod={handleBillingMethod}
                  />
                  <BackForwardArrows
                    handleBack={handleBack}
                    handleForward={handleForward}
                    disableBack={limitParams.offset < 10 ? true : false}
                  />
                </span>
              </Grid>
              <Grid
                container
                justifyContent={"space-around"}
                alignItems={"center"}
                spacing={4}
              >
                {pmType == "clio" && (
                  <Grid item>
                    <StyledToggleButtonGroup
                      onChange={(event, value) =>
                        value !== null && setTopFilter(value)
                      }
                      exclusive
                      value={topFilter}
                      size="small"
                    >
                      <StyledToggleButton key={1} value={"hourly"}>
                        Hourly
                      </StyledToggleButton>
                      <StyledToggleButton key={2} value={"contingency"}>
                        Contingency
                      </StyledToggleButton>
                      <StyledToggleButton key={3} value={"flat"}>
                        Flat
                      </StyledToggleButton>
                    </StyledToggleButtonGroup>
                  </Grid>
                )}
                {pmType == "pp" && (
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={rate}
                          onChange={() => setRate(!rate)}
                          name="checkedA"
                        />
                      }
                      label="Zero Rate"
                    />
                  </Grid>
                )}
                <Grid item>
                  <StyledToggleButtonGroup
                    onChange={(event, value) => setHourValue(value)}
                    exclusive
                    value={hourValue}
                    size="small"
                  >
                    <StyledToggleButton key={1} value={"0"}>
                      Hour
                    </StyledToggleButton>
                    <StyledToggleButton key={2} value={"1"}>
                      Value
                    </StyledToggleButton>
                  </StyledToggleButtonGroup>
                </Grid>

                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={excludeNonBillable}
                        onChange={() =>
                          setExcludeNonBillable(!excludeNonBillable)
                        }
                        name="checkedA"
                      />
                    }
                    label="Exclude Non Billable"
                  />
                </Grid>
              </Grid>
              {chart.available.length > 0 || chart.collected.length > 0 ? (
                <Chart
                  type={"mscolumn2d"}
                  labels={names}
                  series={seriesColumn}
                  height={400}
                  config={hourValue == "0" ? hoursconfig : valueConfig}
                />
              ) : (
                <EmptyPlaceholder />
              )}
            </React.Fragment>
          )}
        </Paper>
        {error ? <ErrorSnackBar errorMsg={errorMsg} /> : null}
      </Grid>
    );
  };

export default ProductivityTopTensUsers;
