import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Loader from "./loader";

const useStyles = makeStyles((theme) => ({
  wrapperContainer: {
    alignSelf: "center",
    justifySelf: "center",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  loadingText: {
    [theme.breakpoints.up("xl")]: {
      fontSize: "1.5em",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.3em",
    },
    fontSize: "1.2em",
    fontWeight: 500,
    marginTop: theme.spacing(2),
  },
}));

const ChartLoader = () => {
  const classes = useStyles();

  return (
    <div className={classes.wrapperContainer}>
      <Loader />
      <span className={classes.loadingText}>Fetching chart</span>
    </div>
  );
};

export default ChartLoader;
