import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import SignupStep0 from "./SignupStep0";
import SignupStep1 from "./SignupStep1";
import SignupStep2 from "./SignupStep2";
import SignupStep3 from "./SignupStep3";
import api from "../../services";
import Alert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";
import queryString from "query-string";
import LocationWrapper from "../locationWrapper";
import { StyledGradientButton } from "../commons/styledButtons";
import images from "../../images";
import {
  CircularProgress,
  Grid,
  Button,
  Step,
  Stepper,
  StepLabel,
  Typography,
  IconButton,
  StepConnector,
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  buttonBack: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(1, 0),
    backgroundColor: theme.palette.secondary.main,
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
    },
    "&:disabled": {
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.secondary.dark,
    },
  },
  stepper: {
    backgroundColor: "transparent",
  },
  step: {
    color: `${theme.palette.primary.light} !important`,
  },
  passwordContainer: {
    marginTop: theme.spacing(3),
  },
  image: {
    width: 150,
  },
  bodyText: {
    display: "block",
    fontSize: "1em",
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.primary,
    width: theme.spacing(20),
    textAlign: "center",
    backgroundColor: "#fff",
    marginLeft: theme.spacing(18),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      marginLeft: theme.spacing(2),
      fontSize: "1em",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "1.4em",
    },
  },
  headingText: {
    display: "block",
    fontFamily: "Open Sans",
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(4),
    fontSize: "2em",
    fontWeight: 600,
    color: theme.palette.text.primary,
    [theme.breakpoints.down("md")]: {
      fontSize: "1.4em",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "1.8em",
    },
  },
  linkWrapper: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
    marginTop: theme.spacing(2),
  },
  link: {
    textDecoration: "none",
    color: theme.palette.primary.dark,
    fontSize: "1em",
    fontWeight: 500,
    [theme.breakpoints.up("xl")]: {
      fontSize: "1.1em",
    },
  },
  successText: {
    margin: theme.spacing(4, 0),
  },
  alertWrapper: {
    marginTop: theme.spacing(2),
  },
  loader: {
    marginLeft: theme.spacing(1),
    color: theme.palette.background.paper,
  },
  xero: {
    marginTop: theme.spacing(3),
    // padding: theme.spacing(1),
    textTransform: "none",
    fontFamily: "Open Sans",
    color: "#fff",
    borderColor: "#00B8E3",
    borderStyle: "solid",
    borderWidth: "1px",
    fontSize: "0.8em",
    "&:hover": {
      backgroundColor: theme.palette.background.paper,
      color: "#00B8E3",
      borderColor: "#00B8E3",
      borderStyle: "solid",
      borderWidth: "1px",
    },
    "&:disabled": {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.dark,
    },
    backgroundColor: "#00B8E3",
  },
  stepLine: {
    [theme.breakpoints.down("sm")]: {
      borderWidth: 0,
    },
  },
}));

const steps = ["Info", "Company", "Type", "Verification"];

function getStepContent(
  accept,
  step,
  userType,
  handleUserType,
  firstName,
  handleFirstName,
  lastName,
  handleLastName,
  email,
  handleEmail,
  password,
  handlePassword,
  companyName,
  handleCompanyName,
  address,
  handleAddress,
  phoneNumber,
  handlePhoneNumber,
  clients,
  handleClients,
  quickBooks,
  handleQuickBooks,
  clioPP,
  handleClioPP,
  privacy,
  handlePrivacy,
  termsConditions,
  handleTermsConditions,
  recaptcha,
  handleRecaptcha
) {
  switch (step) {
    case 0:
      return (
        <SignupStep1
          accept={accept}
          firstName={firstName}
          lastName={lastName}
          email={email}
          password={password}
          handleFirstName={handleFirstName}
          handleLastName={handleLastName}
          handleEmail={handleEmail}
          handlePassword={handlePassword}
        />
      );

    case 1:
      return (
        <SignupStep2
          companyName={companyName}
          address={address}
          phoneNumber={phoneNumber}
          handlePhoneNumber={handlePhoneNumber}
          handleAddress={handleAddress}
          handleCompanyName={handleCompanyName}
        />
      );
    case 2:
      return (
        <SignupStep0
          userType={userType}
          handleUserType={handleUserType}
          clients={clients}
          quickBooks={quickBooks}
          clioPP={clioPP}
          handleClients={handleClients}
          handleQuickBooks={handleQuickBooks}
          handleClioPP={handleClioPP}
        />
      );
    case 3:
      return (
        <SignupStep3
          privacy={privacy}
          termsConditions={termsConditions}
          handlePrivacy={handlePrivacy}
          handleTermsConditions={handleTermsConditions}
          // recaptcha={recaptcha}
          handleRecaptcha={handleRecaptcha}
        />
      );
    default:
      throw new Error("Unknown step");
  }
}

export default function Signup(props) {
  const classes = useStyles();
  const values = queryString.parse(props.location.search);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [userType, setUserType] = useState<number>(0);
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
  const [error, setError] = useState<string>("");
  const [companyName, setCompanyName] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [clients, setClients] = useState<number>(0);
  const [quickBooks, setQuickBooks] = useState<number>(0);
  const [clioPP, setClioPP] = useState<number>(0);
  const [privacy, setPrivacy] = useState<boolean>(false);
  const [termsConditions, setTermsConditions] = useState<boolean>(false);
  const [recaptcha, setRecaptcha] = useState<boolean>(false);
  const [conflict, setConflict] = useState<boolean>(false);
  const [accept, setAccept] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  // *****
  // accept
  // *****

  useEffect(() => {
    if (values.userID && values.emailToken) {
      setAccept(true);
    }
  }, []);

  // *****
  // Input forms check
  // *****

  useEffect(() => {
    let passwordRgx =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*._-])(?=.{8,})/;
    if (
      activeStep === 0 &&
      firstName.length > 0 &&
      lastName.length > 0 &&
      (email.length > 0 || accept) &&
      password.length > 0 &&
      passwordRgx.test(password)
    ) {
      setIsButtonDisabled(false);
    } else if (
      activeStep === 1 &&
      companyName.trim() &&
      address.trim() &&
      phoneNumber.trim()
    ) {
      setIsButtonDisabled(false);
    } else if (activeStep === 2) {
      setIsButtonDisabled(false);
    } else if (activeStep === 3 && privacy && termsConditions) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [
    activeStep,
    firstName,
    lastName,
    email,
    password,
    companyName,
    address,
    phoneNumber,
    privacy,
    termsConditions,
  ]);

  const handleNext = () => {
    let request = api.create("");
    setConflict(false);
    if (activeStep === 0 && !accept) {
      setLoading(true);
      request
        .emailAvailability({ email: email })
        .then(res => {
          setActiveStep(activeStep + 1);
          setLoading(false);
        })
        .catch(err => {
          setConflict(true);
          setError("User with this email already exists.");
          setLoading(false);
        });
    } else if (activeStep < 3) {
      setActiveStep(activeStep + 1);
    } else {
      setIsButtonDisabled(true);
      if (!accept) {
        if (userType === 0) {
          request
            .register({
              email: email,
              password: password,
              firstName: firstName,
              lastName: lastName,
              companyInfo: {
                companyName: companyName,
                address: address,
                phone: phoneNumber,
              },
            })
            .then(res => {
              setIsButtonDisabled(false);
              setActiveStep(activeStep + 1);
            })
            .catch(error => {
              if (error.code === "ECONNABORTED") {
                setConflict(true);
                setError("The API request timed out. Please Refresh the page");
                setIsButtonDisabled(false);
              } else {
                setConflict(true);
                setError(error.response.data.message);
                setIsButtonDisabled(false);
              }
            });
        } else {
          request
            .register({
              email: email,
              password: password,
              firstName: firstName,
              lastName: lastName,
              companyInfo: {
                companyName: companyName,
                address: address,
                phone: phoneNumber,
                coach: {
                  firmClients: clients,
                  quickBookFirms: quickBooks,
                  clioOrPracticePantherFirms: clioPP,
                },
              },
            })
            .then(res => {
              setActiveStep(activeStep + 1);
            })
            .catch(error => {
              if (error.code === "ECONNABORTED") {
                setConflict(true);
                setError("The API request timed out. Please Refresh the page");
                setIsButtonDisabled(false);
              } else {
                setConflict(true);
                setError(error.response.data.message);
                setIsButtonDisabled(false);
              }
            });
        }
      } else if (accept) {
        if (userType === 0) {
          request
            .acceptInvitation({
              emailToken: values.emailToken,
              userID: values.userID,
              password: password,
              firstName: firstName,
              lastName: lastName,
              companyInfo: {
                companyName: companyName,
                address: address,
                phone: phoneNumber,
              },
            })
            .then(res => {
              setIsButtonDisabled(false);
              setActiveStep(activeStep + 1);
            })
            .catch(error => {
              if (error.code === "ECONNABORTED") {
                setConflict(true);
                setError("The API request timed out. Please Refresh the page");
                setIsButtonDisabled(false);
              } else {
                setConflict(true);
                setError(error.response.data.message);
                setIsButtonDisabled(false);
              }
            });
        } else {
          request
            .acceptInvitation({
              userID: values.userID,
              emailToken: values.emailToken,
              password: password,
              firstName: firstName,
              lastName: lastName,
              companyInfo: {
                companyName: companyName,
                address: address,
                phone: phoneNumber,
                coach: {
                  firmClients: clients,
                  quickBookFirms: quickBooks,
                  clioOrPracticePantherFirms: clioPP,
                },
              },
            })
            .then(res => {
              setActiveStep(activeStep + 1);
            })
            .catch(error => {
              if (error.code === "ECONNABORTED") {
                setConflict(true);
                setError("The API request timed out. Please Refresh the page");
                setIsButtonDisabled(false);
              } else {
                setConflict(true);
                setError(error.response.data.message);
                setIsButtonDisabled(false);
              }
            });
        }
      }
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleUserType = value => {
    setUserType(value);
  };

  const handleFirstName = value => {
    setFirstName(value);
  };
  const handleLastName = value => {
    setLastName(value);
  };
  const handleEmail = value => {
    setEmail(value);
  };
  const handlePassword = value => {
    setPassword(value);
  };
  const handleCompanyName = value => {
    setCompanyName(value);
  };
  const handlePhoneNumber = value => {
    setPhoneNumber(value);
  };

  const handleAddress = value => {
    setAddress(value);
  };
  const handleClients = value => {
    setClients(value);
  };
  const handleQuickBooks = value => {
    setQuickBooks(value);
  };

  const handleClioPP = value => {
    setClioPP(value);
  };
  const handlePrivacy = value => {
    setPrivacy(value);
  };

  const handleTermsConditions = value => {
    setTermsConditions(value);
  };
  const handleRecaptcha = value => {
    setRecaptcha(value);
  };

  return (
    <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
      <LocationWrapper>
        <Grid
          container
          direction="column"
          alignContent="center"
          justifyContent="space-between"
          spacing={2}
        >
          <div className={classes.header}>
            <img className={classes.image} src={images.logo} />
            <Button
              component={Link}
              to={"/login"}
              classes={{ root: classes.bodyText }}
            >
              Login
            </Button>
          </div>
          <div className={classes.header}>
            <span className={classes.headingText}>Register</span>
            <Button
              variant="contained"
              color="secondary"
              classes={{ root: classes.xero }}
              endIcon={<img width="30px" height="30px" src={images.XERO} />}
              href={`https://login.xero.com/identity/connect/authorize?response_type=code&client_id=${process.env.REACT_APP_XERO_ID}&redirect_uri=${process.env.REACT_APP_XERO_SIGNUP_REDIRECT_URI}&scope=${process.env.REACT_APP_XERO_SCOPE}&state=signup`}
            >
              Sign up with
            </Button>
          </div>
          <Grid item xs={12} sm={9}>
            <Stepper
              activeStep={activeStep}
              alternativeLabel
              connector={<StepConnector classes={{ line: classes.stepLine }} />}
              className={classes.stepper}
            >
              {steps.map(label => (
                <Step key={label}>
                  <StepLabel
                    StepIconProps={{
                      classes: {
                        active: classes.step,
                      },
                    }}
                  >
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>
          <Grid item xs={12} sm={9}>
            {activeStep === steps.length ? (
              <React.Fragment>
                {/* Code for anything after completion of the steps */}
                <Typography
                  variant="body2"
                  color="textSecondary"
                  align="center"
                  className={classes.successText}
                >
                  {!accept
                    ? "You have sucessfully registered with FirmTrak™. Please check your email OR spam folder and verify your account."
                    : "You have sucessfully registered with FirmTrak™. Please Login to use your account."}
                </Typography>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {getStepContent(
                  accept,
                  activeStep,
                  userType,
                  handleUserType,
                  firstName,
                  handleFirstName,
                  lastName,
                  handleLastName,
                  email,
                  handleEmail,
                  password,
                  handlePassword,
                  companyName,
                  handleCompanyName,
                  address,
                  handleAddress,
                  phoneNumber,
                  handlePhoneNumber,
                  clients,
                  handleClients,
                  quickBooks,
                  handleQuickBooks,
                  clioPP,
                  handleClioPP,
                  privacy,
                  handlePrivacy,
                  termsConditions,
                  handleTermsConditions,
                  recaptcha,
                  handleRecaptcha
                )}
              </React.Fragment>
            )}
          </Grid>
          {conflict && (
            <Grid item xs={9} className={classes.alertWrapper}>
              <Alert
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setConflict(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {error}
              </Alert>
            </Grid>
          )}
          <Grid item xs={12} sm={9}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                {activeStep !== 0 && (
                  <Button
                    variant="contained"
                    color="secondary"
                    fullWidth
                    onClick={handleBack}
                    className={classes.buttonBack}
                  >
                    Back
                  </Button>
                )}
              </Grid>
              <Grid item xs={6}>
                <StyledGradientButton
                  disabled={isButtonDisabled}
                  onClick={handleNext}
                  fullWidth
                >
                  {activeStep === steps.length - 1 ? "Register" : "Next"}
                  {loading && (
                    <CircularProgress className={classes.loader} size={24} />
                  )}
                </StyledGradientButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </LocationWrapper>
    </Grid>
  );
}
