import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { MainListItems, SecondaryListItems } from "./listItems";
import FirmSwitch from "../dashboard/commons/switchFirm";
import Notifications from "../dashboard/commons/notifications/Notifications";
import {
  ButtonBase,
  MenuList,
  Paper,
  CssBaseline,
  List,
  Tooltip,
  Menu,
  MenuItem,
} from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import { useHistory } from "react-router-dom";
import { WindowContext } from "../../window/trackWindow";
import CustomTabPanel from "../dashboard/tabs/customTabPanel";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import useAuth from "../../hooks/useAuth";
const drawerWidth = 60;

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    position: "absolute",
    left: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    zIndex: theme.zIndex.appBar,
  },
  onboardingAppBar: {
    position: "absolute",
    width: "100%",
    zIndex: theme.zIndex.appBar,
  },
  menuButton: {},
  menuButtonHidden: {
    display: "none",
  },
  title: {
    color: theme.palette.text.primary,
    fontSize: "1.2em",
    fontWeight: 600,
    width: "15%",
  },
  titleResponsive: {
    display: "none",
  },
  drawerPaper: {
    zIndex: theme.zIndex.appBar + 1,
    position: "relative",
    display: "flex",
    whiteSpace: "nowrap",
    width: drawerWidth,
    backgroundColor: theme.palette.primary.light,
    justifyContent: "space-between",
    flexDirection: "column",
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },

  list: {},
  logout: {},
  capsText: {
    color: theme.palette.primary.light,
    fontWeight: "bold",
  },
  purple: {
    color: "#fff",
    backgroundColor: "#2650c3",
  },
  icon: {
    color: theme.palette.secondary.dark,
  },
  box: {
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.secondary.light,
    padding: theme.spacing(1) / 1.2,
    "&:hover": {
      backgroundColor: theme.palette.grey[100],
    },
  },
  userName: {
    fontFamily: "Open Sans",
    fontSize: "0.8em",
    color: theme.palette.text.disabled,
    marginRight: theme.spacing(1),
  },
}));

function PrimaryNav() {
  const classes = useStyles();
  const [selected, setSelected] = React.useState(0);
  const window = useContext(WindowContext);
  const {
    generalStates: { user, activeFirm },
    integrationConfigState: { integrationObject, integrationConfig },
    generalActions,
    onboardingActions,
  } = useAuth();

  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <React.Fragment>
      <CssBaseline />
      {user.firmRoles.length > 0 && (
        <div className={classes.drawerPaper}>
          <MenuList className={classes.list}>
            <MainListItems
              selected={selected}
              setSelected={(val) => setSelected(val)}
            />
          </MenuList>
          <List className={classes.logout}>
            <SecondaryListItems />
          </List>
        </div>
      )}
      <Paper
        square
        className={
          user.firmRoles.length > 0 ? classes.appBar : classes.onboardingAppBar
        }
        elevation={2}
      >
        <div className={classes.toolbar}>
          {activeFirm ? (
            <p
              className={
                window > 1200 ? classes.title : classes.titleResponsive
              }
            >
              {activeFirm?.firm?.firmName}
            </p>
          ) : (
            <p
              className={
                window > 1200 ? classes.title : classes.titleResponsive
              }
            >
              firm<strong className={classes.capsText}>TRAK</strong> Visualize
              <sup>™</sup>
            </p>
          )}
          {integrationConfig !== "NONE" && integrationObject && (
            <CustomTabPanel />
          )}
          <div style={{ display: "flex", flexDirection: "row" }}>
            {activeFirm && <Notifications />}
            {user.firmRoles.length > 0 && <FirmSwitch />}
            <Tooltip title={"Profile"}>
              <ButtonBase className={classes.box} onClick={handleClick}>
                <PersonIcon fontSize="medium" className={classes.icon} />
                <span
                  className={classes.userName}
                >{`${user.firstName} ${user.lastName}`}</span>
                <ExpandMoreIcon fontSize="medium" className={classes.icon} />
              </ButtonBase>
            </Tooltip>
          </div>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <MenuItem
              onClick={() => {
                generalActions.setToken(null);
                generalActions.setRefreshToken(null);
                onboardingActions.setFirmName("");
                onboardingActions.setOnboardingStep(0);
                generalActions.setActiveFirm(null);
                generalActions.setUser(null);
                onboardingActions.setCurrentOnboardingIntegration(null);
              }}
            >
              Logout
            </MenuItem>
            <MenuItem
              disabled={!activeFirm}
              onClick={() => {
                setAnchorEl(null);
                history.push("/dashboard/settings/personal-information");
              }}
            >
              Profile
            </MenuItem>
            <MenuItem
              disabled={!activeFirm}
              onClick={() => {
                setAnchorEl(null);
                history.push("/dashboard/settings/change-password");
              }}
            >
              Change Password
            </MenuItem>
            <MenuItem
              disabled={!activeFirm}
              onClick={() => {
                setAnchorEl(null);
                history.push("/dashboard/");
                generalActions.setTutorial(true);
              }}
            >
              Tutorial
            </MenuItem>
          </Menu>
        </div>
      </Paper>
    </React.Fragment>
  );
}
export default React.memo(PrimaryNav);
