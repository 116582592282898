import { initialState } from "../state/initialStates";
import { generalReducer } from "./generalReducers";
import { onboardingReducer } from "./onboardingReducers";
import { authorizationCodeReducer } from "./authorizationCodeReducer";
import { currentAppStateReducer } from "./currentAppStateReducer";
import { integrationConfigReducer } from "./integrationConfigReducer";

const reducer = (state = initialState, action) => {
  return {
    //User Info, Payment Info, Active Firm
    generalStates: generalReducer(state.generalStates, action),
    //Onboarding info
    onboardingState: onboardingReducer(state.onboardingState, action),
    // Redirection codes
    authorizationCodeState: authorizationCodeReducer(
      state.authorizationCodeState,
      action
    ),
    // App State - payment blocked, duplicate clio integration
    currentAppState: currentAppStateReducer(state.currentAppState, action),
    // Grid and Chart Configuration
    integrationConfigState: integrationConfigReducer(
      state.integrationConfigState,
      action
    )
  };
};

export { initialState, reducer };
