import { useState, useCallback, useRef, useEffect } from "react";
import { DateRange } from "materialui-daterange-picker";

export interface DateStateType {
  dateAfter: Date | null;
  dateBefore: Date | null;
}

// *****
// Custom Hook to manage filter state - Possession of state with hook
// *****

const useDateFilterState = (initialDateState: DateStateType) => {
  const [dateFilterState, setDateFilterState] = useState(initialDateState);
  const dateFilterRef = useRef(initialDateState);

  // *****
  // Previous State - Ref
  // *****

  useEffect(() => {
    dateFilterRef.current = dateFilterState;
  }, [dateFilterState]);

  // *****
  // Memoized Callback
  // *****

  const memoizedReducer = useCallback(
    (state, setState) => {
      return stateReducer(state, setState);
    },
    [setDateFilterState]
  );

  const handleDateState = memoizedReducer(dateFilterState, setDateFilterState);
  return { dateFilterRef, dateFilterState, handleDateState };
};

// *****
// Reducer-esque implementation bypassing computationally expensive switch cases
// *****

const stateReducer = (state, setState) => {
  const handleDateAfter = (value: Date | null) => {
    if (typeof state.dateAfter === "undefined") return;
    setState({
      ...state,
      dateAfter: value
    });
  };
  const handleDateBefore = (value: Date | null) => {
    if (typeof state.dateBefore === "undefined") return;
    setState({
      ...state,
      dateBefore: value
    });
  };
  //Date Range Update
  const handleDateUpdate = (dateRange: DateRange): void => {
    setState({
      dateAfter: dateRange.startDate,
      dateBefore: dateRange.endDate
    });
  };
  const resetDate = (initialState: DateStateType) => {
    setState(initialState);
  };
  return {
    handleDateAfter,
    handleDateBefore,
    resetDate,
    handleDateUpdate
  };
};

export default useDateFilterState;
