import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import MobileStepper from "@material-ui/core/MobileStepper";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import images from "../../images";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
const heading = "Complex\nData. Clear\nSolution";

const tutorialSteps = [
  {
    subheading: "Sort All Dashboards",
    imgPath: images.dashboard,
    description:
      "Easily sort by Originating Attorney, Responsible Employee, Area of Practice, Billing Method and Date Range.",
  },
  {
    subheading: "Trend Analysis",
    imgPath: images.trendAnalysis,
    description:
      "View monthly and annual trends along with 3, 6, or 9 month moving averages.",
  },
  {
    subheading: "Top 10 & Bottom 10",
    imgPath: images.topBottomTen,
    description:
      "Quickly analyze top 10 and bottom 10 drivers of revenue, outstanding dues and collections by client, matters or area of practice.",
  },
  {
    subheading: "Matter Management",
    imgPath: images.matterManagement,
    description:
      "Track activity with a prioritized view of all open matters, monthly trends, and easily identify which matters need immediate attention.",
  },
  {
    subheading: "Employee Management",
    imgPath: images.employeeManagement,
    description:
      "Monitor tasks or track employee productivity based on set goals. Export reports to distribute for employee review.",
  },
  {
    subheading: "Account Receivable Management",
    imgPath: images.arManagement,
    description:
      "View office level AR aging. Drill down to customer specific measures and easily analyze client's payment history.",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 500,
    flexGrow: 1,
    alignSelf: "center",
  },
  header: {
    display: "flex",
    alignItems: "center",
    color: "#fff",
  },
  subheader: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  headerText: {
    fontSize: "3em",
    color: theme.palette.background.paper,
    lineHeight: 0,
    fontFamily: "Open Sans",
    fontWeight: theme.typography.fontWeightRegular,
  },
  subheaderText: {
    fontSize: "1.2em",
    color: theme.palette.background.paper,
    fontFamily: "Open Sans",
    marginLeft: theme.spacing(2),
  },

  stepper: {
    backgroundColor: "transparent",
    color: "#fff",
    padding: 0,
    marginTop: theme.spacing(8),
  },
  dot: {
    backgroundColor: "#5970C8",
    margin: theme.spacing(1 / 2),
  },
  dotActive: {
    backgroundColor: "#fff",
  },
  desc: {
    color: theme.palette.background.paper,
    fontFamily: "Open Sans",
    lineHeight: 2,
  },
}));

function SwipeableTextMobileStepper() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = tutorialSteps.length;

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  return (
    <div className={classes.root}>
      <AutoPlaySwipeableViews
        axis={"x"}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {tutorialSteps.map((step, index) => (
          <div key={index}>
            {heading.split("\n").map((str) => (
              <p className={classes.headerText}>{str}</p>
            ))}
            {Math.abs(activeStep - index) <= 2 ? (
              <React.Fragment>
                <div className={classes.subheader}>
                  <img src={step.imgPath} alt={step.subheading} />
                  <span className={classes.subheaderText}>
                    {step.subheading}
                  </span>
                </div>
                <div>
                  <span className={classes.desc}>{step.description}</span>
                </div>
              </React.Fragment>
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <MobileStepper
        steps={maxSteps}
        position="static"
        variant="dots"
        classes={{
          root: classes.stepper,
          dot: classes.dot,
          dotActive: classes.dotActive,
        }}
        activeStep={activeStep}
        nextButton={
          <></>
          //   <IconButton
          //     size="small"
          //     onClick={handleNext}
          //     disabled={activeStep === maxSteps - 1}
          //   >
          //     <KeyboardArrowRight />
          //   </IconButton>
        }
        backButton={
          <></>
          //   <IconButton
          //     size="small"
          //     onClick={handleBack}
          //     disabled={activeStep === 0}
          //   >
          //     <KeyboardArrowLeft />
          //   </IconButton>
        }
      />
    </div>
  );
}

export default SwipeableTextMobileStepper;
