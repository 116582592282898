import { useContext } from "react";
import { Grid, Button, IconButton } from "@material-ui/core";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import {
  clioObject,
  xeroObject,
  ppObject,
  IntegrationObjType,
} from "./constants";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { Alert, AlertTitle } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";
import { WindowContext } from "../../../window/trackWindow";
import SyncProgress from "./SyncProgress";
import useAuth from "../../../hooks/useAuth";
const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    backgroundColor: "#E7F3FA",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    borderRadius: 8,
    marginBottom: theme.spacing(2),
  },
  titleText: {
    fontSize: 16,
    fontWeight: 600,
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(1),
  },
  connectionText: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.error.main,
  },
  reconnectionText: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.warning.main,
  },
  connectedText: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.secondary.main,
  },
  button: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    backgroundColor: theme.palette.primary.light,
    color: "#fff",
    "&:focus": {
      backgroundColor: "#fff",
      color: theme.palette.primary.light,
    },
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  buttonBack: {
    marginRight: theme.spacing(1),
    backgroundColor: "#fff",
    color: theme.palette.primary.light,
  },
  buttonForward: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

export default function StepTwo({
  handleBack,
  handleConnectRedirect,
  handleGoToFrim,
  connectRedirect,
  firmError,
  handleFirmError,
  integrationError,
  firmSuccess,
  currentCode,
  errorMessage,
  syncing,
  progressAmount,
}) {
  const classes = useStyles();
  const backButton = clsx(classes.button, classes.buttonBack);
  const window = useContext(WindowContext);
  const forwardButton = clsx(classes.button, classes.buttonForward);
  const { onboardingState } = useAuth();
  const integrationType = onboardingState?.currentIntegration || "";
  console.log(integrationType);
  let firmName = onboardingState?.firmName || "";
  let currentObject = (): IntegrationObjType => {
    switch (integrationType) {
      case "XERO":
        return xeroObject;
      case "PP":
        return ppObject;
      case "CLIO":
        return clioObject;
      default:
        return { image: null, uri: "" };
    }
  };

  let dash = currentObject()

  return (
    <Grid container justifyContent="center" spacing={2}>
      <Grid item lg={8} md={8} >
        <img
          height={
            window > 960
              ? 100
              : window > 600 && window < 960
                ? 70
                : window < 600
                  ? 50
                  : 50
          }
          src={dash?.image}
          alt={integrationType}
        />
      </Grid>
      {syncing && (
        <Grid item xs={8}>
          <SyncProgress progress={progressAmount} />
        </Grid>
      )}
      <Grid item lg={8} md={8}>
        <div className={classes.container}>
          <span className={classes.titleText}>Connection Status:</span>
          {(currentCode.length > 0 && !integrationError) || syncing ? (
            <span className={classes.connectedText}>Connected</span>
          ) : integrationError ? (
            <span className={classes.reconnectionText}>Reconnect</span>
          ) : (
            <span className={classes.connectionText}>Disconnected</span>
          )}
        </div>
        <Button
          startIcon={<CheckCircleOutlineIcon />}
          size="large"
          href={dash?.uri}
          variant="contained"
          fullWidth
          className={classes.button}
          color="primary"
          onClick={handleConnectRedirect}
          disabled={
            (currentCode.length > 0 && !integrationError ? true : false) ||
            syncing
          }
        >
          {(currentCode.length > 0 && !integrationError) || syncing
            ? "Connected"
            : "Connect"}
        </Button>
        <Grid container spacing={2} className={classes.buttonContainer}>
          <Grid item xs={4}>
            <Button
              onClick={handleBack}
              className={backButton}
              variant="outlined"
              fullWidth
              disabled={syncing}
            >
              Back
            </Button>
          </Grid>

          <Grid item xs={8}>
            <Button
              variant="contained"
              className={forwardButton}
              fullWidth
              onClick={handleGoToFrim}
              disabled={
                currentCode.length > 0 &&
                  firmName.length > 0 &&
                  !firmError &&
                  !integrationError
                  ? !firmSuccess
                    ? false
                    : true
                  : true || syncing
              }
            >
              Sync Firm
            </Button>
          </Grid>
        </Grid>
        {firmError || integrationError ? (
          <Alert
            severity={integrationError ? "warning" : "error"}
            action={
              firmError ? (
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={handleFirmError}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              ) : null
            }
          >
            {firmError ? errorMessage : integrationError ? errorMessage : null}
          </Alert>
        ) : null}
        {firmSuccess ? (
          <Alert severity="success">
            <AlertTitle>Firm Successfully Created</AlertTitle>
            You will be rerouted once the sync is complete.
          </Alert>
        ) : null}
      </Grid>
    </Grid>
  );
}
