import React, { useState, useEffect } from "react";
import { Grid, Button, InputLabel, CircularProgress } from "@material-ui/core";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import queryString from "query-string";
import api from "../../services";
import Layout from "../../containers/Layout";
import images from "../../images";
import { StyledGradientButton } from "../commons/styledButtons";
import { StyledInput } from "../settings/payments/stripe/styledInput";
import ErrorSnackBar from "../dashboard/commons/SnackBar";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  bodyText: {
    display: "block",
    fontSize: "1em",
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.primary,
    width: theme.spacing(20),
    textAlign: "center",
    backgroundColor: "#fff",
    marginLeft: theme.spacing(18),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      marginLeft: theme.spacing(2),
      fontSize: "1em",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "1.4em",
    },
  },
  image: {
    width: 150,
  },
  headingText: {
    display: "block",
    fontFamily: "Open Sans",
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(4),
    fontSize: "2em",
    fontWeight: 600,
    color: theme.palette.text.primary,
    [theme.breakpoints.down("md")]: {
      fontSize: "1.4em",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "1.8em",
    },
  },
}));
export default function ResetPassword(props) {
  const classes = useStyles();
  const [password, setPassword] = useState("");
  const [confirmPassword, setComfirmPassword] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  useEffect(() => {
    let passwordRgx =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*._-])(?=.{8,})/;
    if (password.length > 0 && confirmPassword.length > 0) {
      if (passwordRgx.test(password) && password === confirmPassword) {
        setDisabled(false);
        setMessage("");
        setError(false);
      } else {
        setError(true);
        setMessage(
          "Password must contain 8 characters with at least a digit, special character and a capital letter"
        );
        setDisabled(true);
      }
    }
  }, [password, confirmPassword]);

  function handleReset() {
    const values = queryString.parse(props.location.search);
    let request = api.create("");
    setLoading(true);
    setDisabled(true);
    request
      .resetPassword({
        userID: values.userID,
        emailToken: values.emailToken,
        newPassword: password,
      })
      .then(res => {
        if (res.status === 200) {
          setLoading(false);
          setSuccess(true);
          setMessage("Password reset successfully.");
          setTimeout(() => {
            props.history.push("/login");
          }, 3000);
        }
      })
      .catch(error => {
        setLoading(false);
        setDisabled(false);
        setError(true);
        setMessage(error.response.data.message);
      });
  }
  return (
    <Layout>
      <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
        <Grid
          container
          direction="column"
          alignContent="center"
          justifyContent="space-between"
          spacing={2}
        >
          <div className={classes.header}>
            <img className={classes.image} src={images.logo} />
            <Button
              component={Link}
              to={"/login"}
              classes={{ root: classes.bodyText }}
            >
              Login
            </Button>
          </div>
          <Grid item xs={12}>
            <span className={classes.headingText}>Reset Password</span>
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Password</InputLabel>
            <StyledInput
              onChange={e => setPassword(e.target.value)}
              error={error}
              fullWidth
              required
              type="password"
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Confirm Password</InputLabel>
            <StyledInput
              error={error}
              onChange={e => setComfirmPassword(e.target.value)}
              fullWidth
              required
              type="password"
            />
          </Grid>
          <Grid item xs={12}>
            <StyledGradientButton
              type="submit"
              fullWidth
              onClick={() => handleReset()}
              disabled={disabled}
            >
              {loading ? (
                <CircularProgress
                  size={24}
                  classes={{ circle: classes.buttonProgress }}
                />
              ) : (
                "Reset"
              )}
            </StyledGradientButton>
          </Grid>
        </Grid>
        {error && <ErrorSnackBar errorMsg={message} />}
        {success && <ErrorSnackBar errorMsg={message} success />}
      </Grid>
    </Layout>
  );
}
