import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { InputLabel, ButtonBase, Grid } from "@material-ui/core";
import { StyledInput } from "../settings/payments/stripe/styledInput";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  switch: {
    borderColor: theme.palette.primary.light,
    borderStyle: "solid",
    borderWidth: "1px",
    borderRadius: theme.spacing(1),
    overflow: "hidden",
    flexDirection: "row",
    display: "flex",
    width: 250,
    height: theme.spacing(5),
    justifyContent: "space-between",
    alignItems: "stretch",
    alignContent: "center",
    margin: theme.spacing(2, 0),
  },
  selected: {
    backgroundColor: theme.palette.primary.light,
    color: "#ffffff",
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.up("xl")]: {
      fontSize: "1.1em",
    },
    fontSize: "0.9em",
  },
  unselected: {
    backgroundColor: "#ffffff",
    color: theme.palette.primary.light,
    display: "flex",
    flex: 1,
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.up("xl")]: {
      fontSize: "1.1em",
    },
    fontSize: "0.9em",
  },
}));

export default function AddressForm(props) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <div className={classes.wrapper}>
        <div className={classes.switch}>
          <ButtonBase
            onClick={() => props.handleUserType(0)}
            className={
              props.userType === 0 ? classes.selected : classes.unselected
            }
          >
            Firm Owner
          </ButtonBase>
          <ButtonBase
            onClick={() => props.handleUserType(1)}
            className={
              props.userType === 1 ? classes.selected : classes.unselected
            }
          >
            Consultant
          </ButtonBase>
        </div>
      </div>
      {props.userType === 1 ? (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <InputLabel>How many law firm clients do you have?</InputLabel>
            <StyledInput
              fullWidth
              value={props.clients}
              type={"number"}
              onChange={(event) =>
                props.handleClients(
                  event.target.value.includes("-") ? 0 : event.target.value
                )
              }
              id="How many law firm clients do you have?"
              name="How many law firm clients do you have?"
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel>How many use Xero?</InputLabel>
            <StyledInput
              value={props.quickBooks}
              onChange={(event) =>
                props.handleQuickBooks(
                  event.target.value.includes("-") ? 0 : event.target.value
                )
              }
              fullWidth
              type={"number"}
              id="How many use Xero?"
              name="How many use Xero?"
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel>How many use Clio or Practice Panther?</InputLabel>
            <StyledInput
              value={props.clioPP}
              type={"number"}
              onChange={(event) =>
                props.handleClioPP(
                  event.target.value.includes("-") ? 0 : event.target.value
                )
              }
              fullWidth
              id="How many use Clio or Practice Panther?"
              name="How many use Clio or Practice Panther?"
            />
          </Grid>
        </Grid>
      ) : null}
    </React.Fragment>
  );
}
