import axios from "axios";

/*
IPDATA Apis
*/

export const ipData = (baseURL = process.env.REACT_APP_IPDATA_BASE_URL) => {
  const ipDataApi = axios.create({
    baseURL: baseURL,
    timeout: 3000,
  });

  /*
    Autodetects IP address from the request - response: location details
    */

  const getIPDetails = (queryParams) => {
    return ipDataApi.get("/", {
      params: queryParams,
    });
  };

  return {
    getIPDetails,
  };
};
