import React, { useState, useEffect } from "react";
import api from "../../../services/index";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import FilterUserTimePractice from "../filters/genericFilter/filters";
import useDateFilterState, {
  DateStateType,
} from "../filters/genericFilter/dateFilterStateHook";
import { DateRange } from "materialui-daterange-picker";
import { checkDate } from "../grids/utils";
import moment from "moment";
import { exportAsCSV } from "./utils";
import MaterialTable from "material-table";
import ErrorSnackBar from "../commons/SnackBar";
import NestedRow from "./nestedRow";
import FilterListIcon from "@material-ui/icons/FilterList";
import useAuth from "../../../hooks/useAuth";
// *****
// Types
// *****

interface QueryParamsType {
  generatedAfter: string | Date | null;
  generatedBefore: string | Date | null;
  responseType: string;
}

export interface ColumnDataType {
  title: string;
  field: string;
  export: boolean;
  render?: (row: any) => JSX.Element;
}

interface TableDataType {
  clientIP: string;
  requestTime: string;
  url: string;
  email: number;
  method: number;
  status: number;
  responseLength: number;
  clientAgent: string | null;
  errorTrace: string | null;
  errorCause: string | null;
}

const useStyles = makeStyles(theme => ({
  paper: { padding: theme.spacing(4, 2) },
  titleText: {
    display: "block",
    fontSize: "1.3rem",
    fontWeight: 600,
    marginBottom: theme.spacing(2),
    fontColor: theme.palette.text.primary,
    [theme.breakpoints.down("md")]: {
      fontSize: "1.2rem",
    },
  },
  subheadingText: {
    display: "block",
    fontSize: "1rem",
    fontWeight: 500,
    fontColor: theme.palette.text.secondary,
    marginTop: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      fontSize: "0.9rem",
    },
  },
  link: {
    textDecoration: "none",
    color: theme.palette.primary.dark,
    fontSize: "1rem",
    fontWeight: 500,
    [theme.breakpoints.up("xl")]: {
      fontSize: "1.1em",
    },
  },
  adjust: {
    marginTop: theme.spacing(1),
    display: "block",
  },
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  headingText: {
    fontSize: "1.2em",
    fontWeight: 600,
    color: theme.palette.text.secondary,
  },
  divider: {
    backgroundColor: "#EDEFF7",
    height: 1,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  table: {
    marginTop: theme.spacing(2),
  },
  container: {
    paddingTop: theme.spacing(6),
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
  },
}));

const initialDateFilterState: DateStateType = {
  dateBefore: moment().toDate(),
  dateAfter: moment().subtract(10, "d").toDate(),
};

// *****
// Initial Table Title
// *****

const initialSelectedDate: string = `${moment()
  .subtract(10, "d")
  .format("MM/DD/YYYY")}-${moment().format("MM/DD/YYYY")}`;

export interface AdminLogsProps {}

const AdminLogs: React.FunctionComponent<AdminLogsProps> = () => {
  const {
    generalStates: { token },
  } = useAuth();
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [data, setData] = useState<TableDataType[] | []>([]);
  const [open, setOpen] = useState<boolean>(false);
  const { dateFilterState, handleDateState } = useDateFilterState(
    initialDateFilterState
  );
  const [tableTitle, setTableTitle] = useState<string>(initialSelectedDate);

  const columns = [
    { title: "IP Address", field: "clientIP", export: true },
    {
      title: "User Email",
      field: "userEmail",
      export: true,
    },
    {
      title: "Request Time",
      field: "requestTime",
      export: true,
      render: row => (
        <span>{moment(row["requestTime"]).format("MM/DD/YY")}</span>
      ),
    },
    {
      title: "Method / Status",
      field: "method",
      export: true,
      render: row => <span>{`${row.method} / ${row.status}`}</span>,
    },

    {
      title: "Response Length",
      field: "responseLength",
      export: true,
    },
    {
      title: "URL",
      field: "url",
      export: true,
      render: row => <span style={{ width: 200 }}>{`${row.url}`}</span>,
    },
  ];
  // *****
  // API Logs endpoint
  // *****

  const handleFilterSubmit = () => {
    if (checkDate(dateFilterState.dateAfter, dateFilterState.dateBefore)) {
      setLoading(true);
    } else if (
      dateFilterState.dateAfter === null &&
      dateFilterState.dateBefore === null
    ) {
      setLoading(true);
    }
  };
  const handleDateFilterRange = (dateRange: DateRange): void => {
    handleDateState.handleDateUpdate(dateRange);
  };
  // *****
  // Clear Filter States
  // *****

  const handleFilterClear = () => {
    handleDateState.resetDate(initialDateFilterState);
    setLoading(true);
  };

  // *****
  // API Logs endpoint
  // *****

  useEffect(() => {
    let subscribe = true;
    if (loading) {
      let queryParams: QueryParamsType = {
        generatedAfter: dateFilterState.dateAfter,
        generatedBefore: dateFilterState.dateBefore,
        responseType: "JSON",
      };
      let request = api.create(token);
      request
        .apiLogs(queryParams)
        .then(response => {
          if (subscribe) {
            setData(response.data);
            // Update table title string
            setTableTitle(
              `${moment(dateFilterState.dateAfter).format(
                "MM/DD/YYYY"
              )}-${moment(dateFilterState.dateBefore).format("MM/DD/YYYY")}`
            );
            setLoading(false);
          }
        })
        .catch(error => {
          if (subscribe) {
            if (error.code === "ECONNABORTED") {
              setErrorMsg("The API request timed out. Please Refresh the page");
              setError(true);
            } else {
              setErrorMsg(error.response.data.message);
              setError(true);
            }
          }
        });
    }
    return () => {
      subscribe = false;
    };
  }, [loading, dateFilterState, token]);

  return (
    <React.Fragment>
      <Grid container spacing={2} className={classes.container}>
        <FilterUserTimePractice
          dateFilterState={dateFilterState}
          filterState={{}}
          disabled={loading}
          handleFilterClear={handleFilterClear}
          handleFilterSubmit={handleFilterSubmit}
          handleDateFilterRange={handleDateFilterRange}
          handleFromComponent={open}
          setCloseFromComponent={() => setOpen(false)}
        />
        <Grid item xs={12}>
          <MaterialTable
            title={`Logs: ${tableTitle}`}
            data={data}
            actions={[
              {
                icon: () => <FilterListIcon />,
                isFreeAction: true,
                onClick: (event, rowData) => {
                  setOpen(!open);
                },
                tooltip: "Filters",
              },
            ]}
            options={{
              exportButton: true,
              pageSize: 20,

              exportCsv: (columns, data) => {
                //File Name
                const name = `APILogs-${moment(
                  dateFilterState.dateAfter
                ).format("MM/DD/YYYY")}-${moment(
                  dateFilterState.dateBefore
                ).format("MM/DD/YYYY")}`;
                exportAsCSV(
                  //Addition of the nested rows, so the generetad csv has the required columns
                  columns.concat([
                    {
                      title: "Client Agent",
                      field: "clientAgent",
                      export: true,
                    },
                    {
                      title: "Error Trace",
                      field: "errorTrace",
                      export: true,
                    },
                    {
                      title: "Error Cause",
                      field: "errorCause",
                      export: true,
                    },
                  ]),
                  data,
                  name
                );
              },
            }}
            isLoading={loading}
            columns={columns}
            detailPanel={(rowData: TableDataType) => {
              return <NestedRow data={rowData} />;
            }}
          />
        </Grid>
      </Grid>
      {error ? <ErrorSnackBar errorMsg={errorMsg} /> : null}
    </React.Fragment>
  );
};

export default AdminLogs;
