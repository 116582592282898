export const authorizationCodeActions = (props) => {
  return {
    // Clio Auth code
    setClio: (data) => {
      externSetClio(props, data);
    },
    // Xero Auth Code
    setXero: (data) => {
      externSetXero(props, data);
    },
    // PP Auth Code
    setPP: (data) => {
      externSetPP(props, data);
    },
  };
};

// *****
// External actions
// *****

function externSetPP(props, data) {
  props.dispatch({ type: "SET_PP", data });
}
function externSetClio(props, data) {
  props.dispatch({ type: "SET_CLIO", data });
}
function externSetXero(props, data) {
  props.dispatch({ type: "SET_XERO", data });
}
