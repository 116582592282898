import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { FilterClientsMattersTypes } from "../../../filters/genericFilter/types";
import useFilterState from "../../../filters/genericFilter/filterStateHook";
import Colors from "../../../../../theme/ChartTheme";
import ChartLoader from "../../../commons/loaders/chartLoader";
import ErrorSnackBar from "../../../commons/SnackBar";
import FilterUserTimePractice from "../../../filters/genericFilter/filters";
import { compareFilterDates } from "../../utils";
import useDateFilterState, {
  DateStateType,
} from "../../../filters/genericFilter/dateFilterStateHook";
import { DateRange } from "materialui-daterange-picker";
import api from "../../../../../services";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import clsx from "clsx";
import _ from "lodash";
import Chart from "../../../charts/fusionCharts";
import InfoButton from "../../../infoButton";
import EmptyPlaceholder from "../../../commons/placeholders/emptyPlaceholder";
import useAuth from "../../../../../hooks/useAuth";
import numeral from "numeral";
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "visible",
    flexDirection: "column",
    justifyContent: "center",
  },
  lineAreaHeight: {
    height: 500,
  },
  textTitle: {
    color: theme.palette.text.primary,
    fontSize: "1.2em",
  },
  filterWrapper: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  filterInner: {
    marginLeft: theme.spacing(1),
  },
  headingText: {
    fontSize: "1.2em",
    fontWeight: 600,
    color: theme.palette.text.secondary,
  },
  divider: {
    backgroundColor: "#EDEFF7",
    height: 1,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  gridCheck: {
    display: "flex",
    alignItems: "center",
  },
  individualText: {
    color: Colors.blue,
    fontSize: "1.2em",
    fontWeight: 600,
    display: "inline-block",
  },
  companiesText: {
    color: Colors.red,
    fontSize: "1.2em",
    fontWeight: 600,
    display: "inline-block",
  },
  spanTextWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  totalText: {
    color: Colors.green,
    fontSize: "1.2em",
    fontWeight: 600,
  },
  countTitle: {
    fontSize: "1.3em",
    fontWeight: 600,
    color: theme.palette.text.primary,
    display: "inline-block",
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  radialLoader: {
    marginTop: theme.spacing(16),
  },
}));

// *****
// Client Response Object Type
// *****

interface ClientListItem {
  total_clients: number;
  month: string;
}

// *****
// Initial Filter State
// *****

const initialFilterState: FilterClientsMattersTypes = {
  practiceArea: "",
  billingMethod: "",
  originatingAtt: "",
  responsibleAtt: "",
};

const initialDateFilterState: DateStateType = {
  dateAfter: null,
  dateBefore: null,
};

export interface ClientGrowthTrendsRadialProps {}

const ClientGrowthTrendsRadial: React.FC<ClientGrowthTrendsRadialProps> =
  () => {
    const [skeleton, setSkeleton] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string>("");
    const {
      generalStates: {
        token,
        activeFirm: {
          firm: { _id },
        },
      },
      integrationConfigState: { pmType },
    } = useAuth();

    const [totalIndividuals, setTotalIndividuals] = useState<number>(0);
    const [totalCompanies, setTotalCompanies] = useState<number>(0);
    const [sumOfClients, setSumOfClients] = useState<number>(0);

    const [filterLoaderRadial, setFilterLoaderRadial] =
      useState<boolean>(false);

    // *****
    // Current Values of Filters
    // *****

    const { filterRef, filterState, handleState } =
      useFilterState(initialFilterState);

    // *****
    // Previous Values of Filters
    // *****

    const prevFilterState = filterRef.current;

    // *****
    // Current Values of Date Filters
    // *****

    const { dateFilterRef, dateFilterState, handleDateState } =
      useDateFilterState(initialDateFilterState);

    // *****
    // Previous Values of Date Filters
    // *****

    const prevDateFilterState = dateFilterRef.current;

    const classes = useStyles();

    // *****
    // Get Sum by Type
    // *****

    function getTotalByType(data: ClientListItem[], type: string): number {
      return _.sum(
        _.flatMap(
          _.filter(data, { type: type }),
          (item, i) => item.total_clients
        )
      );
    }

    // *****
    // Filter state updates
    // *****

    const handlePracticeAreas = (value: number | "") => {
      handleState.handlePracticeArea(value);
    };

    const handleBillingMethod = (value: string) => {
      handleState.handleBillingMethod(value);
    };

    const handleOriginatingAtt = (value: number | "") => {
      handleState.handleOriginatingAtt(value);
    };

    const handleResponsibleAtt = (value: number | "") => {
      handleState.handleResponsibleAtt(value);
    };

    const handleDateFilterRange = (dateRange: DateRange): void => {
      handleDateState.handleDateUpdate(dateRange);
    };

    // *****
    // Filter Submit - Leveraging Closure and invoking utility function compareFilterDates
    // Use Case: If any of the filter state has changed and date has not been applied, invoke action.
    // Use Case: both date values have changed with second greater than the first, invoke action.
    // *****

    const handleFilterSubmit = () => {
      if (
        compareFilterDates(
          filterState,
          prevFilterState,
          dateFilterState,
          prevDateFilterState,
          initialFilterState
        ) === true
      ) {
        setFilterLoaderRadial(true);
      }
    };

    // *****
    // Clear Filter States
    // *****

    const handleFilterClear = () => {
      handleState.reset(initialFilterState);
      handleDateState.resetDate(initialDateFilterState);
      setFilterLoaderRadial(true);
    };

    useEffect(() => {
      let subscribe = true;
      if (subscribe) {
        if (skeleton || filterLoaderRadial) {
          let request = api.create(token);
          let queryParams = {
            practice_area_id:
              filterState.practiceArea === "" ? null : filterState.practiceArea,
            billing_method:
              filterState.billingMethod === ""
                ? null
                : filterState.billingMethod,
            originating_attorney_id:
              filterState.originatingAtt === ""
                ? null
                : filterState.originatingAtt,
            responsible_attorney_id:
              filterState.responsibleAtt === ""
                ? null
                : filterState.responsibleAtt,
            created_after: dateFilterState.dateAfter,
            created_before: dateFilterState.dateBefore,
          };
          request
            .getClientsByType(_id, pmType, queryParams)
            .then((response) => {
              if (subscribe) {
                let totalIndividuals = getTotalByType(response.data, "Person");
                let totalCompanies = getTotalByType(response.data, "Company");
                setTotalIndividuals(totalIndividuals);
                setTotalCompanies(totalCompanies);
                setSumOfClients(totalIndividuals + totalCompanies);
                setFilterLoaderRadial(false);
                setSkeleton(false);
              }
            })
            .catch((error) => {
              if (subscribe) {
                if (error.code === "ECONNABORTED") {
                  setErrorMsg(
                    "The API request timed out. Please Refresh the page"
                  );
                  setError(true);
                } else {
                  setErrorMsg(error.response.data.message);
                  setError(true);
                }
              }
            });
        }
      }
      return () => {
        subscribe = false;
      };
    }, [skeleton, filterLoaderRadial]);

    const chartConfig = {
      showToolTip: false,
      aligncaptionwithcanvas: "0",
      captionpadding: "0",
      decimals: "1",
      centerlabel: "<b>$value%</b> $label",
    };
    const lineArea = clsx(classes.paper, classes.lineAreaHeight);

    return (
      <Grid item lg={6} md={6} sm={12} xs={12} xl={6}>
        <Paper className={lineArea}>
          {skeleton || filterLoaderRadial ? (
            <ChartLoader />
          ) : (
            <React.Fragment>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <span className={classes.textTitle}>Client Composition</span>
                <span>
                  <InfoButton
                    title={"Client composition by individuals vs institutions"}
                  />
                  <FilterUserTimePractice
                    filterState={filterState}
                    dateFilterState={dateFilterState}
                    disabled={skeleton || filterLoaderRadial}
                    handleDateFilterRange={handleDateFilterRange}
                    handleFilterSubmit={handleFilterSubmit}
                    handleFilterClear={handleFilterClear}
                    handlePracticeAreas={handlePracticeAreas}
                    handleBillingMethod={handleBillingMethod}
                    handleOriginatingAtt={handleOriginatingAtt}
                    handleResponsibleAtt={handleResponsibleAtt}
                  />
                </span>
              </Grid>
              {totalIndividuals !== 0 || totalCompanies !== 0 ? (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Chart
                    type="doughnut3d"
                    data={[
                      {
                        label: "Individuals",
                        value: isNaN(
                          Math.ceil((totalIndividuals / sumOfClients) * 100)
                        )
                          ? 0
                          : Math.ceil((totalIndividuals / sumOfClients) * 100),
                        color: Colors.blue,
                      },
                      {
                        label: "Companies",
                        value: isNaN(
                          Math.floor((totalCompanies / sumOfClients) * 100)
                        )
                          ? 0
                          : Math.floor((totalCompanies / sumOfClients) * 100),
                        color: Colors.red,
                      },
                    ]}
                    height={380}
                    config={chartConfig}
                  />
                  <span className={classes.spanTextWrapper}>
                    <div>
                      <span className={classes.textTitle}>Total: </span>
                      <span className={classes.totalText}>
                        {numeral(sumOfClients).format("0,0")}
                      </span>
                    </div>
                    <div>
                      <span className={classes.textTitle}>Individuals: </span>
                      <span className={classes.individualText}>
                        {numeral(totalIndividuals).format("0,0")}
                      </span>
                    </div>
                    <div>
                      <span className={classes.textTitle}>Companies: </span>
                      <span className={classes.companiesText}>
                        {numeral(totalCompanies).format("0,0")}
                      </span>
                    </div>
                  </span>
                </Grid>
              ) : (
                <EmptyPlaceholder />
              )}
            </React.Fragment>
          )}
        </Paper>
        {error ? <ErrorSnackBar errorMsg={errorMsg} /> : null}
      </Grid>
    );
  };

export default ClientGrowthTrendsRadial;
