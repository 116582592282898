import {
    Grid,
    makeStyles,
    Paper,
    Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import useAuth from "../../../../hooks/useAuth";
import api from "../../../../services";
import CustomChartsContainer from './RAvsSC';
import ChartLoader from "../../commons/loaders/chartLoader";
import PA from "./PA";

const useStyles = makeStyles((theme) => ({
    headingText: {
        fontSize: "1.5em",
        fontWeight: theme.typography.fontWeightMedium,
        color: theme.palette.text.primary,
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "visible",
        flexDirection: "column",
        justifyContent: "center",
        width: "100%",
        height: 500,
    },
    imageList: {
        width: "100%",
        // Promote the list into its own layer in Chrome. This cost memory, but helps keep FPS high.
        transform: "translateZ(0)",
    },
}));
export default function CustomReports() {
    const [loader, setLoader] = useState<boolean>(true);
    const [isAllowed, setIsAllowed] = useState(false);
    const {
        generalStates: {
            token,
            activeFirm: {
                firm: { _id },
            },
        },
    } = useAuth();

    useEffect(() => {
        let subscribe = true;
        if (loader && _id && token) {
            let request = api.create(token);
            request
                .getCustomReportPermission(_id)
                .then((res) => {
                    console.log(res.data);
                    if (subscribe) {
                        setIsAllowed(res.data.is_allowed);
                        setLoader(false);
                    }
                })
                .catch((e) => {
                    setLoader(false);
                });
        }
        return () => {
            subscribe = false;
        };
    }, [loader]);

    const classes = useStyles();

    if (loader) {
      return <Grid container spacing={2}>
        <Grid item style={{ marginBottom: 20 }}>
          <span className={classes.headingText}>Custom Reports</span>
        </Grid>
        <Paper className={classes.paper}>
          <ChartLoader />
        </Paper>
      </Grid>
    }

    if (!isAllowed) {
      return <Grid container spacing={2}>
        <Grid item style={{ marginBottom: 20 }}>
          <span className={classes.headingText}>Custom Reports</span>
        </Grid>
        <Paper className={classes.paper}>
          <Typography>
            You don't have any custom reports yet. Contact support if you want to add custom reports.
          </Typography>
        </Paper>
      </Grid>
    }

    return (
      <Grid container spacing={2}>
        <Grid xs={12} item>
          <CustomChartsContainer isAllowed={isAllowed} firmId={_id} token={token} />
        </Grid>
        <Grid xs={12} item>
          <PA isAllowed={isAllowed} firmId={_id} token={token} />
        </Grid>
      </Grid>
    )
}
