import React, { useEffect, useState, useCallback } from "react";
import FilterUserTimePractice from "../../../filters/genericFilter/filters";
import EmptyPlaceholder from "../../../commons/placeholders/emptyPlaceholder";
import { makeStyles } from "@material-ui/core/styles";
import { compareArraysAndEquateLength } from "./utils";
import ErrorSnackBar from "../../../commons/SnackBar";
import ChartLoader from "../../../commons/loaders/chartLoader";
import useDateFilterState, {
  DateStateType,
} from "../../../filters/genericFilter/dateFilterStateHook";
import {
  limitLengthToEighteen,
  getAmount,
  getMonthsFusion,
  getAmountFusion,
  addValue,
} from "../../utils";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import api from "../../../../../services";
import { DateRange } from "materialui-daterange-picker";
import usePreviousState, { checkDate } from "../../utils";
import clsx from "clsx";
import _ from "lodash";
import Chart from "../../../charts/fusionCharts";
import InfoButton from "../../../infoButton";
import useAuth from "../../../../../hooks/useAuth";
export interface GrowthTrendsCashflowProps {}

type amountArray = number[] | any[];

interface ChartState {
  draw: amountArray;
  totalRevenue: amountArray;
  opExpense: amountArray;
  months: any[] | [];
}

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "visible",
    flexDirection: "column",
    justifyContent: "center",
  },
  paperHeight: {
    height: 500,
  },
  textTitle: {
    color: theme.palette.text.primary,
    fontSize: "1.2em",
    fontWeight: 600,
  },
  textSubtitle: {
    color: theme.palette.text.secondary,
    fontSize: "0.8em",
  },
  headingText: {
    fontSize: "1.2em",
    fontWeight: 600,
    color: theme.palette.text.secondary,
  },
  divider: {
    backgroundColor: "#EDEFF7",
    height: 1,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const initialDateFilterState: DateStateType = {
  dateAfter: null,
  dateBefore: null,
};
const GrowthTrendsCashflow: React.FC<GrowthTrendsCashflowProps> = () => {
  const {
    generalStates: { token: activeToken, activeFirm },
  } = useAuth();
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.paperHeight);
  const [loading, setLoading] = useState<boolean>(true);
  const [movingAvgSize, setMovingAvgSize] = useState<number>(3);
  const [filterMA, setFilterMA] = useState<number>(3);
  const [error, setError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [movingAvgCheck, setMovingAvgCheck] = useState<boolean>(false);
  const [movAvgSeries, setMovAvgSeries] = useState<any[]>([]);
  const [chartState, setChartState] = useState<ChartState>({
    draw: [],
    opExpense: [],
    totalRevenue: [],
    months: [],
  });
  const { dateFilterState, handleDateState } = useDateFilterState(
    initialDateFilterState
  );
  const prevFilterMA = usePreviousState(filterMA);

  const series = [
    {
      seriesname: "Draw",
      data: chartState.draw,
    },
    {
      seriesname: "Operating Expense",
      data: chartState.opExpense,
    },
    {
      seriesname: "Total Revenue",
      renderas: "line",
      data: movAvgSeries,
    },
  ];

  const handleDateFilterRange = (dateRange: DateRange): void => {
    handleDateState.handleDateUpdate(dateRange);
  };

  const handleMovingAvg = useCallback(
    (value: number) => {
      setFilterMA(value);
    },
    [filterMA]
  );

  // *****
  // Filter Submit
  // *****

  const handleFilterSubmit = () => {
    if (checkDate(dateFilterState.dateAfter, dateFilterState.dateBefore)) {
      setLoading(true);
    } else if (
      dateFilterState.dateAfter === null &&
      dateFilterState.dateBefore === null &&
      filterMA === prevFilterMA
    ) {
      setLoading(true);
    } else if (filterMA !== prevFilterMA) {
      setMovingAvgCheck(true);
      setMovingAvgSize(filterMA);
    }
  };

  // *****
  // Clear Filter States
  // *****

  const handleFilterClear = () => {
    handleDateState.resetDate(initialDateFilterState);
    setFilterMA(3);
    setLoading(true);
  };

  // *****
  // Calculates the moving averages
  // *****

  function sum(numbers) {
    return _.reduce(numbers, (a, b) => a + b, 0);
  }

  function average(numbers) {
    return Math.ceil(sum(numbers) / movingAvgSize);
  }

  function window(_number, index, array) {
    const start = Math.max(0, index - movingAvgSize);
    const end = Math.min(array.length, index);
    return _.slice(array, start, end);
  }

  function movingAverage(numbers) {
    return _.filter(
      _.chain(numbers).map(window).map(average).value(),
      (number, index) => {
        if (index >= movingAvgSize) {
          return number;
        }
      }
    );
  }

  // *****
  // Moving avg calculated after the initial number of months, this returns placeholder zero values for the initial months
  // *****

  function refineMovingAvgArray(array: number[]): number[] {
    let arr = movingAverage(array);
    if (movingAvgSize === 3) {
      arr.splice(0, 0, 0, 0, 0);
    }
    if (movingAvgSize === 6) {
      arr.splice(0, 0, 0, 0, 0, 0, 0, 0);
    }
    if (movingAvgSize === 9) {
      arr.splice(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0);
    }
    return arr;
  }

  useEffect(() => {
    let subscribe = true;
    if (loading) {
      let request = api.create(activeToken);
      let firmId = activeFirm.firm._id;
      let queryParams = {
        startDate: dateFilterState.dateAfter,
        endDate: dateFilterState.dateBefore,
      };
      Promise.all([
        request.getDraw(firmId, queryParams),
        request.getTotalRevenue(firmId, queryParams),
        request.getOperatingExpense(firmId, queryParams),
      ])
        .then((res) => {
          let drawResponse = res[0].data;
          let totalRevenueResponse = res[1].data;
          let opExpenseResponse = res[2].data;
          let [comparedDraw, comparedTotalRevenue, comparedOpExpense] =
            compareArraysAndEquateLength(
              drawResponse,
              totalRevenueResponse,
              opExpenseResponse
            );

          let limitedDraw = limitLengthToEighteen(comparedDraw);
          let months = getMonthsFusion(limitedDraw, "month");
          let drawAmount = getAmountFusion(limitedDraw);
          let totalRevenue = getAmountFusion(
            limitLengthToEighteen(comparedTotalRevenue)
          );

          let opExpenseAmount = getAmountFusion(
            limitLengthToEighteen(comparedOpExpense)
          );

          setChartState({
            totalRevenue: totalRevenue,
            draw: drawAmount,
            opExpense: opExpenseAmount,
            months: months,
          });
          // If moving Average was an applied filter as well
          if (movingAvgSize !== filterMA) {
            setMovingAvgSize(filterMA);
            setMovingAvgCheck(true);
          } else {
            let refined = refineMovingAvgArray(
              _.flatMap(totalRevenue, (item) => item.value)
            );
            setMovAvgSeries(_.flatMap(refined, (item) => ({ value: item })));
            setTimeout(() => {
              setLoading(false);
            }, 1000);
          }
          setLoading(false);
        })
        .catch((error) => {
          if (subscribe) {
            if (error.code === "ECONNABORTED") {
              setErrorMsg("The API request timed out. Please Refresh the page");
              setError(true);
            } else {
              setErrorMsg(error.response.data.message);
              setError(true);
            }
          }
        });
      return () => {
        subscribe = false;
      };
    }
  }, [loading]);

  // *****
  // Update Moving Average and rerender
  // *****

  useEffect(() => {
    let refined = refineMovingAvgArray(
      _.flatMap(chartState.totalRevenue, (item) => item.value)
    );
    setMovAvgSeries(_.flatMap(refined, (item) => ({ value: item })));
    setTimeout(() => {
      setMovingAvgCheck(false);
    }, 500);
  }, [movingAvgSize]);
  const config = {
    numberprefix: "$",
  };
  return (
    <Grid item lg={12} md={12} sm={12} xs={12} xl={12}>
      <Paper className={fixedHeightPaper}>
        {loading || movingAvgCheck ? (
          <ChartLoader />
        ) : (
          <React.Fragment>
            <Grid container spacing={2} justifyContent="space-between">
              <span className={classes.textTitle}>
                Total Revenue vs Operating Expenses and Draws.
              </span>
              <span>
                <InfoButton
                  title={
                    "Quickly analyze operating expenses and personal draws compared to revenue."
                  }
                />
                <FilterUserTimePractice
                  dateFilterState={dateFilterState}
                  movingAvg={filterMA}
                  handleMovingAvg={handleMovingAvg}
                  filterState={{}}
                  disabled={loading || movingAvgCheck}
                  handleFilterClear={handleFilterClear}
                  handleFilterSubmit={handleFilterSubmit}
                  handleDateFilterRange={handleDateFilterRange}
                />
              </span>
            </Grid>

            {chartState.draw.length > 0 ||
            chartState.opExpense.length > 0 ||
            movAvgSeries.length > 0 ? (
              <Chart
                type="mscombi2d"
                config={config}
                labels={chartState.months}
                series={series}
                height={400}
              />
            ) : (
              <EmptyPlaceholder />
            )}
          </React.Fragment>
        )}
      </Paper>
      {error ? <ErrorSnackBar errorMsg={errorMsg} /> : null}
    </Grid>
  );
};

export default GrowthTrendsCashflow;
