import * as React from "react";
import Zendesk from "react-zendesk";
const ZENDESK_KEY = `${process.env.REACT_APP_ZENDESK_WIDGET}`;

interface IZendeskWidgetProps {}
const setting = {
  color: {
    theme: "#1488CC",
  },
  launcher: {
    chatLabel: {
      "en-US": "Need Help",
    },
  },
  webWidget: {
    position: { horizontal: "left", vertical: "bottom" },
    offset: { horizontal: "50px" },
  },
  contactForm: {
    fields: [
      { id: "description", prefill: { "*": "My pre-filled description" } },
    ],
  },
};
const ZendeskWidget: React.FunctionComponent<IZendeskWidgetProps> = () => {
  return (
    <Zendesk
      defer
      zendeskKey={ZENDESK_KEY}
      {...setting}
      onLoaded={() => console.log("is loaded")}
    />
  );
};

export default ZendeskWidget;
