import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Paper, Grid, Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import images from "../../../../images";
import useAuth from "../../../../hooks/useAuth";
export interface SyncPlaceholderProps {}

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  textWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    paddingLeft: theme.spacing(6),
    [theme.breakpoints.down("md")]: {
      paddingBottom: theme.spacing(2),
    },
  },
  imageWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      justifyContent: "flex-start",
    },
  },
  title: {
    display: "inline-block",
    paddingBottom: theme.spacing(2),
    color: theme.palette.text.primary,
    fontFamily: "Open Sans",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipses",
    [theme.breakpoints.up("md")]: {
      fontSize: "1.6em",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "1.3em",
    },
    fontSize: "1.3em",
  },
  subHeading: {
    display: "block",
    whiteSpace: "normal",
    fontSize: "0.8em",
    fontWeight: 200,
    color: theme.palette.text.primary,
    [theme.breakpoints.up("md")]: {
      fontSize: "1.2em",
      fontWeight: 500,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "1em",
      fontWeight: 500,
    },
  },
  button: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(2),
    },
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    backgroundColor: theme.palette.primary.light,
    marginRight: theme.spacing(4),
    textTransform: "none",
  },
  plansDiv: {
    border: "solid",
    borderColor: theme.palette.primary.main,
    borderWidth: 2,
    borderRadius: theme.spacing(1),
    overflow: "hidden",
    padding: theme.spacing(2),
    backgroundColor: "rgb(39,80,195,0.2)",
  },
  selectedPlanDiv: {
    borderColor: theme.palette.secondary.main,
    backgroundColor: "rgb(28,200,138, 0.2)",
  },
  image: {
    maxHeight: "100%",
    width: "auto",
    borderRadius: "20%",
    overflow: "hidden",
  },
}));

const SyncPlaceholder: React.FC<SyncPlaceholderProps> = () => {
  const {
    generalStates: { paymentInfo },
    currentAppState: {
      status: { clioStatus, ppStatus, xeroStatus, code },
    },
  } = useAuth();
  const classes = useStyles();
  const history = useHistory();
  const handleRedirect = () => {
    history.push("/dashboard/settings/integration");
  };

  // const clioIntegration = state.generalStates.activeFirm.firm.clio;
  // const xeroIntegration = state.generalStates.activeFirm.firm.xero;

  return (
    <React.Fragment>
      <span className={classes.title}>
        Integrate{" "}
        {code === "000" ? "Firm" : xeroStatus == 0 ? "Finance " : "Practice "}
        Management Solution
      </span>
      <Grid
        container
        spacing={4}
        justifyContent={"space-between"}
        alignItems={"center"}
        component={Paper}
      >
        <Grid item className={classes.imageWrapper}>
          <img
            src={images.ftLogo}
            className={classes.image}
            height={80}
            alt="logog"
          />
          <div className={classes.textWrapper}>
            <span className={classes.subHeading}>
              Your firm is created but isn't integrated{" "}
              {code === "000"
                ? "yet"
                : xeroStatus == 0
                ? "with a Finance Management Solution."
                : "with a Practice Management Solution."}
              <br />
              Please Integrate to proceed.
            </span>
          </div>
        </Grid>
        <Grid>
          <Button
            onClick={handleRedirect}
            variant="contained"
            color="primary"
            className={classes.button}
          >
            Integrate{" "}
            {code === "000"
              ? "Firm"
              : xeroStatus == 0
              ? "Finance Management Solution"
              : "Practice Management Solution"}
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default SyncPlaceholder;
