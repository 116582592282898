import getDistance from "geolib/es/getDistance";
import { globalServer, canadaServer, euServer, Location } from "./constants";

export interface Server {
  url: string;
  distance: number;
}

/*
Return an object that contains the url & distance-comparing current location to the closest server location
Defaults to Global URL
*/

export const getNearestServer = (currentLocation: Location): Server => {
  let global: Server = {
    url: "https://app.firmtrak.com",
    distance: getDistance(globalServer, currentLocation),
  };
  let canada: Server = {
    url: "https://ca-app.firmtrak.com",
    distance: getDistance(canadaServer, currentLocation),
  };
  let europe: Server = {
    url: "https://eu-app.firmtrak.com",
    distance: getDistance(euServer, currentLocation),
  };
  return [global, canada, europe].reduce((selected, item) => {
    if (item.distance < selected.distance) {
      selected = item;
      return selected;
    } else {
      return selected;
    }
  }, global);
};
