import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import _ from "lodash";
import PrimaryNav from "../components/primaryNav/PrimaryNav";
import { Socket } from "../services/socket/socket";
import DashboardLoader from "../components/dashboard/commons/loaders/dashboardLoader";
import PrivateDashboardRoutes from "../router/PrivateDashboardRoutes";
import ErrorSnackBar from "../components/dashboard/commons/SnackBar";
import api from "../services";
import useAuth from "../hooks/useAuth";
// function Copyright() {
//   return (
//     <Typography variant="body2" color="textSecondary" align="center">
//       {"Copyright © FirmTrak "}
//       {new Date().getFullYear()}
//       {"."}
//     </Typography>
//   );
// }

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    padding: 0,
  },
  list: {
    color: "#ffffff",
  },
}));

export interface ClioContextType {
  clioCode: string;
  handleClio: (clioCode: string, uriState: string) => void;
  uriClioState: string;
}

// *****
// Type Checking - Payments
// *****

interface BillingSubscriptionDetailsType {
  activeUsers: number | null;
  freeUsers: number | null;
  isBlocked: boolean;
  integrationType: string | null;
  trialStarted: string;
}

interface TierType {
  from: number;
  upto: number | string;
  price: number;
  isActive?: boolean;
}

interface BillingInfoStateType {
  isBlocked: boolean;
  billingRequired: boolean;
}

function Dashboard() {
  const classes = useStyles();
  const {
    generalStates: { user, activeFirm },
    currentAppState: { loader },
  } = useAuth();

  const userType = user.userType;

  return (
    <>
      <Socket>
        <div className={classes.root}>
          <PrimaryNav />
          <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            {/* Main Container */}
            <Container maxWidth="xl" className={classes.container}>
              {activeFirm ? (
                loader ? (
                  <DashboardLoader />
                ) : (
                  <PrivateDashboardRoutes userType={userType} />
                )
              ) : (
                <PrivateDashboardRoutes userType={userType} />
              )}
            </Container>
          </main>
        </div>
      </Socket>
    </>
  );
}

export { Dashboard };
