import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import moment from "moment";
import _ from "lodash";
import numeral from "numeral";
import { IconButton } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import TextField from "@material-ui/core/TextField";
import LinkButton from "@material-ui/core/Button";
import FilterListIcon from "@material-ui/icons/FilterList";
import InfoIcon from "@material-ui/icons/Info";
import Colors from "../../../../theme/ChartTheme";
import { PercentageRounded } from "../productivity/utils";
import MatTable from "../../charts/table";
import api from "../../../../services";
import FilterUserTimePractice from "../../filters/genericFilter/filters";
import ProgressBar from "../../charts/progressBar";
import useFilterState from "../../filters/genericFilter/filterStateHook";
import { MatterTrackerTypes } from "../../filters/genericFilter/types";
import ErrorSnackBar from "../../commons/SnackBar";
import useAuth from "../../../../hooks/useAuth";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    display: "flex",
    overflow: "visible",
    flexDirection: "column",
  },
  radialHeight: {
    height: 350,
    alignItems: "center",
    justifyContent: "space-between",
    paddingVertical: theme.spacing(1),
  },
  progressPaper: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  innerWrapper: {
    marginBottom: theme.spacing(2),
  },
  wrap: {
    marginTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  pseudoJeopardyLabel: {
    "&:hover": {
      color: theme.palette.error.main,
      backgroundColor: "transparent",
    },
  },
  pseudoPlannedLabel: {
    "&:hover": {
      color: theme.palette.secondary.main,
      backgroundColor: "transparent",
    },
  },
  pseudoToleranceLabel: {
    "&:hover": {
      color: theme.palette.warning.main,
      backgroundColor: "transparent",
    },
  },
  pseudo90to120: {
    "&:hover": {
      color: Colors.purple,
      backgroundColor: "transparent",
    },
  },
  pseudo120p: {
    "&:hover": {
      color: Colors.blue,
      backgroundColor: "transparent",
    },
  },
  buttonLabelUnselected: {
    color: theme.palette.text.primary,
  },
  barJeopardy: {
    backgroundColor: theme.palette.error.main,
  },
  barTolerance: {
    backgroundColor: theme.palette.warning.main,
  },
  bar90to120: {
    backgroundColor: Colors.purple,
  },
  bar120p: {
    backgroundColor: Colors.blue,
  },
  barPlanned: {
    backgroundColor: theme.palette.secondary.main,
  },
  percentJeopardy: {
    color: theme.palette.error.main,
  },
  percentTolerance: {
    color: theme.palette.warning.main,
  },
  percentPlanned: {
    color: theme.palette.secondary.main,
  },
  percent90t0120: {
    color: Colors.purple,
  },
  percent120plus: {
    color: Colors.blue,
  },
  buttonWrapper: {
    display: "inline-block",
    paddingBottom: theme.spacing(1) / 2,
  },
  buttonRoot: {
    display: "inline-block",
    margin: 0,
    border: "none",
    height: 0,
    minWidth: 0,
    padding: 0,
    "&:hover": {
      color: theme.palette.error.light,
      backgroundColor: "transparent",
    },
  },
  buttonLabel: {
    display: "inline-block",
    textAlign: "start",
    textTransform: "none",
    padding: 0,
    margin: 0,
  },
  headingText: {
    fontSize: "1.2em",
    display: "inline-block",
    fontWeight: 600,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(2),
  },
  divider: {
    backgroundColor: "#EDEFF7",
    height: 1,
    marginBottom: theme.spacing(1),
  },

  wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  switch: {
    borderColor: theme.palette.primary.light,
    borderStyle: "solid",
    borderWidth: "2px",
    borderRadius: 5,
    overflow: "hidden",
    flexDirection: "row",
    display: "flex",
    width: 250,
    height: 40,
    justifyContent: "space-between",
    alignItems: "stretch",
    alignContent: "center",
  },
  newButtonLabel: {
    display: "inline-block",
    textAlign: "start",
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: "25px",
    textTransform: "none",
    cursor: "pointer",
    padding: 0,
    margin: 0,
  },
  selected: {
    backgroundColor: theme.palette.primary.light,
    color: "#ffffff",
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    fontSize: "16px",
  },
  unselected: {
    backgroundColor: "#ffffff",
    color: theme.palette.primary.light,
    display: "flex",
    flex: 1,
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "16px",
  },
  button: {
    backgroundColor: theme.palette.primary.light,
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(2),

    fontSize: "0.9em",
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  sticky: {
    height: "100%",
  },
}));

// *****
// Initial State
// *****

const initialFilterState: MatterTrackerTypes = {
  practiceArea: "",
  billingMethod: "",
};

const ARGrid = ({ id, handleChange, handleBack, detail, type }) => {
  const {
    generalStates: {
      token,
      activeFirm: {
        firm: { _id },
      },
    },
    integrationConfigState: { pmType },
  } = useAuth();
  const [arCount, setArCount] = useState<any>({
    arCount0to30: 0,
    arCount31to60: 0,
    arCount61to90: 0,
    arCount91to120: 0,
    arCount120p: 0,
    arDue0to30: 0,
    arDue31to60: 0,
    arDue61to90: 0,
    arDue91to120: 0,
    arDue120p: 0,
    totalDue: 0,
  });

  const [table, setTable] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [waitForCounts, setWaitForCounts] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [table1, setTable1] = useState([]);
  const [from, setFrom] = useState<number>(1);
  const [to, setTo] = useState<number>(10);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [arCountCustom, setArCountCustom] = useState<number>(0);
  const [arDueCustom, setArDueCustom] = useState<number>(0);
  const [isLoading1, setIsLoading1] = useState<boolean>(false);
  const [loading1, setLoading1] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);

  // *****
  // Current Values of Filters
  // *****

  const { filterRef, filterState, handleState } =
    useFilterState(initialFilterState);

  // *****
  // Previous Values of Filters
  // *****

  const prevFilterState = filterRef.current;

  const classes = useStyles();
  const commonCellStyle = {
    color: "#858796",
    fontSize: 14,
    fontWieght: 700,
  };

  // *****
  // Filter state updates
  // *****

  const handlePracticeAreas = (value: number | "") => {
    handleState.handlePracticeArea(value);
  };

  const handleBillingMethod = (value: string) => {
    handleState.handleBillingMethod(value);
  };

  const handleFilterSubmit = (e) => {
    if (
      filterState.practiceArea !== prevFilterState.practiceArea ||
      filterState.billingMethod !== prevFilterState.billingMethod
    ) {
      if (type === 0) {
        setLoading(true);
        setWaitForCounts(false);
      } else if (type === 1) {
        setLoading1(true);
        setWaitForCounts(false);
        setIsLoading1(true);
        handleCustomGet();
      }
    }
  };
  const handleFilterClear = () => {
    if (type === 0) {
      handleState.reset(initialFilterState);
      setLoading(true);
      setIsLoading(true);
    } else {
      handleState.reset(initialFilterState);
    }
  };
  // const handleTypeChange = (value) => {
  //   if (type === value) return;
  //   setType(value);
  // };
  const columns = [
    { title: "Name", field: "name", cellStyle: { ...commonCellStyle } },
    {
      title: "Total",
      field: "total",
      type: "numeric",
      render: (row) => (
        <span>{`${numeral(row["total"]).format("($ 0.00 a)")}`}</span>
      ),
      cellStyle: { ...commonCellStyle },
    },
    {
      title: "Paid",
      field: "paid",
      render: (row) => (
        <span>{`${numeral(row["paid"]).format("($ 0.00 a)")}`}</span>
      ),
      type: "numeric",
      cellStyle: { ...commonCellStyle },
    },
    {
      title: "Paid %",
      field: "paid_percent",
      render: (row) => <span>{`${row["paid_percent"]} %`}</span>,
      cellStyle: { ...commonCellStyle },
      type: "numeric",
    },
    {
      title: "Due",
      field: "due",
      render: (row) => (
        <span>{`${numeral(row["due"]).format("($ 0.00 a)")}`}</span>
      ),
      cellStyle: { ...commonCellStyle },
      type: "numeric",
    },
    {
      title: "Due %",
      field: "due_percent",
      render: (row) => <span>{`${row["due_percent"]} %`}</span>,
      cellStyle: { ...commonCellStyle },
      type: "numeric",
    },
    {
      title: "Issued At",
      field: "issued_at",
      cellStyle: { ...commonCellStyle },
      render: (row) => (
        <span>{moment(row["issued_at"]).format("MM/DD/YY")}</span>
      ),
      type: "string",
    },
  ];

  function checkNull(value: number | null): number {
    if (value === null) {
      return 0;
    } else {
      return value;
    }
  }

  function returnPeriod() {
    const some = id.filter((item) => {
      return item.selected;
    });
    return some[0].name;
  }
  function handleChangeFrom(e) {
    setFrom(
      e.target.value.includes("-") || e.target.value === "0"
        ? 1
        : parseInt(e.target.value)
    );
  }
  function handleChangeTo(e) {
    setTo(
      e.target.value.includes("-") || e.target.value === "0"
        ? 1
        : parseInt(e.target.value)
    );
  }

  function handleCustomGet() {
    let request = api.create(token);
    let queryParams = {
      days_count_start: from,
      days_count_end: to,
      practice_area_id:
        filterState.practiceArea === "" ? null : filterState.practiceArea,
      billing_method:
        filterState.billingMethod === "" ? null : filterState.billingMethod,
    };
    request
      .getARDateFilterCount(_id, pmType, queryParams)
      .then((response) => {
        setArDueCustom(
          response.data[0].total_due === null ? 0 : response.data[0].total_due
        );
        setArCountCustom(response.data[0].count);
        setLoading1(false);
        setIsLoading1(true);
      })
      .catch((error) => {
        if (error.code === "ECONNABORTED") {
          setErrorMsg("The API request timed out. Please Refresh the page");
          setError(true);
        } else {
          setError(true);
          setErrorMsg(error.response.data.message);
        }
      });
  }

  useEffect(() => {
    let subscribe = true;
    if (type === 1) {
      if (arCountCustom > 0) {
        let request = api.create(token);
        let queryParams = {
          days_count_start: `${from}`,
          days_count_end: `${to}`,
          practice_area_id:
            filterState.practiceArea === "" ? null : filterState.practiceArea,
          billing_method:
            filterState.billingMethod === "" ? null : filterState.billingMethod,
          limit: arCountCustom,
          offset: 0,
        };
        request
          .getARDateFilterDetail(_id, pmType, queryParams)
          .then((response) => {
            if (subscribe) {
              setTable1(response.data);
              setIsLoading1(false);
              setLoading1(false);
            }
          })
          .catch((error) => {
            if (subscribe) {
              if (error.code === "ECONNABORTED") {
                setErrorMsg(
                  "The API request timed out. Please Refresh the page"
                );
                setError(true);
              } else {
                setError(true);
                setErrorMsg(error.response.data.message);
              }
            }
          });
      } else {
        setTable1([]);
        setIsLoading1(false);
        setLoading1(false);
      }
    }
    return () => {
      subscribe = false;
    };
  }, [isLoading1]);
  useEffect(() => {
    if (type === 1) {
      if (to === from || from > to || isNaN(from) || isNaN(to)) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    }
  }, [to, from, type]);

  useEffect(() => {
    let subscribe = true;
    if (loading) {
      let request = api.create(token);
      let queryParams = {
        practice_area_id:
          filterState.practiceArea === "" ? null : filterState.practiceArea,
        billing_method:
          filterState.billingMethod === "" ? null : filterState.billingMethod,
      };
      if (type === 0) {
        request
          .getARCount(_id, pmType, queryParams)
          .then((response) => {
            if (subscribe) {
              let data = response.data;
              const arrayOfObj = _.toPairs(data);

              return arrayOfObj;
            }
          })
          .then((response) => {
            if (subscribe) {
              setArCount({
                arCount0to30: checkNull(response[0][1][0].count),
                arCount31to60: checkNull(response[1][1][0].count),
                arCount61to90: checkNull(response[2][1][0].count),
                arCount91to120: checkNull(response[3][1][0].count),
                arCount120p: checkNull(response[4][1][0].count),
                arDue0to30: checkNull(response[0][1][0].total_due),
                arDue31to60: checkNull(response[1][1][0].total_due),
                arDue61to90: checkNull(response[2][1][0].total_due),
                arDue91to120: checkNull(response[3][1][0].total_due),
                arDue120p: checkNull(response[4][1][0].total_due),
                totalDue: checkNull(
                  response[0][1][0].total_due +
                    response[1][1][0].total_due +
                    response[2][1][0].total_due +
                    response[3][1][0].total_due +
                    response[4][1][0].total_due
                ),
              });
              setWaitForCounts(true);
              setLoading(false);
            }
          })
          .catch((error) => {
            if (subscribe) {
              if (error.code === "ECONNABORTED") {
                setErrorMsg(
                  "The API request timed out. Please Refresh the page"
                );
                setError(true);
              } else {
                setError(true);
                setErrorMsg(error.response.data.message);
              }
            }
          });
      }
    }
    return () => {
      subscribe = false;
    };
  }, [loading]);

  useEffect(() => {
    let subscribe = true;
    if (waitForCounts && type === 0 && id[0].selected) {
      if (arCount.arCount0to30 > 0) {
        let request = api.create(token);
        let queryParamsCountOffset = {
          practice_area_id:
            filterState.practiceArea === "" ? null : filterState.practiceArea,
          billing_method:
            filterState.billingMethod === "" ? null : filterState.billingMethod,
          limit: arCount.arCount0to30,
          offset: 0,
        };
        request
          .getARDetail0to30(_id, pmType, queryParamsCountOffset)
          .then((res) => {
            if (subscribe) {
              setTable(res.data);
              setIsLoading(false);
            }
          })
          .catch((error) => {
            if (subscribe) {
              if (error.code === "ECONNABORTED") {
                setErrorMsg(
                  "The API request timed out. Please Refresh the page"
                );
                setError(true);
              } else {
                setError(true);
                setErrorMsg(error.response.data.message);
              }
            }
          });
      } else {
        setIsLoading(false);
        setTable([]);
      }
    }
    return () => {
      subscribe = false;
    };
  }, [arCount, waitForCounts, id]);

  useEffect(() => {
    let subscribe = true;
    let request = api.create(token);

    if (
      waitForCounts &&
      type === 0 &&
      arCount.arCount31to60 > 0 &&
      id[1].selected
    ) {
      let queryParamsCountOffset = {
        practice_area_id:
          filterState.practiceArea === "" ? null : filterState.practiceArea,
        billing_method:
          filterState.billingMethod === "" ? null : filterState.billingMethod,
        limit: arCount.arCount31to60,
        offset: 0,
      };
      request
        .getARDetail31to60(_id, pmType, queryParamsCountOffset)
        .then((res) => {
          if (subscribe) {
            setTable(res.data);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          if (subscribe) {
            if (error.code === "ECONNABORTED") {
              setErrorMsg("The API request timed out. Please Refresh the page");
              setError(true);
            } else {
              setError(true);
              setErrorMsg(error.response.data.message);
            }
          }
        });
    } else {
      setIsLoading(false);
      setTable([]);
    }
    return () => {
      subscribe = false;
    };
  }, [arCount, waitForCounts, id]);
  useEffect(() => {
    if (type === 0) {
      let subscribe = true;
      let request = api.create(token);
      if (
        waitForCounts &&
        type === 0 &&
        arCount.arCount61to90 > 0 &&
        id[2].selected
      ) {
        let queryParamsCountOffset = {
          practice_area_id:
            filterState.practiceArea === "" ? null : filterState.practiceArea,
          billing_method:
            filterState.billingMethod === "" ? null : filterState.billingMethod,
          limit: arCount.arCount61to90,
          offset: 0,
        };
        request
          .getARDetail61to90(_id, pmType, queryParamsCountOffset)
          .then((res) => {
            if (subscribe) {
              setTable(res.data);
              setIsLoading(false);
            }
          })
          .catch((error) => {
            if (subscribe) {
              if (error.code === "ECONNABORTED") {
                setErrorMsg(
                  "The API request timed out. Please Refresh the page"
                );
                setError(true);
              } else {
                setError(true);
                setErrorMsg(error.response.data.message);
              }
            }
          });
      } else {
        setIsLoading(false);
        setTable([]);
      }
      return () => {
        subscribe = false;
      };
    }
  }, [arCount, waitForCounts, id]);
  useEffect(() => {
    let subscribe = true;
    let request = api.create(token);

    if (
      waitForCounts &&
      type === 0 &&
      arCount.arCount91to120 > 0 &&
      id[3].selected
    ) {
      let queryParamsCountOffset = {
        practice_area_id:
          filterState.practiceArea === "" ? null : filterState.practiceArea,
        billing_method:
          filterState.billingMethod === "" ? null : filterState.billingMethod,
        limit: arCount.arCount91to120,
        offset: 0,
      };
      request
        .getARDetail91to120(_id, pmType, queryParamsCountOffset)
        .then((res) => {
          if (subscribe) {
            setTable(res.data);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          if (subscribe) {
            if (error.code === "ECONNABORTED") {
              setErrorMsg("The API request timed out. Please Refresh the page");
              setError(true);
            } else {
              setError(true);
              setErrorMsg(error.response.data.message);
            }
          }
        });
    } else {
      setIsLoading(false);
      setTable([]);
    }
    return () => {
      subscribe = false;
    };
  }, [arCount, waitForCounts, id]);
  useEffect(() => {
    let subscribe = true;
    let request = api.create(token);

    if (
      waitForCounts &&
      type === 0 &&
      arCount.arCount120p > 0 &&
      id[4].selected
    ) {
      let queryParamsCountOffset = {
        practice_area_id:
          filterState.practiceArea === "" ? null : filterState.practiceArea,
        billing_method:
          filterState.billingMethod === "" ? null : filterState.billingMethod,
        limit: arCount.arCount120p,
        offset: 0,
      };
      request
        .getARDetail120plus(_id, pmType, queryParamsCountOffset)
        .then((res) => {
          if (subscribe) {
            setTable(res.data);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          if (subscribe) {
            if (error.code === "ECONNABORTED") {
              setErrorMsg("The API request timed out. Please Refresh the page");
              setError(true);
            } else {
              setError(true);
              setErrorMsg(error.response.data.message);
            }
          }
        });
    } else {
      setIsLoading(false);
      setTable([]);
    }
    return () => {
      subscribe = false;
    };
  }, [arCount, waitForCounts, id]);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
        <div className={classes.sticky}>
          <Paper className={classes.progressPaper}>
            {type === 0 ? (
              <React.Fragment>
                <div className={classes.innerWrapper}>
                  <span className={classes.buttonWrapper}>
                    <a
                      onClick={() => handleChange(0)}
                      className={`${classes.newButtonLabel} ${
                        classes.pseudoJeopardyLabel
                      } ${id[0].selected && classes.percentJeopardy}`}
                    >
                      0 to 30 days
                    </a>
                  </span>
                  <ProgressBar
                    value={PercentageRounded(
                      arCount.arDue0to30,
                      arCount.totalDue
                    )}
                    total={numeral(arCount.arDue0to30).format("($ 0.00 a)")}
                    loading={loading}
                    barColor={classes.barJeopardy}
                    percentColor={classes.percentJeopardy}
                  />
                </div>
                <div className={classes.innerWrapper}>
                  <span className={classes.buttonWrapper}>
                    <a
                      onClick={() => handleChange(1)}
                      className={`${classes.newButtonLabel} ${
                        classes.pseudoToleranceLabel
                      } ${id[1].selected && classes.percentTolerance}`}
                    >
                      31 to 60 days
                    </a>
                  </span>
                  <ProgressBar
                    value={PercentageRounded(
                      arCount.arDue31to60,
                      arCount.totalDue
                    )}
                    total={numeral(arCount.arDue31to60).format("($ 0.00 a)")}
                    barColor={classes.barTolerance}
                    percentColor={classes.percentTolerance}
                    loading={loading}
                  />
                </div>
                <div className={classes.innerWrapper}>
                  <span className={classes.buttonWrapper}>
                    <a
                      onClick={() => handleChange(2)}
                      className={`${classes.newButtonLabel} ${
                        classes.pseudoPlannedLabel
                      } ${id[2].selected && classes.percentPlanned}`}
                    >
                      61 to 90 days
                    </a>
                  </span>
                  <ProgressBar
                    value={PercentageRounded(
                      arCount.arDue61to90,
                      arCount.totalDue
                    )}
                    total={numeral(arCount.arDue61to90).format("($ 0.00 a)")}
                    barColor={classes.barPlanned}
                    percentColor={classes.percentPlanned}
                    loading={loading}
                  />
                </div>
                <div className={classes.innerWrapper}>
                  <span className={classes.buttonWrapper}>
                    <a
                      onClick={() => handleChange(3)}
                      className={`${classes.newButtonLabel} ${
                        classes.pseudo90to120
                      } ${id[3].selected && classes.percent90t0120}`}
                    >
                      91 to 120 days
                    </a>
                  </span>
                  <ProgressBar
                    value={PercentageRounded(
                      arCount.arDue91to120,
                      arCount.totalDue
                    )}
                    total={numeral(arCount.arDue91to120).format("($ 0.00 a)")}
                    barColor={classes.bar90to120}
                    percentColor={classes.percent90t0120}
                    loading={loading}
                  />
                </div>
                <div className={classes.innerWrapper}>
                  <span className={classes.buttonWrapper}>
                    <a
                      onClick={() => handleChange(4)}
                      className={`${classes.newButtonLabel} ${
                        classes.pseudo120p
                      } ${id[4].selected && classes.percent120plus}`}
                    >
                      120 days +
                    </a>
                  </span>
                  <ProgressBar
                    value={PercentageRounded(
                      arCount.arDue120p,
                      arCount.totalDue
                    )}
                    total={numeral(arCount.arDue120p).format("($ 0.00 a)")}
                    barColor={classes.bar120p}
                    percentColor={classes.percent120plus}
                    loading={loading}
                  />
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Grid container spacing={2} className={classes.wrap}>
                  <Grid item xs sm md lg xl>
                    <TextField
                      label="From"
                      variant="outlined"
                      fullWidth
                      type={"number"}
                      value={from}
                      onChange={(e: object) => handleChangeFrom(e)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs sm md lg xl>
                    <TextField
                      variant="outlined"
                      fullWidth
                      value={to}
                      type={"number"}
                      onChange={(e: object) => handleChangeTo(e)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="To"
                    />
                  </Grid>
                </Grid>
                <Grid item xs sm md lg xl>
                  <LinkButton
                    type="submit"
                    size="large"
                    onClick={() => handleCustomGet()}
                    className={classes.button}
                    disabled={disabled}
                  >
                    Get
                  </LinkButton>
                </Grid>
                <div className={classes.innerWrapper}>
                  <span
                    className={classes.buttonWrapper}
                  >{`Total Due: ${numeral(arDueCustom).format(
                    "($ 0.00 a)"
                  )}`}</span>
                </div>
              </React.Fragment>
            )}
          </Paper>
        </div>
      </Grid>
      <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
        <Grid container spacing={2}>
          <FilterUserTimePractice
            disabled={loading || isLoading || isLoading1 || loading1}
            filterState={filterState}
            dateFilterState={{}}
            handleBillingMethod={handleBillingMethod}
            handlePracticeAreas={handlePracticeAreas}
            handleFilterSubmit={handleFilterSubmit}
            handleFilterClear={handleFilterClear}
            handleFromComponent={open}
            setCloseFromComponent={() => setOpen(false)}
          />
          <Grid item xs={12}>
            {type === 0 ? (
              <MatTable
                data={table}
                column={columns}
                isLoading={isLoading || loading}
                title={`AR Aging (${returnPeriod()})`}
                search
                back={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <IconButton onClick={handleBack}>
                      <ArrowBackIcon />
                    </IconButton>
                    <span>Back to AR</span>
                  </div>
                }
                actions={[
                  {
                    icon: () => <InfoIcon />,
                    isFreeAction: true,
                    tooltip: "AR Buckets",
                  },
                  {
                    icon: () => <FilterListIcon />,
                    isFreeAction: true,
                    onClick: (event, rowData) => {
                      setOpen(!open);
                    },
                    tooltip: "Filters",
                  },
                ]}
              />
            ) : (
              <MatTable
                data={table1}
                column={columns}
                isLoading={isLoading1 || loading1}
                back={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <IconButton onClick={handleBack}>
                      <ArrowBackIcon />
                    </IconButton>
                    <span>Back to AR</span>
                  </div>
                }
                title={`AR Aging`}
                search
                actions={[
                  {
                    icon: () => <InfoIcon />,
                    isFreeAction: true,
                    tooltip: (
                      <div>
                        Concentration ratio closer to Zero indicates that the AR
                        portfolio is less concentrated, which is a good
                        indicator that the risk is spread so that one single
                        customer is not a key source of income.
                        <br />
                        <br />
                        Diversity Index represents the firm’s AR Risk in client
                        representation. A diversity rate closer to 1 represents
                        AR Risk is consolidated to 1 client. A higher number
                        represents more equally distributed AR.
                      </div>
                    ),
                  },
                  {
                    icon: () => <FilterListIcon />,
                    isFreeAction: true,
                    onClick: (event, rowData) => {
                      setOpen(!open);
                    },
                    tooltip: "Filters",
                  },
                ]}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
      {error ? <ErrorSnackBar errorMsg={errorMsg} /> : null}
    </Grid>
  );
};

export default ARGrid;
