import {
  Grid,
  ImageList,
  ImageListItem,
  makeStyles,
  Paper,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import useAuth from "../../../../hooks/useAuth";
import api from "../../../../services";

const useStyles = makeStyles((theme) => ({
  headingText: {
    fontSize: "1.5em",
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.text.primary,
  },
  container: {
    padding: theme.spacing(3),
    fontFamily: "Open Sans",
    alignItems: "center",
    flex: 1,
    width: "100%",
    height: "100%",
  },
  imageList: {
    width: "100%",
    // Promote the list into its own layer in Chrome. This cost memory, but helps keep FPS high.
    transform: "translateZ(0)",
  },
}));
export default function Lawmatics() {
  const [loader, setLoader] = useState<boolean>(true);
  const [data, setData] = useState<any[]>([]);
  const {
    generalStates: {
      token,
      activeFirm: {
        firm: { _id },
      },
    },
  } = useAuth();

  useEffect(() => {
    let subscribe = true;
    if (loader && _id && token) {
      let request = api.create(token);
      request
        .getLawmatics(_id)
        .then((res) => {
          console.log(res.data);
          if (subscribe) {
            setData(res.data);
            setLoader(false);
          }
        })
        .catch((e) => {
          setLoader(false);
        });
    }
    return () => {
      subscribe = false;
    };
  }, [loader]);

  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      <Grid item style={{ marginBottom: 20 }}>
        <span className={classes.headingText}>Lawmatics</span>
      </Grid>
      <Grid container>
        <Paper className={classes.container}>
          <ImageList rowHeight="auto" cols={1} className={classes.imageList}>
            {data.map((item) => (
              <ImageListItem component="li" key={item.image_url}>
                <img
                  style={{ width: "100%" }}
                  src={item.image_url}
                  srcSet={item.img_url}
                  alt={item?.updatedAt}
                  loading="lazy"
                />
              </ImageListItem>
            ))}
          </ImageList>
        </Paper>
      </Grid>
    </Grid>
  );
}
