import React from "react";
import "./App.css";
import Router from "./router";
import { StoreProvider } from "./context/store/storeContext";
import Theming from "./theme/Theme";
import { TrackWindow } from "./window/trackWindow";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import FusionCharts from "fusioncharts/core";

// *****
// Load Stripe = resolves a promise with a stripe object;
// sets up public index.html with stripe security vulnerability scripts
// *****

const stripePromise: Stripe | null | Promise<Stripe | null> = loadStripe(
  process.env.REACT_APP_STRIPE_API_KEY as any
);

FusionCharts.options["license"]({
  key: process.env.REACT_APP_FUSION_CHARTS_KEY,
  creditLabel: false,
});

function App() {
  // localStorage.clear();
  return (
    <StoreProvider>
      <Elements stripe={stripePromise}>
        <Theming>
          <TrackWindow>
            <Router />
          </TrackWindow>
        </Theming>
      </Elements>
    </StoreProvider>
  );
}

export default App;
