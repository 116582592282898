import React, { useState, useContext } from "react";
import MattersInvoices from "../../grids/matters/invoices";
import MattersGrowthTrends from "../../grids/matters/growthTrends";
import MattersRevenue from "../../grids/matters/revenue";
import MattersAR from "../../grids/matters/ar";
import MattersBilledVsNonBilled from "../../grids/matters/billedNonBilled";

import { StyledToggleButtonGroup, StyledToggleButton } from "../styledTabs";
import { makeStyles } from "@material-ui/core/styles";
import { WindowContext } from "../../../../window/trackWindow";
import Grid from "@material-ui/core/Grid";
import useAuth from "../../../../hooks/useAuth";

const useStyles = makeStyles((theme) => ({
  headingText: {
    fontSize: "1.5em",
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.text.primary,
  },
  divider: {
    marginBottom: theme.spacing(1),
  },
}));

export interface MattersTabProps {}

const MattersTab: React.FC<MattersTabProps> = () => {
  const window = useContext(WindowContext);
  const [active, setActive] = useState(1);
  const classes = useStyles();
  const {
    integrationConfigState: { pmType },
  } = useAuth();
  const handleChange = (event, value) => {
    if (value !== null) {
      setActive(value);
    }
  };

  function switchTabs() {
    switch (active) {
      case 1:
        return <MattersGrowthTrends />;
      case 2:
        return <MattersInvoices />;
      case 3:
        return <MattersRevenue />;
      case 4:
        return <MattersAR />;
      case 5:
        return <MattersBilledVsNonBilled />;
    }
  }

  return (
    <Grid container spacing={4} justifyContent="space-between">
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <span className={classes.headingText}>Matters</span>
      </Grid>
      <StyledToggleButtonGroup
        onChange={handleChange}
        exclusive
        value={active}
        size="small"
        style={{ fontSize: window < 600 ? 11 : 13 }}
      >
        <StyledToggleButton key={1} value={1}>
          Growth & Trend
        </StyledToggleButton>
        <StyledToggleButton key={2} value={2}>
          Invoices
        </StyledToggleButton>
        <StyledToggleButton key={3} value={3}>
          Revenue
        </StyledToggleButton>
        <StyledToggleButton key={4} value={4}>
          AR
        </StyledToggleButton>
        {pmType == "clio" && (
          <StyledToggleButton key={5} value={5}>
            Billable vs Non Billable
          </StyledToggleButton>
        )}
      </StyledToggleButtonGroup>
      <Grid
        item
        xs={12}
        // sm={12}
        // md={9}
        // lg={9}
        // xl={9}
      >
        {switchTabs()}
      </Grid>
    </Grid>
  );
};

export default MattersTab;
