import BilledNonBilled from "./BilledNonBilled";
import { PlaceholderAcl } from "../../../../commons/placeholderAcl";
import useAuth from "../../../../../hooks/useAuth";

interface Props {}

const BilledNonBilledContainer = (props: Props) => {
  const {
    generalStates: { acl },
  } = useAuth();
  return acl?.matters_billable_non_billable ? (
    <BilledNonBilled />
  ) : (
    <PlaceholderAcl />
  );
};

export default BilledNonBilledContainer;
