import React, { useContext, useState, useEffect } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Grid, Paper } from "@material-ui/core";
import clsx from "clsx";
import useAuth from "../../../hooks/useAuth";
export interface SubscriptionInfoProps {
  current?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    height: 360,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      height: 600,
    },
    display: "flex",
    alignItems: "center",
  },
  innerElementContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    border: "solid",
    borderWidth: 1,
    borderColor: "#dadaed",
    borderRadius: theme.spacing(1),
    backgroundColor: "#f4f4f9",
    fontSize: "1rem",
    fontWeight: 600,
    color: theme.palette.text.secondary,
    [theme.breakpoints.down("md")]: {
      fontSize: "0.8rem",
      fontWeight: 600,
    },
  },
  titleText: {
    display: "block",
    textAlign: "center",
    fontSize: "1rem",
    fontWeight: 600,
    color: theme.palette.text.secondary,
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      fontSize: "0.9rem",
      fontWeight: 600,
    },
  },
  subscriptionActive: {
    color: theme.palette.secondary.main,
  },
  subscriptionInActive: {
    color: theme.palette.error.main,
  },
}));

const SubscriptionInfo: React.FC<SubscriptionInfoProps> = ({ current }) => {
  const classes = useStyles();
  const {
    generalStates: { paymentInfo },
  } = useAuth();
  const [currentObject, setCurrentObject] = useState<any>(null);

  useEffect(() => {
    if (current === "xero") {
      setCurrentObject(paymentInfo?.subscribedXero);
    } else if (current === "clio") {
      setCurrentObject(paymentInfo?.subscribedClio);
    } else if (current === "pp") {
      setCurrentObject(paymentInfo?.subscribedPP);
    }
  }, [current, paymentInfo]);

  // *****
  // Extracting from app state
  // *****

  const freeUsers: number =
    currentObject && currentObject.billingSubscriptionDetails.freeUsers;
  const activeUsers: number =
    currentObject && currentObject.billingSubscriptionDetails.activeUsers;
  const diffInDays: number = currentObject && currentObject.daysToExpire;
  const isBlocked: boolean = currentObject && currentObject.isBlocked;
  const integrationType =
    currentObject && currentObject.billingSubscriptionDetails.integrationType;
  const isFree =
    currentObject && currentObject.billingSubscriptionDetails.isFree;
  const billingRequired: boolean =
    currentObject && currentObject.billingRequired;
  const xeroPNL: number =
    currentObject && currentObject.billingSubscriptionDetails.xeroPNL;

  const subscriptionElementClass =
    currentObject && billingRequired && isBlocked
      ? clsx(classes.innerElementContainer, classes.subscriptionInActive)
      : clsx(classes.innerElementContainer, classes.subscriptionActive);
  console.log(currentObject);
  // *****
  // callback state update - lazily update local state when new app state has been updated
  // *****

  const [plan, setPlan] = useState<string>("");
  useEffect(() => {
    if (currentObject) {
      if (current == "clio" || current == "pp") {
        if (activeUsers <= 3) {
          setPlan("0-3 Employees");
        } else if (activeUsers > 3 && activeUsers <= 9) {
          setPlan("4-9 Employees");
        } else if (activeUsers > 9 && activeUsers <= 19) {
          setPlan("10-19 Employees");
        } else if (activeUsers >= 20) {
          setPlan("20 and above Employees");
        }
      } else if (current == "xero") {
        if (xeroPNL <= 1000000) {
          setPlan("0-1 Million ($)");
        } else if (xeroPNL > 2000000 && xeroPNL <= 5000000) {
          setPlan("2-5 Million ($)");
        } else if (xeroPNL >= 6000000) {
          setPlan("6+ Million ($)");
        }
      }
    }
  }, [currentObject]);
  const subscriptionStatus = () => {
    if (isBlocked && billingRequired) {
      return "Blocked - Payment Required";
    } else if (!isBlocked && billingRequired) {
      return "Trial Period";
    } else if (!isBlocked && !billingRequired && isFree) {
      return "Active (free)";
    } else if (!isBlocked && !billingRequired) {
      return "Active";
    } else {
      return "Error";
    }
  };
  return (
    <Paper className={classes.paper}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <Grid container spacing={4}>
            {(current === "clio" || current === "pp") && (
              <Grid item xs={12}>
                <span className={classes.titleText}>No. of Users:</span>
                <div className={classes.innerElementContainer}>
                  {activeUsers === 0 || activeUsers === null
                    ? "none"
                    : activeUsers}
                </div>
              </Grid>
            )}
            {(current === "clio" || current === "pp") && (
              <Grid item xs={12}>
                <span className={classes.titleText}>Free Users:</span>
                <div className={classes.innerElementContainer}>
                  {freeUsers === 0 || freeUsers === null ? "none" : freeUsers}
                </div>
              </Grid>
            )}
            {current === "xero" && (
              <Grid item xs={12}>
                <span className={classes.titleText}>Xero PNL:</span>
                <div className={classes.innerElementContainer}>
                  $ {xeroPNL === 0 || xeroPNL === null ? 0 : xeroPNL}
                </div>
              </Grid>
            )}
            <Grid item xs={12}>
              <span className={classes.titleText}>Plan:</span>
              <div className={classes.innerElementContainer}>{plan}</div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <span className={classes.titleText}>Integration Type:</span>
              <div className={classes.innerElementContainer}>
                {integrationType}
              </div>
            </Grid>
            <Grid item xs={12}>
              <span className={classes.titleText}>Trial Remaining:</span>
              <div className={classes.innerElementContainer}>
                {isFree ? "Unlimited" : diffInDays}
              </div>
            </Grid>
            <Grid item xs={12}>
              <span className={classes.titleText}>Subscription Status:</span>
              <div className={subscriptionElementClass}>
                {subscriptionStatus()}
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default SubscriptionInfo;
