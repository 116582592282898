import { ClientDistributionState, Action } from "./types";

export default function reducer(
  state: ClientDistributionState,
  action: Action
) {
  switch (action.type) {
    case "SET_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "SET_RESPONSE":
      return {
        ...state,
        loading: false,
        response: {
          status: true,
          activeClients: action.activeClients,
          dormantClients: action.dormantClients,
          months: action.months,
        },
        error: { status: false, message: "" },
      };

    case "SET_ERROR":
      return {
        ...state,
        loading: true,
        error: { status: true, message: action.message },
      };
    default:
      return state;
  }
}
