import React, { useEffect, useState } from "react";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Colors from "../../../../../../theme/ChartTheme";
import { compareFilterDates } from "../../../utils";
import ErrorSnackBar from "../../../../commons/SnackBar";
import { DateRange } from "materialui-daterange-picker";
import useFetchMattersDistribution from "./fetchMattersDistributionHook";
import FilterUserTimePractice from "../../../../filters/genericFilter/filters";
import useFilterState from "../../../../filters/genericFilter/filterStateHook";
import { FilterClientsMattersTypes } from "../../../../filters/genericFilter/types";
import { makeStyles, Theme } from "@material-ui/core/styles";
import useDateFilterState, {
  DateStateType,
} from "../../../../filters/genericFilter/dateFilterStateHook";
import ChartLoader from "../../../../commons/loaders/chartLoader";
import Chart from "../../../../charts/fusionCharts";
import EmptyPlaceholder from "../../../../commons/placeholders/emptyPlaceholder";
import InfoButton from "../../../../infoButton";
import { MattersDistributionState } from "./fetchMattersDistributionHook/types";

export interface MattersGrowthTrendsStackedBarProps {}

// *****
// Initial Filter State
// *****

const initialFilterState: FilterClientsMattersTypes = {
  practiceArea: "",
  billingMethod: "",
  originatingAtt: "",
  responsibleAtt: "",
};

const initialDateFilterState: DateStateType = {
  dateAfter: moment().subtract(12, "M").toDate(),
  dateBefore: new Date(),
};

/*
Hook that returns the current state and a function to invoke the Client Distribution API
*/

const useStyles = makeStyles((theme: Theme) => ({
  headingText: {
    fontSize: "1.2em",
    fontWeight: 600,
    color: theme.palette.text.secondary,
  },
  divider: {
    backgroundColor: "#EDEFF7",
    height: 1,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "visible",
    flexDirection: "column",
    justifyContent: "center",
    height: 500,
  },
  textTitle: {
    color: theme.palette.text.primary,
    fontSize: "1.2em",
  },
}));

const initialState: MattersDistributionState = {
  loading: true,
  response: {
    status: false,
    activeMatters: [],
    closedMatters: [],
    dormantMatters: [],
    months: [],
  },
  error: {
    status: false,
    message: "",
  },
};

const MattersGrowthTrendsStackedBar: React.FC<MattersGrowthTrendsStackedBarProps> = () => {
  const classes = useStyles();
  const [invoke, setInvoke] = useState<boolean>(true);
  const {
    mattersDistributionState: {
      loading,
      response: { activeMatters, closedMatters, dormantMatters, months },
      error: { status: errorStatus, message: errorMessage },
    },
    fetchMattersDistribution,
  } = useFetchMattersDistribution(initialState);
  const series = [
    {
      seriesname: "Active Matters",
      color: Colors.purple,
      data: activeMatters,
    },
    {
      seriesname: "Dormant Matters",
      data: dormantMatters,
      color: Colors.orange,
    },
    {
      seriesname: "Closed Matters",
      color: Colors.red,
      data: closedMatters,
    },
  ];

  // *****
  // Current Values of Filters
  // *****

  const {
    filterRef: { current: prevFilterState },
    filterState,
    handleState,
  } = useFilterState(initialFilterState);
  const {
    dateFilterRef: { current: prevDateFilterState },
    dateFilterState,
    handleDateState,
  } = useDateFilterState(initialDateFilterState);

  // *****
  // Filter state updates
  // *****

  const handlePracticeAreas = (value: number | "") => {
    handleState.handlePracticeArea(value);
  };

  const handleBillingMethod = (value: string) => {
    handleState.handleBillingMethod(value);
  };

  const handleOriginatingAtt = (value: number | "") => {
    handleState.handleOriginatingAtt(value);
  };

  const handleResponsibleAtt = (value: number | "") => {
    handleState.handleResponsibleAtt(value);
  };

  const handleDateFilterRange = (dateRange: DateRange): void => {
    handleDateState.handleDateUpdate(dateRange);
  };

  const handleFilterSubmit = () => {
    if (
      compareFilterDates(
        filterState,
        prevFilterState,
        dateFilterState,
        prevDateFilterState,
        initialFilterState
      ) === true
    ) {
      setInvoke(true);
    }
  };

  const handleFilterClear = () => {
    handleState.reset(initialFilterState);
    handleDateState.resetDate(initialDateFilterState);
    setInvoke(true);
  };

  // *****
  // Trigger API on filter submit & on first mount
  // *****

  useEffect(() => {
    if (invoke) {
      fetchMattersDistribution(filterState, dateFilterState);
      setInvoke(false);
    }
  }, [filterState, dateFilterState, fetchMattersDistribution, invoke]);

  const chartConfig = {
    plottooltext: "<b>$dataValue</b> $seriesName",
    showsum: "0",
  };

  return (
    <React.Fragment>
      <Grid item lg={12} md={12} sm={12} xs={12} xl={12}>
        <Paper className={classes.paper}>
          {loading ? (
            <ChartLoader />
          ) : (
            <React.Fragment>
              <Grid container justifyContent="space-between" alignItems="center">
                <span className={classes.textTitle}>Total Matters</span>
                <span>
                  <InfoButton
                    title={
                      "Active Matters represents clients that are active in billing during the last three months. "
                    }
                  />
                  <FilterUserTimePractice
                    filterState={filterState}
                    dateFilterState={dateFilterState}
                    disabled={loading}
                    handleFilterSubmit={handleFilterSubmit}
                    handleFilterClear={handleFilterClear}
                    handlePracticeAreas={handlePracticeAreas}
                    handleBillingMethod={handleBillingMethod}
                    handleOriginatingAtt={handleOriginatingAtt}
                    handleResponsibleAtt={handleResponsibleAtt}
                    handleDateFilterRange={handleDateFilterRange}
                  />
                </span>
              </Grid>
              {activeMatters.length !== 0 &&
              dormantMatters.length !== 0 &&
              closedMatters.length !== 0 ? (
                <Chart
                  series={series}
                  labels={months}
                  type={"stackedcolumn2d"}
                  config={chartConfig}
                  height={400}
                />
              ) : (
                <EmptyPlaceholder />
              )}
            </React.Fragment>
          )}
        </Paper>
      </Grid>
      {errorStatus && <ErrorSnackBar errorMsg={errorMessage} />}
    </React.Fragment>
  );
};

export default MattersGrowthTrendsStackedBar;
