import images from "../../../images";

const data = [
  {
    title: "Do this that",
    points: ["Do this do that", "Do that do this"],
    image: "https://i.ibb.co/JjvsvJ6/sc.png",
  },
  {
    title: "Then Do that this",
    points: ["Do this do that", "Do that do this"],
    image: "https://i.ibb.co/JjvsvJ6/sc.png",
  },
];

export { data };
