import { Grid, makeStyles } from "@material-ui/core";
import { useEffect, useState } from "react";
import images from "../../../../images";
import ClientsMatters from "./clientsMatters";
import InvoicesARRevenue from "./invoicesARRevenue";
import ClientsMattersAR from "./clientsMattersAR";
import BillingRevenueAR from "./billingRevenueAR";
import BillableNonBillable from "./billableNonBillable";
import OWIP from "./owip";
import DSO from "./dso";
import Widget from "./widget";
import api from "../../../../services";
import moment from "moment";
import ErrorSnackBar from "../../commons/SnackBar";
import useAuth from "../../../../hooks/useAuth";
import { PlaceholderAcl } from "../../../commons/placeholderAcl";
import numeral from "numeral";
const useStyles = makeStyles((theme) => ({
  headingText: {
    fontSize: "1.5em",
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.text.primary,
  },
  divider: {},
}));

export default function Home() {
  const [loader, setLoader] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [data, setData] = useState<any>({
    totalBalance: 0,
    diversityIndex: 0,
    concentrationRatio: 0,
  });
  const {
    generalStates: {
      token,
      activeFirm: {
        firm: { _id },
      },
      acl,
    },
    integrationConfigState: { pmType },
  } = useAuth();

  useEffect(() => {
    let subscribe = true;
    if (loader && acl?.dashboard) {
      let request = api.create(token);
      let currQuery = {
        start_date: `${moment().startOf("year").format("YYYY-MM-DD")}`,
        end_date: `${moment().endOf("year").format("YYYY-MM-DD")}`,
      };
      Promise.all([
        request.getTrustReportDashboard(_id, pmType),
        request.getDashboardConcentrationRatio(_id, pmType, currQuery),
      ])
        .then((res) => {
          if (subscribe) {
            setData({
              totalBalance: res[0].data.total_balance,
              diversityIndex: res[1].data.diversity_index,
              concentrationRatio: res[1].data.ar_concentration_ratio,
            });
            setLoader(false);
          }
        })
        .catch((error) => {
          if (subscribe) {
            if (error.code === "ECONNABORTED") {
              setErrorMsg("The API request timed out. Please Refresh the page");
              setError(true);
            } else {
              setErrorMsg(error.response.data.message);
              setError(true);
            }
          }
        });
    }
    return () => {
      subscribe = false;
    };
  }, [loader, acl]);
  const classes = useStyles();
  return (
    <Grid container spacing={2}>
      <Grid item style={{ marginBottom: 20 }}>
        <span className={classes.headingText}>Dashboard</span>
      </Grid>
      {acl?.dashboard && (
        <>
          <Grid item container spacing={2} justifyContent={"space-between"}>
            <Widget
              title={"Trust Balance"}
              id={1}
              info={
                "Total of all client trust balances in the practicemanagement system."
              }
              value={`$ ${numeral(data.totalBalance).format("0,0.00")}`}
              image={images.bBars}
              link={"/dashboard/trust"}
            />
            <Widget
              title={"Concentration Ratio"}
              id={2}
              info={
                "Displays the quantity of clients in a ratio with the highest value of 1 equating to only 1 client. Lower ratio equates to more clients and less concentration."
              }
              value={`${data.concentrationRatio}`}
              image={images.bars}
              link={"/dashboard/a-r"}
            />
            <Widget
              title={"Diversity Index"}
              id={3}
              info={
                "Displays the quantity of clients as an inverse ofthe concentration ratio. A higher value equates to more clients and more diversity."
              }
              value={`${data.diversityIndex}`}
              image={images.yBars}
              link={"/dashboard/a-r"}
            />
            <OWIP token={token} firmId={_id} pmType={pmType} />
            <DSO token={token} firmId={_id} pmType={pmType} />
          </Grid>
          <Grid item container spacing={2}>
            <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
              <ClientsMattersAR token={token} firmId={_id} pmType={pmType} />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
              <ClientsMatters token={token} firmId={_id} pmType={pmType} />
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
              <BillingRevenueAR token={token} firmId={_id} pmType={pmType} />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
              <InvoicesARRevenue token={token} firmId={_id} pmType={pmType} />
            </Grid>
            <Grid item xs={12}>
              <BillableNonBillable token={token} firmId={_id} pmType={pmType} />
            </Grid>
          </Grid>
        </>
      )}
      {!acl?.dashboard && (
        <Grid container spacing={2}>
          <PlaceholderAcl />
        </Grid>
      )}

      {error ? <ErrorSnackBar errorMsg={errorMsg} /> : null}
    </Grid>
  );
}
