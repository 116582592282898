import React, { useContext, useMemo, useRef, useEffect } from "react";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import SettingsIcon from "@material-ui/icons/Settings";
import ExitToAppTwoToneIcon from "@material-ui/icons/ExitToAppTwoTone";
import ViewListTwoToneIcon from "@material-ui/icons/ViewListTwoTone";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useRouteMatch, useLocation, useHistory } from "react-router-dom";
import { MenuList, MenuItem, ButtonBase } from "@material-ui/core";
import FT from "../../images/FT.png";
import useAuth from "../../hooks/useAuth";

import TutorialPopup from "../dashboard/tutorial";
const useStyles = makeStyles((theme) => ({
  icon: {
    color: "#fff",
    position: "relative",
    left: -theme.spacing(1),
  },
  iconSelected: {
    color: theme.palette.primary.light,
    position: "relative",
    left: -theme.spacing(1),
  },
  logo: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(5),
    marginTop: theme.spacing(2),
  },
  settingIcon: {
    marginLeft: theme.spacing(1),
  },
  listItemText: {
    fontSize: "1em",
    fontWeight: 600,
  },
  menuButton: {
    backgroundColor: "transparent",
    color: theme.palette.background.paper,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    borderTopLeftRadius: theme.spacing(1) / 2,
    borderBottomLeftRadius: theme.spacing(1) / 2,
    marginLeft: theme.spacing(1),
    "&:hover": {
      backgroundColor: theme.palette.text.disabled,
    },
  },
  settings: {
    backgroundColor: theme.palette.background.paper,
  },
  selectedSettingsButton: {
    background: theme.palette.background.paper,
    color: theme.palette.primary.light,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    borderTopLeftRadius: theme.spacing(1) / 2,
    borderBottomLeftRadius: theme.spacing(1) / 2,
    marginLeft: theme.spacing(1),
    "&:hover": {
      backgroundColor: theme.palette.background.default,
    },
  },
  selectedMenuButton: {
    background: theme.palette.background.default,
    color: theme.palette.primary.light,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    borderTopLeftRadius: theme.spacing(1) / 2,
    borderBottomLeftRadius: theme.spacing(1) / 2,
    marginLeft: theme.spacing(1),
    "&:hover": {
      backgroundColor: theme.palette.background.default,
    },
  },
  next: {
    width: "100%",
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.background.paper,
    padding: theme.spacing(1.5),
  },
}));

export function MainListItems({ selected, setSelected }) {
  const {
    generalStates: { user, tutorial },
    authorizationCodeActions: { setClio, setXero },
    generalActions: { setTutorialPosition },
  } = useAuth();
  const classes = useStyles();
  // const { handleClio } = useContext(ClioContext);
  const { url, path } = useRouteMatch();
  const { pathname } = useLocation();
  const history = useHistory();
  const userType = user.userType;
  const settingRef = useRef<any>(null);
  useEffect(() => {
    if (settingRef.current.offsetTop && tutorial) {
      const { offsetTop, offsetWidth } = settingRef.current;
      setTutorialPosition({
        top: offsetTop,
        width: offsetWidth,
      });
    }
  }, [tutorial]);
  return (
    <React.Fragment>
      <TutorialPopup />
      <div>
        <ButtonBase target="_blank" href="https://www.firmtrak.com/">
          <img
            className={classes.logo}
            height="30px"
            width="30px"
            src={FT}
            alt="FirmTrak"
          />
        </ButtonBase>

        <MenuItem
          disableRipple
          className={
            pathname.includes("/dashboard") &&
            pathname.includes("/settings") === false &&
            pathname.includes("/support") === false &&
            pathname.includes("/logs") === false
              ? classes.selectedMenuButton
              : classes.menuButton
          }
          onClick={() => {
            // handleClio("", "");
            setSelected(0);
            setClio(null);
            setXero(null);
            history.push(`${path}`);
          }}
          button
          to={`${url}`}
          component={Link}
        >
          <ListItemIcon>
            <DashboardIcon
              className={
                pathname.includes("/dashboard") &&
                pathname.includes("/settings") === false &&
                pathname.includes("/support") === false &&
                pathname.includes("/logs") === false
                  ? classes.iconSelected
                  : classes.icon
              }
            />
          </ListItemIcon>
        </MenuItem>
        <MenuItem
          disableRipple
          ref={settingRef}
          className={
            pathname.includes("/dashboard/settings")
              ? `${classes.selectedSettingsButton}`
              : classes.menuButton
          }
          button
          onClick={() => {
            setSelected(1);
            setClio(null);
            setXero(null);
          }}
          to={`${url}/settings`}
          component={Link}
        >
          <ListItemIcon>
            <SettingsIcon
              className={
                pathname.includes("/settings")
                  ? classes.iconSelected
                  : classes.icon
              }
            />
          </ListItemIcon>
        </MenuItem>
        <MenuItem
          disableRipple
          className={
            pathname === "/dashboard/support"
              ? classes.selectedMenuButton
              : classes.menuButton
          }
          button
          onClick={() => {
            setSelected(2);
            // handleClio("", "");
            setClio(null);
            setXero(null);
          }}
          to={`${url}/support`}
          component={Link}
        >
          <ListItemIcon>
            <ContactSupportIcon
              className={
                pathname === "/dashboard/support"
                  ? classes.iconSelected
                  : classes.icon
              }
            />
          </ListItemIcon>
        </MenuItem>
        {userType === "SUPER_ADMIN" && (
          <MenuItem
            disableRipple
            className={
              pathname === "/dashboard/logs"
                ? classes.selectedMenuButton
                : classes.menuButton
            }
            button
            onClick={() => {
              setSelected(3);
              // handleClio("", "");
              setClio(null);
              setXero(null);
            }}
            to={`${url}/logs`}
            component={Link}
          >
            <ListItemIcon>
              <ViewListTwoToneIcon
                className={
                  pathname === "/dashboard/logs"
                    ? classes.iconSelected
                    : classes.icon
                }
              />
            </ListItemIcon>
          </MenuItem>
        )}
      </div>
    </React.Fragment>
  );
}

export function SecondaryListItems() {
  const {
    generalStates,
    generalActions: { setToken, setActiveFirm, setUser, setRefreshToken },
  } = useAuth();
  const classes = useStyles();

  return useMemo(() => {
    return (
      <MenuList>
        <MenuItem
          className={classes.menuButton}
          button
          onClick={() => {
            setToken(null);
            setRefreshToken(null);
            setActiveFirm(null);
            setUser(null);
          }}
        >
          <ListItemIcon>
            <ExitToAppTwoToneIcon className={classes.icon} />
          </ListItemIcon>
        </MenuItem>
      </MenuList>
    );
  }, [generalStates]);
}
