import React, { useState, useEffect } from "react";
import { ipData } from "../../services/integrations/ipData";
import { getNearestServer, Server } from "./utils";
import Popup from "../commons/popup";

export interface LocationWrapperProps {
  children: any;
}

const LocationWrapper: React.FC<LocationWrapperProps> = ({ children }) => {
  const [open, setOpen] = useState<boolean>(true);
  const [userLocation, setuserLocation] = useState<string>("");
  const [displayPopup, setDisplayPopup] = useState<boolean>(false);
  const [server, setServer] = useState<Server>({
    url: "https://app.firmtrak.com",
    distance: 0,
  });
  const [websiteVersion] = useState<string>(() => {
    if (window.location.href.includes("eu-app.firmtrak")) {
      return "the European";
    } else if (window.location.href.includes("ca-app.firmtrak")) {
      return "Canada's";
    } else {
      return "the Global";
    }
  });

  const handleClose = () => {
    setOpen(!open);
  };

  /*
  Fetch IP Address; Calculate closest server and update server object
  */

  useEffect(() => {
    const queryParams = {
      "api-key": process.env.REACT_APP_IPDATA_ID,
    };
    const ipDataAPI = ipData();
    ipDataAPI.getIPDetails(queryParams).then((response) => {
      let currentLocation = {
        latitude: response.data.latitude,
        longitude: response.data.longitude,
      };
      let server = getNearestServer(currentLocation);
      setServer(server);
      setuserLocation(response.data.country_name);
      //Display popup if url does not match computed url
      if (!window.location.href.includes(server.url)) {
        setTimeout(() => {
          setDisplayPopup(true);
        }, 2000);
      }
    });
  }, []);

  return (
    <>
      {false && (
        <Popup
          title={`It looks like you are in ${userLocation}`}
          open={false}
          buttonText={"Proceed to relevant website"}
          buttonLink={server.url}
          handleClose={handleClose}
          cancel={true}
          cancelText={`Stay on the ${websiteVersion} website`}
          body={`You're currently on ${websiteVersion} version of this website. Click "Proceed to Relevant Website" to see content relevant to your location.`}
        />
      )}
      <>{children}</>
    </>
  );
};

export default LocationWrapper;
