export const integrationConfigActions = (props) => {
  return {
    // No Integration
    setNoIntegration: () => {
      externSetNoIntegration(props);
    },
    // Clio Integration
    setClioIntegration: () => {
      externSetClioIntegration(props);
    },
    // Xero Integration
    setXeroIntegration: () => {
      externSetXeroIntegration(props);
    },
    setClioXeroIntegration: () => {
      externSetClioXeroIntegration(props);
    },
    setPMType: (data) => {
      externSetPMType(props, data);
    },
  };
};

// *****
// External actions
// *****
function externSetPMType(props, data) {
  props.dispatch({ type: "SET_PM_TYPE", data });
}

function externSetNoIntegration(props) {
  props.dispatch({ type: "SET_NO_INTEGRATION" });
}

function externSetClioIntegration(props) {
  props.dispatch({ type: "SET_CLIO_INTEGRATION" });
}
function externSetXeroIntegration(props) {
  props.dispatch({ type: "SET_XERO_INTEGRATION" });
}
function externSetClioXeroIntegration(props) {
  props.dispatch({ type: "SET_CLIO_XERO_INTEGRATION" });
}
