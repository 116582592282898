// *****
// Component Blocking State : duplicate clio integration, payment expiry
// *****

export const currentAppState = {
  status: {
    clioStatus: 0,
    ppStatus: 0,
    xeroStatus: 0,
    code: "000",
  },
  loader: true,
  clioHasDuplicate: false,
  paymentExpired: false,
};

export const currentAppStateReducer = (state, action) => {
  switch (action.type) {
    case "SET_STATUS":
      return {
        ...state,
        status: action.data,
        loader: false,
      };
    case "SET_CLIO_HAS_DUPLICATE":
      return {
        ...state,
        clioHasDuplicate: action.data,
      };
    case "SET_PAYMENT_EXPIRED":
      return {
        ...state,
        paymentExpired: action.data,
      };
    case "SET_LOADER":
      return {
        ...state,
        loader: action.data,
      };
    default:
      return state;
  }
};
