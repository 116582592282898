import { Grid, IconButton, makeStyles, Paper } from "@material-ui/core";
import React, { useEffect, useState, useContext } from "react";
import api from "../../../../services";
import {
  StyledToggleButton,
  StyledToggleButtonGroup,
} from "../../tabs/styledTabs";
import { WindowContext } from "../../../../window/trackWindow";
import ErrorSnackBar from "../../commons/SnackBar";
import images from "../../../../images";
import InfoButton from "../../infoButton";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    fontFamily: "Open Sans",
    alignItems: "center",
    flex: 1,
    height: "100%",
  },
  prefix: {
    fontSize: theme.typography.pxToRem(18),
  },
  image: {
    marginRight: theme.spacing(1),
  },
  value: {
    fontSize: theme.typography.pxToRem(32),
    fontWeight: "bold",
    color: theme.palette.secondary.dark,
    margin: 0,
  },
  title: {
    margin: 0,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.secondary.dark,
  },
}));
export default function DSO({ token, firmId, pmType }) {
  const classes = useStyles();
  const [loader, setLoader] = useState<boolean>(true);
  const [active, setActive] = useState<number>(2);
  const [data, setData] = useState<any>([0, 0, 0]);
  const [error, setError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const history = useHistory();
  const window = useContext(WindowContext);

  useEffect(() => {
    let subscribe = true;
    if (loader) {
      let request = api.create(token);
      Promise.all([
        request.getDailySalesOutstanding(firmId, pmType, { interval: 3 }),
        request.getDailySalesOutstanding(firmId, pmType, { interval: 6 }),
        request.getDailySalesOutstanding(firmId, pmType, { interval: 9 }),
      ])
        .then((res) => {
          setData([res[0].data.DSO, res[1].data.DSO, res[2].data.DSO]);
          setLoader(false);
        })
        .catch((error) => {
          if (subscribe) {
            if (error.code === "ECONNABORTED") {
              setErrorMsg("The API request timed out. Please Refresh the page");
              setError(true);
            } else {
              setErrorMsg(error.response.data.message);
              setError(true);
            }
          }
        });
    }
    return () => {
      subscribe = false;
    };
  }, [loader]);
  const handleTabChange = (event, value) => {
    if (value !== null) {
      setActive(value);
    }
  };
  return (
    <Grid item xs={12} sm={6} md={6} lg>
      <Paper square className={classes.container}>
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <StyledToggleButtonGroup
              onChange={handleTabChange}
              exclusive
              value={active}
              size="small"
              style={{
                marginLeft: 0,
                marginRight: 0,
              }}
            >
              <StyledToggleButton key={1} value={0}>
                3
              </StyledToggleButton>
              <StyledToggleButton key={2} value={1}>
                6
              </StyledToggleButton>
              <StyledToggleButton key={3} value={2}>
                9
              </StyledToggleButton>
            </StyledToggleButtonGroup>
            <span>
              <InfoButton
                title={
                  "Average of days that Accounts Receivable remains uncollected. Toggle for 30, 60, or 90 day average values."
                }
              />
              <IconButton
                onClick={() => history.push("/dashboard/productivity")}
                style={{ padding: 0, margin: 0 }}
              >
                <img src={images.linkIcon} />
              </IconButton>
            </span>
          </div>
          <p className={classes.value}>
            {parseInt(data[active])}{" "}
            <span className={classes.prefix}> days</span>
          </p>
          <p className={classes.title}>Daily Sales Outstanding</p>
        </div>
      </Paper>
      {error ? <ErrorSnackBar errorMsg={errorMsg} /> : null}
    </Grid>
  );
}
