import React from "react";
import { Grid, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { data } from "./static";
import ListAltIcon from "@material-ui/icons/ListAlt";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import AlternateEmailIcon from "@material-ui/icons/AlternateEmail";
import RoomIcon from "@material-ui/icons/Room";
import YouTubeIcon from "@material-ui/icons/YouTube";
import useAuth from "../../../hooks/useAuth";
const useStyles = makeStyles(theme => ({
  paper: { padding: theme.spacing(4, 2) },
  titleText: {
    display: "block",
    fontSize: "1.3rem",
    fontWeight: 600,
    fontColor: theme.palette.text.primary,
    [theme.breakpoints.down("md")]: {
      fontSize: "1.2rem",
    },
  },
  subheadingText: {
    display: "block",
    fontSize: "1rem",
    fontWeight: 500,
    fontColor: theme.palette.text.secondary,
    marginTop: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      fontSize: "0.9rem",
    },
  },
  itemContainer: {
    marginTop: theme.spacing(1),
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  link: {
    textDecoration: "none",
    color: theme.palette.primary.main,
    fontSize: "1rem",
    fontWeight: 500,
    "&:hover": {
      color: theme.palette.action.active,
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "1.1em",
    },
  },
  adjust: {
    marginTop: theme.spacing(1),
    display: "block",
  },
  heading: {
    fontSize: "1.2rem",
    fontWeight: "bold",
  },
  container: {
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  topContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  icon: {
    color: theme.palette.grey[400],
    marginRight: theme.spacing(2),
  },
  no: {
    textDecoration: "none",
    color: "#000",
    fontWeight: theme.typography.fontWeightBold,
  },
}));

export default function Support() {
  const {
    generalStates: {
      user: { email: userEmail },
    },
  } = useAuth();
  const classes = useStyles();
  return (
    <>
      <div className={classes.topContainer}>
        <span className={classes.titleText}>Support</span>
      </div>
      <Paper className={classes.container}>
        <Grid direction="row" container>
          {data.map((item, index) => (
            <Grid key={index} item xs={12} sm={12} md={6} lg={4} xl={4}>
              <div className={classes.itemContainer}>
                <LiveHelpIcon className={classes.icon} fontSize="large" />
                <span>
                  <a
                    className={`${classes.heading} ${classes.no}`}
                    target="_blank"
                    href={item.heading.url}
                  >
                    {item.heading.title}
                  </a>
                </span>
              </div>
              <div style={{ marginLeft: 8, marginBottom: 20 }}>
                {item.list.map((i, id) => (
                  <div className={classes.itemContainer} key={id}>
                    <ListAltIcon className={classes.icon} />
                    <a
                      className={`${classes.link} ${i.sub && classes.no}`}
                      target="_blank"
                      href={i.url}
                    >
                      {i.title}
                    </a>
                  </div>
                ))}
              </div>
            </Grid>
          ))}
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <div className={classes.itemContainer}>
              <LiveHelpIcon className={classes.icon} fontSize="large" />
              <span>
                <span className={classes.heading}>Contact Us</span>
              </span>
            </div>
            {/* Email: support@firmtraksolutions.zendesk.com */}
            <a
              className={classes.link}
              href={`mailto:support@firmtrak.com?bcc=za@firmtrak.com&subject=Support Ticket - ${userEmail}`}
            >
              <div style={{ marginLeft: 8 }}>
                <div className={classes.itemContainer}>
                  <AlternateEmailIcon className={classes.icon} />
                  <span className={classes.link}>{`support@firmtrak.com`}</span>
                </div>
              </div>
            </a>
            <div style={{ marginLeft: 8 }}>
              <div className={classes.itemContainer}>
                <ListAltIcon className={classes.icon} />
                <span className={classes.link}>(309) 556-7016</span>
              </div>
            </div>
            <div style={{ marginLeft: 8 }}>
              <div className={classes.itemContainer}>
                <RoomIcon className={classes.icon} />
                <span className={classes.link}>
                  Address: 221 E. Front Street Bloomington, IL 61701
                </span>
              </div>
            </div>
            <a
              className={classes.link}
              target="_blank"
              href={`http://www.youtube.com/channel/UCGmxwX3jhmMreYgYMvHYzDA`}
            >
              <div style={{ marginLeft: 8 }}>
                <div className={classes.itemContainer}>
                  <YouTubeIcon className={classes.icon} />
                  <span className={classes.link}>{`Training Videos`}</span>
                </div>
              </div>
            </a>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
