import React from "react";
import { Box, LinearProgress, Typography } from "@material-ui/core";

const equality = (prevProps, nextProps) => {
  if (prevProps.progress === nextProps.progress) {
    return true;
  } else {
    return false;
  }
};
function SyncProgress(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress
          color="secondary"
          variant="determinate"
          value={props.progress}
        />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="secondary">{`${Math.round(
          props.progress
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

export default React.memo(SyncProgress, equality);
