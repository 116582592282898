import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import api from "../../../../../services";
import ChartLoader from "../../../commons/loaders/chartLoader";
import Colors from "../../../../../theme/ChartTheme";
import ErrorSnackBar from "../../../commons/SnackBar";
import {
  getAmountFusion,
  getNestedListByPropertyLabelFusion,
  returnApi,
} from "../../utils";
import TopBottomSelect from "../../../commons/topBottomSelect";
import EmptyPlaceholder from "../../../commons/placeholders/emptyPlaceholder";
import FilterUserTimePractice from "../../../filters/genericFilter/filters";
import { compareFilterDates } from "../../utils";
import useFilterState from "../../../filters/genericFilter/filterStateHook";
import { FilterClientsMattersTypes } from "../../../filters/genericFilter/types";
import useDateFilterState, {
  DateStateType,
} from "../../../filters/genericFilter/dateFilterStateHook";
import { DateRange } from "materialui-daterange-picker";
import useChartLimits from "../../../commons/chartLimitsHook";
import BackForwardArrows from "../../../commons/backForwardArrows";
import Chart from "../../../charts/fusionCharts";
import InfoButton from "../../../infoButton";
import useAuth from "../../../../../hooks/useAuth";
export interface ClientTopTensInvoiceProps {}

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "visible",
    flexDirection: "column",
    height: 650,
    justifyContent: "center",
  },
  textTitle: {
    color: theme.palette.text.primary,
    fontSize: "1.2em",
    fontWeight: 600,
  },
  headingText: {
    fontSize: "1.2em",
    fontWeight: 600,
    color: theme.palette.text.secondary,
  },
  divider: {
    backgroundColor: "#EDEFF7",
    height: 1,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  titleWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

// *****
// Initial Filter State
// *****

const initialFilterState: FilterClientsMattersTypes = {
  practiceArea: "",
  billingMethod: "",
  originatingAtt: "",
  responsibleAtt: "",
};

const initialDateFilterState: DateStateType = {
  dateAfter: null,
  dateBefore: null,
};

const ClientTopTensInvoice: React.FC<ClientTopTensInvoiceProps> = () => {
  const [loader, setLoader] = useState<boolean>(true);
  const [byInvoiceList, setByInvoiceList] = useState<number[]>([]);
  const [byInvoiceNames, setByInvoiceNames] = useState<any[]>([]);
  const [error, setError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const { limitParams, handleLimitState } = useChartLimits();
  const [current, setCurrent] = useState<number>(0);
  useEffect(() => {
    setLoader(true);
  }, [current]);

  const handleSelect = (val: number) => {
    if (val === 0) {
      setCurrent(1);
    } else {
      setCurrent(0);
    }
  };
  // *****
  // Current Values of Filters
  // *****

  const { filterRef, filterState, handleState } =
    useFilterState(initialFilterState);

  // *****
  // Previous Values of Filters
  // *****

  const prevFilterState = filterRef.current;

  // *****
  // Current Values of Date Filters
  // *****

  const { dateFilterRef, dateFilterState, handleDateState } =
    useDateFilterState(initialDateFilterState);

  // *****
  // Previous Values of Date Filters
  // *****

  const prevDateFilterState = dateFilterRef.current;

  const {
    generalStates: {
      token,
      activeFirm: {
        firm: { _id },
      },
    },
    integrationConfigState: { pmType },
  } = useAuth();

  // *****
  // Chart Series
  // *****

  const seriesByInvoice = [
    {
      name: "By Bills & Invoice",
      color: Colors.blue,
      data: byInvoiceList,
    },
  ];

  // *****
  // Filter state updates
  // *****

  const handlePracticeAreas = (value: number | "") => {
    handleState.handlePracticeArea(value);
  };

  const handleBillingMethod = (value: string) => {
    handleState.handleBillingMethod(value);
  };

  const handleOriginatingAtt = (value: number | "") => {
    handleState.handleOriginatingAtt(value);
  };

  const handleResponsibleAtt = (value: number | "") => {
    handleState.handleResponsibleAtt(value);
  };

  const handleDateFilterRange = (dateRange: DateRange): void => {
    handleDateState.handleDateUpdate(dateRange);
  };

  // *****
  // Filter Submit - Leveraging Closure and invoking utility function compareFilterDates
  // Use Case: If any of the filter state has changed and date has not been applied, invoke action.
  // Use Case: both date values have changed with second greater than the first, invoke action.
  // *****

  const handleFilterSubmit = () => {
    handleLimitState.limitReset();
    if (
      compareFilterDates(
        filterState,
        prevFilterState,
        dateFilterState,
        prevDateFilterState,
        initialFilterState
      ) === true
    ) {
      setLoader(true);
    }
  };

  // *****
  // Clear Filter States
  // *****

  const handleFilterClear = () => {
    handleState.reset(initialFilterState);
    handleDateState.resetDate(initialDateFilterState);
    handleLimitState.limitReset();
    setLoader(true);
  };

  // *****
  // Filter - Invoice
  // Conditionally render and request by Invoice API when 'filteredInvoice' state has changed.
  // *****

  useEffect(() => {
    let subscribe = true;
    if (loader) {
      let request = api.create(token);
      let queryParams = {
        practice_area_id:
          filterState.practiceArea === "" ? null : filterState.practiceArea,
        billing_method:
          filterState.billingMethod === "" ? null : filterState.billingMethod,
        originating_attorney_id:
          filterState.originatingAtt === "" ? null : filterState.originatingAtt,
        responsible_attorney_id:
          filterState.responsibleAtt === "" ? null : filterState.responsibleAtt,
        issued_after: dateFilterState.dateAfter,
        issued_before: dateFilterState.dateBefore,
        limit: limitParams.limit,
        offset: limitParams.offset,
      };

      const type = returnApi(
        current,
        "top10-matters-by-bills",
        "worst10-matters-by-bills"
      );

      request
        .getTopWorst(type, pmType, _id, queryParams)
        .then((response) => {
          if (subscribe) {
            let data = response.data;

            let byInvoiceList = getAmountFusion(data);
            let byInvoiceNames = getNestedListByPropertyLabelFusion(
              data,
              "matter_name"
            );
            setByInvoiceList(byInvoiceList);
            setByInvoiceNames(byInvoiceNames);
            setTimeout(() => {
              setLoader(false);
            }, 500);
          }
        })
        .catch((error) => {
          if (subscribe) {
            if (error.code === "ECONNABORTED") {
              setErrorMsg("The API request timed out. Please Refresh the page");
              setError(true);
            } else {
              setErrorMsg(error.response.data.message);
              setError(true);
            }
          }
        });
    }
    return () => {
      subscribe = false;
    };
  }, [loader]);

  const classes = useStyles();
  useEffect(() => {
    setLoader(true);
  }, [limitParams]);

  const handleForward = () => {
    handleLimitState.handleForward();
  };
  const handleBack = () => {
    handleLimitState.handleBack();
  };
  const chartConfig = {
    plottooltext: "<b>$$dataValue</b> $seriesName",
  };
  return (
    <React.Fragment>
      <Grid item lg={12} md={12} sm={12} xs={12} xl={12}>
        <Paper className={classes.paper}>
          {loader ? (
            <ChartLoader />
          ) : (
            <React.Fragment>
              <div className={classes.titleWrapper}>
                <TopBottomSelect
                  title={"Matters By Invoices"}
                  current={current}
                  handleSelect={handleSelect}
                />
                <span>
                  <InfoButton title={"Assess matters by Invoices."} />
                  <FilterUserTimePractice
                    disabled={loader}
                    filterState={filterState}
                    dateFilterState={dateFilterState}
                    handleFilterSubmit={handleFilterSubmit}
                    handleFilterClear={handleFilterClear}
                    handlePracticeAreas={handlePracticeAreas}
                    handleBillingMethod={handleBillingMethod}
                    handleOriginatingAtt={handleOriginatingAtt}
                    handleResponsibleAtt={handleResponsibleAtt}
                    handleDateFilterRange={handleDateFilterRange}
                  />
                  <BackForwardArrows
                    handleBack={handleBack}
                    handleForward={handleForward}
                    disableBack={limitParams.offset < 10 ? true : false}
                  />
                </span>
              </div>
              {byInvoiceList.length > 0 ? (
                <Chart
                  type={"msbar2d"}
                  series={seriesByInvoice}
                  labels={byInvoiceNames}
                  height={600}
                  config={chartConfig}
                />
              ) : (
                <EmptyPlaceholder height />
              )}
            </React.Fragment>
          )}
        </Paper>
        {error ? <ErrorSnackBar errorMsg={errorMsg} /> : null}
      </Grid>
    </React.Fragment>
  );
};

export default ClientTopTensInvoice;
