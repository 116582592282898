import _ from "lodash";
// import { PercentageRounded } from "../../utils";

// *****
// Returns an array with the calculated difference of CurrentElement - PrevElement from "Collection"
// *****

function calcDifferenceInArrayElements(collection: number[]): number[] | [] {
  return _.map(collection, (value, index, collection) => {
    if (index === 0) {
      return 0;
    } else {
      return value - collection[index - 1];
    }
  });
}

// *****
// Accepts a nested Array and returns the longest array
// *****

function returnLongestArray(nestedArray) {
  return nestedArray.reduce((longestArray, currentArray) => {
    if (currentArray.length > longestArray.length) {
      return (longestArray = currentArray);
    } else {
      return longestArray;
    }
  }, []);
}

interface ResponseObject {
  month: string | Date;
  total: number | string;
}

type ResponseArray = ResponseObject[];

// *****
// Accepts a nested array of type responseArray and Returns an equated nested array of type responseArray
// *****

function compareArraysAndEquateLength(
  ...lists: ResponseArray[]
): ResponseArray[] {
  let benchmarkList = returnLongestArray(lists);
  let tempArr: any = [];
  lists.map((comparisonList) => {
    benchmarkList.map((item, i) => {
      if (!_.find(comparisonList, (item2) => item2.month === item.month)) {
        comparisonList.splice(i, 0, { month: item.month, total: 0 });
      }
    });
    tempArr.push(comparisonList);
  });
  return tempArr;
}

// *****
// Divides Elements at Index from Two Arrays returns a new percentage array
// *****

function divisionOfElementsAtIndexFromTwoCollections(
  numArray: number[],
  denomArray: number[]
): number[] | [] {
  let tempArray: any[] = [];
  denomArray.map((denominator, index) => {
    let division: number = numArray[index] / denominator;
    if (numArray[index] === 0 || denominator === 0) {
      tempArray.push(0);
    } else {
      tempArray.push(Math.round(division * 100));
    }
  });
  return tempArray;
}

function removeNaNInfinity(numArray: number[]): number[] | [] {
  let tempArray: any[] = [];
  numArray.map((item) => {
    if (typeof item !== "number") {
      tempArray.push({ value: 0 });
    } else tempArray.push({ value: item });
  });
  return tempArray;
}

// *****
// Unites Chart series dollar and month arrays to an array of type ChartSeries[]
// *****

type ChartSeries = {
  y: number;
  x: string;
};

function uniteChartSeries(
  amountArr: number[],
  months: string[]
): ChartSeries[] {
  let tempArr: any[] = [];
  amountArr.map((amount, index) => {
    tempArr.push({
      y: amount,
      x: months[index],
    });
  });
  return tempArr;
}

export {
  calcDifferenceInArrayElements,
  compareArraysAndEquateLength,
  returnLongestArray,
  divisionOfElementsAtIndexFromTwoCollections,
  uniteChartSeries,
  removeNaNInfinity,
};
