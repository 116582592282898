import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";
import { Link } from "react-router-dom";
import api from "../../services";
import Layout from "../../containers/Layout";
import {
  InputLabel,
  Button,
  Divider,
  CircularProgress,
  IconButton,
  Grid,
} from "@material-ui/core";
import { StyledInput } from "../settings/payments/stripe/styledInput";
import { StyledGradientButton } from "../commons/styledButtons";
import images from "../../images";

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(20, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    borderRadius: theme.spacing(1),
    overflow: "hidden",
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(12, 4),
    },
    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(8, 4),
    },
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: theme.palette.primary.main,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
    "&:disabled": {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.dark,
    },
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  image: {
    width: 150,
  },
  bodyText: {
    display: "block",
    fontSize: "1em",
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.primary,
    width: theme.spacing(20),
    textAlign: "center",
    backgroundColor: "#fff",
    marginLeft: theme.spacing(18),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      marginLeft: theme.spacing(2),
      fontSize: "1em",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "1.4em",
    },
  },
  headingText: {
    display: "block",
    fontFamily: "Open Sans",
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(4),
    fontSize: "2em",
    fontWeight: 600,
    color: theme.palette.text.primary,
    [theme.breakpoints.down("md")]: {
      fontSize: "1.4em",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "1.8em",
    },
  },
  circularProgress: {
    color: "#fff",
    backgroundColor: "#fff",
  },
  linkWrapper: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  link: {
    textDecoration: "none",
    color: theme.palette.primary.light,
    fontSize: "1em",
    fontWeight: 500,
    [theme.breakpoints.up("xl")]: {
      fontSize: "1.1em",
    },
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

export default function ForgotPassword() {
  const classes = useStyles();
  const [button, setButton] = useState<boolean>(true);
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [success, setSuccess] = useState<boolean>(false);

  // *****
  // Regex check for email
  // *****

  useEffect(() => {
    setError(false);
    let emailRgx =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email.trim() && emailRgx.test(email)) {
      setButton(false);
    } else {
      setButton(true);
    }
  }, [email]);

  // *****
  // Call API
  // *****

  useEffect(() => {
    let subscribe = true;
    if (loading) {
      let request = api.create("");
      request
        .forgotPassword({
          email: email,
        })
        .then(res => {
          if (subscribe) {
            if (res.status === 200) {
              setSuccess(true);
              setLoading(false);
            }
          }
        })
        .catch(error => {
          if (subscribe) {
            if (error.code === "ECONNABORTED") {
              setErrorMsg("The API request timed out. Please Refresh the page");
              setError(true);
            } else {
              setErrorMsg(error.response.data.message);
              setError(true);
            }
          }
        });
    }
    return () => {
      subscribe = false;
    };
  }, [loading]);

  const handleForgotPassword = () => {
    setLoading(true);
  };

  return (
    <Layout>
      <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
        <Grid
          container
          direction="column"
          alignContent="center"
          justifyContent="space-between"
          spacing={2}
        >
          <div className={classes.header}>
            <img className={classes.image} src={images.logo} />
            <Button
              component={Link}
              to={"/login"}
              classes={{ root: classes.bodyText }}
            >
              Login
            </Button>
          </div>
          <Grid item xs={12}>
            <span className={classes.headingText}>Forgot Password?</span>
          </Grid>
          <Grid item xs={12}>
            <InputLabel required>Email Address</InputLabel>
            <StyledInput
              error={error}
              required
              fullWidth
              id="email"
              name="email"
              autoComplete="email"
              onChange={e => setEmail(e.target.value)}
              autoFocus
            />
          </Grid>
          <Grid item xs={12}>
            {error || success ? (
              <Alert
                severity={error ? `error` : `success`}
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      if (error) {
                        setError(false);
                      } else if (success) {
                        setSuccess(false);
                      }
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {error
                  ? errorMsg
                  : "Reset Password link sent to email. Please verify."}
              </Alert>
            ) : null}
            <Divider flexItem />
          </Grid>
          <Grid item xs={12}>
            <StyledGradientButton
              type="submit"
              fullWidth
              onClick={handleForgotPassword}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress
                  size={24}
                  classes={{ circle: classes.circularProgress }}
                />
              ) : (
                "Send Password Reset Link"
              )}
            </StyledGradientButton>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.linkWrapper}>
              <div></div>
              <Link to={"/signup"} className={classes.link}>
                {"Don't have an account? Sign Up"}
              </Link>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
}
