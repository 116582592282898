import React, { useContext, useState, useEffect, useMemo } from "react";
import { Grid, Paper, Tooltip, IconButton } from "@material-ui/core";
import { useHistory } from "react-router";
import _ from "lodash";
import RefreshIcon from "@material-ui/icons/Refresh";
import {
  SocketContext,
  SocketStateType,
} from "../../../services/socket/socket";
import api from "../../../services";
import { evalSocketState } from "./utils";
import Alert from "@material-ui/lab/Alert";
import { clioObject, xeroObject, ppObject } from "./constants";
import CloseIcon from "@material-ui/icons/Close";
import { IntegrationTab, Header, Title, ErrorText } from "./integrationTab";

import ProgressBar from "../../dashboard/commons/notifications/progressBar";
import SyncStatus from "../../dashboard/commons/notifications/syncStatus";
import Popup from "../../commons/popup";
import XeroErrorLog from "./xeroErrorlog";
import useAuth from "../../../hooks/useAuth";
export default function Integrations() {
  const {
    generalStates: { activeFirm, token, user },
    onboardingState: { currentIntegration },
    authorizationCodeState: { clio, xero, pp },
    integrationConfigState: { pmType },
    currentAppState: {
      clioHasDuplicate,
      status: { code, clioStatus, ppStatus, xeroStatus },
    },
    getStatus,
    generalActions,
    authorizationCodeActions,
    onboardingActions,
  } = useAuth();
  const {
    firmUpdatesClio: { info: clioFirmInfo },
    firmUpdatesXero: { info: xeroFirmInfo },
    firmUpdatesPP: { info: ppFirmInfo },
  } = useContext<SocketStateType>(SocketContext);
  const clioCode = clio ? clio.code : "";
  const xeroCode = xero ? xero.code : "";
  const ppCode = pp ? pp.code : "";

  const integratedXero = activeFirm?.firm?.xero;

  const integrationType = currentIntegration === null ? "" : currentIntegration;
  const currentCode =
    integrationType !== "" && integrationType === "XERO"
      ? xeroCode
      : integrationType === "CLIO"
      ? clioCode
      : ppCode;
  const [success, setSuccess] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [remove, setRemove] = useState<any>({ state: false, type: "" });
  const [error, setError] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [clioSyncing, setClioSyncing] = useState<boolean>(false);
  const [xeroSyncing, setXeroSyncing] = useState<boolean>(false);
  const [ppSyncing, setPPSyncing] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [disconnected, setDisconnected] = useState<boolean>(false);
  const [current, setCurrent] = useState<boolean>(false);
  const [paymentPending, setPaymentPending] = useState<boolean>(false);
  const [getSubscriptionUpdate, setGetSubscriptionUpdate] =
    useState<boolean>(false);
  const history = useHistory();
  const firmId = activeFirm?.firm?._id;
  // *****
  // Update the activeFirm object when has completed, to reflect the updated change in Last Synced parameter
  // *****
  useEffect(() => {
    let subscribe = true;

    if (currentCode.length > 0) {
      let integration =
        integrationType === "XERO"
          ? "xero"
          : integrationType === "CLIO"
          ? "clio"
          : "pp";
      if (integrationType === "XERO") {
        setXeroSyncing(true);
      } else if (integrationType === "CLIO") {
        setClioSyncing(true);
      } else {
        setPPSyncing(true);
      }
      let request = api.create(token);
      // Request body
      const body =
        integrationType === "XERO" || integrationType === "PP"
          ? { code: currentCode }
          : { authCode: currentCode };
      request
        .firmIntegration(firmId, integration, body)
        .then((res) => {
          authorizationCodeActions.setClio(null);
          authorizationCodeActions.setXero(null);
          authorizationCodeActions.setPP(null);
          onboardingActions.setCurrentOnboardingIntegration(null);
          onboardingActions.setFirmName("");
          setSuccess(true);
          setMessage(res.data.msg);
          handleGetUser();
        })
        .catch((error) => {
          if (error.code === "ECONNABORTED") {
            setMessage("The API request timed out. Please Refresh the page");
            setError(true);
          } else {
            setMessage(error.response.data.message);
            setError(true);
          }
          if (integrationType === "XERO") {
            setXeroSyncing(false);
          } else if (integrationType === "CLIO") {
            setClioSyncing(false);
          } else if (integrationType === "PP") {
            setPPSyncing(false);
          }
        });
    }
    return () => {
      subscribe = false;
    };
  }, [currentCode]);
  useEffect(() => {
    if (clioFirmInfo || xeroFirmInfo || ppFirmInfo) {
      // handleGetUser();
      if (
        evalSocketState(
          clioStatus === 1 ? clioFirmInfo : ppFirmInfo,
          xeroFirmInfo
        )
      ) {
        handleGetUser();
        setXeroSyncing(false);
      }
    }
  }, [clioFirmInfo, xeroFirmInfo]);

  const handlePopup = () => {
    setPaymentPending(true);
  };
  useEffect(() => {
    handleGetUser();
  }, []);

  useEffect(() => {
    let subscribe = true;
    if (getSubscriptionUpdate) {
      if (subscribe) getStatus();
    }
    return () => {
      subscribe = false;
    };
  }, [getSubscriptionUpdate]);
  const handleGetUser = () => {
    setLoader(true);
    let request = api.create(token);
    request.getUserProfile().then((response) => {
      let data = response.data;
      // Deep clone firm object array
      let firmRoles = _.cloneDeep(data.firmRoles);
      let matchedFirm = _.find(firmRoles, (item) => item.firm._id === firmId);
      if (
          (matchedFirm == "undefined" || matchedFirm == undefined) && firmRoles.lenght > 0
      ) {
        generalActions.setActiveFirm(firmRoles[0]);
        return;
      }
      // console.log("here", data);
      //Update app state
      // Set active Integration Type
      let xero = matchedFirm?.firm?.xero;
      console.log(xero);
      if (
        xeroFirmInfo &&
        (xeroFirmInfo.sync_progress !== 100 ||
          xeroFirmInfo.sync_status !== "completed")
      ) {
        setXeroSyncing(true);
        console.log("inside socket");
      } else if (xero?.sync_status === "disconnected") {
        setXeroSyncing(false);
      } else if (
        xero &&
        xero.sync_progress !== 100 &&
        xero.sync_status !== "completed" &&
        !xero.error_log
      ) {
        setXeroSyncing(true);
        console.log("true true true");
      } else {
        setXeroSyncing(false);
      }
      if (matchedFirm) {
        if (xero && xero.sync_status === "disconnected" && xeroCode === "") {
          setCurrent(true);
        }
      }
      if (!_.isEqual(activeFirm, matchedFirm)) {
        generalActions.setUser(data);
        if (matchedFirm == undefined && firmRoles.lenght > 0) {
          generalActions.setActiveFirm(firmRoles[0]);
        } else if (matchedFirm == undefined && firmRoles.length == 0) {
          generalActions.setActiveFirm(null);
          history.go(0);
        } else {
          generalActions.setActiveFirm(matchedFirm);
        }

        setGetSubscriptionUpdate(true);
        setLoader(false);
        console.log("new Firm");
      } else {
        setGetSubscriptionUpdate(false);
        setLoader(false);
        console.log("old Firm");
      }
    });
  };

  function handleNavigate() {
    history.push("/dashboard/settings/payments");
  }
  // *****
  // Handles Xero disconnect
  // *****
  function handleXeroDisconnect() {
    let request = api.create(token);
    setOpen(false);
    request.diconnectFirm(firmId, "XERO").then((res) => {
      setMessage(res.data.message);
      setSuccess(true);
      setDisconnected(true);
      handleGetUser();
    });
  }
  // *****
  // Handles to update current IntegrationType in global State
  // *****

  const handleXeroCurrentIntegrationString = () => {
    onboardingActions.setCurrentOnboardingIntegration("XERO");
  };
  const handleClioCurrentIntegrationString = () => {
    onboardingActions.setCurrentOnboardingIntegration("CLIO");
  };
  const handlePPCurrentIntegrationString = () => {
    onboardingActions.setCurrentOnboardingIntegration("PP");
  };

  // *****
  // Force Refresh state
  // *****

  function handleRefresh(type) {
    let request = api.create(token);
    request
      .forceSync(firmId, type)
      .then((res) => {
        setMessage(res.data.msg);
        setSuccess(true);
      })
      .catch((error) => {
        if (error.code === "ECONNABORTED") {
          setMessage("The API request timed out. Please Refresh the page");
          setError(true);
        } else {
          setError(true);
          setMessage(error.response.data.message);
        }
      });
  }

  function handleDelete(type) {
    let request = api.create(token);
    request
      .deleteIntegration({ id: firmId, integrationType: type })
      .then((res) => {
        handleGetUser();
        setRemove({ state: false, type: "" });
      })
      .catch((error) => {
        if (error.code === "ECONNABORTED") {
          setMessage("The API request timed out. Please Refresh the page");
          setError(true);
        } else {
          setError(true);
          setMessage(error.response.data.message);
        }
      });
  }
  const memoizedSyncStatus = useMemo(
    () => () => {
      return (
        <>
          {clioFirmInfo !== null &&
            (!clioFirmInfo.sync_had_error || !clioHasDuplicate ? (
              <React.Fragment>
                <SyncStatus
                  syncStatus={clioFirmInfo.sync_status}
                  integrationName={"Clio"}
                />
                {(clioFirmInfo.sync_status === "update_in_progress" ||
                  clioFirmInfo.sync_status === "first_in_progress") && (
                  <ProgressBar value={clioFirmInfo.sync_progress} />
                )}
              </React.Fragment>
            ) : (
              <ErrorText>Clio sync had an error.</ErrorText>
            ))}
          {ppFirmInfo !== null &&
            (!ppFirmInfo.sync_had_error || !clioHasDuplicate ? (
              <React.Fragment>
                <SyncStatus
                  syncStatus={ppFirmInfo.sync_status}
                  integrationName={"Practice Panther"}
                />
                {(ppFirmInfo.sync_status === "update_in_progress" ||
                  ppFirmInfo.sync_status === "first_in_progress") && (
                  <ProgressBar value={ppFirmInfo.sync_progress} />
                )}
              </React.Fragment>
            ) : (
              <ErrorText>Practice Panther sync had an error.</ErrorText>
            ))}
          {xeroFirmInfo !== null &&
            (!xeroFirmInfo.sync_had_error ? (
              <React.Fragment>
                <SyncStatus
                  syncStatus={xeroFirmInfo.sync_status}
                  integrationName={"Xero"}
                />
                {(xeroFirmInfo.sync_status === "update_in_progress" ||
                  xeroFirmInfo.sync_status === "first_in_progress") && (
                  <ProgressBar value={xeroFirmInfo.sync_progress} />
                )}
              </React.Fragment>
            ) : (
              <ErrorText>Xero sync had an error.</ErrorText>
            ))}
        </>
      );
    },
    [clioFirmInfo, xeroFirmInfo, clioHasDuplicate, ppFirmInfo]
  );
  return (
    <React.Fragment>
      <Popup
        title={`Payment Pending!`}
        warning={true}
        open={paymentPending}
        buttonText={"Pay Now"}
        handleClick={handleNavigate}
        handleClose={() => setPaymentPending(false)}
        body={`Please clear your pending payment.`}
      />
      <Popup
        title={`Do you want to disconnect from Xero?`}
        warning={true}
        open={open}
        buttonText={"Disconnect"}
        handleClick={handleXeroDisconnect}
        handleClose={() => setOpen(false)}
        body={`If you want to disconnect from Xero, click "Disconnect" button. You won't be able to sync this firm again unless it is reconnected. To cancel,  click "x" icon.`}
      />
      <Popup
        title={`Do you want to delete this integration?`}
        warning={true}
        open={remove.state}
        buttonText={"Delete"}
        handleClick={() => handleDelete(remove.type)}
        handleClose={() => setRemove(false)}
        body={`Once the integration is deleted, you won't be able to access the charts from that integration.`}
      />
      <Popup
        title={
          disconnected
            ? `Xero has been disconnected successfully!`
            : `Xero is currently not Connected!`
        }
        warning={true}
        open={current}
        buttonText={"Ok"}
        handleClick={() => {
          setCurrent(false);
          setDisconnected(false);
        }}
        handleClose={() => {
          setCurrent(false);
          setDisconnected(false);
        }}
        body={`To reconnect with Xero, click "Reconnect xero" button.`}
      />
      {success && (
        <div style={{ marginBottom: 10 }}>
          <Alert
            severity="success"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setSuccess(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {message}
          </Alert>
        </div>
      )}
      {error && (
        <div style={{ marginBottom: 10 }}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setError(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {message}
          </Alert>
        </div>
      )}

      <Grid container direction={"column"} spacing={4} style={{ padding: 24 }}>
        {memoizedSyncStatus()}
        <Grid item container alignContent="center">
          <Header />
          <IconButton disabled={loader} onClick={handleGetUser} color="inherit">
            <Tooltip title={"Refresh"}>
              <RefreshIcon />
            </Tooltip>
          </IconButton>
        </Grid>
        <Grid item container component={Paper}>
          <Grid
            item
            container
            direction={"row"}
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Title title={"System"} />
            <Title title={"Status"} />
            <Title title={"Last Synced"} />
            <Title title={"Connect/Disconnect"} />
            <Grid item xs={user?.userType == "SUPER_ADMIN" ? 1 : 2}>
              <Title title={"Sync"} />
            </Grid>

            {user?.userType == "SUPER_ADMIN" && (
              <Grid item xs={1}>
                <Title title={"Delete"} />
              </Grid>
            )}
          </Grid>
          {pmType !== "pp" && (
            <IntegrationTab
              uri={clioObject.uri}
              image={clioObject.image}
              disconnectDisabled={true}
              clioSyncing={clioSyncing}
              xeroSyncing={xeroSyncing}
              ppSyncing={ppSyncing}
              handleRefresh={() => handleRefresh("CLIO")}
              handleDelete={() => setRemove({ state: true, type: "CLIO" })}
              handleClick={handleClioCurrentIntegrationString}
              handlePopup={handlePopup}
              integrationType={"CLIO"}
            />
          )}
          {pmType !== "clio" && (
            <IntegrationTab
              uri={ppObject.uri}
              image={ppObject.image}
              disconnectDisabled={false}
              clioSyncing={clioSyncing}
              xeroSyncing={xeroSyncing}
              ppSyncing={ppSyncing}
              handleRefresh={() => handleRefresh("PP")}
              handleDelete={() => setRemove({ state: true, type: "PP" })}
              handleClick={handlePPCurrentIntegrationString}
              handlePopup={handlePopup}
              integrationType={"PP"}
            />
          )}
          <IntegrationTab
            uri={xeroObject.uri}
            image={xeroObject.image}
            disconnectDisabled={false}
            clioSyncing={clioSyncing}
            xeroSyncing={xeroSyncing}
            handlePopup={handlePopup}
            ppSyncing={ppSyncing}
            //Pass false for everything other than clio
            xeroDisconnect={() => setOpen(true)}
            handleRefresh={() => handleRefresh("XERO")}
            handleDelete={() => setRemove({ state: true, type: "XERO" })}
            handleClick={handleXeroCurrentIntegrationString}
            integrationType={"XERO"}
          />
        </Grid>
        {integratedXero && <XeroErrorLog firmId={firmId} token={token} />}
      </Grid>
    </React.Fragment>
  );
}
