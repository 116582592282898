import React from "react";
import { DateRangePicker, DateRange } from "materialui-daterange-picker";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { definedRanges } from "./static";
import clsx from "clsx";

export interface DateDropdownRangeProps {
  open: boolean;
  handleOpen: () => void;
  handleDateRange: (dateRange: DateRange) => void | any;
  currentPosition: object | any;
}
const useStyles = makeStyles((theme: Theme) => ({
  popover: {
    position: "absolute",
    top: theme.spacing(1),
    zIndex: 1000,
    width: "44rem"
  },
  shiftRight: {
    [theme.breakpoints.up("lg")]: {
      left: -500
    }
  },
  shiftLeft: {
    [theme.breakpoints.up("lg")]: {
      left: 0
    }
  },
  generic: {
    [theme.breakpoints.up("lg")]: {
      left: -250
    }
  }
}));

const DateDropdownRange: React.FC<DateDropdownRangeProps> = ({
  open,
  handleOpen,
  handleDateRange,
  currentPosition
}) => {
  const classes = useStyles();
  const wrapperGeneric = clsx(classes.popover, classes.generic);
  const wrapperLeft = clsx(classes.popover, classes.shiftLeft);
  const wrapperRight = clsx(classes.popover, classes.shiftRight);

  // *****
  // Set Location relative to Parent component's window location
  // *****

  function updateScreenLocation() {
    switch (currentPosition) {
      case "GENERIC":
        return wrapperGeneric;
      case "SHIFT_LEFT":
        return wrapperLeft;
      case "SHIFT_RIGHT":
        return wrapperRight;
      default:
        return wrapperGeneric;
    }
  }

  return (
    <>
      <DateRangePicker
        open={open}
        toggle={handleOpen}
        onChange={dateRange => {
          handleOpen();
          handleDateRange(dateRange);
        }}
        wrapperClassName={updateScreenLocation()}
        definedRanges={definedRanges}
        maxDate={new Date()}
      />
    </>
  );
};

export default DateDropdownRange;
