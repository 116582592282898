import { useState, useRef, useEffect, useCallback } from "react";

export interface LimitState {
  limit: number;
  offset: number;
}

const initialState: LimitState = {
  limit: 20,
  offset: 0,
};

type Handle = () => void;
type UpdateLimit = (limit: number) => void;

/*
A standard hook with the ability to update limit and offset;
Instantiates with a limit of 20 and an offset of 0;
Updates limit with 20 steps;
Returns state and handles;
Used in top 20 and worst 20 charts and updates according to user arrow input
*/

const useChartLimits = () => {
  const [limitParams, setLimitParams] = useState<LimitState>(initialState);
  const limitParamsRef = useRef(initialState);

  useEffect(() => {
    limitParamsRef.current = initialState;
  }, [limitParamsRef]);

  // *****
  // Memoized Callback
  // *****

  const memoizedReducer = useCallback(
    (state, setState) => {
      return stateReducer(state, setState);
    },
    []
  );

  const handleLimitState = memoizedReducer(limitParams, setLimitParams);
  return { limitParamsRef, limitParams, handleLimitState };
};

const stateReducer = (state, setState) => {
  const handleBack: Handle = () => {
    if (state.offset < 20) return;
    setState({
      ...state,
      offset: state.offset - 20,
    });
  };

  const handleForward: Handle = () => {
    console.log(state)
    setState({
      ...state,
      offset: state.offset + 20,
    });
  };
  const updateLimit: UpdateLimit = (limit: number) => {
    setState({
      ...state,
      limit: limit,
    });
  };
  const limitReset: Handle = () => {
    setState(initialState);
  };

  return { handleBack, handleForward, updateLimit, limitReset }
};

export default useChartLimits;
