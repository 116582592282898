import Images from "../../../images";

export interface IntegrationObjType {
  image: any;
  uri: string;
}

/* *****
Integration platfrom information
***** */

const clioObject: IntegrationObjType = {
  image: Images.CLIO,
  uri: `${process.env.REACT_APP_CLIO_BASE_URL}/oauth/authorize?response_type=code&client_id=${process.env.REACT_APP_CLIO_ID}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&state=dashboard%2Fonboarding`,
};
const xeroObject: IntegrationObjType = {
  image: Images.XERO,
  uri: `https://login.xero.com/identity/connect/authorize?response_type=code&client_id=${process.env.REACT_APP_XERO_ID}&redirect_uri=${process.env.REACT_APP_XERO_REDIRECT_URI}&scope=${process.env.REACT_APP_XERO_SCOPE}&state=dashboard%2Fonboarding`,
};

const ppObject: IntegrationObjType = {
  image: Images.PP,
  uri: `https://app.practicepanther.com/oauth/authorize?response_type=code&client_id=${process.env.REACT_APP_PP_ID}&redirect_uri=${process.env.REACT_APP_PP_REDIRECT_URI}&state=dashboard%2Fonboarding`,
};

export { clioObject, ppObject, xeroObject };
