// *****
// Third Party Integrations - authorization code: Clio, Xero
// *****
interface AuthCodeState {
  clio: any;
  xero: any;
  pp: any;
}

export const authorizationCodeState: AuthCodeState = {
  clio: null,
  xero: null,
  pp: null,
};

export const authorizationCodeReducer = (state, action) => {
  switch (action.type) {
    case "SET_CLIO":
      return {
        ...state,
        clio: action.data,
      };
    case "SET_XERO":
      return {
        ...state,
        xero: action.data,
      };
    case "SET_PP":
      return {
        ...state,
        pp: action.data,
      };
    default:
      return state;
  }
};
