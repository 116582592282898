import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  Select,
  InputLabel,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { alpha, makeStyles } from "@material-ui/core/styles";
import { StyledInput } from "../../settings/payments/stripe/styledInput";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import InfoButton from "../infoButton";
import useAuth from "../../../hooks/useAuth";
const useStyles = makeStyles((theme) => ({
  imageContainer: {
    alignItems: "center",
    justifyContent: "center",
  },
  span: {
    fontFamily: "Open Sans",
    fontSize: 30,
    fontColor: theme.palette.text.primary,
    fontWeight: 500,
  },
  formControl: {
    minWidth: 120,
    marginTop: theme.spacing(2),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(4),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    backgroundColor: theme.palette.primary.light,
  },
  icon: {
    color: theme.palette.text.primary,
    right: theme.spacing(1) / 2,
    position: "absolute",
    userSelect: "none",
    pointerEvents: "none",
  },
  paper: {
    borderRadius: theme.spacing(1) / 2,
    marginTop: theme.spacing(1),
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    background: "white",

    "& li": {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      color: theme.palette.text.primary,
      fontWeight: 500,
      fontSize: "0.9em",
      overflowWrap: "break-word",
      wordWrap: "break-word",
    },
    "& li:hover": {
      background: "0px 5px 5px -3px rgba(0,0,0,0.1)",
    },
    "& li.Mui-selected": {
      color: "white",
      background: theme.palette.primary.light,
    },
    "& li.Mui-selected:hover": {
      background: theme.palette.primary.light,
    },
  },
  text: {
    fontSize: "1em",
    fontWeight: 700,
    color: theme.palette.primary.light,
  },
  placeholderText: {
    fontSize: "0.8em",
    fontWeight: 700,
    "&:disabled": {
      color: "#fff",
    },
  },
  select: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.common.white,
    border: "1px solid #dadaed",
    fontSize: 16,
    width: "100%",
    padding: "10px 12px",
    marginTop: theme.spacing(1),
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.light, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.light,
      borderRadius: 4,
    },
  },
}));

export default function StepOne({ handleForward }) {
  const classes = useStyles();
  const { onboardingState, onboardingActions } = useAuth();
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
  const firmName = onboardingState?.firmName || "";
  const integrationType =
    onboardingState?.currentIntegration === null
      ? ""
      : onboardingState?.currentIntegration;

  /* *****
Input Handles
***** */

  useEffect(() => {
    if (firmName.length > 0 && integrationType.length > 0) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [integrationType, firmName]);
  const handleCompanyName = (value) => {
    onboardingActions.setFirmName(value);
  };

  const handleIntegration = (value) => {
    onboardingActions.setCurrentOnboardingIntegration(value);
  };

  /* *****
Check if Input fields have values and the button is disabled, then enable it
***** */

  useEffect(() => {
    if (firmName.length > 0 && integrationType.length > 0 && isButtonDisabled) {
      setIsButtonDisabled(false);
    }
  }, [firmName, integrationType]);
  const list = [
    {
      id: "CLIO",
      disabled: false,
      name: "Clio",
    },
    {
      id: "XERO",
      disabled: false,
      name: "Xero",
    },
    {
      id: "PP",
      disabled: false,
      name: "Practice Panther",
    },
    {
      id: "QB",
      disabled: true,
      name: "Quick Books",
    },
  ];
  const iconComponent = (props) => {
    return <ExpandMoreIcon className={props.className + " " + classes.icon} />;
  };

  const menuProps = {
    classes: {
      paper: classes.paper,
      list: classes.list,
    },
    getContentAnchorEl: null,
  };

  const DropDown = ({ value, handleChange, listItems, name }) => {
    return (
      <React.Fragment>
        <Select
          classes={{ root: classes.select }}
          MenuProps={menuProps}
          IconComponent={iconComponent}
          value={value}
          onChange={(e) => handleChange(e.target.value)}
          labelId={"input-label"}
          disableUnderline
          fullWidth
          displayEmpty
        >
          <MenuItem value={""}>
            <span className={classes.placeholderText}>{name}</span>
          </MenuItem>
          {typeof listItems !== "undefined"
            ? listItems.map((item, i) => {
                return (
                  <MenuItem
                    disabled={item.disabled}
                    key={item.id}
                    value={item.id}
                  >
                    <Typography variant="inherit" noWrap>
                      {item.name}
                    </Typography>
                  </MenuItem>
                );
              })
            : null}
        </Select>
      </React.Fragment>
    );
  };

  return (
    <Grid container justifyContent="center" spacing={2}>
      <Grid item lg={8} md={8}>
        <span className={classes.span}>
          Register your firm <InfoButton title={"Information text here"} />
        </span>
      </Grid>
      <Grid item lg={8} md={8}>
        <InputLabel required>Company Name</InputLabel>
        <StyledInput
          value={firmName}
          onChange={(event) => handleCompanyName(event.target.value)}
          required
          fullWidth
          id="companyName"
          name="companyname"
          autoComplete="text"
        />
      </Grid>
      <Grid item lg={8} md={8}>
        <InputLabel>{"Integration *"}</InputLabel>
        <DropDown
          value={integrationType}
          handleChange={handleIntegration}
          name={"Integration"}
          listItems={list}
        />
      </Grid>
      <Grid item lg={8} md={8}>
        <Button
          variant="contained"
          color="primary"
          disabled={isButtonDisabled}
          onClick={handleForward}
          className={classes.button}
          fullWidth
        >
          Next
        </Button>
      </Grid>
    </Grid>
  );
}
