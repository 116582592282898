import React from "react";
import { withStyles, createStyles } from "@material-ui/core/styles";
import { Box, Typography, Tab, Tabs, CssBaseline } from "@material-ui/core";
import { ToggleButtonGroup, ToggleButton } from "@material-ui/lab";

//Styled Tabs component

interface StyledTabsProps {
  value: number;
  onChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
  scrollButtons: "auto" | "desktop" | "on" | "off";
  variant?: "standard" | "scrollable" | "fullWidth";
  centered?: boolean;
  orientation?: "horizontal" | "vertical";
}

export const StyledTabs = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.light,
    padding: 0,
  },

  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    height: "8%",
    "& > div": {
      width: "80%",
      backgroundColor: theme.palette.primary.light,
      maxWidth: 80,
    },
  },
}))((props: StyledTabsProps) => (
  <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />
));

//Styled Tab component

interface StyledTabProps {
  label: string;
  icon?: React.ReactElement;
  value?: any;
  to?: string;
  component?: any;
  wrapped?: boolean;
  disabled?: boolean;
}

export const StyledTab = withStyles((theme) =>
  createStyles({
    root: {
      textTransform: "none",
      fontWeight: theme.typography.fontWeightMedium,
      fontSize: theme.typography.pxToRem(12),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      minWidth: "0px",
      whiteSpace: "unset",
      letterSpacing: "unset",
      fontFamily: "Open Sans !important",
      "&:disabled > span": {
        color: `${theme.palette.background.default} !important`,
      },
      "&:selected": {
        opacity: 1,
        color: theme.palette.primary.light,
      },
    },
  })
)((props: StyledTabProps) => (
  <>
    <CssBaseline />
    <Tab disableRipple {...props} />
  </>
));

//Tab Panel Wrapper

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
  className?: any;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
}

//Styled toggle button group for inner tab

interface StyledToggleButtonGroupProps {
  children?: React.ReactNode;
  classes?: object;
  exclusive?: boolean;
  size?: "large" | "medium" | "small";
  value?: any;
  onChange: any;
  style?: any;
}

export const StyledToggleButtonGroup = withStyles((theme) => ({
  grouped: {
    borderWidth: 0,
    color: theme.palette.background.paper,
    fontSize: 11,
    fontWeight: "bold",
  },
  root: {
    justifySelf: "flex-end",
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(1) / 2,
    paddingBottom: theme.spacing(1) / 2,
    margin: theme.spacing(2),
    backgroundColor: theme.palette.primary.light,
  },
  groupedHorizontal: {
    borderRadius: theme.spacing(1) / 2,
    "&:first-child": {
      borderRadius: theme.spacing(1) / 2,
    },
    "&:not(:last-child)": {
      borderRadius: theme.spacing(1) / 2,
    },
    "&:not(:first-child)": {
      borderRadius: theme.spacing(1) / 2,
    },
    "&:last-child": {
      borderRadius: theme.spacing(1) / 2,
    },
  },
}))((props: StyledToggleButtonGroupProps) => <ToggleButtonGroup {...props} />);

//Styled toggle button for inner tab
interface StyledToggleButtonProps {
  children?: React.ReactNode;
  classes?: object;
  disabled?: boolean;
  disableFocusRipple?: boolean;
  disableRipple?: false;
  selected?: boolean;
  value?: any;
}

export const StyledToggleButton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.light,
    textTransform: "none",
    marginLeft: theme.spacing(1) / 2,
    marginRight: theme.spacing(1) / 2,
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(1) / 2,
    paddingBottom: theme.spacing(1) / 2,
    alignSelf: "flex-end",
    overflow: "hidden",
    "&:hover": {
      backgroundColor: theme.palette.text.hint,
      color: theme.palette.background.paper,
    },
    "&.Mui-selected": {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.primary.light,
      boxShadow:
        "0 0px 2px 0 rgba(55, 64, 78, 0.06), 0 0px 5px 0 rgba(55, 64, 78, 0.06)",
    },
  },
}))((props: StyledToggleButtonProps) => <ToggleButton {...props} />);
