import { generalActions } from "./generalActions";
import { onboardingActions } from "./onboardingActions";
import { authorizationCodeActions } from "./authorizationCodeActions";
import { currentAppStateActions } from "./currentAppStateActions";
import { integrationConfigActions } from "./integrationConfigActions";

export const useActions = (state, dispatch) => {
  return {
    generalActions: generalActions({ state, dispatch }),
    onboardingActions: onboardingActions({ state, dispatch }),
    authorizationCodeActions: authorizationCodeActions({ state, dispatch }),
    currentAppStateActions: currentAppStateActions({ state, dispatch }),
    integrationConfigActions: integrationConfigActions({ state, dispatch })
  };
};
