import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ClientsInvoices from "../../grids/clients/invoices";
import ClientGrowthTrends from "../../grids/clients/growthTrends";
import ClientRevenue from "../../grids/clients/revenue";
import ClientAR from "../../grids/clients/ar";

import { StyledToggleButtonGroup, StyledToggleButton } from "../styledTabs";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  headingText: {
    fontSize: "1.5em",
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.text.primary,
  },
  divider: {},
}));

export interface ClientTabProps {}

const ClientTab: React.FC<ClientTabProps> = () => {
  const [active, setActive] = useState(1);
  const classes = useStyles();

  const handleChange = (event, value) => {
    if (value !== null) {
      setActive(value);
    }
  };

  function switchTabs() {
    switch (active) {
      case 1:
        return <ClientGrowthTrends />;
      case 2:
        return <ClientsInvoices />;
      case 3:
        return <ClientRevenue />;
      case 4:
        return <ClientAR />;
    }
  }

  return (
    <Grid container spacing={4} justifyContent="space-between">
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <span className={classes.headingText}>Clients</span>
      </Grid>
      <StyledToggleButtonGroup
        onChange={handleChange}
        exclusive
        value={active}
        size="small"
      >
        <StyledToggleButton key={1} value={1}>
          Growth & Trend
        </StyledToggleButton>
        <StyledToggleButton key={2} value={2}>
          Invoices
        </StyledToggleButton>
        <StyledToggleButton key={3} value={3}>
          Revenue
        </StyledToggleButton>
        <StyledToggleButton key={4} value={4}>
          AR
        </StyledToggleButton>
      </StyledToggleButtonGroup>
      <Grid
        item
        xs={12}
        // sm={12}
        // md={9}
        // lg={9}
        // xl={9}
      >
        {switchTabs()}
      </Grid>
    </Grid>
  );
};

export default ClientTab;
