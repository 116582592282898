import React, { useContext, Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import ClientTopWorstInvoice from "./clientsByInvoice";
import useAuth from "../../../../../hooks/useAuth";
import { PlaceholderAcl } from "../../../../commons/placeholderAcl";

export interface ClientTopTensProps {}

const ClientTopTens: React.FC<ClientTopTensProps> = () => {
  const {
    integrationConfigState: {
      integrationObject: {
        clients: { invoices },
      },
    },
    generalStates: { acl },
  } = useAuth();
  return (
    <Grid container spacing={2}>
      {!acl?.clients_invoices && <PlaceholderAcl />}
      {acl?.clients_invoices &&
        invoices.map((item, index) => <Fragment key={index}>{item}</Fragment>)}
    </Grid>
  );
};

export default ClientTopTens;
export { ClientTopWorstInvoice };
