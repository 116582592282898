import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";
import { Link, useLocation } from "react-router-dom";
import api from "../../services";
import Layout from "../../containers/Layout";
import queryString from "query-string";
import {
  InputLabel,
  Button,
  Divider,
  CircularProgress,
  IconButton,
  Grid,
  InputAdornment,
} from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { StyledInput } from "../settings/payments/stripe/styledInput";
import { StyledGradientButton } from "../commons/styledButtons";
import images from "../../images";
import useAuth from "../../hooks/useAuth";

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(20, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    borderRadius: theme.spacing(1),
    overflow: "hidden",
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(12, 4),
    },
    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(8, 4),
    },
  },
  iconHide: {
    color: "#cfd1d6",
  },
  iconShow: {
    color: theme.palette.primary.light,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: theme.palette.primary.main,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
    "&:disabled": {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.dark,
    },
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },

  image: {
    width: 150,
  },

  bodyText: {
    display: "block",
    fontSize: "1em",
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.primary,
    width: theme.spacing(20),
    textAlign: "center",
    backgroundColor: "#fff",
    marginLeft: theme.spacing(18),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      marginLeft: theme.spacing(2),
      fontSize: "1em",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "1.4em",
    },
  },
  headingText: {
    display: "block",
    fontFamily: "Open Sans",
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(4),
    fontSize: "2em",
    fontWeight: 600,
    color: theme.palette.text.primary,
    [theme.breakpoints.down("md")]: {
      fontSize: "1.4em",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "1.8em",
    },
  },
  circularProgress: {
    color: "#fff",
    backgroundColor: "#fff",
  },
  linkWrapper: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  link: {
    textDecoration: "none",
    color: theme.palette.primary.light,
    fontSize: "1em",
    fontWeight: 500,
    [theme.breakpoints.up("xl")]: {
      fontSize: "1.1em",
    },
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  error: {
    color: theme.palette.error.dark,
    fontSize: 12,
    fontFamily: "Open Sans",
    marginTop: theme.spacing(1),
    maxWidth: 40,
  },
}));

export default function ForgotPassword() {
  const classes = useStyles();
  const location = useLocation();
  const {
    generalStates: { clientID },
    generalActions,
    integrationConfigActions,
    getStatus,
  } = useAuth();
  const { code } = queryString.parse(location.search);
  console.log(code);
  const [button, setButton] = useState<boolean>(true);
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [success, setSuccess] = useState<boolean>(false);
  const [passwordHide, setPasswordHide] = useState<boolean>(true);

  // *****
  // Regex check for email
  // *****

  useEffect(() => {
    setError(false);
    let passwordRgx =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*._-])(?=.{8,})/;

    if (password.length > 0 && passwordRgx.test(password)) {
      setButton(false);
    } else {
      setButton(true);
    }
  }, [password]);

  // *****
  // Call API
  // *****

  useEffect(() => {
    let subscribe = true;
    if (loading) {
      let request = api.create("");
      request
        .signupWithXero({
          code: code,
          password: password,
          clientID: clientID,
        })
        .then((res) => {
          if (subscribe) {
            setSuccess(true);
            generalActions.setToken(res.data.token);
            generalActions.setRefreshToken(res.data.refreshToken);
            let activeFirm =
              res.data.user.firmRoles.length > 0
                ? res.data.user.firmRoles[0]
                : null;
            // Set active Integration Type
            // if (activeFirm) {
            //   getStatus();
            // }
            generalActions.setActiveFirm(activeFirm);
            generalActions.setUser(res.data.user);
            setLoading(false);
          }
        })
        .catch((error) => {
          if (subscribe) {
            if (error.code === "ECONNABORTED") {
              setErrorMsg("The API request timed out. Please Refresh the page");
              setError(true);
              setLoading(false);
            } else {
              setErrorMsg(error.response.data.message);
              setError(true);
              setLoading(false);
            }
          }
        });
    }
    return () => {
      subscribe = false;
    };
  }, [loading]);

  const handleForgotPassword = () => {
    setLoading(true);
  };

  const handlePasswordToggle = () => {
    setPasswordHide(!passwordHide);
  };

  return (
    <Layout>
      <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
        <Grid container item direction="column" alignContent="center">
          <div className={classes.header}>
            <img className={classes.image} src={images.logo} />
            <Button
              component={Link}
              to={"/login"}
              classes={{ root: classes.bodyText }}
            >
              Login
            </Button>
          </div>
          <Grid item xs={9}>
            <span className={classes.headingText}>Signup with Xero</span>
          </Grid>
          <Grid item xs={9}>
            <InputLabel required>Password</InputLabel>
            <StyledInput
              error={error}
              required
              fullWidth
              id="password"
              name="password"
              type={passwordHide ? "password" : "text"}
              autoComplete="password"
              onChange={(e) => setPassword(e.target.value)}
              autoFocus
              endAdornment={
                <InputAdornment position="end">
                  {passwordHide ? (
                    <VisibilityOffIcon
                      onClick={handlePasswordToggle}
                      className={classes.iconHide}
                    />
                  ) : (
                    <VisibilityIcon
                      onClick={handlePasswordToggle}
                      className={classes.iconShow}
                    />
                  )}
                </InputAdornment>
              }
            />
            {button && (
              <span className={classes.error}>
                Must include 8 alphanumeric characters with one special
                character,
              </span>
            )}
          </Grid>
          <Grid item xs={9}>
            {error || success ? (
              <Alert
                severity={error ? `error` : `success`}
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      if (error) {
                        setError(false);
                      } else if (success) {
                        setSuccess(false);
                      }
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {error
                  ? errorMsg
                  : "Reset Password link sent to email. Please verify."}
              </Alert>
            ) : null}
            <Divider flexItem />
          </Grid>
          <Grid item xs={9}>
            <StyledGradientButton
              type="submit"
              fullWidth
              onClick={handleForgotPassword}
              disabled={loading || button}
            >
              {loading ? (
                <CircularProgress
                  size={24}
                  classes={{ circle: classes.circularProgress }}
                />
              ) : (
                "Set Password and Login"
              )}
            </StyledGradientButton>
          </Grid>
          <Grid item xs={9}>
            <div className={classes.linkWrapper}>
              <div></div>
              <Link to={"/signup"} className={classes.link}>
                {"Don't have an account? Sign Up"}
              </Link>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
}
