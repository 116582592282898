// *****
// Filters - Billing Method array
// *****

interface FilterItem {
  id: number | string;
  name: string;
}

const billingMethodArrayCLIO: FilterItem[] = [
  {
    id: "hourly",
    name: "hourly",
  },
  {
    id: "flat",
    name: "flat",
  },
  {
    id: "contingency",
    name: "contingency",
  },
];

const billingMethodArrayPP: FilterItem[] = [
  {
    id: "Hourly",
    name: "Hourly",
  },
  {
    id: "Flat Rate",
    name: "Flat Rate",
  },
  {
    id: "Contingency",
    name: "Contingency",
  },
];

const statusArray: FilterItem[] = [
  {
    id: "Open",
    name: "Open",
  },
  {
    id: "Closed",
    name: "Closed",
  },
  {
    id: "Pending",
    name: "Pending",
  },
];
const movingAvgArray: FilterItem[] = [
  {
    id: 3,
    name: "3 months",
  },
  {
    id: 6,
    name: "6 months",
  },
  {
    id: 9,
    name: "9 months",
  },
];

const dateDropdown: FilterItem[] = [
  {
    id: "LAST_THIRTY_DAYS",
    name: "Last 30 Days",
  },
  {
    id: "LAST_MONTH",
    name: "Last Month",
  },
  {
    id: "THIS_YEAR",
    name: "This Year",
  },
  {
    id: "LAST_YEAR",
    name: "last-year",
  },
  {
    id: "CUSTOM_RANGE",
    name: "Custom Range",
  },
];

export {
  dateDropdown,
  billingMethodArrayPP,
  billingMethodArrayCLIO,
  movingAvgArray,
  statusArray,
};
