import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";
import { Paper, Grid } from "@material-ui/core";
import Tiers from "./Tiers";
import useAuth from "../../../../hooks/useAuth";

export interface TrialExpiryProps {
  current?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  textWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    [theme.breakpoints.down("md")]: {
      paddingBottom: theme.spacing(2),
    },
  },

  title: {
    display: "inline-block",
    paddingBottom: theme.spacing(2),
    color: theme.palette.text.primary,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipses",
    [theme.breakpoints.up("md")]: {
      fontSize: "1.5em",
      fontWeight: theme.typography.fontWeightMedium,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "1.3em",
      fontWeight: 600,
    },
    fontSize: "1.3em",
    fontWeight: 500,
  },
  subHeading: {
    display: "block",
    whiteSpace: "normal",
    fontSize: "0.9em",
    fontWeight: 200,
    color: theme.palette.text.primary,
    [theme.breakpoints.up("md")]: {
      fontSize: "1.1em",
      fontWeight: 500,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "1.3em",
      fontWeight: 500,
    },
  },
}));

const TrialExpiry: React.FC<TrialExpiryProps> = ({ current }): any => {
  const {
    generalStates: {
      paymentInfo,
      activeFirm: {
        firm: { firstPayment },
      },
    },
    currentAppState: {
      status: { code, clioStatus, xeroStatus, ppStatus },
    },
  } = useAuth();

  const location = useLocation();
  const showExpiry = !location.pathname.includes("payment");
  const classes = useStyles();
  let clio = [
    { des: "For firms with 1-3 Law Firm Employees" },
    { des: "For firms with 4-9 Law Firm Employees" },
    { des: "For firms with 10-19 Law Firm Employees" },
  ];
  let pp = [
    { des: "For firms with 1-3 Law Firm Employees" },
    { des: "For firms with 4-9 Law Firm Employees" },
    { des: "For firms with 10-19 Law Firm Employees" },
  ];
  let xero = [
    { des: "For firms with revenue under $2M" },
    { des: "For firms with revenue from $2M - $5M" },
    { des: "For firms with revenue over $5M" },
  ];

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {code.includes("2") && showExpiry && (
            <span className={classes.title}>
              {firstPayment
                ? "Sign Up With Stripe to Begin Your 30 Day Trial"
                : "Trial Expired"}
            </span>
          )}
          <Paper className={classes.paper}>
            <Grid container spacing={2}>
              {code.includes("2") && showExpiry && (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <div className={classes.textWrapper}>
                    <span className={classes.subHeading}>
                      {firstPayment
                        ? `Simply enter your credit card information in stripe's secure payment system to get started with a 30 day free trial. Pricing is calculated automatically.
                         We take the headace out of limiting your usage and sectioning off different features. Our plans scale with you with transparent simplified pricing bands.`
                        : "Your Trial Period has expired. Please Pay to proceed."}
                      <br />
                    </span>
                  </div>
                </Grid>
              )}
              {current && (
                <Tiers
                  obj={
                    current === "xero"
                      ? paymentInfo?.subscribedXero?.tiers?.map(
                          (item, index) => ({
                            ...item,
                            ...xero[index],
                          })
                        )
                      : current === "clio"
                      ? paymentInfo?.subscribedClio?.tiers?.map(
                          (item, index) => ({
                            ...item,
                            ...clio[index],
                          })
                        )
                      : paymentInfo?.subscribedPP?.tiers?.map(
                          (item, index) => ({
                            ...item,
                            ...pp[index],
                          })
                        )
                  }
                  showExpiry={showExpiry}
                />
              )}
              {clioStatus == 2 && !current && (
                <>
                  <span className={classes.title}>Clio</span>
                  <span className={classes.subHeading}>
                    <br />
                    <br />
                    Our CLIO integration pricing model is based on the number of
                    users within the system. There is a lifetime 25% discount
                    for additional integrations such as Xero or QuickBooks
                    Online
                  </span>
                  <Tiers
                    current={"clio"}
                    obj={paymentInfo?.subscribedClio?.tiers?.map(
                      (item, index) => ({
                        ...item,
                        ...clio[index],
                      })
                    )}
                    showExpiry={showExpiry}
                  />
                </>
              )}
              {xeroStatus == 2 && !current && (
                <>
                  <span className={classes.title}>Xero</span>
                  <span className={classes.subHeading}>
                    {" "}
                    <br />
                    <br />
                    Our Xero integration pricing model is simplified and based
                    on the firms revenue. There is a lifetime 25% discount for
                    additional integrations such as CLIO or PracticePanther, or
                    other legal practice management system.
                  </span>
                  <Tiers
                    current={"xero"}
                    obj={paymentInfo?.subscribedXero?.tiers?.map(
                      (item, index) => ({
                        ...item,
                        ...xero[index],
                      })
                    )}
                    showExpiry={showExpiry}
                  />
                </>
              )}
              {ppStatus == 2 && !current && (
                <>
                  <span className={classes.title}>Practice Panther</span>
                  <span className={classes.subHeading}>
                    {" "}
                    <br />
                    <br />
                    Our Practive Panther integration pricing model is based on
                    the number of users within the system. There is a lifetime
                    25% discount for additional integrations such as Xero or
                    QuickBooks Online
                  </span>
                  <Tiers
                    current={"pp"}
                    obj={paymentInfo?.subscribedPP?.tiers?.map(
                      (item, index) => ({
                        ...item,
                        ...pp[index],
                      })
                    )}
                    showExpiry={showExpiry}
                  />
                </>
              )}
              {!paymentInfo?.subscribedClio &&
                !paymentInfo?.subscribedXero &&
                !paymentInfo?.subscribedPP && (
                  <span className={classes.title}>
                    Please wait while the firm is syncing. Refresh this page
                    when the sync is completed.
                  </span>
                )}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default TrialExpiry;
