export const generalActions = ({ dispatch }) => {
  return {
    setRefreshToken: (data) => {
      localStorage.setItem("refresh-token", data);
      externSetRefreshToken(dispatch, data);
    },
    setToken: (data) => {
      localStorage.setItem("token", data);
      externSetToken(dispatch, data);
    },
    //User Agent ID
    setClientID: (data) => {
      localStorage.setItem("clientID", data);
      externSetClientID(dispatch, data);
    },
    //User object
    setUser: (data) => {
      localStorage.setItem("user", JSON.stringify(data));
      externSetUser(dispatch, data);
    },
    setUserACL: (data) => {
      localStorage.setItem("acl", JSON.stringify(data));
      externSetUserACL(dispatch, data);
    },
    // Current active firm
    setActiveFirm: (data) => {
      localStorage.setItem("activeFirm", JSON.stringify(data));
      externSetActiveFirm(dispatch, data);
    },
    // Payment & billing object
    setPaymentInfo: (data) => {
      externSetPaymentInfo(dispatch, data);
    },
    setTutorial: (data) => {
      localStorage.setItem("tutorial", data);
      externSetTutorial(dispatch, data);
    },
    setTutorialStep: () => {
      dispatch({ type: "SET_TUTORIAL_STEP" });
    },
    resetTutorialStep: () => {
      dispatch({ type: "RESET_TUTORIAL_STEP" });
    },
    setTutorialPosition: (data) => {
      dispatch({ type: "SET_TUTORIAL_POSITION", data });
    },
    logout: () => {
      localStorage.removeItem("activeFirm");
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      localStorage.removeItem("refresh-token");
      externLogout(dispatch);
    },
  };
};

// *****
// External actions
// *****

function externLogout(dispatch) {
  dispatch({ type: "LOGOUT" });
}
function externSetUserACL(dispatch, data) {
  dispatch({ type: "SET_ACL", data });
}
function externSetTutorial(dispatch, data) {
  dispatch({ type: "SET_TUTORIAL", data });
}
function externSetRefreshToken(dispatch, data) {
  dispatch({ type: "SET_REFRESH_TOKEN", data });
}

function externSetToken(dispatch, data) {
  dispatch({ type: "SET_TOKEN", data });
}

function externSetClientID(dispatch, data) {
  dispatch({ type: "SET_CLIENTID", data });
}
function externSetUser(dispatch, data) {
  dispatch({ type: "SET_USER", data });
}
function externSetActiveFirm(dispatch, data) {
  dispatch({ type: "SET_ACTIVEFIRM", data });
}
function externSetPaymentInfo(dispatch, data) {
  dispatch({ type: "SET_PAYMENTINFO", data });
}
