import {
  FormControl,
  FormHelperText,
  FormGroup,
  FormLabel,
  FormControlLabel,
  Checkbox,
  makeStyles,
  Grid,
  Button,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import useAuth from "../../../hooks/useAuth";
import api from "../../../services";
interface Props {
  userID: string;
}
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  formControl: {
    margin: theme.spacing(3),
    height: "30%",
  },
  formGroup: {
    justifyContent: "flex-start",
  },
  button: {
    alignSelf: "flex-end",
    width: 100,
    margin: theme.spacing(2),
  },
}));
const UserPermissions = ({ userID }: Props) => {
  const {
    generalStates: {
      token,
      activeFirm: {
        firm: { _id },
      },
    },
    getUserACL,
  } = useAuth();
  //   alert(JSON.stringify(props));
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<any>();
  const [submit, setSubmit] = useState<boolean>(false);

  useEffect(() => {
    let subscribed = true;
    if (loading) {
      let request = api.create(token);
      request.getUserPermissions(_id, { userID: userID }).then((res) => {
        const {
          dashboard,
          clients_growth_and_trend,
          clients_invoices,
          clients_revenue,
          clients_ar,
          matters_growth_and_trend,
          matters_invoices,
          matters_revenue,
          matters_ar,
          matters_billable_non_billable,
          financials,
          productivity_growth_and_trend,
          productivity_aop,
          productivity_user,
          matterTracker,
          trust,
          ar,
        } = res.data;
        setData([
          {
            label: "Dashboard",
            value: dashboard,
            slug: "dashboard",
          },
          {
            label: "Clients - Growth & Trend",
            value: clients_growth_and_trend,
            slug: "clients_growth_and_trend",
          },
          {
            label: "Clients - Invoices",
            value: clients_invoices,
            slug: "clients_invoices",
          },
          {
            label: "Clients - Revenue",
            value: clients_revenue,
            slug: "clients_revenue",
          },
          { label: "Clients - AR", value: clients_ar, slug: "clients_ar" },
          {
            label: "Matters - Growth & Trend",
            value: matters_growth_and_trend,
            slug: "matters_growth_and_trend",
          },
          {
            label: "Matters - Invoices",
            value: matters_invoices,
            slug: "matters_invoices",
          },
          {
            label: "Matters - Revenue",
            value: matters_revenue,
            slug: "matters_revenue",
          },
          { label: "Matters - AR", value: matters_ar, slug: "matters_ar" },
          {
            label: "Matters - Billable vs Non Billable",
            value: matters_billable_non_billable,
            slug: "matters_billable_non_billable",
          },
          {
            label: "Financials",
            value: financials,
            slug: "financials",
          },
          {
            label: "Productivity - Growth & Trend",
            value: productivity_growth_and_trend,
            slug: "productivity_growth_and_trend",
          },
          {
            label: "Productivity - AOP",
            value: productivity_aop,
            slug: "productivity_aop",
          },
          {
            label: "Productivity - User",
            value: productivity_user,
            slug: "productivity_user",
          },
          {
            label: "Matter Tracker",
            value: matterTracker,
            slug: "matterTracker",
          },
          {
            label: "Trust",
            value: trust,
            slug: "trust",
          },
          {
            label: "AR",
            value: ar,
            slug: "ar",
          },
        ]);
        setLoading(false);
      });
    }
    return () => {
      subscribed = false;
    };
  }, [loading]);
  const classes = useStyles();
  const handleChange = (index) => {
    setData([
      ...data.slice(0, index),
      Object.assign({}, { ...data[index], value: !data[index].value }),
      ...data.slice(index + 1),
    ]);
  };
  return (
    <FormControl className={classes.formControl}>
      <FormLabel component="legend">Access Control List</FormLabel>
      <FormGroup classes={{ root: classes.formGroup }}>
        {!loading && (
          <Grid container>
            {data.map((item, index) => (
              <Grid item xs={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={() => handleChange(index)}
                      checked={item.value}
                      name={item.label}
                    />
                  }
                  label={item.label}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </FormGroup>
      <FormHelperText>
        Be careful; Deselecting the checkboxes will result in restricting the
        user from accessing the tabs.
      </FormHelperText>
      <Button
        className={classes.button}
        disabled={submit}
        onClick={() => {
          setSubmit(true);
          let obj = data
            .map((item) => ({ [item.slug]: item.value }))
            .reduce(function (result, item) {
              var key = Object.keys(item)[0];
              result[key] = item[key];
              return result;
            }, {});

          let request = api.create(token);
          request
            .setUserPermissions(_id, obj, { userID: userID })
            .then((res) => {
              getUserACL();
              setSubmit(false);
              alert("User access control list updated.");
            });
        }}
        variant="contained"
        color="secondary"
      >
        Submit
      </Button>
    </FormControl>
  );
};

export default UserPermissions;
