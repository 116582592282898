import { Grid } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import MatTable from "../../charts/table";
import numeral from "numeral";
import api from "../../../../services";
import ErrorSnackBar from "../../commons/SnackBar";
import TrustDetail from "./trustDetail";
import InfoIcon from "@material-ui/icons/Info";
import FilterListIcon from "@material-ui/icons/FilterList";
import FilterUserTimePractice from "../../filters/genericFilter/filters";
import useFilterState from "../../filters/genericFilter/filterStateHook";
import { FilterClientsMattersTypes } from "../../filters/genericFilter/types";
import useAuth from "../../../../hooks/useAuth";
import ETT from "./ETT";
interface IAppProps {}

const initialFilterState: any = {
  practiceArea: "",
  billingMethod: "",
  status: "",
};

const Trust: React.FunctionComponent<IAppProps> = () => {
  const {
    generalStates: {
      token,
      user : {
        email
      },
      activeFirm: {
        firm: { _id },
      },
    },
    integrationConfigState: { pmType },
  } = useAuth();

  const [loader, setLoader] = useState<boolean>(true);
  const [data, setData] = useState<any[]>([]);
  const [error, setError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);

  const { filterRef, filterState, handleState } = useFilterState(initialFilterState);

  useEffect(() => {
    let subscribe = true;
    if (loader) {
      let request = api.create(token);
      let queryParams = {
        practice_area_id:
          filterState.practiceArea === "" ? null : filterState.practiceArea,
        billing_method:
          filterState.billingMethod === "" ? null : filterState.billingMethod,

        status: filterState.status === "" ? null : filterState.status,
      };
      request
        .getTrustReport(_id, pmType, queryParams)
        .then((res) => {
          if (subscribe) {
            setData(res.data);
            setLoader(false);
          }
        })
        .catch((error) => {
          if (subscribe) {
            setData([]);
            setLoader(false);
            if (error.code === "ECONNABORTED") {
              setErrorMsg("The API request timed out. Please Refresh the page");
              setError(true);
            } else {
              setError(true);
              setErrorMsg(error.response.data.message);
            }
          }
        });
    }
    return () => {
      subscribe = false;
    };
  }, [loader]);

  const handlePracticeAreas = (value: number | "") => {
    handleState.handlePracticeArea(value);
  };

  const handleBillingMethod = (value: string) => {
    handleState.handleBillingMethod(value);
  };
  const handleStatus = (value: string) => {
    handleState.handleStatus(value);
  };

  const prevFilterState = filterRef.current;

  const handleFilterSubmit = () => {
    setLoader(true);
  };

  // *****
  // Clear Filter States
  // *****

  const handleFilterClear = () => {
    handleState.reset(initialFilterState);
    setLoader(true);
  };
  const commonCellStyle = {
    color: "#858796",
    fontSize: 14,
    fontWieght: 700,
  };

  const columns = [
    {
      title: "Contact Name",
      field: "client_name",
      editable: "never",
      cellStyle: { ...commonCellStyle },
    },
    {
      title: "Matter Name",
      field: "matter_name",
      editable: "never",

      cellStyle: { ...commonCellStyle },
    },
    {
      title: "Trust Balance",
      field: "trust_balance",
      editable: "never",
      defaultSort: "desc",
      type: "numeric",
      render: (row) => (
        <span>{`${numeral(row["trust_balance"]).format("($ 0.00 a)")}`}</span>
      ),
      cellStyle: { ...commonCellStyle },
    },
    // {
    //   title: "Matter Value",
    //   field: "guaranteed_collection_threshold",
    //   render: (row) => (
    //     <span>{`${numeral(row["guaranteed_collection_threshold"]).format(
    //       "($ 0.00 a)"
    //     )}`}</span>
    //   ),
    //   type: "numeric",
    //   cellStyle: { ...commonCellStyle },
    // },
    {
      title: "Unbilled WIP Hours",
      field: "billed_time",
      editable: "never",

      cellStyle: { ...commonCellStyle },
      render: (row) => (
        <span>{`${
          Math.round((row["billed_time"] + Number.EPSILON) * 100) / 100
        }`}</span>
      ),
      type: "numeric",
    },
    {
      title: "Unbilled WIP Value",
      field: "billed_value",
      editable: "never",

      render: (row) => (
        <span>{`${numeral(row["billed_value"]).format("($ 0.00 a)")}`}</span>
      ),
      cellStyle: { ...commonCellStyle },
      type: "numeric",
    },
    {
      title: "Billable Expense ",
      field: "billed_expense",
      editable: "never",
      render: (row) => (
        <span>{`${numeral(row["billed_expense"]).format("($ 0.00 a)")}`}</span>
      ),
      cellStyle: { ...commonCellStyle },
      type: "numeric",
    },
    {
      title: "Total WIP",
      field: "total_CWIP",
      cellStyle: { ...commonCellStyle },
      render: (row) => (
        <span>{`${numeral(row["total_CWIP"]).format("($ 0.00 a)")}`}</span>
      ),
      type: "numeric",
    },
    {
      title: "Deviation",
      field: "deviation",
      cellStyle: { ...commonCellStyle, fontWeight: "bold" },
      render: (row) => (
        <span
          style={
            row["deviation"] == 0
              ? { color: "#fec146" }
              : row["deviation"] > 0
              ? { color: "#22ca90" }
              : { color: "#eb3f56" }
          }
        >{`${numeral(row["deviation"]).format("($ 0.00 a)")}`}</span>
      ),
      type: "numeric",
    },

    // {
    //   title: "AR",
    //   field: "AR",
    //   editable: "never",

    //   render: (row) => (
    //     <span>{`${numeral(row["AR"]).format("($ 0.00 a)")}`}</span>
    //   ),
    //   cellStyle: { ...commonCellStyle },

    //   type: "numeric",
    // },
    // {
    //   title: "Guaranteed Collection",
    //   field: "guaranteedCollection",
    //   editable: "never",

    //   render: (row) => (
    //     <span>{`${numeral(row["guaranteedCollection"]).format(
    //       "($ 0.00 a)"
    //     )}`}</span>
    //   ),
    //   cellStyle: { ...commonCellStyle },
    //   type: "numeric",
    // },
    // {
    //   title: "Expected Collection",
    //   field: "expectedCollection",
    //   editable: "never",

    //   cellStyle: { ...commonCellStyle },
    //   render: (row) => (
    //     <span>{`${numeral(row["expectedCollection"]).format(
    //       "($ 0.00 a)"
    //     )}`}</span>
    //   ),
    //   type: "numeric",
    // },
    // {
    //   title: "Threshold Included",
    //   field: "is_threshold_included",
    //   lookup: {
    //     0: "No",
    //     1: "Yes",
    //   },
    //   cellStyle: { ...commonCellStyle },
    // },
  ];

  return (
    <Grid container spacing={2}>
      <Grid xs={12} item>
          <ETT isAllowed={true} firmId={_id} token={token} />
      </Grid>
      <Grid item xs={12}>
        <FilterUserTimePractice
          filterState={filterState}
          disabled={loader}
          handleFilterSubmit={handleFilterSubmit}
          handleFilterClear={handleFilterClear}
          handlePracticeAreas={handlePracticeAreas}
          handleBillingMethod={handleBillingMethod}
          handleStatus={handleStatus}
          handleFromComponent={open}
          setCloseFromComponent={() => setOpen(false)}
        />
        <MatTable
          title={"Trust Balance vs Work In Progress"}
          isLoading={loader}
          column={columns}
          data={data}
          search={true}
          actions={[
            {
              icon: () => <InfoIcon />,
              isFreeAction: true,
              tooltip: (
                <div>
                  Guaranteed collections is defined as billable client expenses,
                  un-billed time value and AR, within a specified time period,
                  that is covered or matched by available funds in trust.
                  <br />
                  <br />
                  Expected collections is defined as billable client expenses,
                  un-billed time value and AR not covered or matched by
                  available funds in trust. Constantly measure if your work is
                  in line with your expectations or comfort level of financial
                  exposure.
                </div>
              ),
            },
            {
              icon: () => <FilterListIcon />,
              isFreeAction: true,
              onClick: (event, rowData) => {
                setOpen(!open);
              },
              tooltip: "Filters",
            },
          ]}
          // editable={{
          //   onRowUpdate: (newData, oldData) =>
          //     new Promise((resolve) => {
          //       let request = api.create(token);
          //       let r = Promise.all([
          //         request.addThresholdIncluded(_id, pmType, {
          //           id: newData.id,
          //           is_included:
          //             newData.is_threshold_included === "0" ? false : true,
          //         }),
          //         request.addThresholdValue(_id, pmType, {
          //           id: newData.id,
          //           threshold_value: newData.guaranteed_collection_threshold,
          //         }),
          //       ])
          //         .then((res) => {
          //           setLoader(true);
          //         })
          //         .catch((error) => {
          //           if (error.code === "ECONNABORTED") {
          //             setErrorMsg(
          //               "The API request timed out. Please Refresh the page"
          //             );
          //             setError(true);
          //           } else {
          //             setErrorMsg(error.response.data.message);
          //             setError(true);
          //           }
          //         });
          //       resolve(r);
          //     }),
          // }}
          detailPanel={(rowData) => {
            return (
              <TrustDetail
                pmType={pmType}
                id={rowData.id}
                firmId={_id}
                token={token}
              />
            );
          }}
        />
      </Grid>
      {error ? <ErrorSnackBar errorMsg={errorMsg} /> : null}
    </Grid>
  );
};

export default Trust;
