import React, { useContext, useState, useEffect } from "react";
import {
  Grid,
  TextField,
  Button,
  Paper,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ErrorSnackBar from "../dashboard/commons/SnackBar";
import api from "../../services";
import useAuth from "../../hooks/useAuth";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  titleText: {
    display: "block",
    fontSize: "1.3rem",
    fontWeight: 600,
    fontColor: theme.palette.text.primary,
    [theme.breakpoints.down("md")]: {
      fontSize: "1.2rem",
    },
  },
  button: {
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
}));
export default function PersonalInformation() {
  const {
    generalStates: { user, token },
    generalActions,
  } = useAuth();
  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);
  const [apiError, setApiError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");

  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    if (firstName === user.firstName && lastName === user.lastName) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [firstName, lastName]);

  const handleSubmit = () => {
    let request = api.create(token);
    setLoading(true);
    request
      .updateUserProfile({ firstName: firstName, lastName: lastName })
      .then((res) => {
        request.getUserProfile().then((response) => {
          setIsDisabled(false);
          setLoading(false);
          generalActions.setUser(response.data);
        });
      })
      .catch((error) => {
        if (error.code === "ECONNABORTED") {
          setIsDisabled(false);
          setLoading(false);
          setErrorMsg("The API request timed out. Please Refresh the page");
          setApiError(true);
        } else {
          setIsDisabled(false);
          setLoading(false);
          setErrorMsg(error.response.data.message);
          setApiError(true);
        }
      });
  };

  const classes = useStyles();
  return (
    <React.Fragment>
      <Paper className={classes.root}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <span className={classes.titleText}>Personal Information</span>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              variant="outlined"
              fullWidth
              label="Display Name"
              value={user.firstName}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              variant="outlined"
              fullWidth
              label="Email"
              value={user.email}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              variant="outlined"
              fullWidth
              label="First Name"
              required
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              variant="outlined"
              onChange={(e) => setLastName(e.target.value)}
              value={lastName}
              fullWidth
              required
              label="Last Name"
            />
          </Grid>
          <Grid
            style={{ padding: 16 }}
            item
            xs={12}
            lg={12}
            md={12}
            xl={12}
            sm={12}
          >
            <Button
              disabled={isDisabled}
              onClick={handleSubmit}
              variant="contained"
              color="primary"
              className={classes.button}
            >
              {loading ? <CircularProgress size={24} /> : "Change"}
            </Button>
          </Grid>
        </Grid>
      </Paper>
      {apiError && <ErrorSnackBar errorMsg={errorMsg} />}
    </React.Fragment>
  );
}
