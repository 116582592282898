// *****
// General App State: User Information, Payment Information
// *****
interface GeneralStatesTypes {
  token: any;
  "refresh-token": any;
  clientID: any;
  user: any;
  activeFirm: any;
  paymentInfo: any;
  tutorial: any;
  tutorialStep: number;
  tutorialPosition: any;
  acl: any;
}

export const generalStates: GeneralStatesTypes = {
  token:
    localStorage.getItem("token") == null
      ? null
      : localStorage.getItem("token"),
  "refresh-token":
    localStorage.getItem("refresh-token") == null
      ? null
      : localStorage.getItem("refresh-token"),
  clientID:
    localStorage.getItem("clientID") == null
      ? null
      : localStorage.getItem("clientID"),
  user:
    localStorage.getItem("user") == null
      ? null
      : JSON.parse(localStorage.getItem("user") as any),
  activeFirm:
    localStorage.getItem("activeFirm") == null
      ? null
      : JSON.parse(localStorage.getItem("activeFirm") as any),
  paymentInfo: null,
  tutorial:
    localStorage.getItem("tutorial") == null
      ? true
      : JSON.parse(localStorage.getItem("tutorial") as any),
  tutorialStep: 0,
  tutorialPosition: { top: 0, left: 0, width: 0 },
  acl: localStorage.getItem("acl") == null ? null : localStorage.getItem("acl"),
};

export const generalReducer = (state, action) => {
  switch (action.type) {
    case "SET_TOKEN":
      return {
        ...state,
        token: action.data,
      };
    case "SET_REFRESH_TOKEN":
      return {
        ...state,
        "refresh-token": action.data,
      };
    case "SET_TUTORIAL_POSITION":
      return {
        ...state,
        tutorialPosition: action.data,
      };
    case "SET_TUTORIAL":
      return {
        ...state,
        tutorial: action.data,
      };
    case "SET_TUTORIAL_STEP":
      return {
        ...state,
        tutorialStep: state.tutorialStep + 1,
      };

    case "RESET_TUTORIAL_STEP":
      return {
        ...state,
        tutorialStep: 0,
      };
    case "SET_CLIENTID":
      return {
        ...state,
        clientID: action.data,
      };
    case "SET_USER":
      return {
        ...state,
        user: action.data,
      };
    case "SET_ACTIVEFIRM":
      return {
        ...state,
        activeFirm: action.data,
      };
    case "SET_PAYMENTINFO":
      return {
        ...state,
        paymentInfo: action.data,
      };
    case "SET_ACL":
      return {
        ...state,
        acl: action.data,
      };
    case "LOGOUT":
      return {
        token: null,
        user: null,
        activeFirm: null,
        paymentInfo: null,
        "refresh-token": null,
      };
    default:
      return state;
  }
};
