import { useReducer, useCallback } from "react";
import reducer from "./reducer";
import api from "../../../../../../../services";
import { MattersDistributionState, Action } from "./types";
import {
  getNestedListByPropertyFusion,
  getNestedListByPropertyFusionClosed,
  limitLengthToEighteen,
  subtractElementfromArray,
  addElementsAtIndexInArrays,
  getMonthsFusion,
  compareMonths,
} from "../../../../utils";
import useAuth from "../../../../../../../hooks/useAuth";
const useFetchMattersDistribution = (initialState) => {
  const [mattersDistributionState, dispatch] = useReducer(
    reducer,
    initialState
  );
  const {
    generalStates: {
      token,
      activeFirm: {
        firm: { _id },
      },
    },
    integrationConfigState: { pmType },
  } = useAuth();

  const fetchMattersDistribution = useCallback(
    (filterState, dateFilterState) => {
      const request = api.create(token);
      // Dispatch action: request generated
      dispatch({ type: "SET_REQUEST" } as Action);
      const queryParams = {
        practice_area_id:
          filterState.practiceArea === "" ? null : filterState.practiceArea,
        billing_method:
          filterState.billingMethod === "" ? null : filterState.billingMethod,
        originating_attorney_id:
          filterState.originatingAtt === "" ? null : filterState.originatingAtt,
        responsible_attorney_id:
          filterState.responsibleAtt === "" ? null : filterState.responsibleAtt,
        startDate: dateFilterState.dateAfter,
        endDate: dateFilterState.dateBefore,
      };
      request
        .getMatterDistribution(_id, pmType, queryParams)
        .then((response) => {
          const {
            active_matters: activeMatters,
            closed_matters: closedMatters,
            total_matters: totalmatters,
          } = response.data;

          //Equate arrays by common months
          const equatedTotalMatters = limitLengthToEighteen(
            compareMonths(totalmatters as any[], activeMatters, {
              cumulative_matters: 0,
            })
          );
          const equatedActiveMatters = limitLengthToEighteen(
            compareMonths(activeMatters, equatedTotalMatters)
          );
          // Extract by property
          const equatedClosedMatters = limitLengthToEighteen(
            compareMonths(closedMatters, equatedTotalMatters)
          );
          const extractedClosedMatters = getNestedListByPropertyFusion(
            equatedClosedMatters,
            "cumulative_matters"
          );
          const extractedActiveMatters = getNestedListByPropertyFusion(
            equatedActiveMatters,
            "total"
          );
          const extractedTotalMatters = getNestedListByPropertyFusion(
            equatedTotalMatters,
            "cumulative_matters"
          );

          const months = getMonthsFusion(equatedActiveMatters, "month");

          // Calculate dormant matters
          const dormantMatters = subtractElementfromArray(
            addElementsAtIndexInArrays(
              extractedClosedMatters as any,
              extractedActiveMatters as any
            ),
            extractedTotalMatters
          );
          // Dispatch action - set response
          dispatch({
            type: "SET_RESPONSE",
            activeMatters: extractedActiveMatters,
            closedMatters: extractedClosedMatters,
            dormantMatters: dormantMatters,
            months: months,
          } as Action);
        })
        .catch((error) => {
          console.log(error);
          if (error.code === "ECONNABORTED") {
            // Dispatch action: error response received
            dispatch({
              type: "SET_ERROR",
              message: "The API request timed out. Please Refresh the page",
            } as Action);
          } else {
            if (error.response.data.message) {
              dispatch({
                type: "SET_ERROR",
                message: error.response.data.message,
              } as Action);
            }
          }
        });
    },
    [_id, token]
  );

  return { mattersDistributionState, fetchMattersDistribution };
};

export default useFetchMattersDistribution;
