import React, { useContext, Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import ClientTopWorstCollected from "./clientsByRevenue";
import useAuth from "../../../../../hooks/useAuth";
import { PlaceholderAcl } from "../../../../commons/placeholderAcl";
export interface ClientWorstTensProps {}

const ClientWorstTens: React.FC<ClientWorstTensProps> = () => {
  const {
    integrationConfigState: {
      integrationObject: {
        clients: { revenue },
      },
    },
    generalStates: { acl },
  } = useAuth();
  return (
    <Grid container spacing={2}>
      {!acl?.clients_revenue && <PlaceholderAcl />}
      {acl?.clients_revenue &&
        revenue.map((item, index) => <Fragment key={index}>{item}</Fragment>)}
    </Grid>
  );
};

export default ClientWorstTens;
export { ClientTopWorstCollected };
