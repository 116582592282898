import React, { useEffect, useState, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import api from "../../../../../services";
import _ from "lodash";
import ChartLoader from "../../../commons/loaders/chartLoader";
import Colors from "../../../../../theme/ChartTheme";
import ErrorSnackBar from "../../../commons/SnackBar";
import FilterUserTimePractice from "../../../filters/genericFilter/filters";
import { ProductivityGTTypes } from "../../../filters/genericFilter/types";
import usePreviousState, {
  compareFilterDates,
  getMonthsFusion,
  getNestedListByPropertyFusion,
  limitLengthToEighteen,
} from "../../utils";
import useDateFilterState, {
  DateStateType,
} from "../../../filters/genericFilter/dateFilterStateHook";
import useFilterState from "../../../filters/genericFilter/filterStateHook";
import EmptyPlaceholder from "../../../commons/placeholders/emptyPlaceholder";
import { DateRange } from "materialui-daterange-picker";
import Chart from "../../../charts/fusionCharts";
import InfoButton from "../../../infoButton";
import { Grid, Paper } from "@material-ui/core";
import UserName from "../../../filters/commons/userName";
import moment from "moment";
import {
  StyledToggleButtonGroup,
  StyledToggleButton,
} from "../../../tabs/styledTabs";
import useAuth from "../../../../../hooks/useAuth";

// *****
// Types
// *****

export interface TBvsAbProps {}

interface ResponseObject {
  total_hours: number;
  total_value: number;
  forecast_values: number;
  forecast_hours: number;
  month: string;
}

interface ChartStateType {
  targetedBilled: any[];
  actualBilled: any[] | [];
  months: any[] | [];
  forcastedBilled: any[] | [];
}

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "visible",
    flexDirection: "column",
    minHeight: 550,
    justifyContent: "center",
  },
  textTitle: {
    color: theme.palette.text.primary,
    fontSize: "1.2em",
    fontWeight: 600,
  },
  headingText: {
    fontSize: "1.2em",
    fontWeight: 600,
    color: theme.palette.text.secondary,
  },
  divider: {
    backgroundColor: "#EDEFF7",
    height: 1,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

// *****
// Initial Filter state
// *****

const initialFilterState: ProductivityGTTypes = {
  practiceArea: "",
  user: "",
  year: {
    label: "Current Year",
    startDate: moment().utc().startOf("year").toDate(),
    endDate: new Date(),
  },
};

const initialDateFilterState: DateStateType = {
  dateAfter: null,
  dateBefore: null,
};

const TBvsAb: React.FC<TBvsAbProps> = () => {
  const [error, setError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [loader, setLoader] = useState<boolean>(true);
  const [movingAvgSize, setMovingAvgSize] = useState<number>(3);
  const [filterMA, setFilterMA] = useState<number>(3);
  const [movAvgSeries, setMovAvgSeries] = useState<any>([]);
  const [movingAvgCheck, setMovingAvgCheck] = useState<boolean>(false);
  const [chart, setChart] = useState<ChartStateType>({
    targetedBilled: [],
    actualBilled: [],
    forcastedBilled: [],
    months: [],
  });
  const [current, setCurrent] = useState<number>(0);

  const classes = useStyles();
  const {
    generalStates: {
      token,
      activeFirm: {
        firm: { _id: firmId },
      },
    },
    integrationConfigState: { pmType },
  } = useAuth();
  const prevFilterMA = usePreviousState(filterMA);

  // *****
  // Filter States
  // *****

  const { filterRef, filterState, handleState } =
    useFilterState(initialFilterState);
  const { dateFilterRef, dateFilterState, handleDateState } =
    useDateFilterState(initialDateFilterState);

  const prevDateFilterState = dateFilterRef.current;
  const prevFilterState = filterRef.current;

  // *****
  // Series for the chart
  // *****

  // *****
  // Filter Events
  // *****

  const handlePracticeAreas = (value: number | "") => {
    handleState.handlePracticeArea(value);
  };

  const handleUser = (value: number | "") => {
    handleState.handleUser(value);
  };
  const handleYearRange = (value: string) => {
    handleState.handleYearRange(value);
  };

  const handleDateFilterRange = (dateRange: DateRange): void => {
    handleDateState.handleDateUpdate(dateRange);
  };

  // *****
  // Filter Submit
  // Use Case 1: If dates have been applied, then check for date consistency.
  // Use Case 2: All filters have been applied and changed.
  // Use Case 3: Dates have been applied and changed.
  // *****

  const handleFilterSubmit = () => {
    //if filters or date Filters have changed
    if (
      compareFilterDates(
        filterState,
        prevFilterState,
        dateFilterState,
        prevDateFilterState,
        initialFilterState
      ) === true
    ) {
      setLoader(true);
    } else if (filterMA !== prevFilterMA) {
      setMovingAvgCheck(true);
      setMovingAvgSize(filterMA);
    }
  };
  const handleMovingAvg = useCallback(
    (value: number) => {
      setFilterMA(value);
    },
    [filterMA]
  );
  // *****
  // Clear Filter States
  // *****

  const handleFilterClear = useCallback(() => {
    handleState.reset(initialFilterState);
    handleDateState.resetDate(initialDateFilterState);
    setFilterMA(3);
    setLoader(true);
  }, []);
  function sum(numbers) {
    return _.reduce(numbers, (a, b) => a + b, 0);
  }

  function average(numbers) {
    return Math.ceil(sum(numbers) / movingAvgSize);
  }

  function window(_number, index, array) {
    const start = Math.max(0, index - movingAvgSize);
    const end = Math.min(array.length, index);
    return _.slice(array, start, end);
  }

  function movingAverage(numbers) {
    return _.filter(
      _.chain(numbers).map(window).map(average).value(),
      (number, index) => {
        if (index >= movingAvgSize) {
          return number;
        }
      }
    );
  }

  // *****
  // Moving avg calculated after the initial number of months, this returns placeholder zero values for the initial months
  // *****

  function refineMovingAvgArray(array: number[]): number[] {
    let arr = movingAverage(array);
    if (movingAvgSize === 3) {
      arr.splice(0, 0, 0, 0, 0);
    }
    if (movingAvgSize === 6) {
      arr.splice(0, 0, 0, 0, 0, 0, 0, 0);
    }
    if (movingAvgSize === 9) {
      arr.splice(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0);
    }
    return arr;
  }

  useEffect(() => {
    let subscribe = true;
    if (loader) {
      let request = api.create(token);
      let queryParams = {
        user_id: filterState.user === "" ? null : filterState.user,
        practice_area_id:
          filterState.practiceArea === "" ? null : filterState.practiceArea,
        date_after: filterState.year.startDate,
        date_before: filterState.year.endDate,
      };
      let queryParamsTargetedBills = {
        user_id: filterState.user === "" ? null : filterState.user,
        created_after: filterState.year.startDate,
        created_before: filterState.year.endDate,
      };
      Promise.all([
        request.getBilledTime(firmId, pmType, queryParams),
        request.getTotalTargetedBill(firmId, pmType, queryParamsTargetedBills),
      ])
        .then((response) => {
          if (subscribe) {
            let billedResponse: ResponseObject[] = _.cloneDeep(
              response[0].data
            );

            let targetedbillsResponse = _.cloneDeep(response[1].data);
            // *****
            // Calculating total values and hours for the whole year
            // *****

            let targetedBillHours = targetedbillsResponse[0].total_hours * 12;
            let targetedBillValue = targetedbillsResponse[0].total_value * 12;

            // *****
            // Adding predicted data for the whole year
            // *****
            if (billedResponse.length > 0) {
              let [year, month] =
                billedResponse[billedResponse.length - 1].month.split("-");
              let addForcast: any[] = [];
              let hour =
                (targetedBillHours -
                  billedResponse[billedResponse.length - 1].forecast_hours) /
                (13 - parseInt(month));
              let value =
                (targetedBillValue -
                  billedResponse[billedResponse.length - 1].forecast_values) /
                (13 - parseInt(month));
              let temp: any[] = [];
              for (let i = parseInt(month) + 1; i <= 12; i++) {
                temp = addForcast;
                temp.length == 0
                  ? addForcast.push({
                      total_hours: 0,
                      total_value: 0,
                      month: `${year}-${i}`,
                      forecast_hours:
                        billedResponse[billedResponse.length - 1]
                          .forecast_hours + hour,
                      forecast_values:
                        billedResponse[billedResponse.length - 1]
                          .forecast_values + value,
                    })
                  : addForcast.push({
                      total_hours: 0,
                      total_value: 0,
                      month: `${year}-${i}`,
                      forecast_hours:
                        temp[temp.length - 1].forecast_hours + hour,
                      forecast_values:
                        temp[temp.length - 1].forecast_values + value,
                    });
              }
              let correctedBilledResponse = [...billedResponse, ...addForcast];

              let targetedBilledHoursArray = new Array(
                correctedBilledResponse.length
              ).fill({ value: targetedBillHours / 12 });
              let targetedBilledValuesArray = new Array(
                correctedBilledResponse.length
              ).fill({ value: targetedBillValue / 12 });
              let forcastHours = correctedBilledResponse.map((item) => ({
                value: item.month.includes("01")
                  ? item.forecast_hours
                  : (targetedBillHours - item.forecast_hours) /
                    (13 - parseInt(item.month.split("-")[1])),
              }));
              let forcastValues = correctedBilledResponse.map((item) => ({
                value: item.month.includes("01")
                  ? item.forecast_values
                  : (targetedBillValue - item.forecast_values) /
                    (13 - parseInt(item.month.split("-")[1])),
              }));
              let getBilledHours = getNestedListByPropertyFusion(
                correctedBilledResponse,
                "total_hours"
              );
              let getBilledValues = getNestedListByPropertyFusion(
                correctedBilledResponse,
                "total_value"
              );
              let getMonths = getMonthsFusion(correctedBilledResponse, "month");

              // Remove limit of 18 when date filter is applied
              if (
                dateFilterState.dateAfter !== null &&
                dateFilterState.dateBefore !== null
              ) {
                if (movingAvgSize !== filterMA) {
                  setMovingAvgSize(filterMA);
                  setMovingAvgCheck(true);
                } else {
                  let {
                    refinedHours,
                    predictedHoursAvg,
                    refinedValues,
                    predictedValueAvg,
                  } = calcPredMovAvg(
                    refineMovingAvgArray,
                    getBilledHours,
                    getBilledValues,
                    billedResponse,
                    year,
                    targetedBillHours,
                    sum,
                    targetedBillValue
                  );

                  setMovAvgSeries([
                    _.flatMap(
                      [...refinedHours.slice(0, -2), ...predictedHoursAvg],
                      (item) => ({
                        value: item,
                      })
                    ),
                    _.flatMap(
                      [...refinedValues.slice(0, -2), ...predictedValueAvg],
                      (item) => ({ value: item })
                    ),
                  ]);
                }
                setChart({
                  actualBilled: [getBilledHours, getBilledValues],
                  targetedBilled: [
                    targetedBilledHoursArray,
                    targetedBilledValuesArray,
                  ],
                  forcastedBilled: [forcastHours, forcastValues],
                  months: getMonths,
                });
              } else {
                let billedHours = limitLengthToEighteen(getBilledHours);
                let billedValues = limitLengthToEighteen(getBilledValues);
                let months = limitLengthToEighteen(getMonths);
                let valuesForcasted = limitLengthToEighteen(forcastValues);
                let hoursForcasted = limitLengthToEighteen(forcastHours);
                if (movingAvgSize !== filterMA) {
                  setMovingAvgSize(filterMA);
                  setMovingAvgCheck(true);
                } else {
                  let {
                    refinedHours,
                    predictedHoursAvg,
                    refinedValues,
                    predictedValueAvg,
                  } = calcPredMovAvg(
                    refineMovingAvgArray,
                    billedHours,
                    billedValues,
                    billedResponse,
                    year,
                    targetedBillHours,
                    sum,
                    targetedBillValue
                  );
                  setMovAvgSeries([
                    _.flatMap(
                      [...refinedHours.slice(0, -2), ...predictedHoursAvg],
                      (item) => ({
                        value: item,
                      })
                    ),
                    _.flatMap(
                      [...refinedValues.slice(0, -2), ...predictedValueAvg],
                      (item) => ({ value: item })
                    ),
                  ]);
                }
                setChart({
                  actualBilled: [
                    billedHours.splice(0, currentMonth),
                    billedValues.splice(0, currentMonth),
                  ],
                  months: months,
                  targetedBilled: [
                    limitLengthToEighteen(targetedBilledHoursArray),
                    limitLengthToEighteen(targetedBilledValuesArray),
                  ],
                  forcastedBilled: [hoursForcasted, valuesForcasted],
                });
              }
            } else {
              setChart({
                actualBilled: [],
                months: [],
                forcastedBilled: [],
                targetedBilled: [],
              });
            }
            setTimeout(() => {
              setLoader(false);
            }, 1500);
          }
        })
        .catch((error) => {
          if (subscribe) {
            if (error.code === "ECONNABORTED") {
              setErrorMsg("The API request timed out. Please Refresh the page");
              setError(true);
            } else {
              setErrorMsg(error.response.data.message);
              setError(true);
            }
          }
        });
    }
    return () => {
      subscribe = false;
    };
  }, [loader]);

  useEffect(() => {
    !loader && setLoader(true);
  }, [movingAvgSize]);

  const config = {
    yAxisName: "Hours",
    numberSuffix: " hrs",
  };
  let currentMonth = new Date().getMonth();
  return (
    <Grid item lg={12} md={12} sm={12} xs={12} xl={12}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          {loader ? (
            <ChartLoader />
          ) : (
            <React.Fragment>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <span className={classes.textTitle}>
                  Target, Forecast & Actual Billed Time
                </span>
                {!(filterState.user == "") && (
                  <UserName id={filterState.user} />
                )}
                <span>
                  <InfoButton
                    title={
                      "Monitor or track employee billed hours based on set goals."
                    }
                  />
                  <FilterUserTimePractice
                    filterState={filterState}
                    disabled={loader}
                    handleUser={handleUser}
                    handleYearRange={handleYearRange}
                    handleFilterSubmit={handleFilterSubmit}
                    handleFilterClear={handleFilterClear}
                    handlePracticeAreas={handlePracticeAreas}
                    movingAvg={filterMA}
                    handleMovingAvg={handleMovingAvg}
                  />
                </span>
              </Grid>
              <Grid container justifyContent="center">
                <Grid item>
                  <StyledToggleButtonGroup
                    onChange={(event, value) =>
                      value !== null && setCurrent(value)
                    }
                    exclusive
                    value={current}
                    size="small"
                  >
                    <StyledToggleButton key={1} value={0}>
                      Hour
                    </StyledToggleButton>
                    <StyledToggleButton key={2} value={1}>
                      Value
                    </StyledToggleButton>
                  </StyledToggleButtonGroup>
                </Grid>
              </Grid>

              {chart.actualBilled.length > 0 ||
              chart.targetedBilled.length > 0 ? (
                <Chart
                  type="scrollcombi2d"
                  dataSource={{
                    chart: {
                      placevaluesinside: "1",
                      showvalues: "0",
                      plottooltext: "<b>$dataValue</b> $seriesName",
                      yaxisname:
                        current === 0 ? `Number Of Hours` : `Amount (In USD)`,
                      theme: "fusion",
                    },
                    categories: [
                      {
                        category: chart.months,
                      },
                    ],
                    dataset: [
                      {
                        seriesname: `Actual Billed ${
                          current === 0 ? "Hour" : "Value"
                        }`,
                        renderas: "spline",
                        data: chart.actualBilled[current],
                        color: Colors.blue,
                      },
                      {
                        seriesname: `Forecasted Billed ${
                          current === 0 ? "Hour" : "Value"
                        }`,
                        data: chart.forcastedBilled[current],
                        color: Colors.green,
                      },
                      {
                        seriesname: `Target Billed${
                          current === 0 ? "Hour" : "Value"
                        }`,
                        data: chart.targetedBilled[current],
                        color: Colors.red,
                      },
                    ],

                    annotations: {
                      groups:
                        moment(filterState.year.startDate).year() ===
                        moment().year()
                          ? [
                              {
                                id: "infobar",
                                items: [
                                  {
                                    id: "2",
                                    type: "line",
                                    x: `$dataset.1.set.${currentMonth}.x`,
                                    y: `$dataset.1.set.${currentMonth}.y-120`,
                                    tox: `$dataset.1.set.${currentMonth}.x`,
                                    toy: `$dataset.1.set.${currentMonth}.y`,
                                    color: "#2F9AC4",
                                    dashed: "0",
                                    thickness: "1",
                                  },

                                  {
                                    id: "shape",
                                    type: "polygon",
                                    startangle: "90",
                                    sides: "3",
                                    radius: "6",
                                    color: "2F9AC4",
                                    x: `$dataset.1.set.${currentMonth}.x`,
                                    y: `$dataset.1.set.${currentMonth}.starty`,
                                  },
                                  {
                                    id: "label1",
                                    align: "center",
                                    type: "text",
                                    text: `Moving Avg: ${movAvgSeries[current][currentMonth]?.value}`,
                                    fillcolor: "#2F9AC4",
                                    rotate: "0",
                                    x: `$dataset.1.set.${currentMonth}.x`,
                                    y: `$dataset.1.set.${currentMonth}.starty-10`,
                                  },
                                ],
                              },
                            ]
                          : [],
                    },
                  }}
                  height={400}
                />
              ) : (
                <EmptyPlaceholder />
              )}
            </React.Fragment>
          )}
        </Paper>
      </Grid>
      {error ? <ErrorSnackBar errorMsg={errorMsg} /> : null}
    </Grid>
  );
};

export default TBvsAb;

function calcPredMovAvg(
  refineMovingAvgArray: (array: number[]) => number[],
  billedHours: any,
  billedValues: any,
  billedResponse: ResponseObject[],
  year: string,
  targetedBillHours: number,
  sum: (numbers: any) => any,
  targetedBillValue: number
) {
  let refinedHours = refineMovingAvgArray(
    _.flatMap(billedHours, (item) => item.value)
  );
  let refinedValues = refineMovingAvgArray(
    _.flatMap(billedValues, (item) => item.value)
  );
  let newres = billedResponse
    .filter((item) => item.month.includes(year))
    .map((item) => ({
      value: item.total_value,
      hour: item.total_hours,
    }));
  let sumOfHours =
    (targetedBillHours -
      (sum(newres.map((item) => item.hour)) +
        refinedHours[refinedHours.length - 3])) /
    (11 - newres.length);
  let sumOfValues =
    (targetedBillValue -
      (sum(newres.map((item) => item.value)) +
        refinedValues[refinedHours.length - 3])) /
    (11 - newres.length);

  let predictedHoursAvg = new Array(12 - newres.length).fill(sumOfHours);
  let predictedValueAvg = new Array(12 - newres.length).fill(sumOfValues);
  return { refinedHours, predictedHoursAvg, refinedValues, predictedValueAvg };
}
