import React, { useContext, useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import FilterListIcon from "@material-ui/icons/FilterList";
import InfoIcon from "@material-ui/icons/Info";
import api from "../../../../../services";
import MatTable from "../../../charts/table";
import FilterUserTimePractice from "../../../filters/genericFilter/filters";
import useFilterState from "../../../filters/genericFilter/filterStateHook";
import { FilterClientsMattersTypes } from "../../../filters/genericFilter/types";
import useDateFilterState, {
  DateStateType,
} from "../../../filters/genericFilter/dateFilterStateHook";
import { DateRange } from "materialui-daterange-picker";
import { compareFilterDates } from "../../utils";
import ErrorSnackBar from "../../../commons/SnackBar";
import { TableResponseItem } from "./types";
import useAuth from "../../../../../hooks/useAuth";
export interface ClientTopTensProps {}

const initialFilterState: FilterClientsMattersTypes = {
  practiceArea: "",
  billingMethod: "",
  originatingAtt: "",
  responsibleAtt: "",
  user: "",
  status: "",
};

const initialDateFilterState: DateStateType = {
  dateAfter: null,
  dateBefore: null,
};

const BilledNonBilled: React.FC<ClientTopTensProps> = () => {
  const {
    generalStates: {
      token,
      activeFirm: {
        firm: { _id },
      },
    },
    integrationConfigState: { pmType },
  } = useAuth();

  const [loader, setLoader] = useState<boolean>(true);
  const [table, setTable] = useState<TableResponseItem[]>([]);
  const [error, setError] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const { filterRef, filterState, handleState } =
    useFilterState(initialFilterState);
  const { dateFilterRef, dateFilterState, handleDateState } =
    useDateFilterState(initialDateFilterState);

  useEffect(() => {
    let subscribe = true;
    if (loader) {
      const request = api.create(token);
      let queryParams = {
        practice_area_id:
          filterState.practiceArea === "" ? null : filterState.practiceArea,
        billing_method:
          filterState.billingMethod === "" ? null : filterState.billingMethod,
        originating_attorney_id:
          filterState.originatingAtt === "" ? null : filterState.originatingAtt,
        responsible_attorney_id:
          filterState.responsibleAtt === "" ? null : filterState.responsibleAtt,
        user_id: filterState.user === "" ? null : filterState.user,
        status: filterState.status === "" ? null : filterState.status,
        created_after: dateFilterState.dateAfter,
        created_before: dateFilterState.dateBefore,
      };
      request
        .getBillableVsNonBillableMatters(_id, pmType, queryParams)
        .then((res) => {
          if (subscribe) {
            setTable(res.data);
            setLoader(false);
          }
        })
        .catch((error) => {
          if (subscribe) {
            if (error.code === "ECONNABORTED") {
              setErrorMsg("The API request timed out. Please Refresh the page");
              setError(true);
            } else {
              setErrorMsg(error.response.data.message);
              setError(true);
            }
          }
        });
    }

    return () => {
      subscribe = false;
    };
  }, [loader]);
  const commonCellStyle = {
    color: "#858796",
    fontSize: 14,
    fontWieght: 700,
  };
  const columns = [
    { title: "Name", field: "matter_name", cellStyle: { ...commonCellStyle } },
    {
      title: "Total",
      field: "total_hours",
      render: (row) => <span>{`${row["total_hours"]} hrs`}</span>,
      type: "numeric",
      cellStyle: { ...commonCellStyle },
    },
    {
      title: "Non Billed",
      field: "non_billed_hours",
      render: (row) => <span>{`${row["non_billed_hours"]} hrs`}</span>,
      type: "numeric",
      cellStyle: { ...commonCellStyle },
    },
    {
      title: "Non Billed %",
      field: "non_billed_hours_percentage",
      render: (row) => <span>{`${row["non_billed_hours_percentage"]} %`}</span>,
      cellStyle: { ...commonCellStyle },
      type: "numeric",
    },
    {
      title: "Billed",
      field: "billed_hours",
      render: (row) => <span>{`${row["billed_hours"]} hrs`}</span>,
      cellStyle: { ...commonCellStyle },
      type: "numeric",
    },
    {
      title: "Billed %",
      field: "billed_hours_percentage",
      render: (row) => <span>{`${row["billed_hours_percentage"]} %`}</span>,
      cellStyle: { ...commonCellStyle },
      type: "numeric",
    },
  ];
  const handlePracticeAreas = (value: number | "") => {
    handleState.handlePracticeArea(value);
  };

  const handleBillingMethod = (value: string) => {
    handleState.handleBillingMethod(value);
  };
  const handleStatus = (value: string) => {
    handleState.handleStatus(value);
  };

  const handleOriginatingAtt = (value: number | "") => {
    handleState.handleOriginatingAtt(value);
  };

  const handleResponsibleAtt = (value: number | "") => {
    handleState.handleResponsibleAtt(value);
  };
  const handleUser = (value: number | "") => {
    handleState.handleUser(value);
  };

  const prevDateFilterState = dateFilterRef.current;
  const prevFilterState = filterRef.current;

  const handleDateFilterRange = (dateRange: DateRange): void => {
    handleDateState.handleDateUpdate(dateRange);
  };

  // *****
  // Filter Submit - Leveraging Closure and invoking utility function compareFilterDates
  // Use Case: If any of the filter state has changed and date has not been applied, invoke action.
  // Use Case: both date values have changed with second greater than the first, invoke action.
  // *****

  const handleFilterSubmit = () => {
    if (
      compareFilterDates(
        filterState,
        prevFilterState,
        dateFilterState,
        prevDateFilterState,
        initialFilterState
      ) === true
    ) {
      setLoader(true);
    }
  };

  // *****
  // Clear Filter States
  // *****

  const handleFilterClear = () => {
    handleState.reset(initialFilterState);
    handleDateState.resetDate(initialDateFilterState);
    setLoader(true);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FilterUserTimePractice
          filterState={filterState}
          dateFilterState={dateFilterState}
          disabled={loader}
          handleDateFilterRange={handleDateFilterRange}
          handleFilterSubmit={handleFilterSubmit}
          handleFilterClear={handleFilterClear}
          handlePracticeAreas={handlePracticeAreas}
          handleBillingMethod={handleBillingMethod}
          handleOriginatingAtt={handleOriginatingAtt}
          handleResponsibleAtt={handleResponsibleAtt}
          handleUser={handleUser}
          handleStatus={handleStatus}
          handleFromComponent={open}
          setCloseFromComponent={() => setOpen(false)}
        />

        <MatTable
          data={table}
          column={columns}
          isLoading={loader}
          search
          actions={[
            {
              icon: () => <InfoIcon />,
              isFreeAction: true,
              tooltip:
                "Track time billed compared to accrued time entries that are not billed. " +
                "This shows accrued time that is yet to be billed or has been inadvertently not billed." +
                " Measure team productivity not on billed time alone but also on accrued time. " +
                "This is helpful for matters that are billed at the end of representation.",
            },
            {
              icon: () => <FilterListIcon />,
              isFreeAction: true,
              onClick: (event, rowData) => {
                setOpen(!open);
              },
              tooltip: "Filters",
            },
          ]}
          title={"Billable vs Non Billable Matters"}
        />
      </Grid>
      {error ? <ErrorSnackBar errorMsg={errorMsg} /> : null}
    </Grid>
  );
};

export default BilledNonBilled;
