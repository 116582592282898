import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Checkbox, Link, FormControlLabel } from "@material-ui/core";
import Recaptcha from "react-recaptcha";

const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: theme.spacing(1, 0),
  },
  total: {
    fontWeight: 700,
  },
  title: {
    marginTop: theme.spacing(2),
  },
  font: {
    fontSize: "12px",
  },
  linkWrapper: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  link: {
    textDecoration: "none",
    color: theme.palette.text.secondary,
    fontSize: "1em",
    marginLeft: -20,
    fontWeight: 500,
    [theme.breakpoints.up("xl")]: {
      fontSize: "1.1em",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.8em",
    },
  },
  recaptcha: {
    margin: theme.spacing(2, 0),
  },
}));

export default function Review(props) {
  const classes = useStyles();

  const recaptchaCallback = () => {
    console.log("captcha successfully loaded");
  };

  return (
    <React.Fragment>
      <Grid container direction="row" justifyContent="center" alignItems="center">
        <Grid item xs={12} className={classes.recaptcha}>
          <Recaptcha
            sitekey="6Lers98UAAAAAIuSIHIYJdVDooUgLWIX6tsSSY9H"
            verifyCallback={(res) => {
              if (res) props.setRecaptcha(res);
            }}
            render="explicit"
            onloadCallback={recaptchaCallback}
          />
        </Grid>
        <Grid item xs={12} className={classes.linkWrapper}>
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={props.privacy}
                onChange={() => props.handlePrivacy(!props.privacy)}
                value="secondary"
                color="primary"
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
            }
            label=" "
            className={classes.font}
          />
          <Link
            href="https://www.firmtrak.com/privacy-policy/"
            className={classes.link}
            rel="noopener"
            target="_blank"
          >
            I have read and agreed to the Privacy Terms
          </Link>
        </Grid>
        <Grid xs={12} className={classes.linkWrapper}>
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                value="secondary"
                color="primary"
                checked={props.termsConditions}
                onChange={() =>
                  props.handleTermsConditions(!props.termsConditions)
                }
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
            }
            label=" "
            className={classes.font}
          />
          <Link
            href="https://www.firmtrak.com/terms/"
            className={classes.link}
            rel="noopener"
            target="_blank"
          >
            I have read and agreed to the Terms & Conditions
          </Link>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
