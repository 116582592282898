import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import useDateFilterState, {
  DateStateType,
} from "../../../filters/genericFilter/dateFilterStateHook";
import ChartLoader from "../../../commons/loaders/chartLoader";
import FilterUserTimePractice from "../../../filters/genericFilter/filters";
import EmptyPlaceholder from "../../../commons/placeholders/emptyPlaceholder";
import ErrorSnackBar from "../../../commons/SnackBar";
import {
  limitLengthToEighteen,
  checkDate,
  getMonthsFusion,
  getAmountFusion,
} from "../../utils";
import { DateRange } from "materialui-daterange-picker";
import { Paper, Grid } from "@material-ui/core";
import clsx from "clsx";
import _ from "lodash";

import { useMarketingExpenseApi } from "../../MarketingExpenseHook";
import Chart from "../../../charts/fusionCharts";
import InfoButton from "../../../infoButton";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "visible",
    flexDirection: "column",
    justifyContent: "center",
  },
  lineAreaHeight: {
    height: 500,
  },
  radialHeight: {
    height: 350,
  },
  textTitle: {
    color: theme.palette.text.primary,
    fontSize: "1.2em",
    fontWeight: 600,
  },
  filterWrapper: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  filterInner: {
    marginLeft: theme.spacing(1),
  },
  headingText: {
    fontSize: "1.2em",
    fontWeight: 600,
    color: theme.palette.text.secondary,
  },
  divider: {
    backgroundColor: "#EDEFF7",
    height: 1,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export interface ClientGrowthTrendsAreaProps {}

// *****
// Initial Filter State
// *****

const initialDateFilterState: DateStateType = {
  dateAfter: null,
  dateBefore: null,
};

const ClientGrowthTrendsArea: React.FC<ClientGrowthTrendsAreaProps> = () => {
  const [skeleton, setSkeleton] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string | null>();
  const [
    marketingState,
    handleInvoke,
    handleFilters,
  ] = useMarketingExpenseApi();
  const [months, setMonths] = useState<string[]>([]);
  const [marketingExpense, setmarketingExpense] = useState<number[]>([]);

  // *****
  // Conditionally render useEffect on Filtered Area Chart
  // *****

  // *****
  // Current Values of Filters
  // *****

  // *****
  // Previous Values of Filters
  // *****

  // *****
  // Current Values of Date Filters
  // *****

  const {
    dateFilterRef,
    dateFilterState,
    handleDateState,
  } = useDateFilterState(initialDateFilterState);

  // *****
  // Previous Values of Date Filters
  // *****

  const prevDateFilterState = dateFilterRef.current;

  const classes = useStyles();
  const lineArea = clsx(classes.paper, classes.lineAreaHeight);

  const commClientSeries = [
    {
      name: "Marketing Expense",
      renderas: "area",
      showanchors: "0",
      data: marketingExpense,
    },
  ];

  // *****
  // Filter state updates
  // *****

  const handleDateFilterRange = (dateRange: DateRange): void => {
    handleDateState.handleDateUpdate(dateRange);
    handleFilters(dateRange);
  };

  // *****
  // Filter Submit
  // Use Case 1: if moving average is the only one that has changed and all others have not, then invoke moving avg effect
  // Use Case 2: If dates have been applied, then check for date consistency.
  // Use Case 3: All filters have been applied and changed.
  // *****

  const handleFilterSubmit = () => {
    //if filters or date Filters have changed
    if (_.isEqual(dateFilterState, prevDateFilterState) === false) {
      //nested - then check if both date parameters are applied with second greater than the first
      if (
        checkDate(dateFilterState.dateAfter, dateFilterState.dateBefore) ===
        true
      ) {
        setSkeleton(true);
        //nested - or if none of them are applied
      } else if (
        dateFilterState.dateAfter === null &&
        dateFilterState.dateBefore === null
      ) {
        setSkeleton(true);
      }
    }
  };

  // *****
  // Clear Filter States
  // *****
  const handleFilterClear = () => {
    handleDateState.resetDate(initialDateFilterState);
    setSkeleton(true);
  };
  // *****
  // Set Marketing Expense flag to true, if integration state is "CLIO_XERO"
  // *****

  // *****
  //  Marketing Expense flag runs this effect to calculate expense data
  // *****

  useEffect(() => {
    if (marketingState.response.status > 0 && !marketingState.error.status) {
      let marketingData = _.cloneDeep(marketingState.response.data);
      let totalList = getAmountFusion(limitLengthToEighteen(marketingData));
      let getMonths = limitLengthToEighteen(
        getMonthsFusion(marketingData, "month")
      );
      setMonths(getMonths);
      setmarketingExpense(totalList);
      setSkeleton(false);
    } else if (marketingState.error.status) {
      setError(true);
      setSkeleton(false);
      setErrorMsg(marketingState.error.message);
    }
  }, [marketingState]);

  useEffect(() => {
    let subscribe = true;
    if (subscribe) {
      skeleton && handleInvoke();
    }

    return () => {
      subscribe = false;
    };
  }, [skeleton]);

  // *****
  // Update Moving Average and rerender
  // *****
  const config = {
    numberprefix: "$",
  };
  return (
    <Grid item lg={12} md={12} sm={12} xs={12} xl={12}>
      <Paper className={lineArea}>
        {skeleton ? (
          <ChartLoader />
        ) : (
          <React.Fragment>
            <Grid container spacing={2} justifyContent="space-between">
              <span className={classes.textTitle}>Marketing Expense</span>
              <span>
                <InfoButton title={"Marketing Expense"} />
                <FilterUserTimePractice
                  dateFilterState={dateFilterState}
                  filterState={{}}
                  disabled={skeleton}
                  handleFilterClear={handleFilterClear}
                  handleFilterSubmit={handleFilterSubmit}
                  handleDateFilterRange={handleDateFilterRange}
                />
              </span>
            </Grid>
            {marketingExpense.length > 0 ? (
              <Chart
                type="mscombi2d"
                config={config}
                labels={months}
                series={commClientSeries}
                height={400}
              />
            ) : (
              <EmptyPlaceholder />
            )}
          </React.Fragment>
        )}
      </Paper>
      {error ? <ErrorSnackBar errorMsg={errorMsg} /> : null}
    </Grid>
  );
};

export default ClientGrowthTrendsArea;
