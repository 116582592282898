import React, { useState, useContext } from "react";

import { MatterTracker } from "../../grids/matterTracker";
import { StyledToggleButtonGroup, StyledToggleButton } from "../styledTabs";
import { makeStyles } from "@material-ui/core/styles";
import { WindowContext } from "../../../../window/trackWindow";
import Grid from "@material-ui/core/Grid";
import useAuth from "../../../../hooks/useAuth";
import { PlaceholderAcl } from "../../../commons/placeholderAcl";

const useStyles = makeStyles((theme) => ({
  headingText: {
    fontSize: "1.5em",
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.text.primary,
  },
  divider: {
    marginBottom: theme.spacing(1),
  },
}));

export interface MattersTabProps {}

const MattersTab: React.FC<MattersTabProps> = () => {
  const window = useContext(WindowContext);
  const [active, setActive] = useState(1);
  const classes = useStyles();
  const {
    generalStates: { acl },
  } = useAuth();

  const handleChange = (event, value) => {
    if (value !== null) {
      setActive(value);
    }
  };

  return (
    <Grid container spacing={4} justifyContent="space-between">
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <span className={classes.headingText}>Matter Tracker</span>
      </Grid>
      {/* <StyledToggleButtonGroup
        onChange={handleChange}
        exclusive
        value={active}
        size="small"
        style={{ fontSize: window < 600 ? 11 : 13 }}
      >
        <StyledToggleButton key={1} value={1}>
          Engagement
        </StyledToggleButton>
        <StyledToggleButton key={2} value={2}>
          Progress
        </StyledToggleButton>
      </StyledToggleButtonGroup> */}
      <Grid item xs={12}>
        {!acl?.matterTracker && <PlaceholderAcl />}
        {acl?.matterTracker && <MatterTracker  />}
      </Grid>
    </Grid>
  );
};

export default MattersTab;
