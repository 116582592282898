import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Loader from "./loader";

export interface DashboardLoaderProps {}

const useStyles = makeStyles((theme) => ({
  circularLoader: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "80vh",
    [theme.breakpoints.down("xs")]: {
      minHeight: "70vh",
    },
    [theme.breakpoints.up("lg")]: {
      minHeight: "85vh",
    },
  },
  loaderText: {
    display: "inline-block",
    fontSize: "1.3em",
    fontWeight: 500,
    marginTop: theme.spacing(2),
  },
}));

const DashboardLoader: React.FC<DashboardLoaderProps> = () => {
  const classes = useStyles();

  return (
    <div className={classes.circularLoader}>
      <Loader />
      <span className={classes.loaderText}>Loading Content</span>
    </div>
  );
};

export default DashboardLoader;
