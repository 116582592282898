import React, { useContext, useEffect, useState, createContext } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import _ from "lodash";
import { useRouteMatch, Redirect, Switch } from "react-router-dom";
import ARGrid from "../grids/AR";
import ClientTab from "./inner/clientsTab";
import MattersTab from "./inner/mattersTab";
import FinancialsTab from "./inner/financialsTab";
import MatterTrackerTab from "./inner/matterTrackerTab";
import ProductivityTab from "./inner/productivityTabs";
import PrivateRoute from "../../../router/PrivateRoute";
import api from "../../../services/index";
import TrialExpiry from "../commons/trialExpiry";
import IntegrationPlaceholder from "../commons/placeholders/IntegrationPlaceholder";
import SyncPlaceholder from "../commons/placeholders/SyncPlaceholder";
import Trust from "../grids/trust";
import Home from "../grids/home";
import Lawmatics from "../grids/lawmatics";
import CustomReports from "../grids/customReports";


import useAuth from "../../../hooks/useAuth";
// *****
// Single user object - api getUsers
// *****

interface UserItemType {
  createdAt?: string;
  created_at?: string;
  email?: string;
  enabled?: number;
  etag?: string;
  firm_integration_id?: string;
  first_name?: string;
  hours_per_month?: number;
  id: number;
  is_calculated?: string;
  last_name?: string;
  name: string;
  phone_number?: string;
  rate: number;
  subscription_type: "Attorney" | "NonAttorney";
  target_hours_per_month?: number;
  updatedAt?: string;
  updated_at?: string;
}

// *****
// Single Practice Area object - api getPracticeAreas
// *****

interface PracticeAreaItem {
  id: number;
  name: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(6),
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
  },
  appBar: {
    backgroundColor: "#ffff",
    marginBottom: theme.spacing(2),
    position: "sticky",
    top: theme.spacing(8),
  },
}));

const FilterContext = createContext({
  practiceAreas: [] as any,
  attorneys: [] as any,
  users: [] as any,
});

// *****
// Select Attorneys from user list
// *****

interface AttorneyItem {
  id: number;
  name: string;
}

function extractAttorneys(userList: UserItemType[]) {
  let attorneyArr: AttorneyItem[] = [];
  _.map(_.filter(userList, { subscription_type: "Attorney" }), (item) => {
    attorneyArr.push({
      id: item.id,
      name: item.name,
    });
  });
  return attorneyArr;
}

// *****
// Normalize all users list objects(making them filter compatible)
// *****

interface UserArrayItem {
  id: number;
  name: string;
  type: "Attorney" | "NonAttorney";
}

function normalizeAllUsers(userList: UserItemType[], pmType) {
  let usersArray: UserArrayItem[] = [];
  _.map(userList, (item) => {
    let clio = {
      id: item.id,
      name: item.name,
      type: item.subscription_type,
    };
    let pp = {
      id: item.id,
      name: item.display_name,
      type: item.subscription_type,
    };
    item.is_calculated == 1 && usersArray.push(pmType == "clio" ? clio : pp);
  });
  return usersArray;
}

function TabPanel() {
  const classes = useStyles();
  const {
    generalStates: {
      // paymentInfo: { subscribedXero, subscribedClio },
      user,
      activeFirm,
      token,
    },
    currentAppState: {
      clioHasDuplicate,
      status: { code, clioStatus, ppStatus, xeroStatus },
    },
    integrationConfigState: {
      integrationConfig,
      pmType,
      integrationObject: {
        clients,
        matters,
        productivity,
        financials,
        matterTracker,
        AR,
        trust,
        dashboard,
        lawmatics,
        customReports
      },
    },
  } = useAuth();
  const [loading, setLoading] = useState(true);
  const [practiceAreas, setPracticeAreas] = useState([] as any);
  const [attorneys, setAttorneys] = useState([] as any);
  const [users, setUsers] = useState([] as any);
  const { path } = useRouteMatch();
  const userType = user.userType;
  const firmId = activeFirm?.firm?._id;

  // *****
  // Filter API calls
  // *****

  useEffect(() => {
    let subscribe = true;
    if (loading && (clioStatus != 0 || ppStatus != 0) && !clioHasDuplicate) {
      let request = api.create(token);
      Promise.all([
        request.getPracticeAreas(firmId, pmType),
        request.getUsers(firmId, pmType),
      ])
        .then((responsesArray) => {
          if (subscribe) {
            let practiceAreas = responsesArray[0].data;
            let allUsers = responsesArray[1].data;
            let attorneyList = extractAttorneys(allUsers);
            let usersList = normalizeAllUsers(allUsers, pmType);
            console.log(usersList);
            setPracticeAreas(practiceAreas);
            setAttorneys(attorneyList);
            setUsers(usersList);
            setLoading(false);
          }
        })
        .catch((error) => {
          if (subscribe) {
          }
        });
    }
    return () => {
      subscribe = false;
    };
  }, [loading, clioHasDuplicate]);
  useEffect(() => {
    if (!loading) setLoading(true);
  }, [pmType]);
  console.log();
  return (
    <div className={classes.root}>
      {(code === "200" ||
        code === "020" ||
        code === "002" ||
        code === "042" ||
        code === "402") &&
        userType !== "SUPER_ADMIN" ? (
        <TrialExpiry />
      ) : (code === "030" || code === "300") && userType !== "SUPER_ADMIN" ? (
        <SyncPlaceholder />
      ) : integrationConfig === "NONE" && !clioHasDuplicate ? (
        <IntegrationPlaceholder />
      ) : clioHasDuplicate && integrationConfig == "NONE" ? (
        <SyncPlaceholder />
      ) : (
        <React.Fragment>
          <FilterContext.Provider
            value={{
              practiceAreas: practiceAreas,
              attorneys: attorneys,
              users: users,
            }}
          >
            <Switch>
              {dashboard.state && (
                <PrivateRoute path={`${path}/home`} component={Home} />
              )}
              {clients.state && (
                <PrivateRoute path={`${path}/clients`} component={ClientTab} />
              )}
              {matters.state && (
                <PrivateRoute path={`${path}/matters`} component={MattersTab} />
              )}
              {financials.state && (
                <PrivateRoute
                  path={`${path}/financials`}
                  component={FinancialsTab}
                />
              )}
              {productivity.state && (
                <PrivateRoute
                  path={`${path}/productivity`}
                  component={ProductivityTab}
                />
              )}
              {matterTracker.state && (
                <PrivateRoute
                  exact
                  path={`${path}/matter-tracker`}
                  component={MatterTrackerTab}
                />
              )}
              {AR.state && (
                <PrivateRoute exact path={`${path}/a-r`} component={ARGrid} />
              )}
              {trust.state && (
                <PrivateRoute exact path={`${path}/trust`} component={Trust} />
              )}
              {lawmatics.state && (
                <PrivateRoute
                  exact
                  path={`${path}/lawmatics`}
                  component={Lawmatics}
                />
              )}
              {
                customReports.state && (
                  <PrivateRoute
                    exact
                    path={`${path}/custom-reports`}
                    component={CustomReports}
                  />
                )
              }
              {/* 
              <PrivateRoute
                path={`${path}/reconciliations`}
                component={ReconciliationsTab}
              /> */}
              <Redirect
                from={`${path}`}
                to={`${path}${dashboard.state ? "/home" : "/financials"}`}
              />
            </Switch>
          </FilterContext.Provider>
        </React.Fragment>
      )}
    </div>
  );
}

export { FilterContext, TabPanel };
