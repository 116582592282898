import { Grid, IconButton, makeStyles, Paper } from "@material-ui/core";
import InfoButton from "../../infoButton";
import images from "../../../../images";
import { useHistory } from "react-router";
const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3),
    fontFamily: "Open Sans",
    alignItems: "center",
    flex: 1,
    width: "100%",
    height: "100%",
  },
  image: {
    // marginRight: theme.spacing(8),
  },
  value: {
    fontSize: theme.typography.pxToRem(32),
    fontWeight: "bold",
    color: theme.palette.secondary.dark,
    margin: 0,
  },
  title: {
    margin: 0,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.secondary.dark,
  },
  prefix: {
    fontSize: theme.typography.pxToRem(18),
  },
}));
export default function Widget({ image, value, title, id, link, info }) {
  const history = useHistory();
  const classes = useStyles();
  return (
    <Grid item xs={12} sm={4} md={4} lg>
      <Paper square className={classes.container}>
        <div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <img className={classes.image} src={image} />
            <span>
              <InfoButton title={info} />
              <IconButton
                onClick={() => history.push(link)}
                style={{ padding: 0, margin: 0 }}
              >
                <img src={images.linkIcon} />
              </IconButton>
            </span>
          </div>
          {id === 1 && (
            <>
              <p className={classes.value}>
                <span className={classes.prefix}>{`${value.split(" ")[0]
                  } `}</span>
                {value.split(" ")[1]}
                <span className={classes.prefix}>{value.split(" ")[2]}</span>
              </p>
              <p className={classes.title}>{title}</p>
            </>
          )}

          {id === 2 && (
            <>
              <p className={classes.value}>
                {value}
                <span className={classes.prefix}> %</span>
              </p>
              <p className={classes.title}>{title}</p>
            </>
          )}
          {id === 3 && (
            <>
              <p className={classes.value}>{value}</p>
              <p className={classes.title}>{title}</p>
            </>
          )}
        </div>
      </Paper>
    </Grid>
  );
}
