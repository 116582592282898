import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { makeStyles, Theme, alpha } from "@material-ui/core/styles";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import CircularProgress from "@material-ui/core/CircularProgress";
import ErrorSnackBar from "../../../dashboard/commons/SnackBar";
import api from "../../../../services/index";
import { useHistory } from "react-router-dom";
import { StyledInput } from "./styledInput";
import { InputLabel } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import useAuth from "../../../../hooks/useAuth";
export interface StripeInputProps {
  current?: string | any;
}

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    display: "flex",
    alignItems: "center",
    height: 420,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      height: 800,
    },
  },
  innerWrapper: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  stripeInput: {
    paddingBottom: theme.spacing(1) + 4,
    paddingTop: theme.spacing(1) + 4,
    marginTop: theme.spacing(1),
    border: "solid",
    borderRadius: theme.spacing(1) / 2,
    borderColor: "#dadaed",
    borderWidth: 0.5,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
  button: {
    backgroundColor: "#6772E5",
    "&:hover": {
      backgroundColor: "#7581ea",
    },
    color: "#fff",
    paddingRight: theme.spacing(10),
    paddingLeft: theme.spacing(10),
  },
  buttonWrapper: {
    position: "relative",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "80%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

// *****
// Stripe Input Fields State types
// *****

interface StripeFormStateType {
  name: string;
  city: string;
  state: string;
  address: string;
  zipCode: string;
  phoneNumber: string;
}

const cardElementOptions = {
  style: {
    base: {
      fontSize: "16px",
      color: "#424770",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#9e2146",
    },
  },
};

const StripeInput: React.FC<StripeInputProps> = ({ current }) => {
  const integrationType = current.toUpperCase();
  const classes = useStyles();
  const {
    generalStates: {
      activeFirm: {
        firm: { clio, xero, _id },
      },
      token,
    },
    generalActions,
    getStatus,
  } = useAuth();
  const stripe: any = useStripe();
  const elements: any = useElements();
  const history = useHistory();
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [getSubscriptionUpdate, setGetSubscriptionUpdate] =
    useState<boolean>(false);
  const [successMsg, setSuccessMsg] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [emailError, setEmailError] = useState<boolean>(false);
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
  const [stripeForm, setStripeForm] = useState<StripeFormStateType>({
    name: "",
    city: "",
    state: "",
    address: "",
    zipCode: "",
    phoneNumber: "",
  });

  // *****
  // Check Email Input Validity
  // *****

  useEffect(() => {
    let Regex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    setEmailError(
      Regex.test(email.length > 0 ? email : "test@test.com") ? false : true
    );
  }, [email]);

  // *****
  // Check Form Submission Validity
  // *****

  useEffect(() => {
    if (
      stripeForm.name.length > 0 &&
      stripeForm.city.length > 0 &&
      stripeForm.state.length > 0 &&
      stripeForm.address.length > 0 &&
      stripeForm.zipCode.length > 0 &&
      stripeForm.phoneNumber.length > 0
    ) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [stripeForm]);

  // *****
  // Stripe get updated payment info
  // *****
  useEffect(() => {
    let subscribe = true;
    if (getSubscriptionUpdate) {
      let request = api.create(token);
      request
        .getStripeAllSubscription(_id)
        .then((response) => {
          if (subscribe) {
            const { CLIO, XERO, PP } = response.data;
            getStatus();
            generalActions.setPaymentInfo({
              subscribedClio: CLIO,
              subscribedXero: XERO,
              subscribedPP: PP,
            });
            setIsProcessing(false);
            setGetSubscriptionUpdate(false);
            history.replace("/dashboard/settings/payments");
          }
        })
        .catch((error) => {
          if (subscribe) {
            if (error.code === "ECONNABORTED") {
              setErrorMsg("The API request timed out. Please Refresh the page");
              setError(true);
            } else {
              setErrorMsg(error.response.data.message);
              setError(true);
            }
          }
        });
    }
    return () => {
      subscribe = false;
    };
  }, [getSubscriptionUpdate]);

  // *****
  // Stripe Form Submit
  // *****

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsProcessing(true);

    // *****
    // Stripe createPaymentRequest object info
    // *****

    const billingInfo = {
      name: stripeForm.name,
      email: email,
      address: {
        city: stripeForm.city,
        line1: stripeForm.zipCode,
        state: stripeForm.state,
        postal_code: stripeForm.zipCode,
      },
    };
    //get stripe card element instantiated object
    const cardElement = elements.getElement(CardElement);

    //instantiate a payment request, retrieve payment method id.
    const paymentMethodRequest = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: billingInfo,
    });

    if (typeof paymentMethodRequest.error !== "undefined") {
      setError(true);
      setIsProcessing(false);
      setErrorMsg(paymentMethodRequest.error.message);
    } else {
      //Create Post request and pass id.
      let request = api.create(token);
      request
        .postStripeSubscription(_id, integrationType, {
          payment_method: paymentMethodRequest.paymentMethod.id,
          email: email,
        })
        .then((response) => {
          let data = response.data;
          if (data.actionRequired === false) {
            //when api response results to no action required
            setSuccessMsg(data.msg);
            setGetSubscriptionUpdate(true);
          } else if (
            data.actionRequired === true &&
            data.clientSecret !== null
          ) {
            //when api response results to action required, create a stripe card confirmation and pass in the clientSecret and Payment method
            stripe
              .confirmCardPayment(
                data.clientSecret,
                paymentMethodRequest.paymentMethod.id
              )
              .then((confirmedCardPayment) => {
                let status = confirmedCardPayment.paymentIntent.status;
                if (status === "succeeded") {
                  // if payment has succeeded update the subsription status
                  setGetSubscriptionUpdate(true);
                }
              })
              .catch((error) => {
                setIsProcessing(false);
                if (error.code === "ECONNABORTED") {
                  setErrorMsg(
                    "The API request timed out. Please Refresh the page"
                  );
                  setIsProcessing(false);
                  setError(true);
                } else {
                  setErrorMsg(error.response.data.message);
                  setIsProcessing(false);
                  setError(true);
                }
              });
          }
        })
        .catch((error) => {
          if (error.code === "ECONNABORTED") {
            setErrorMsg("The API request timed out. Please Refresh the page");
            setIsProcessing(false);
            setError(true);
          } else {
            setErrorMsg(error.response.data.message);
            setIsProcessing(false);
            setError(true);
          }
        });
    }
  };

  return (
    <React.Fragment>
      <Paper className={classes.paper}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <InputLabel>Name</InputLabel>
                <StyledInput
                  id="stripe-form-name"
                  fullWidth
                  onChange={(e) => {
                    setStripeForm({
                      ...stripeForm,
                      name: e.target.value,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel>Email</InputLabel>
                <StyledInput
                  id="stripe-form-email"
                  fullWidth
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel>Phone Number</InputLabel>
                <StyledInput
                  id="stripe-form-number"
                  fullWidth
                  onChange={(e) => {
                    setStripeForm({
                      ...stripeForm,
                      phoneNumber: e.target.value,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel>Address</InputLabel>
                <StyledInput
                  id="stripe-form-address"
                  fullWidth
                  onChange={(e) => {
                    setStripeForm({
                      ...stripeForm,
                      address: e.target.value,
                    });
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <InputLabel>City</InputLabel>
                <StyledInput
                  id="stripe-form-city"
                  fullWidth
                  onChange={(e) => {
                    setStripeForm({
                      ...stripeForm,
                      city: e.target.value,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel>State</InputLabel>
                <StyledInput
                  id="stripe-form-state"
                  fullWidth
                  onChange={(e) => {
                    setStripeForm({
                      ...stripeForm,
                      state: e.target.value,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel>Zip Code</InputLabel>
                <StyledInput
                  id="stripe-form-zip-code"
                  fullWidth
                  onChange={(e) => {
                    setStripeForm({
                      ...stripeForm,
                      zipCode: e.target.value,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel>Stripe Payment</InputLabel>
                <div className={classes.stripeInput}>
                  <CardElement options={{ ...cardElementOptions }} />
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              onClick={handleSubmit}
              className={classes.button}
              disabled={buttonDisabled || emailError}
            >
              {!isProcessing ? (
                "Pay Now"
              ) : (
                <div>
                  Processing
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                    color="secondary"
                  />
                </div>
              )}
            </Button>
          </Grid>
        </Grid>
      </Paper>
      {error ? <ErrorSnackBar errorMsg={errorMsg} /> : null}
    </React.Fragment>
  );
};

export default StripeInput;
