import { gridConfig } from "../../config/gridConfig/gridConfig";
// *****
// State used to configure grids and charts according to active integrations
// *****
interface IntegrationsConfigStateTypes {
  integrationObject: any;
  integrationConfig: any;
  pmType: string;
}
export const integrationConfigState: IntegrationsConfigStateTypes = {
  integrationObject: null,
  integrationConfig: null,
  pmType: "",
};

export const integrationConfigReducer = (state, action) => {
  switch (action.type) {
    case "SET_NO_INTEGRATION":
      return {
        ...state,
        integrationObject: gridConfig.none,
        integrationConfig: "NONE",
      };
    case "SET_CLIO_INTEGRATION":
      return {
        ...state,
        integrationObject: gridConfig.clio,
        integrationConfig: "CLIO",
      };
    case "SET_XERO_INTEGRATION":
      return {
        ...state,
        integrationObject: gridConfig.xero,
        integrationConfig: "XERO",
      };
    case "SET_CLIO_XERO_INTEGRATION":
      return {
        ...state,
        integrationObject: gridConfig.clioAndXero,
        integrationConfig: "CLIO_XERO",
      };
    case "SET_PM_TYPE":
      return {
        ...state,
        pmType: action.data,
      };
    default:
      return state;
  }
};
