import { GridConfig } from "./types";
import React from "react";
import {
  ClientGrowthTrendsAreaClio,
  ClientGrowthTrendsRadial,
  ClientGrowthThrendsStackedBar,
} from "../../components/dashboard/grids/clients/growthTrends";
import { ClientTopWorstInvoice } from "../../components/dashboard/grids/clients/invoices";
import { ClientTopWorstCollected } from "../../components/dashboard/grids/clients/revenue";
import { ClientTopWorstDues } from "../../components/dashboard/grids/clients/ar";
import {
  MattersGrowthTrendsAreaClio,
  MattersGrowthTrendsRadial,
  MattersGrowthTrendsStackedBar,
} from "../../components/dashboard/grids/matters/growthTrends";
import { MattersTopWorstInvoice } from "../../components/dashboard/grids/matters/invoices";
import { MattersTopWorstCollected } from "../../components/dashboard/grids/matters/revenue";
import { MattersTopWorstDues } from "../../components/dashboard/grids/matters/ar";
import {
  TBvsAb,
  CumulativeTBvsAB,
  WorkedBilledCollectedRadial,
  Utilization,
  HeatMap,
} from "../../components/dashboard/grids/productivity/growthTrends";
import { ProductivityTopTensAOP } from "../../components/dashboard/grids/productivity/aop";
import { ProductivityTopTensUsers } from "../../components/dashboard/grids/productivity/users";
import {
  FinancialsGrowthTrendsBillExpColl,
  FinancialsGrowthTrendsSalesCashflow,
  FinancialsGrowthThrendsCashflow,
  FinancialsGrowthTrendsNetIncomeMargin,
  FinancialsGrowthTrendsExpenseOverheadsSalaries,
} from "../../components/dashboard/grids/financials/growthTrends";
import IntegrationPlaceholder from "../../components/dashboard/commons/placeholders/IntegrationPlaceholder";

/*
  Grid configuration according to active integration type
  State = used to block or display routes
  [] = used to map components within subTabs(eg growth & trend)
*/

export const gridConfig: GridConfig = {
  none: {
    dashboard: {
      state: true,
    },
    clients: {
      state: true,
      growthTrends: [<IntegrationPlaceholder />],
      invoices: [<IntegrationPlaceholder />],
      revenue: [<IntegrationPlaceholder />],
      ar: [<IntegrationPlaceholder />],
    },
    matters: {
      state: true,
      growthTrends: [<IntegrationPlaceholder />],
      invoices: [<IntegrationPlaceholder />],
      revenue: [<IntegrationPlaceholder />],
      ar: [<IntegrationPlaceholder />],
      billedNonBilled: [<IntegrationPlaceholder />],
    },
    productivity: {
      state: true,
      growthTrends: [<IntegrationPlaceholder />],
      aop: [<IntegrationPlaceholder />],
      users: [<IntegrationPlaceholder />],
    },
    matterTracker: {
      state: true,
    },
    AR: {
      state: true,
    },
    trust: {
      state: true,
    },
    financials: {
      state: true,
      growthTrends: [<IntegrationPlaceholder />],
    },
    lawmatics: {
      state: true,
    },
    customReports: {
      state: true
    }
  },
  clio: {
    dashboard: {
      state: true,
    },
    clients: {
      state: true,
      growthTrends: [
        <ClientGrowthTrendsAreaClio />,
        <ClientGrowthTrendsRadial />,
        <ClientGrowthThrendsStackedBar />,
      ],
      invoices: [<ClientTopWorstInvoice />],
      revenue: [<ClientTopWorstCollected />],
      ar: [<ClientTopWorstDues />],
    },
    matters: {
      state: true,
      growthTrends: [
        <MattersGrowthTrendsAreaClio />,
        <MattersGrowthTrendsRadial />,
        <MattersGrowthTrendsStackedBar />,
      ],
      invoices: [<MattersTopWorstInvoice />],
      revenue: [<MattersTopWorstCollected />],
      ar: [<MattersTopWorstDues />],
      billedNonBilled: [],
    },
    productivity: {
      state: true,
      growthTrends: [
        <HeatMap />,
        <TBvsAb />,
        <CumulativeTBvsAB />,
        <WorkedBilledCollectedRadial />,
        <Utilization />,
      ],
      aop: [<ProductivityTopTensAOP />],
      users: [<ProductivityTopTensUsers />],
    },
    matterTracker: {
      state: true,
    },
    AR: {
      state: true,
    },
    financials: {
      state: true,
      growthTrends: [<FinancialsGrowthTrendsBillExpColl />],
    },
    trust: {
      state: true,
    },
    lawmatics: {
      state: true,
    },
    customReports: {
      state: true,
    },
  },
  xero: {
    dashboard: {
      state: false,
    },
    clients: {
      state: false,
      growthTrends: [<IntegrationPlaceholder />],
      invoices: [<IntegrationPlaceholder />],
      revenue: [<IntegrationPlaceholder />],
      ar: [<IntegrationPlaceholder />],
    },
    matters: {
      state: false,
      growthTrends: [<IntegrationPlaceholder />],
      invoices: [<IntegrationPlaceholder />],
      revenue: [<IntegrationPlaceholder />],
      ar: [<IntegrationPlaceholder />],
      billedNonBilled: [<IntegrationPlaceholder />],
    },
    productivity: {
      state: false,
      growthTrends: [<IntegrationPlaceholder />],
      aop: [<IntegrationPlaceholder />],
      users: [<IntegrationPlaceholder />],
    },
    matterTracker: {
      state: false,
    },
    AR: {
      state: false,
    },
    financials: {
      state: true,
      growthTrends: [
        <FinancialsGrowthTrendsSalesCashflow />,
        <FinancialsGrowthThrendsCashflow />,
        <FinancialsGrowthTrendsNetIncomeMargin />,
        <FinancialsGrowthTrendsExpenseOverheadsSalaries />,
      ],
    },
    trust: {
      state: false,
    },
    lawmatics: {
      state: true,
    },
    customReports: {
      state: true,
    },
  },
  clioAndXero: {
    dashboard: {
      state: true,
    },
    clients: {
      state: true,
      growthTrends: [
        <ClientGrowthTrendsAreaClio />,
        <ClientGrowthTrendsRadial />,
        <ClientGrowthThrendsStackedBar />,
      ],
      invoices: [<ClientTopWorstInvoice />],
      revenue: [<ClientTopWorstCollected />],
      ar: [<ClientTopWorstDues />],
    },
    matters: {
      state: true,
      growthTrends: [
        <MattersGrowthTrendsAreaClio />,
        <MattersGrowthTrendsRadial />,
        <MattersGrowthTrendsStackedBar />,
      ],
      invoices: [<MattersTopWorstInvoice />],
      revenue: [<MattersTopWorstCollected />],
      ar: [<MattersTopWorstDues />],
      billedNonBilled: [],
    },
    productivity: {
      state: true,
      growthTrends: [
        <HeatMap />,
        <TBvsAb />,
        <CumulativeTBvsAB />,
        <WorkedBilledCollectedRadial />,
        <Utilization />,
      ],
      aop: [<ProductivityTopTensAOP />],
      users: [<ProductivityTopTensUsers />],
    },
    matterTracker: {
      state: true,
    },
    AR: {
      state: true,
    },
    financials: {
      state: true,
      growthTrends: [
        <FinancialsGrowthTrendsBillExpColl />,
        <FinancialsGrowthTrendsSalesCashflow />,
        <FinancialsGrowthThrendsCashflow />,
        <FinancialsGrowthTrendsNetIncomeMargin />,
        <FinancialsGrowthTrendsExpenseOverheadsSalaries />,
      ],
    },
    trust: {
      state: true,
    },
    lawmatics: {
      state: true,
    },
    customReports: {
      state: true,
    },
  },
};
