import { createContext, useReducer, useEffect } from "react";
import { initialState, reducer } from "../reducers/reducers";
import { useActions } from "../actions";
import api from "../../services";
import _ from "lodash";

const StoreContext = createContext(initialState);

export const StoreProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const actions = useActions(state, dispatch);

  const { generalStates, integrationConfigState } = state;
  const { currentAppStateActions, integrationConfigActions, generalActions } =
    actions;

  const id = generalStates?.activeFirm?.firm?._id;
  const userID = generalStates?.user?._id;
  const token = generalStates?.token;
  const pmType = integrationConfigState?.pmType;
  const getUserACL = () => {
    let request = api.create(token);
    request
      .getUserPermissions(id, {
        userID: userID,
      })
      .then((res) => {
        generalActions.setUserACL(res.data);
      });
  };

  const getMatterTrackerObj = () => {
    let request = api.create(token);
    request.getMatterTrackerSetup(id, pmType);
  };

  const getPaymentInformation = () => {
    let request = api.create(token);
    request
      .getStripeAllSubscription(id)
      .then((res) => {
        const { XERO, PP, CLIO } = res.data;

        let response = {
          subscribedClio: CLIO,
          subscribedPP: PP,
          subscribedXero: XERO,
        };
        if (!_.isEqual(response, generalStates.paymentInfo)) {
          generalActions.setPaymentInfo(response);
        }
      })
      .catch((error) => console.log(error));
  };

  const getStatus = () => {
    let request = api.create(token);
    request
      .getFirmState(id)
      .then((res) => {
        const { status } = res.data;
        if (status) {
          console.log(status);
          let response = {
            clioStatus: status[0],
            ppStatus: status[1],
            xeroStatus: status[2],
            code: status,
          };
          if (generalStates?.user?.userType == "SUPER_ADMIN") {
            if (status === "000") {
              integrationConfigActions.setNoIntegration();
            }

            if (status[0] !== "0") {
              integrationConfigActions.setPMType("clio");
            }
            if (status[1] !== "0") {
              integrationConfigActions.setPMType("pp");
            }
            if (
              (status[0] == "1" ||
                status[0] == "2" ||
                status[1] == "1" ||
                status[1] == "2") &&
              (status[2] == "1" || status[2] == "2")
            ) {
              integrationConfigActions.setClioXeroIntegration();
              console.log("clio xero integration called");
            } else if (
              (status[0] != "1" || status[0] != "2") &&
              (status[1] != "1" || status[1] != "2") &&
              (status[2] == "1" || status[2] == "2")
            ) {
              integrationConfigActions.setXeroIntegration();
              console.log("xero");
            } else if (
              (status[0] == "1" ||
                status[0] == "2" ||
                status[1] == "1" ||
                status[1] == "2") &&
              (status[2] != "1" || status[2] != "2")
            ) {
              integrationConfigActions.setClioIntegration();
            } else {
              integrationConfigActions.setNoIntegration();
            }
            if (status[0] == "4" || status[1] == "4") {
              currentAppStateActions.setClioHasDuplicate(true);
              if (status[2] == "1") {
                integrationConfigActions.setXeroIntegration();
              } else {
                integrationConfigActions.setNoIntegration();
              }
            }
            console.log("isnot");
            currentAppStateActions.setStatus(response);
          } else {
            if (status === "000") {
              integrationConfigActions.setNoIntegration();
            }
            if (status[0] != "0") {
              integrationConfigActions.setPMType("clio");
            }
            if (status[1] != "0") {
              integrationConfigActions.setPMType("pp");
            }

            if ((status[0] == "1" || status[1] == "1") && status[2] !== "1") {
              integrationConfigActions.setClioIntegration();
            } else if (
              status[0] !== "1" &&
              status[1] !== "1" &&
              status[2] === "1"
            ) {
              integrationConfigActions.setXeroIntegration();
            } else if (
              (status[0] == "1" || status[1] == "1") &&
              status[2] === "1"
            ) {
              integrationConfigActions.setClioXeroIntegration();
            } else if (status.includes("2")) {
              integrationConfigActions.setNoIntegration();
            } else {
              integrationConfigActions.setNoIntegration();
            }
            if (status[0] === "4" || status[1] === "4") {
              currentAppStateActions.setClioHasDuplicate(true);
              if (status[2] === "1") {
                integrationConfigActions.setXeroIntegration();
              } else {
                integrationConfigActions.setNoIntegration();
              }
            }
            console.log("isnot");
            currentAppStateActions.setStatus(response);
          }
        }
      })
      .catch((error) => {
        if (error.code === "ECONNABORTED") {
          alert("The API request timed out. Please Refresh the page");
        } else {
          if (error && error.response && error.response.status === 401) {
            localStorage.clear();
            alert("Session Expired. Logging out.");
            window.location.reload();
            currentAppStateActions.setLoader(false);
          }
        }
      });
  };

  useEffect(() => {
    if (generalStates?.activeFirm) {
      getPaymentInformation();
      getStatus();
    }
    if (userID && id) {
      getUserACL();
    }
  }, [generalStates?.activeFirm, generalStates?.user]);
  useEffect(() => {
    if (pmType == "clio" || pmType == "pp") {
      getMatterTrackerObj();
    }
  }, [integrationConfigState?.pmType]);
  return (
    <StoreContext.Provider
      value={{
        ...state,
        ...actions,
        getStatus,
        getPaymentInformation,
        getUserACL,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};

export default StoreContext;
