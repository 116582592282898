import React, { useEffect, useState } from "react";
import FilterUserTimePractice from "../../../filters/genericFilter/filters";
import EmptyPlaceholder from "../../../commons/placeholders/emptyPlaceholder";
import { makeStyles } from "@material-ui/core/styles";
import ErrorSnackBar from "../../../commons/SnackBar";
import ChartLoader from "../../../commons/loaders/chartLoader";
import useDateFilterState, {
  DateStateType,
} from "../../../filters/genericFilter/dateFilterStateHook";
import {
  calcDifferenceInArrayElements,
  compareArraysAndEquateLength,
  divisionOfElementsAtIndexFromTwoCollections,
  removeNaNInfinity,
} from "./utils";
import {
  limitLengthToEighteen,
  getAmount,
  getNestedListByProperty,
  getMonthsFusion,
} from "../../utils";
import Colors from "../../../../../theme/ChartTheme";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import api from "../../../../../services";
import { DateRange } from "materialui-daterange-picker";
import { checkDate } from "../../utils";
import clsx from "clsx";
import Chart from "../../../charts/fusionCharts";
import InfoButton from "../../../infoButton";
import useAuth from "../../../../../hooks/useAuth";
export interface GrowthTrendsSalesCashflowProps {}

interface ChartState {
  sales: any[] | string[] | [];
  cashflow: any[] | string[] | [];
  netIncomeMargin: any[] | string[] | [];
  months: any[] | [];
}

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "visible",
    flexDirection: "column",
    justifyContent: "center",
  },
  paperHeight: {
    height: 500,
  },
  textTitle: {
    color: theme.palette.text.primary,
    fontSize: "1.2em",
    fontWeight: 600,
  },
  textSubtitle: {
    color: theme.palette.text.secondary,
    fontSize: "0.8em",
  },
  headingText: {
    fontSize: "1.2em",
    fontWeight: 600,
    color: theme.palette.text.secondary,
  },
  divider: {
    backgroundColor: "#EDEFF7",
    height: 1,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));
const initialDateFilterState: DateStateType = {
  dateAfter: null,
  dateBefore: null,
};
const GrowthTrendsSalesCashflow: React.FC<GrowthTrendsSalesCashflowProps> =
  () => {
    const {
      generalStates: { token: activeToken, activeFirm },
    } = useAuth();
    const classes = useStyles();
    const fixedHeightPaper = clsx(classes.paper, classes.paperHeight);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string>("");
    const [chartState, setChartState] = useState<ChartState>({
      sales: [],
      cashflow: [],
      netIncomeMargin: [],
      months: [],
    });
    const { dateFilterRef, dateFilterState, handleDateState } =
      useDateFilterState(initialDateFilterState);
    const handleDateFilterRange = (dateRange: DateRange): void => {
      handleDateState.handleDateUpdate(dateRange);
    };

    const series = [
      {
        seriesname: "Sales",
        data: chartState.sales,
        plottooltext: "<b>$dataValue</b> $seriesName",
      },
      {
        seriesname: "Cashflow",
        data: chartState.cashflow,
        plottooltext: "<b>$dataValue</b> $seriesName",
      },
      {
        seriesname: "Income Margin",
        renderas: "spline",
        parentyaxis: "S",
        plottooltext: "<b>$dataValue</b> $seriesName",
        numbersuffix: "%",
        data: chartState.netIncomeMargin,
      },
    ];

    // *****
    // Filter Submit
    // *****

    const handleFilterSubmit = () => {
      if (checkDate(dateFilterState.dateAfter, dateFilterState.dateBefore)) {
        setLoading(true);
      } else if (
        dateFilterState.dateAfter === null &&
        dateFilterState.dateBefore === null
      ) {
        setLoading(true);
      }
    };

    // *****
    // Clear Filter States
    // *****

    const handleFilterClear = () => {
      handleDateState.resetDate(initialDateFilterState);
      setLoading(true);
    };

    useEffect(() => {
      let subscribe = true;
      if (loading) {
        let request = api.create(activeToken);
        let firmId = activeFirm.firm._id;
        let queryParams = {
          startDate: dateFilterState.dateAfter,
          endDate: dateFilterState.dateBefore,
        };
        Promise.all([
          request.getNetIncome(firmId, queryParams),
          request.getTotalRevenue(firmId, queryParams),
          request.getNetCashFlow(firmId, queryParams),
        ])
          .then(response => {
            if (subscribe) {
              let netIncomeResponse = response[0].data;
              let totalRevenue = response[1].data;
              let netCashFlow = response[2].data;
              //Extract normalised net income, revenue and op expenses from returned nested Array
              let [comparedNetIncome, comparedTotalRevenue, comparedCashFlow] =
                compareArraysAndEquateLength(
                  netIncomeResponse,
                  totalRevenue,
                  netCashFlow
                );
              // Limit length to 18
              let limitedIncome = limitLengthToEighteen(comparedNetIncome);
              let months = getMonthsFusion(limitedIncome, "month");
              let incomeAmount = getAmount(limitedIncome);
              // limited to a length of 18, returns amounts
              let totalRevenueAmount = getAmount(
                limitLengthToEighteen(comparedTotalRevenue)
              );
              let cashFlowAmounts = getAmount(
                limitLengthToEighteen(comparedCashFlow)
              );
              //Cashflow, net Income Margin, total Revenue
              let cashflow = removeNaNInfinity(
                calcDifferenceInArrayElements(cashFlowAmounts)
              );
              let netIncomeMargin = removeNaNInfinity(
                divisionOfElementsAtIndexFromTwoCollections(
                  incomeAmount,
                  totalRevenueAmount
                )
              );
              let tra = totalRevenueAmount.map(item => ({ value: item }));
              setChartState({
                netIncomeMargin: netIncomeMargin,
                cashflow: cashflow,
                sales: tra,
                months: months,
              });
              setLoading(false);
            }
          })
          .catch(error => {
            if (subscribe) {
              if (error.code === "ECONNABORTED") {
                setErrorMsg(
                  "The API request timed out. Please Refresh the page"
                );
                setError(true);
              } else {
                setErrorMsg(error.response.data.message);
                setError(true);
              }
            }
          });
      }
      return () => {
        subscribe = false;
      };
    }, [loading]);
    const config = {
      pyaxisname: "Sales vs Cashflow ($)",
      syaxisname: "Income Margin (%)",
      numberprefix: "$",
      snumbersuffix: "%",
    };
    return (
      <Grid item lg={12} md={12} sm={12} xs={12} xl={12}>
        <Paper className={fixedHeightPaper}>
          {loading ? (
            <ChartLoader />
          ) : (
            <React.Fragment>
              <Grid spacing={2} container justifyContent="space-between">
                <span className={classes.textTitle}>
                  Sales, Cashflows and Net Income
                </span>
                <span>
                  <InfoButton
                    title={
                      "Every business wants to increase sales, but if cash collections do not increase at the same rate, a firm may quickly run short on cash."
                    }
                  />
                  <FilterUserTimePractice
                    dateFilterState={dateFilterState}
                    filterState={{}}
                    disabled={loading}
                    handleFilterClear={handleFilterClear}
                    handleFilterSubmit={handleFilterSubmit}
                    handleDateFilterRange={handleDateFilterRange}
                  />
                </span>
              </Grid>
              {chartState.sales.length > 0 ||
              chartState.cashflow.length > 0 ||
              chartState.netIncomeMargin.length > 0 ? (
                <Chart
                  series={series}
                  type="mscombidy2d"
                  // color={[Colors.blue, Colors.yellow, Colors.green]}
                  height={400}
                  labels={chartState.months}
                  config={config}
                  // yAxisLeft={"Sales vs Cashflow"}
                  // yAxisRight={"Income Margin"}
                  // type={"dollars"}
                />
              ) : (
                <EmptyPlaceholder />
              )}
            </React.Fragment>
          )}
        </Paper>
        {error ? <ErrorSnackBar errorMsg={errorMsg} /> : null}
      </Grid>
    );
  };
export default GrowthTrendsSalesCashflow;
