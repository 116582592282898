import React, { useEffect, useState } from "react";
import numeral from "numeral";
import api from "../../../../services";
import MatTable from "../../charts/table";
import Grid from "@material-ui/core/Grid";
interface ITrustDetailProps {
  id: number;
  firmId: number;
  token: number | string | null;
  pmType: string;
}

const TrustDetail: React.FunctionComponent<ITrustDetailProps> = ({
  id,
  firmId,
  token,
  pmType,
}) => {
  const [loader, setLoader] = useState<boolean>(true);
  const [detail, setDetail] = useState<any[]>([]);
  const [length, setLength] = useState<number>(10);
  useEffect(() => {
    let subscribe = true;
    if (loader) {
      let request = api.create(token);
      request.getTrustDetail(firmId, pmType, { matterID: id }).then((res) => {
        if (subscribe) {
          setDetail(res.data);
          setLength(res.data.length);
          setLoader(false);
        }
      });
    }
    return () => {
      subscribe = false;
    };
  }, [loader]);
  const commonCellStyle = {
    color: "#858796",
    fontSize: 14,
    fontWieght: 700,
  };

  const columns = [
    {
      title: "User Name",
      field: "user_name",
      cellStyle: { ...commonCellStyle },
    },

    {
      title: "Billable Time(hrs)",
      field: "billed_time",
      cellStyle: { ...commonCellStyle },
      render: (row) => (
        <span>{`${
          Math.round((row["billed_time"] + Number.EPSILON) * 100) / 100
        }`}</span>
      ),
      type: "numeric",
    },
    {
      title: "Billable Value ",
      field: "billed_value",
      render: (row) => (
        <span>{`${numeral(row["billed_value"]).format("($ 0.00 a)")}`}</span>
      ),
      cellStyle: { ...commonCellStyle },
      type: "numeric",
    },
    {
      title: "Billable Expense ",
      field: "billed_expense",
      render: (row) => (
        <span>{`${numeral(row["billed_expense"]).format("($ 0.00 a)")}`}</span>
      ),
      cellStyle: { ...commonCellStyle },
      type: "numeric",
    },
    {
      title: "Total Cost",
      field: "total_cost",
      render: (row) => (
        <span>{`${numeral(row["total_cost"]).format("($ 0.00 a)")}`}</span>
      ),
      cellStyle: { ...commonCellStyle },
      type: "numeric",
    },
  ];
  return (
    <div style={{ padding: 16 }}>
      <MatTable
        title={"Trust Detail"}
        pageSize={length}
        isLoading={loader}
        column={columns}
        data={detail}
      />
    </div>
  );
};

export default TrustDetail;
