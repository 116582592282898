import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

export interface SyncStatusProps {
  syncStatus: string;
  integrationName: string;
}

const useStyles = makeStyles((theme) => ({
  typography: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}));

const SyncStatus: React.FC<SyncStatusProps> = ({
  syncStatus,
  integrationName,
}) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Typography className={classes.typography}>
        {syncStatus === "update_in_progress" || syncStatus === "first_in_progress"
          ? `${integrationName} sync in progress...`
          : syncStatus === "completed"
          ? "Sync completed."
          : "No new updates."}
      </Typography>
    </React.Fragment>
  );
};

export default SyncStatus;
