import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import { green, blue } from "@material-ui/core/colors";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import { useHistory, useRouteMatch } from "react-router-dom";
import CompareArrowsIcon from "@material-ui/icons/CompareArrows";
import {
  Menu,
  MenuItem,
  Avatar,
  List,
  ListItemAvatar,
  IconButton,
  Tooltip,
  ListItemText,
} from "@material-ui/core";
import SearchBar from "material-ui-search-bar";
import useAuth from "../../../hooks/useAuth";

const emails = ["username@gmail.com", "user02@gmail.com"];

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: green[100],
    color: green[600],
  },
  notavatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  color: {
    color: theme.palette.secondary.dark,
  },
  background: {
    marginRight: 20,
    backgroundColor: theme.palette.secondary.light,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
  },
  icon: {
    color: theme.palette.secondary.dark,
  },
  title: {
    marginLeft: theme.spacing(4),
    marginTop: theme.spacing(2),
    fontSize: theme.typography.pxToRem(26),
  },
}));

export interface SimpleDialogProps {
  open: any;
  selectedValue: string;
  onClose: (value: string) => void;
}

function SimpleDialog(props: SimpleDialogProps) {
  const { generalStates, generalActions, onboardingActions } = useAuth();
  const [search, setSearch] = useState<string>("");
  const history = useHistory();
  const { path } = useRouteMatch();
  const [filteredFirms, setFilteredFirms] = useState<any>(
    generalStates.user.firmRoles
  );

  useEffect(() => {
    if (search !== "") {
      let found = filteredFirms
        .filter((i) =>
          i.firm.firmName.toLowerCase().includes(search.toLocaleLowerCase())
        )
        .sort(
          (a, b) =>
            a.firm.firmName.toLowerCase().indexOf(search.toLowerCase()) -
            b.firm.firmName.toLowerCase().indexOf(search.toLowerCase())
        );

      if (found && found.length > 0) {
        setFilteredFirms(found);
      } else {
        setFilteredFirms(generalStates.user.firmRoles);
      }
    } else {
      setFilteredFirms(generalStates.user.firmRoles);
    }
  }, [search, generalStates]);

  const classes = useStyles();
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value: string) => {
    onClose(value);
    history.replace(`${path}/onboarding`);
  };

  let activeFirm = generalStates.activeFirm;
  function active(firm) {
    return firm?.firm?._id === activeFirm?.firm?._id ? (
      <Avatar className={classes.avatar}>
        <CheckCircleOutlineIcon />
      </Avatar>
    ) : (
      <Avatar className={classes.notavatar}>
        <RemoveCircleOutlineIcon />
      </Avatar>
    );
  }

  return (
    // <Dialog onClose={handleClose} open={open}>
    <Menu
      anchorEl={open}
      getContentAnchorEl={null}
      open={Boolean(open)}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      onClose={handleClose}
    >
      <div
        style={{
          position: "sticky",
          top: 0,
          flex: 1,
          backgroundColor: "white",
          zIndex: 2,
        }}
      >
        <h1 className={classes.title}>
          <span style={{ fontSize: 20 }}>⇆</span>
          {" Switch Firm"}
        </h1>
        <div>
          <SearchBar
            value={search}
            onChange={(newValue) => {
              setSearch(newValue);
            }}
            onCancelSearch={() => setSearch("")}
          />
        </div>
      </div>

      <List>
        {filteredFirms.map((firm) => (
          <MenuItem
            button
            style={{ minWidth: 320 }}
            key={firm._id}
            onClick={() => {
              generalActions.setActiveFirm(firm);
              history.push("/dashboard");
              setTimeout(() => {
                history.go(0);
              }, 10);
            }}
          >
            <ListItemAvatar>{active(firm)}</ListItemAvatar>
            <ListItemText primary={firm.firm.firmName} />
          </MenuItem>
        ))}
        <MenuItem
          autoFocus
          button
          onClick={() => {
            handleListItemClick("addAccount");
            //Clear previous firmName and integration Type from global state
            onboardingActions.setCurrentOnboardingIntegration(null);
            onboardingActions.setFirmName("");
            onboardingActions.setOnboardingStep(0);
          }}
        >
          <ListItemAvatar>
            <Avatar>
              <AddIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Add a new firm" />
        </MenuItem>
      </List>
    </Menu>
  );
}

export default function SimpleDialogDemo() {
  const classes = useStyles();
  const [selectedValue, setSelectedValue] = React.useState(emails[1]);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  // const handleClose = (value: string) => {
  //   setOpen(false);
  // };
  const handleClose = (value: string) => {
    setAnchorEl(null);
    setSelectedValue(value);
  };
  return (
    <div>
      <Tooltip title={"Switch Firm"}>
        <IconButton
          size="small"
          className={classes.background}
          onClick={handleClick}
        >
          <CompareArrowsIcon fontSize="medium" className={classes.icon} />
        </IconButton>
      </Tooltip>

      <SimpleDialog
        selectedValue={selectedValue}
        open={anchorEl}
        onClose={handleClose}
      />
    </div>
  );
}
