import React, { useContext, useEffect, useState } from "react";
import MaterialTable from "material-table";
import api from "../../../services";
import ErrorSnackBar from "../../dashboard/commons/SnackBar";
import useAuth from "../../../hooks/useAuth";
import UserPermissions from "./UserPermissions";
export default function MaterialTableDemo() {
  // const classes = useStyles();
  const {
    generalStates: {
      token,
      activeFirm: {
        firm: { _id: activeFirm },
        roleType,
      },
    },
  } = useAuth();
  const [data, setData] = useState<any[]>([]);
  const [tableLoading, setTableLoading] = useState<boolean>(true);
  const [updated, setUpdated] = useState<boolean>(false);
  const [apiError, setApiError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const columns: any = [
    {
      title: "First Name",
      field: "firstName",
      editable: "never",
    },
    {
      title: "Last Name",
      field: "lastName",
      editable: "never",
    },

    { title: "Role Type", field: "roleType", editable: "never" },
    {
      title: "Email address",
      field: "email",
    },
  ];
  useEffect(() => {
    let subscribe = true;
    if (subscribe) {
      let request = api.create(token);
      request
        .getRoles(activeFirm)
        .then((res) => {
          let temp = res.data.map((item) => ({
            id: item._id,
            firstName: item.user.firstName,
            userID: item.user._id,
            lastName: item.user.lastName,
            email: item.user.email,
            roleType: item.roleType,
          }));
          setData(temp);
          setTableLoading(false);
        })
        .catch((error) => {});
    }
    return () => {
      subscribe = false;
    };
  }, [updated]);
  return (
    <React.Fragment>
      <MaterialTable
        title={"Users"}
        style={{ padding: 16 }}
        data={data}
        columns={columns}
        isLoading={tableLoading}
        localization={{
          body: {
            editRow: {
              deleteText: "Are you sure you want to delete this user?",
            },
          },
        }}
        detailPanel={
          roleType !== "USER"
            ? (rowData) => {
                return <UserPermissions {...rowData} />;
              }
            : undefined
        }
        editable={{
          isDeletable: (rowData) =>
            rowData.roleType === "USER" && roleType !== "USER", // only name(b) rows would be deletable,
          onRowAdd:
            roleType !== "USER"
              ? (newData) =>
                  new Promise((resolve, reject) => {
                    let request = api.create(token);
                    request
                      .addRole(activeFirm, newData)
                      .then((res) => {
                        setTableLoading(true);
                        setUpdated(true);
                      })
                      .catch((error) => {
                        if (error.code === "ECONNABORTED") {
                          setTableLoading(false);
                          setErrorMsg(
                            "The API request timed out. Please Refresh the page"
                          );
                          setApiError(true);
                        } else {
                          setTableLoading(false);
                          setErrorMsg(error.response.data.message);
                          setApiError(true);
                        }
                      });

                    resolve(request);
                  })
              : undefined,
          onRowDelete: (data) =>
            new Promise((resolve, reject) => {
              let request = api.create(token);
              request
                .deleteRole(activeFirm, data.id)
                .then((res) => {
                  setTableLoading(true);
                  setUpdated(!updated);
                })
                .catch((error) => {
                  if (error.code === "ECONNABORTED") {
                    setTableLoading(false);
                    setErrorMsg(
                      "The API request timed out. Please Refresh the page"
                    );
                    setApiError(true);
                  } else {
                    setTableLoading(false);
                    setErrorMsg(error.response.data.message);
                    setApiError(true);
                  }
                });
              resolve(request);
            }),
        }}
      />
      {apiError && <ErrorSnackBar errorMsg={errorMsg} />}
    </React.Fragment>
  );
}
