import React, { useContext } from "react";
import MaterialTable, { MTableToolbar } from "material-table";
import Paper from "@material-ui/core/Paper";
import { WindowContext } from "../../../window/trackWindow";
import Colors from "../../../theme/ChartTheme";
import { TableResponseItem } from "../grids/matterTracker/types";
import { withStyles } from "@material-ui/core/styles";
import { exportAsCSV } from "../adminLogs/utils";

//Styled Paper
export const StyledPaper = withStyles((theme) => ({
  root: {
    fontSize: 13,
    color: theme.palette.text.primary,
  },
}))((props) => <Paper {...props} />);

export interface MatTableProps {
  data: object[] | TableResponseItem[];
  column: any[];
  title?: string;
  search?: boolean;
  isLoading?: boolean;
  color?: string;
  actions?: any;
  filtering?: boolean;
  back?: any;
  detailPanel?: any;
  editable?: any;
  options?: any;
  pageSize?: number;
  style?: any;
}

const MatTable: React.FC<MatTableProps> = ({
  column,
  color,
  data,
  title,
  search,
  isLoading,
  actions,
  filtering,
  back,
  detailPanel,
  editable,
  options,
  style,
  pageSize,
}) => {
  const window = useContext(WindowContext);
  return (
    <React.Fragment>
      <MaterialTable
        style={style}
        components={{
          Container: (props) => <StyledPaper {...props} />,
          Toolbar: (props) => (
            <div>
              {back}
              <MTableToolbar {...props} />
            </div>
          ),
        }}
        data={data}
        isLoading={isLoading}
        columns={column}
        title={title ? title : ""}
        actions={typeof actions !== "undefined" ? actions : null}
        editable={editable}
        detailPanel={typeof detailPanel !== "undefined" ? detailPanel : null}
        options={{
          search: search ? true : false,
          filtering: filtering ? true : false,
          headerStyle: {
            color: color ? color : "#5A5C69",
            fontSize: window > 400 ? 13 : 11,
            fontWeight: 700,
            position: "sticky",
            top: 0,
          },
          maxBodyHeight: "650px",
          rowStyle: (data: any, index: number, level: number) => {
            return index % 2 ? { backgroundColor: "#eaeaea" } : {}; // or empty {}
          },
          pageSize:
            typeof pageSize !== "undefined" && pageSize < 10 ? pageSize : 10,
          paging:
            typeof pageSize !== "undefined" && pageSize < 10 ? false : true,
          exportButton: true,
          exportCsv: (columns, data) => {
            const name = title ? title : "";
            exportAsCSV(
              //Addition of the nested rows, so the generetad csv has the required columns
              columns,
              data,
              name
            );
          },
          searchFieldStyle: {
            fontSize: window > 400 ? 13 : 11,
            color: Colors.labels,
            width: 150,
          },
        }}
      />
    </React.Fragment>
  );
};

export default MatTable;
