import React from "react";
import ReactFC from "react-fusioncharts";
import FusionCharts from "fusioncharts/core";
import FusionTheme from "fusioncharts/themes/es/fusioncharts.theme.fusion";
import ExcelExport from "fusioncharts/fusioncharts.excelexport";
import MSBar2d from "fusioncharts/viz/msbar2d";
import MSCombi2d from "fusioncharts/viz/mscombi2d";
import Scrollcombi2d from "fusioncharts/viz/scrollcombi2d";
import Doughnut3d from "fusioncharts/viz/doughnut3d";
import Stackedcolumn2d from "fusioncharts/viz/stackedcolumn2d";
import AngularGauge from "fusioncharts/viz/angulargauge";
import Overlappedcolumn2d from "fusioncharts/viz/overlappedcolumn2d";
import MSsplinearea from "fusioncharts/viz/mssplinearea";
import MScombidy2d from "fusioncharts/viz/mscombidy2d";
import MScolumn2d from "fusioncharts/viz/mscolumn2d";
import Scrollcombidy2d from "fusioncharts/viz/scrollcombidy2d";
import HeatMap from "fusioncharts/viz/heatmap";
import Column2d from "fusioncharts/viz/column2d";
import Bar2d from "fusioncharts/viz/bar2d";
import MSstackedcolumn2d from "fusioncharts/viz/msstackedcolumn2d";

ReactFC.fcRoot(
  FusionCharts,
  FusionTheme,
  ExcelExport,
  MSBar2d,
  MSCombi2d,
  Scrollcombi2d,
  Doughnut3d,
  Stackedcolumn2d,
  AngularGauge,
  Overlappedcolumn2d,
  MSsplinearea,
  MScombidy2d,
  MScolumn2d,
  Scrollcombidy2d,
  HeatMap,
  Column2d,
  Bar2d,
  MSstackedcolumn2d
);

export interface IChartProps {
  labels?: string[];
  series?: any;
  height: number;
  color?: string;
  type?: string;
  config?: any;
  data?: any;
  dataSource?: any;
}

// Resolves charts dependancy

const Chart: React.FC<IChartProps> = ({
  labels,
  series,
  type,
  height,
  config,
  data,
  color,
  dataSource,
}) => {
  const set =
    typeof dataSource != "undefined"
      ? dataSource
      : labels && series
        ? {
          chart: {
            showsum: "1",
            theme: "fusion",
            drawcrossline: "1",
            renderAt: "chart-container",
            formatnumberscale: "0",
            animation: 1,
            animationDuration: 2,
            exportenabled: "1",
            legendPosition: "bottom",
            usedataplotcolorforlabels: "1",
            ...config,
          },
          categories: [
            {
              category: labels,
            },
          ],
          dataset: series,
        }
        : !isNaN(data)
          ? {
            chart: {
              lowerlimit: "0",
              upperlimit: "100",
              showvalue: "1",
              numbersuffix: "%",
              theme: "fusion",
              showtooltip: "0",
              usedataplotcolorforlabels: "1",
              ...config,
            },
            colorrange: {
              color: [
                {
                  minvalue: "0",
                  maxvalue: "100",
                  code: color,
                },
              ],
            },
            dials: {
              dial: [
                {
                  value: data,
                },
              ],
            },
          }
          : {
            chart: {
              showsum: "1",
              theme: "fusion",
              drawcrossline: "1",
              animation: 1,
              animationDuration: 2,
              exportenabled: "1",
              legendPosition: "bottom",
              usedataplotcolorforlabels: "1",
              exportshowmenuitem: "0",
              ...config,
            },
            data: data,
          };
  return (
    <ReactFC
      type={type}
      renderAt="chart-container"
      width="100%"
      height={height}
      dataFormat="JSON"
      dataSource={set}
    />
  );
};
export default Chart;
