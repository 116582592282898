import React, { useReducer, useEffect, memo, createContext } from "react";
import socketIOClient from "socket.io-client";
import socketReducer from "./reducer";
import useAuth from "../../hooks/useAuth";

export interface SocketProps {}

// *****
// Interface For the Socket "subscription-acceptance" & "subscription-rejection" connection
// *****

interface AcceptedRejectedType {
  state: boolean;
  message: string | null;
  firmID: string | null;
}

export interface FirmUpdatesType {
  auth_bounced: boolean;
  createdAt: string;
  error_log: any;
  id: string;
  last_synced: string;
  payment_bounced: boolean;
  sync_had_error: boolean;
  sync_progress: number;
  sync_status: string;
  updatedAt: string;
}

interface FirmType {
  state: boolean;
  info: FirmUpdatesType | null;
}

// *****
// Socket State and Context Interface
// *****

export interface SocketStateType {
  connected: boolean;
  rejected: AcceptedRejectedType;
  accepted: AcceptedRejectedType;
  firmUpdatesClio: FirmType;
  firmUpdatesXero: FirmType;
  firmUpdatesPP: FirmType;
}

//Change firm updates object and rejected object when that information is available
const initialSocketState: SocketStateType = {
  connected: false,
  rejected: {
    state: false,
    message: null,
    firmID: null,
  },
  accepted: {
    state: false,
    message: null,
    firmID: null,
  },
  firmUpdatesClio: {
    state: false,
    info: null,
  },
  firmUpdatesXero: {
    state: false,
    info: null,
  },
  firmUpdatesPP: {
    state: false,
    info: null,
  },
};

const SocketContext = createContext<SocketStateType>(initialSocketState);
const subPath = { path: "/notifications/socket.io", timeout: 20000 };

const Socket: React.FC<SocketProps> = memo(({ children }) => {
  const [state, dispatch] = useReducer(socketReducer, initialSocketState);
  const {
    generalStates: { token, activeFirm },
  } = useAuth();
  const socket = socketIOClient(
    process.env.REACT_APP_ENDPOINT as string,
    subPath
  );

  useEffect(() => {
    if (activeFirm !== null) {
      const firmID = activeFirm?.firm?._id;
      socket.on("subscription-rejection", (data) => {
        dispatch({ type: "SUBSCRIPTION_REJECTED", payload: data });
        console.log("rejected");
      });

      socket.on("subscription-acceptance", (data) => {
        dispatch({ type: "SUBSCRIPTION_ACCEPTED", payload: data });
        socket.on("firm-updates-" + firmID, (data) => {
          console.log("data received", data.update);
          if (data.update.clio) {
            console.log("clio data received", data.update.clio);
            dispatch({
              type: "FIRM_UPDATES_CLIO",
              payload: data.update.clio,
            });
          }
          if (data.update.xero) {
            console.log("xero data received", data.update.xero);
            dispatch({
              type: "FIRM_UPDATES_XERO",
              payload: data.update.xero,
            });
          }
          if (data.update.pp) {
            console.log("pp data received", data.update.pp);
            dispatch({
              type: "FIRM_UPDATES_PP",
              payload: data.update.pp,
            });
          }
        });
      });
      socket.emit("subscribe-firm", { token, firmID });
    }
    return () => {
      socket.disconnect();
    };
  }, [activeFirm, token]);

  return (
    <React.Fragment>
      <SocketContext.Provider value={state}>{children}</SocketContext.Provider>
    </React.Fragment>
  );
});

export { Socket, SocketContext };
