import * as React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import CircularProgress, {
  CircularProgressProps
} from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: "relative"
  },
  background: {
    color: "#E1E1E6"
  },
  element: {
    color: theme.palette.primary.light,
    animationDuration: "600ms",
    position: "absolute",
    left: 0,
    top: 0
  },
  circle: {
    strokeLinecap: "round"
  }
}));

// *****
// Two Loaders one acts as a background
// *****

const Loader: React.FC<CircularProgressProps> = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <CircularProgress
        variant="determinate"
        value={100}
        className={classes.background}
        thickness={4}
        size={48}
      />
      <CircularProgress
        variant="indeterminate"
        className={classes.element}
        thickness={4}
        size={48}
        classes={{
          circle: classes.circle
        }}
      />
    </div>
  );
};

export default Loader;
