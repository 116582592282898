import React from "react";
import Login from "../components/login/Login";
import Layout from "./Layout";
import { useRouteMatch } from "react-router-dom";

export default function SignInSide() {
  const { path } = useRouteMatch();

  return (
    <Layout>
      <Login />
    </Layout>
  );
}
