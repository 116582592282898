import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Chart from "../../../charts/fusionCharts";
import _ from "lodash";
import ChartLoader from "../../../commons/loaders/chartLoader";
import Colors from "../../../../../theme/ChartTheme";
import api from "../../../../../services";
import { getNestedListByPropertyFusion, getMonthsFusion } from "../../utils";
import ErrorSnackBar from "../../../commons/SnackBar";
import EmptyPlaceholder from "../../../commons/placeholders/emptyPlaceholder";
import FilterUserTimePractice from "../../../filters/genericFilter/filters";
import { compareFilterDates, limitLengthToEighteen } from "../../utils";
import useFilterState from "../../../filters/genericFilter/filterStateHook";
import { FinancialsGTTypes } from "../../../filters/genericFilter/types";
import useDateFilterState, {
  DateStateType,
} from "../../../filters/genericFilter/dateFilterStateHook";
import { DateRange } from "materialui-daterange-picker";
import InfoButton from "../../../infoButton";
import useAuth from "../../../../../hooks/useAuth";
export interface FinancialsGrowthTrendsBillExpCollProps {}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "visible",
    flexDirection: "column",
    justifyContent: "center",
    height: 500,
  },
  textTitle: {
    ccolor: theme.palette.text.primary,
    fontSize: "1.2em",
    fontWeight: 600,
  },
  headingText: {
    fontSize: "1.2em",
    fontWeight: 600,
    color: theme.palette.text.secondary,
  },
  divider: {
    backgroundColor: "#EDEFF7",
    height: 1,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

// *****
// Initial Filter State
// *****

const initialFilterState: FinancialsGTTypes = {
  practiceArea: "",
  billingMethod: "",
  originatingAtt: "",
};

const initialDateFilterState: DateStateType = {
  dateAfter: null,
  dateBefore: null,
};

const FinancialsGrowthTrendsBillExpColl: React.FC<FinancialsGrowthTrendsBillExpCollProps> =
  () => {
    const [loader, setLoader] = useState<boolean>(true);
    const {
      generalStates: {
        token,
        activeFirm: {
          firm: { _id },
        },
      },
      integrationConfigState: { pmType },
    } = useAuth();

    const [billed, setBilled] = useState<any[]>([]);
    const [months, setMonths] = useState<any[]>([]);
    const [error, setError] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string>("");
    const [outStanding, setOutStanding] = useState<any[]>([]);
    const [collected, setCollected] = useState<any[]>([]);
    const classes = useStyles();

    // *****
    // Current Values of Filters
    // *****

    const { filterRef, filterState, handleState } =
      useFilterState(initialFilterState);

    // *****
    // Previous Values of Filters
    // *****

    const prevFilterState = filterRef.current;

    // *****
    // Current Values of Date Filters
    // *****

    const { dateFilterRef, dateFilterState, handleDateState } =
      useDateFilterState(initialDateFilterState);

    // *****
    // Previous Values of Date Filters
    // *****

    const prevDateFilterState = dateFilterRef.current;

    const seriesMultipleYAxis = [
      {
        seriesname: "Invoices",
        renderas: "spline",
        color: Colors.blue,
        data: billed,
      },
      {
        seriesname: "Revenue",
        renderas: "spline",
        color: Colors.orange,
        data: collected,
      },
      {
        seriesname: "AR",
        color: Colors.red,
        renderas: "spline",
        data: outStanding,
      },
    ];

    // *****
    // Filter state updates
    // *****

    const handlePracticeAreas = (value: number | "") => {
      handleState.handlePracticeArea(value);
    };

    const handleBillingMethod = (value: string) => {
      handleState.handleBillingMethod(value);
    };

    const handleOriginatingAtt = (value: number | "") => {
      handleState.handleOriginatingAtt(value);
    };

    const handleDateFilterRange = (dateRange: DateRange): void => {
      handleDateState.handleDateUpdate(dateRange);
    };
    // *****
    // Filter Submit - Leveraging Closure and invoking utility function compareFilterDates
    // Use Case: If any of the filter state has changed and date has not been applied, invoke action.
    // Use Case: both date values have changed with second greater than the first, invoke action.
    // *****

    const handleFilterSubmit = () => {
      if (
        compareFilterDates(
          filterState,
          prevFilterState,
          dateFilterState,
          prevDateFilterState,
          initialFilterState
        ) === true
      ) {
        setLoader(true);
      }
    };

    // *****
    // Clear Filter States
    // *****

    const handleFilterClear = () => {
      handleState.reset(initialFilterState);
      handleDateState.resetDate(initialDateFilterState);
      setLoader(true);
    };

    // *****
    // Return total dollars array
    // *****

    interface Response {
      total: number;
      month: string;
    }

    // *****
    // Remove month inconsistency
    // *****

    function compareMonths(comparisonList, benchmarkList) {
      benchmarkList.map((item, i) => {
        if (!_.find(comparisonList, (item2) => item2.month === item.month)) {
          comparisonList.splice(i, 0, { total: 0, month: item.month });
        }
      });
      return comparisonList;
    }

    useEffect(() => {
      let subscribed = true;
      if (loader) {
        let request = api.create(token);
        let queryParams = {
          practice_area_id:
            filterState.practiceArea === "" ? null : filterState.practiceArea,
          billing_method:
            filterState.billingMethod === "" ? null : filterState.billingMethod,
          originating_attorney_id:
            filterState.originatingAtt === ""
              ? null
              : filterState.originatingAtt,
          issued_after: dateFilterState.dateAfter,
          issued_before: dateFilterState.dateBefore,
        };

        Promise.all([
          request.getFeeCollected(_id, pmType, queryParams),
          request.getFeeBilled(_id, pmType, queryParams),
          request.getFeeOutstanding(_id, pmType, queryParams),
        ])
          .then((responses) => {
            if (subscribed) {
              let feeCollected = responses[0].data;
              let feeBilled = responses[1].data;
              let feeOutStanding = responses[2].data;
              let fixedFeeCollectedData, fixedFeeOutStandingData;
              if (feeBilled.length > feeCollected.length) {
                fixedFeeCollectedData = compareMonths(feeCollected, feeBilled);
              } else {
                fixedFeeCollectedData = feeCollected;
              }
              if (feeBilled.length > feeOutStanding.length) {
                fixedFeeOutStandingData = compareMonths(
                  feeOutStanding,
                  feeBilled
                );
              } else {
                fixedFeeOutStandingData = feeOutStanding;
              }
              let getMonths = getMonthsFusion(feeBilled, "month");
              let getCollected = getNestedListByPropertyFusion(
                fixedFeeCollectedData,
                "total"
              );
              let getBilled = getNestedListByPropertyFusion(feeBilled, "total");
              let getOutstanding = getNestedListByPropertyFusion(
                fixedFeeOutStandingData,
                "total"
              );
              // Remove limit of 18 on date filters
              if (
                dateFilterState.dateAfter !== null &&
                dateFilterState.dateBefore !== null
              ) {
                setMonths(getMonths);
                setCollected(getCollected);
                setBilled(getBilled);
                setOutStanding(feeOutStanding.length > 0 ? getOutstanding : []);
              } else {
                setMonths(limitLengthToEighteen(getMonths));
                setCollected(limitLengthToEighteen(getCollected));
                setBilled(limitLengthToEighteen(getBilled));
                setOutStanding(limitLengthToEighteen(getOutstanding));
              }
              setLoader(false);
            }
          })
          .catch((error) => {
            if (subscribed) {
              if (error.code === "ECONNABORTED") {
                setErrorMsg(
                  "The API request timed out. Please Refresh the page"
                );
                setError(true);
              } else {
                setErrorMsg(error.response.data.message);
                setError(true);
              }
            }
          });
      }
      return () => {
        subscribed = false;
      };
    }, [loader]);

    const chartConfig = {
      plottooltext: "<b>$dataValue</b> $seriesName",
      yaxisname: "Amount (In USD)",
      numberprefix: "$",
      lineThickness: "4",
      yAxisMaxValue: [
        ...billed.map((item) => item.value),
        ...collected.map((item) => item.value),
        ...outStanding.map((item) => item.value),
      ].reduce((a, b) => Math.max(a, b) , 1) * 1.1,
    };

    // return max value from combination of three arrays

    return (
      <React.Fragment>
        <Grid item lg={12} md={12} sm={12} xs={12} xl={12}>
          <Paper className={classes.paper}>
            {loader ? (
              <ChartLoader />
            ) : (
              <React.Fragment>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <span className={classes.textTitle}>
                    Invoices, Revenue and AR
                  </span>
                  <span>
                    <InfoButton
                      title={
                        "Quickly analyze Invoices, Revenue and AR by area of practice, billing method, originating attorney and time."
                      }
                    />
                    <FilterUserTimePractice
                      disabled={loader}
                      filterState={filterState}
                      dateFilterState={dateFilterState}
                      handleDateFilterRange={handleDateFilterRange}
                      handleFilterSubmit={handleFilterSubmit}
                      handleFilterClear={handleFilterClear}
                      handlePracticeAreas={handlePracticeAreas}
                      handleBillingMethod={handleBillingMethod}
                      handleOriginatingAtt={handleOriginatingAtt}
                    />
                  </span>
                </Grid>
                {outStanding.length > 0 || collected.length > 0 ? (
                  <Chart
                    series={seriesMultipleYAxis}
                    labels={months}
                    type={"mscombi2d"}
                    config={chartConfig}
                    height={400}
                  />
                ) : (
                  <EmptyPlaceholder />
                )}
              </React.Fragment>
            )}
          </Paper>
          {error ? <ErrorSnackBar errorMsg={errorMsg} /> : null}
        </Grid>
      </React.Fragment>
    );
  };

export default FinancialsGrowthTrendsBillExpColl;
