import React, { useEffect, useState } from "react";
import FilterUserTimePractice from "../../../filters/genericFilter/filters";
import EmptyPlaceholder from "../../../commons/placeholders/emptyPlaceholder";
import { makeStyles } from "@material-ui/core/styles";
import {
  limitLengthToEighteen,
  checkDate,
  getMonthsFusion,
  getAmountFusion,
} from "../../utils";
import ErrorSnackBar from "../../../commons/SnackBar";
import ChartLoader from "../../../commons/loaders/chartLoader";
import useDateFilterState, {
  DateStateType,
} from "../../../filters/genericFilter/dateFilterStateHook";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import api from "../../../../../services";
import { DateRange } from "materialui-daterange-picker";
import clsx from "clsx";
import Chart from "../../../charts/fusionCharts";
import InfoButton from "../../../infoButton";
import useAuth from "../../../../../hooks/useAuth";
export interface GrowthTrendsNetIncomeMarginProps {}

interface ChartSeriesObject {
  y: number;
  x: string;
}

interface ChartState {
  months: any[] | [];
  netIncomeMargin: any[] | [];
}

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "visible",
    flexDirection: "column",
    justifyContent: "center",
  },
  paperHeight: {
    height: 500,
  },
  textTitle: {
    color: theme.palette.text.primary,
    fontSize: "1.2em",
    fontWeight: 600,
    marginLeft: theme.spacing(2),
  },
  headingText: {
    fontSize: "1.2em",
    fontWeight: 600,
    color: theme.palette.text.secondary,
  },
  divider: {
    backgroundColor: "#EDEFF7",
    height: 1,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const initialDateFilterState: DateStateType = {
  dateAfter: null,
  dateBefore: null,
};

const GrowthTrendsNetIncomeMargin: React.FC<GrowthTrendsNetIncomeMarginProps> =
  () => {
    const {
      generalStates: {
        token,
        activeFirm: {
          firm: { _id },
        },
      },
    } = useAuth();

    const classes = useStyles();
    const fixedHeightPaper = clsx(classes.paper, classes.paperHeight);

    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string>("");
    const [chartState, setChartState] = useState<ChartState>({
      months: [],
      netIncomeMargin: [],
    });
    const { dateFilterRef, dateFilterState, handleDateState } =
      useDateFilterState(initialDateFilterState);
    const handleDateFilterRange = (dateRange: DateRange): void => {
      handleDateState.handleDateUpdate(dateRange);
    };

    const series = [
      {
        renderas: "area",
        data: chartState.netIncomeMargin,
      },
    ];

    // *****
    // Filter Submit
    // *****

    const handleFilterSubmit = () => {
      if (checkDate(dateFilterState.dateAfter, dateFilterState.dateBefore)) {
        setLoading(true);
      } else if (
        dateFilterState.dateAfter === null &&
        dateFilterState.dateBefore === null
      ) {
        setLoading(true);
      }
    };

    // *****
    // Clear Filter States
    // *****

    const handleFilterClear = () => {
      handleDateState.resetDate(initialDateFilterState);
      setLoading(true);
    };

    useEffect(() => {
      let subscribe = true;
      if (loading) {
        let request = api.create(token);
        let queryParams = {
          startDate: dateFilterState.dateAfter,
          endDate: dateFilterState.dateBefore,
        };
        request
          .getNetIncome(_id, queryParams)
          .then(res => {
            if (subscribe) {
              let limitedLength = limitLengthToEighteen(res.data);
              let months = getMonthsFusion(limitedLength, "month");
              let netIncomeMargin = getAmountFusion(limitedLength);
              setChartState({
                months: months,
                netIncomeMargin: netIncomeMargin,
              });
            }
            setLoading(false);
          })
          .catch(error => {
            if (subscribe) {
              if (error.code === "ECONNABORTED") {
                setErrorMsg(
                  "The API request timed out. Please Refresh the page"
                );
                setError(true);
              } else {
                setErrorMsg(error.response.data.message);
                setError(true);
              }
            }
          });
      }
      return () => {
        subscribe = false;
      };
    }, [loading]);
    const config = {
      numberprefix: "$",
    };
    return (
      <Grid item lg={12} md={12} sm={12} xs={12} xl={12}>
        <Paper className={fixedHeightPaper}>
          {loading ? (
            <ChartLoader />
          ) : (
            <React.Fragment>
              <Grid container spacing={2} justifyContent="space-between">
                <span className={classes.textTitle}>Net Income Margin</span>
                <span>
                  <InfoButton
                    title={
                      "Tracked net income trend to see if there are any spikes or dips."
                    }
                  />
                  <FilterUserTimePractice
                    dateFilterState={dateFilterState}
                    filterState={{}}
                    disabled={loading}
                    handleFilterClear={handleFilterClear}
                    handleFilterSubmit={handleFilterSubmit}
                    handleDateFilterRange={handleDateFilterRange}
                  />
                </span>
              </Grid>
              {chartState.netIncomeMargin.length > 0 ? (
                <Chart
                  type="mssplinearea"
                  series={series}
                  labels={chartState.months}
                  height={400}
                  config={config}
                />
              ) : (
                <EmptyPlaceholder />
              )}
            </React.Fragment>
          )}
        </Paper>
        {error ? <ErrorSnackBar errorMsg={errorMsg} /> : null}
      </Grid>
    );
  };
export default GrowthTrendsNetIncomeMargin;
