import {
  createStyles,
  alpha,
  Theme,
  withStyles,
} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const StyledGradientButton = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "label + &": {
        marginTop: theme.spacing(1),
      },
      "&:error": {
        boxShadow: `${alpha(theme.palette.error.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.error.main,
      },
      margin: theme.spacing(3, 0, 2),
      background: `linear-gradient(90deg, ${theme.palette.primary.main} , ${theme.palette.primary.light} )`,
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      color: "#fff",
      "&:hover": {
        backgroundColor: theme.palette.primary.light,
      },
      "&:disabled": {
        color: "#eaeaea",
      },
    },
  })
)(Button);


export { StyledGradientButton };
