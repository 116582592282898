import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  Button,
  Paper,
  InputAdornment,
  CircularProgress,
} from "@material-ui/core";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ErrorSnackBar from "../dashboard/commons/SnackBar";
import { makeStyles } from "@material-ui/core/styles";

import Popup from "../commons/popup";
import api from "../../services";
import useAuth from "../../hooks/useAuth";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  titleText: {
    display: "block",
    fontSize: "1.3rem",
    fontWeight: 600,
    fontColor: theme.palette.text.primary,
    [theme.breakpoints.down("md")]: {
      fontSize: "1.2rem",
    },
  },
  subheadingText: {
    display: "block",
    fontSize: "1rem",
    fontWeight: 500,
    fontColor: theme.palette.text.secondary,
    marginTop: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      fontSize: "0.9rem",
    },
  },
  iconHide: {
    color: "#cfd1d6",
  },
  iconShow: {
    color: theme.palette.primary.light,
  },
  button: {
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
}));

export default function ChangePassword(props) {
  const classes = useStyles();
  const {
    generalStates: { token },
    generalActions,
    onboardingActions,
  } = useAuth();
  const [password, setPassword] = useState<string>("");
  const [currentPassword, setCurrentPassword] = useState<string>("");
  const [passwordHide, setPasswordHide] = useState<boolean>(true);
  const [currPasswordHide, setCurrPasswordHide] = useState<boolean>(true);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [apiError, setApiError] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [displayPopup, setDisplayPopup] = useState<boolean>(false);
  const [forceLogout, setForceLogout] = useState<boolean>(false);

  // *****
  // Check password against password constraints
  // *****

  useEffect(() => {
    let passwordRgx =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*._-])(?=.{8,})/;
    if (password.length > 0 && currentPassword.length > 0) {
      if (passwordRgx.test(password)) {
        setDisabled(false);
        setError(false);
      } else {
        setError(true);
        setDisabled(true);
      }
    }
  }, [password, currentPassword]);

  // *****
  // Submit Changes
  // *****

  function handleChange() {
    setLoading(true);
    setDisabled(true);
  }

  // *****
  // Popup handle that invokes user logout
  // *****

  const handleClose = () => {
    setDisplayPopup(false);
    setForceLogout(true);
  };

  // *****
  // Password Visibility Toggles
  // *****

  const handleCurrPasswordToggle = () => {
    setCurrPasswordHide(!currPasswordHide);
  };

  const handlePasswordToggle = () => {
    setPasswordHide(!passwordHide);
  };

  // *****
  // Invoke on change password submission
  // *****

  useEffect(() => {
    let subscribe = true;
    if (loading) {
      let request = api.create(token);
      request
        .changePassword({
          currentPassword: currentPassword,
          newPassword: password,
        })
        .then((res) => {
          if (subscribe) {
            if (res.status === 200) {
              setDisabled(false);
              setLoading(false);
              setDisplayPopup(true);
            }
          }
        })
        .catch((error) => {
          if (subscribe) {
            if (error.code === "ECONNABORTED") {
              setDisabled(false);
              setLoading(false);
              setErrorMsg("The API request timed out. Please Refresh the page");
              setApiError(true);
            } else {
              setDisabled(false);
              setLoading(false);
              setErrorMsg(error.response.data.message);
              setApiError(true);
            }
          }
        });
    }
    return () => {
      subscribe = false;
    };
  }, [loading]);

  // *****
  // Logout once the popup has been displayed
  // *****

  useEffect(() => {
    if (forceLogout) {
      generalActions.setToken(null);
      generalActions.setRefreshToken(null);
      onboardingActions.setFirmName("");
      onboardingActions.setOnboardingStep(0);
      generalActions.setActiveFirm(null);
      generalActions.setUser(null);
    }
  }, [forceLogout]);

  return (
    <React.Fragment>
      <Popup
        open={displayPopup}
        handleClose={handleClose}
        title={"Password Updated"}
        body={`The password has been successfully updated. In order to maintain security
        you have been logged out from all devices. Please login again to proceed.
        `}
        buttonText={"Proceed"}
        disableBackdropClick
        disableEscapeKeyDown
      />
      <Paper className={classes.root}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <span className={classes.titleText}>Change Password</span>
            <span className={classes.subheadingText}>
              Password must contain 8 characters with a digit, special character
              and a capital letter. In order to maintain security you will be
              logged out from all devices.
            </span>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              variant="outlined"
              onChange={(e) => setCurrentPassword(e.target.value)}
              fullWidth
              error={error}
              required
              type={currPasswordHide ? "password" : "text"}
              label="Current Password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {currPasswordHide ? (
                      <VisibilityOffIcon
                        onClick={handleCurrPasswordToggle}
                        className={classes.iconHide}
                      />
                    ) : (
                      <VisibilityIcon
                        onClick={handleCurrPasswordToggle}
                        className={classes.iconShow}
                      />
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              variant="outlined"
              onChange={(e) => setPassword(e.target.value)}
              fullWidth
              error={error}
              required
              type={passwordHide ? "password" : "text"}
              label="Password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {passwordHide ? (
                      <VisibilityOffIcon
                        onClick={handlePasswordToggle}
                        className={classes.iconHide}
                      />
                    ) : (
                      <VisibilityIcon
                        onClick={handlePasswordToggle}
                        className={classes.iconShow}
                      />
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid
            style={{ padding: 16 }}
            item
            xs={12}
            lg={12}
            md={12}
            xl={12}
            sm={12}
          >
            <Button
              disabled={disabled}
              className={classes.button}
              onClick={() => handleChange()}
              variant="contained"
              color="primary"
            >
              {loading ? <CircularProgress size={24} /> : "Change"}
            </Button>
          </Grid>
        </Grid>
      </Paper>
      {apiError ? <ErrorSnackBar errorMsg={errorMsg} /> : null}
    </React.Fragment>
  );
}
