import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { StyledInput } from "../settings/payments/stripe/styledInput";
import { InputLabel, InputAdornment, Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  iconHide: {
    color: "#cfd1d6",
  },
  iconShow: {
    color: theme.palette.primary.light,
  },
  error: {
    color: theme.palette.error.dark,
    fontSize: 12,
    fontFamily: "Open Sans",
    marginTop: theme.spacing(1),
  },
}));

export default function AddressForm(props) {
  const classes = useStyles();
  const [passwordHide, setPasswordHide] = useState<boolean>(true);

  let emailRgx =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  let emailError =
    emailRgx.test(props.email.length > 0 ? props.email : "test@test.com") ||
    props.accept
      ? false
      : true;

  let firstLastNameRgx = /(?=^.{3,30}$)^([A-Za-z][\s]?)+$/;

  let firstError = firstLastNameRgx.test(
    props.firstName.length > 0 ? props.firstName : "name"
  )
    ? false
    : true;

  let lastError = firstLastNameRgx.test(
    props.lastName.length > 0 ? props.lastName : "name"
  )
    ? false
    : true;
  let passwordRgx =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*._-])(?=.{8,30})/;

  let passwordError = passwordRgx.test(
    props.password.length > 0 ? props.password : "Abcd@1234"
  )
    ? false
    : true;
  let confirmPasswordError =
    props.password === props.confirmPassword ? false : true;

  const handlePasswordToggle = () => {
    setPasswordHide(!passwordHide);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <InputLabel required>First Name</InputLabel>
        <StyledInput
          value={props.firstName}
          onChange={(event) => props.handleFirstName(event.target.value)}
          error={firstError}
          required
          fullWidth
          id="firstName"
          name="First Name"
          autoComplete="off"
        />
        {firstError && (
          <span className={classes.error}>3 or more characters</span>
        )}
      </Grid>
      <Grid item xs={12}>
        <InputLabel required>Last Name</InputLabel>
        <StyledInput
          value={props.lastName}
          error={lastError}
          onChange={(event) => props.handleLastName(event.target.value)}
          required
          fullWidth
          id="lastName"
          name="lastName"
          autoComplete="off"
        />
        {lastError && (
          <span className={classes.error}>3 or more characters</span>
        )}
      </Grid>
      {!props.accept ? (
        <Grid item xs={12}>
          <InputLabel required>Email Address</InputLabel>
          <StyledInput
            value={props.email}
            onChange={(event) => props.handleEmail(event.target.value)}
            error={emailError}
            required
            fullWidth
            id="email"
            name="email"
            autoComplete="off"
          />
          {emailError && (
            <span className={classes.error}>
              Must include '@' and ends with '.com' or '.co'
            </span>
          )}
        </Grid>
      ) : null}

      <Grid item xs={12}>
        <InputLabel required>Password</InputLabel>
        <StyledInput
          value={props.password}
          onChange={(event) => props.handlePassword(event.target.value)}
          error={passwordError}
          required
          fullWidth
          name="password"
          type={passwordHide ? "password" : "text"}
          id="password"
          autoComplete="off"
          endAdornment={
            <InputAdornment position="end">
              {passwordHide ? (
                <VisibilityOffIcon
                  onClick={handlePasswordToggle}
                  className={classes.iconHide}
                />
              ) : (
                <VisibilityIcon
                  onClick={handlePasswordToggle}
                  className={classes.iconShow}
                />
              )}
            </InputAdornment>
          }
        />
        {passwordError && (
          <span className={classes.error}>
            Must include 8 alphanumeric characters with one special character,
          </span>
        )}
      </Grid>
    </Grid>
  );
}
