export const onboardingActions = (props) => {
  return {
    //Onboarding - firm name
    setFirmName: (data) => {
      localStorage.setItem("firmName", data);
      externSetFirmName(props, data);
    },
    setFirmId: (data) => {
      localStorage.setItem("firmId", data);
      externSetFirmId(props, data);
    },
    //Onboarding - active step
    setOnboardingStep: (data) => {
      localStorage.setItem("onboardingStep", data);
      externSetOnboardingStep(props, data);
    },
    //Onboarding - current integration "CLIO" || "XERO"
    setCurrentOnboardingIntegration: (data) => {
      localStorage.setItem("currentIntegration", data);
      externSetCurrentOnboardingIntegration(props, data);
    },
  };
};

// *****
// External actions
// *****
function externSetFirmId(props, data) {
  props.dispatch({ type: "SET_FIRMID", data });
}
function externSetFirmName(props, data) {
  props.dispatch({ type: "SET_FIRMNAME", data });
}
function externSetOnboardingStep(props, data) {
  props.dispatch({ type: "SET_ONBOARDINGSTEP", data });
}
function externSetCurrentOnboardingIntegration(props, data) {
  props.dispatch({ type: "SET_CURRENT_INTEGRATION", data });
}
