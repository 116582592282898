// *****
// Socket State Reducer
// *****

export default function socketReducer(state, action) {
  switch (action.type) {
    case "NEW_CONNECTION":
      return { ...state, connected: true };
    case "SUBSCRIPTION_ACCEPTED":
      return {
        ...state,
        connected: true,
        rejected: { state: false, message: null, firmID: null },
        accepted: {
          state: true,
          message: action.payload.msg,
          firmID: action.payload.firmID,
        },
      };
    case "SUBSCRIPTION_REJECTED":
      return {
        ...state,
        rejected: {
          state: true,
          message: action.payload.msg,
          firmID: action.payload.firmID,
        },
        accepted: { state: false, message: null, firmID: null },
      };
    case "FIRM_UPDATES_CLIO":
      return {
        ...state,
        firmUpdatesClio: {
          state: true,
          info: action.payload,
        },
        rejected: { state: false, message: null, firmID: null },
      };
    case "FIRM_UPDATES_XERO":
      return {
        ...state,
        firmUpdatesXero: {
          state: true,
          info: action.payload,
        },
        rejected: { state: false, message: null, firmID: null },
      };
    case "FIRM_UPDATES_PP":
      return {
        ...state,
        firmUpdatesPP: {
          state: true,
          info: action.payload,
        },
        rejected: { state: false, message: null, firmID: null },
      };
    default:
      throw new Error("Socket State could not be updated!");
  }
}
