import React, { useEffect, useState } from "react";
import FilterUserTimePractice from "../../../filters/genericFilter/filters";
import EmptyPlaceholder from "../../../commons/placeholders/emptyPlaceholder";
import { makeStyles } from "@material-ui/core/styles";
import ErrorSnackBar from "../../../commons/SnackBar";
import ChartLoader from "../../../commons/loaders/chartLoader";
import useDateFilterState, {
  DateStateType,
} from "../../../filters/genericFilter/dateFilterStateHook";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import api from "../../../../../services";
import { DateRange } from "materialui-daterange-picker";
import clsx from "clsx";
import { checkDate, getMonthsFusion, limitLengthToEighteen } from "../../utils";
import { compareArraysAndEquateLength } from "./utils";
import _ from "lodash";
import Chart from "../../../charts/fusionCharts";
import InfoButton from "../../../infoButton";
import useAuth from "../../../../../hooks/useAuth";
export interface GrowthTrendsExpenseOverheadsSalariesProps {}

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "visible",
    flexDirection: "column",
    justifyContent: "center",
  },
  paperHeight: {
    height: 500,
  },
  textTitle: {
    color: theme.palette.text.primary,
    fontSize: "1.2em",
    fontWeight: 600,
  },
  textSubtitle: {
    color: theme.palette.text.secondary,
    fontSize: "0.8em",
  },
  headingText: {
    fontSize: "1.2em",
    fontWeight: 600,
    color: theme.palette.text.secondary,
  },
  divider: {
    backgroundColor: "#EDEFF7",
    height: 1,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));
const initialDateFilterState: DateStateType = {
  dateAfter: null,
  dateBefore: null,
};

function returnTotal(list) {
  return _.flatMap(list, (item, i) => ({
    value: item.total,
  }));
}

const GrowthTrendsExpenseOverheadsSalaries: React.FC<GrowthTrendsExpenseOverheadsSalariesProps> =
  () => {
    const {
      generalStates: {
        token,
        activeFirm: {
          firm: { _id },
        },
      },
    } = useAuth();

    const classes = useStyles();
    const fixedHeightPaper = clsx(classes.paper, classes.paperHeight);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string>("");
    const [clientExpense, setClientExpense] = useState<number[] | string[]>([]);
    const [clientExpensesPNL, setClientExpensesPNL] = useState<
      number[] | string[]
    >([]);
    const [wagesSalaries, setWagesSalaries] = useState<number[] | string[]>([]);
    const [months, setMonths] = useState<any[]>([]);
    const { dateFilterRef, dateFilterState, handleDateState } =
      useDateFilterState(initialDateFilterState);
    const handleDateFilterRange = (dateRange: DateRange): void => {
      handleDateState.handleDateUpdate(dateRange);
    };

    const seriesColumn1 = [
      {
        seriesname: "Client Expense",
        data: clientExpense,
      },
      {
        seriesname: "Operating Overheads",
        data: clientExpensesPNL,
      },
      {
        seriesname: "Wages & Salaries",
        data: wagesSalaries,
      },
    ];

    // *****
    // Filter Submit
    // *****

    const handleFilterSubmit = () => {
      if (checkDate(dateFilterState.dateAfter, dateFilterState.dateBefore)) {
        setLoading(true);
      } else if (
        dateFilterState.dateAfter === null &&
        dateFilterState.dateBefore === null
      ) {
        setLoading(true);
      }
    };

    // *****
    // Clear Filter States
    // *****

    const handleFilterClear = () => {
      handleDateState.resetDate(initialDateFilterState);
      setLoading(true);
    };

    useEffect(() => {
      let subscribe = true;
      if (loading) {
        let request = api.create(token);
        let queryParams = {
          startDate: dateFilterState.dateAfter,
          endDate: dateFilterState.dateBefore,
        };

        Promise.all([
          request.getClientExpenses(_id, queryParams),
          request.getWagesSalaries(_id, queryParams),
          request.getClientExpensesPNL(_id, queryParams),
        ])
          .then(res => {
            if (subscribe) {
              let clientExpense = res[0].data;
              let wagesSalaries = res[1].data;
              let clientExpensePNL = res[2].data;
              let [comparedCE, comparedWS, comparedCEPNL] =
                compareArraysAndEquateLength(
                  clientExpense,
                  wagesSalaries,
                  clientExpensePNL
                );
              let getMonths = getMonthsFusion(
                limitLengthToEighteen(comparedCE),
                "month"
              );
              let getClientExpenses = returnTotal(comparedCE);
              let getWagesSalaries = returnTotal(comparedWS);
              let getClientExpensesPNL = returnTotal(comparedCEPNL);
              if (
                dateFilterState.dateAfter !== null &&
                dateFilterState.dateBefore !== null
              ) {
                setMonths(getMonths);
                setClientExpense(getClientExpenses);
                setWagesSalaries(getWagesSalaries);
                setClientExpensesPNL(getClientExpensesPNL);
              } else {
                setMonths(limitLengthToEighteen(getMonths));
                setClientExpense(limitLengthToEighteen(getClientExpenses));
                setWagesSalaries(limitLengthToEighteen(getWagesSalaries));
                setClientExpensesPNL(
                  limitLengthToEighteen(getClientExpensesPNL)
                );
              }
              // Remove limit of 18 on date filters
              setLoading(false);
            }
          })
          .catch(error => {
            if (subscribe) {
              if (error.code === "ECONNABORTED") {
                setErrorMsg(
                  "The API request timed out. Please Refresh the page"
                );
                setError(true);
              } else {
                setErrorMsg(error.response.data.message);
                setError(true);
              }
            }
          });
      }

      return () => {
        subscribe = false;
      };
    }, [loading]);
    const config = {
      numberprefix: "$",
    };
    return (
      <Grid item lg={12} md={12} sm={12} xs={12} xl={12}>
        <Paper className={fixedHeightPaper}>
          {loading ? (
            <ChartLoader />
          ) : (
            <React.Fragment>
              <Grid container spacing={2} justifyContent="space-between">
                <span className={classes.textTitle}>
                  Client Expenses, Operating Overheads, Wages & Salaries{" "}
                </span>
                <span>
                  <InfoButton
                    title={
                      "Compare client expenses, wages and salaries and operating overheads."
                    }
                  />
                  <FilterUserTimePractice
                    dateFilterState={dateFilterState}
                    filterState={{}}
                    disabled={loading}
                    handleFilterClear={handleFilterClear}
                    handleFilterSubmit={handleFilterSubmit}
                    handleDateFilterRange={handleDateFilterRange}
                  />
                </span>
              </Grid>
              {clientExpense.length > 0 ||
              wagesSalaries.length > 0 ||
              clientExpensesPNL.length > 0 ? (
                <Chart
                  type={"mscolumn2d"}
                  labels={months}
                  series={seriesColumn1}
                  height={400}
                  config={config}
                />
              ) : (
                <EmptyPlaceholder
                  text={
                    "Please categorize your Client Expenses, Wages & Salaries under account setups."
                  }
                />
              )}
            </React.Fragment>
          )}
        </Paper>
        {error ? <ErrorSnackBar errorMsg={errorMsg} /> : null}
      </Grid>
    );
  };
export default GrowthTrendsExpenseOverheadsSalaries;
