import React from "react";
import { Route, Redirect } from "react-router-dom";
import useAuth from "../hooks/useAuth";
//If the user is not authenticated, redirect to login.
const PublicRoute = ({ component, ...properties }) => {
  const {
    generalStates: { user },
  } = useAuth();

  return user ? (
    <Redirect to="/dashboard" />
  ) : (
    <Route {...properties} component={component} />
  );
};

export default PublicRoute;
