import React, { useState, useEffect } from "react";
import { CircularProgress, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import api from "../services";
import queryString from "query-string";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
}));
export default function EmailVerify(props) {
  const classes = useStyles();
  const [loader, setLoader] = useState(true);
  const [error, setError] = useState(false);
  useEffect(() => {
    if (loader) {
      const values = queryString.parse(props.location.search);

      let request = api.create("");
      request
        .verifyEmail({
          userID: values.userID,
          emailToken: values.emailToken,
        })
        .then((res) => {
          setLoader(false);
        })
        .catch((error) => {
          setLoader(false);
          setError(true);
        });
    } else {
      setTimeout(() => {
        !error
          ? props.history.push("/login")
          : props.history.push("/forgot-password");
      }, 3000);
    }
  }, [loader]);
  if (loader) {
    return (
      <div className={classes.root}>
        <CircularProgress />
      </div>
    );
  } else if (!error) {
    return (
      <div className={classes.root}>
        <CheckCircleIcon />
        <Typography variant="body2" color="textSecondary" align="center">
          {"Your email has been verified. Redirecting to Login..."}
        </Typography>
      </div>
    );
  } else {
    return (
      <div className={classes.root}>
        <HighlightOffIcon />
        <Typography variant="body2" color="textSecondary" align="center">
          {
            "Something went wrong, try resetting your password and try again. Redirecting to Forgot Password..."
          }
        </Typography>
      </div>
    );
  }
}
