import React, { useReducer, useCallback } from "react";
import reducer from "./reducer";
import api from "../../../../../../../services";
import { ClientDistributionState, Action } from "./types";
import {
  getCommonElementsFromArrays,
  getMonthsFusion,
  getNestedListByPropertyFusion,
  limitLengthToEighteen,
  subtractElementfromArray,
} from "../../../../utils";
import useAuth from "../../../../../../../hooks/useAuth";
const initialState: ClientDistributionState = {
  loading: true,
  response: {
    status: false,
    activeClients: [],
    dormantClients: [],
    months: [],
  },
  error: {
    status: false,
    message: "",
  },
};

/*
A hook that does an Async request to fetch data and store relevant information
*/

const useFetchClientDistribution = () => {
  const [clientDistributionState, dispatch] = useReducer(reducer, initialState);
  const {
    generalStates: {
      token,
      activeFirm: {
        firm: { _id },
      },
    },
    integrationConfigState: { pmType },
  } = useAuth();

  const fetchClientDistribution = useCallback(
    (filterState, dateFilterState) => {
      const request = api.create(token);
      // Dispatch action: request generated
      dispatch({ type: "SET_REQUEST" } as Action);

      const queryParams = {
        practice_area_id:
          filterState.practiceArea === "" ? null : filterState.practiceArea,
        billing_method:
          filterState.billingMethod === "" ? null : filterState.billingMethod,
        originating_attorney_id:
          filterState.originatingAtt === "" ? null : filterState.originatingAtt,
        responsible_attorney_id:
          filterState.responsibleAtt === "" ? null : filterState.responsibleAtt,
        startDate: dateFilterState.dateAfter,
        endDate: dateFilterState.dateBefore,
      };
      request
        .getClientDistribution(_id, pmType, queryParams)
        .then((response) => {
          const { active_clients: activeClients, total_clients: totalClients } =
            response.data;
          // Equate both arrays
          const equatedTotalClients = limitLengthToEighteen(
            getCommonElementsFromArrays(totalClients, activeClients, "month")
          );
          const equatedActiveClients = limitLengthToEighteen(
            getCommonElementsFromArrays(
              activeClients,
              equatedTotalClients,
              "month"
            )
          );
          // Extract relevant information
          const finalActiveClients = getNestedListByPropertyFusion(
            equatedActiveClients,
            "total"
          );
          const cumulativeClients = getNestedListByPropertyFusion(
            equatedTotalClients,
            "cumulative_clients"
          );
          const months = getMonthsFusion(equatedActiveClients, "month");
          const dormantClients = subtractElementfromArray(
            finalActiveClients,
            cumulativeClients
          );
          // Dispatch action - set response
          dispatch({
            type: "SET_RESPONSE",
            activeClients: finalActiveClients,
            dormantClients: dormantClients,
            months: months,
          } as Action);
        })
        .catch((error) => {
          if (error.code === "ECONNABORTED") {
            // Dispatch action: error response received
            dispatch({
              type: "SET_ERROR",
              message: "The API request timed out. Please Refresh the page",
            } as Action);
          } else {
            if (error.response.data.message) {
              dispatch({
                type: "SET_ERROR",
                message: error.response.data.message,
              } as Action);
            }
          }
        });
    },
    [_id, token]
  );

  return { clientDistributionState, fetchClientDistribution };
};

export default useFetchClientDistribution;
