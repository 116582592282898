import { useContext, Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import MattersGrowthTrendsAreaClio from "./growthTrendsArea";
import MattersGrowthTrendsRadial from "./growthTrendsRadial";
import MattersGrowthTrendsStackedBar from "./growthTrendsStackedBar";
import useAuth from "../../../../../hooks/useAuth";
import { PlaceholderAcl } from "../../../../commons/placeholderAcl";

// *****
// Hook - Returns the Previous value of filters using Ref
// *****
function ClientGrowthTrends() {
  const {
    integrationConfigState: {
      integrationObject: {
        matters: { growthTrends },
      },
    },
    generalStates: { acl },
  } = useAuth();
  return (
    <Grid container spacing={2}>
      {!acl.matters_growth_and_trend && <PlaceholderAcl />}
      {acl?.matters_growth_and_trend &&
        growthTrends.map((item, index) => (
          <Fragment key={index}>{item}</Fragment>
        ))}
    </Grid>
  );
}

export default ClientGrowthTrends;
export {
  MattersGrowthTrendsAreaClio,
  MattersGrowthTrendsRadial,
  MattersGrowthTrendsStackedBar,
};
