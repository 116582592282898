import React, { useEffect, useState, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import api from "../../../../../services";
import _ from "lodash";
import ChartLoader from "../../../commons/loaders/chartLoader";
import Colors from "../../../../../theme/ChartTheme";
import ErrorSnackBar from "../../../commons/SnackBar";
import FilterUserTimePractice from "../../../filters/genericFilter/filters";
import { ProductivityGTTypes } from "../../../filters/genericFilter/types";
import usePreviousState, {
  compareFilterDates,
  getMonthsFusion,
  getNestedListByPropertyFusion,
} from "../../utils";
import useDateFilterState, {
  DateStateType,
} from "../../../filters/genericFilter/dateFilterStateHook";
import useFilterState from "../../../filters/genericFilter/filterStateHook";
import EmptyPlaceholder from "../../../commons/placeholders/emptyPlaceholder";
import { DateRange } from "materialui-daterange-picker";
import Chart from "../../../charts/fusionCharts";
import InfoButton from "../../../infoButton";
import { Grid, Paper } from "@material-ui/core";
import UserName from "../../../filters/commons/userName";
import moment from "moment";
import {
  StyledToggleButtonGroup,
  StyledToggleButton,
} from "../../../tabs/styledTabs";
import useAuth from "../../../../../hooks/useAuth";

// *****
// Types
// *****

export interface CumulativeTBvsABProps {}

interface ResponseObject {
  total_hours: number;
  total_value: number;
  forecast_values: number;
  forecast_hours: number;
  month: string;
}

interface ChartStateType {
  targetBilledValues: number[];
  targetedBilledHours: number[];
  actualBilledHours: any[] | [];
  actualBilledValue: any[] | [];
  months: any[] | [];
  forcastedBilledValue: any[] | [];
  forcastedBilledHours: any[] | [];
}

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "visible",
    flexDirection: "column",
    minHeight: 550,
    justifyContent: "center",
  },
  textTitle: {
    color: theme.palette.text.primary,
    fontSize: "1.2em",
    fontWeight: 600,
  },
  headingText: {
    fontSize: "1.2em",
    fontWeight: 600,
    color: theme.palette.text.secondary,
  },
  divider: {
    backgroundColor: "#EDEFF7",
    height: 1,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

// *****
// Initial Filter state
// *****

const initialFilterState: ProductivityGTTypes = {
  practiceArea: "",
  user: "",
  year: {
    label: "Current Year",
    startDate: moment().utc().startOf("year").toDate(),
    endDate: new Date(),
  },
};

const initialDateFilterState: DateStateType = {
  dateAfter: null,
  dateBefore: null,
};

const CumulativeTBvsAB: React.FC<CumulativeTBvsABProps> = () => {
  const [error, setError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [loader, setLoader] = useState<boolean>(true);
  const [chart, setChart] = useState<ChartStateType>({
    targetBilledValues: [],
    targetedBilledHours: [],
    actualBilledHours: [],
    actualBilledValue: [],
    forcastedBilledHours: [],
    forcastedBilledValue: [],
    months: [],
  });
  const [current, setCurrent] = useState<string>("0");

  const classes = useStyles();
  const {
    generalStates: {
      token,
      activeFirm: {
        firm: { _id: firmId },
      },
    },
    integrationConfigState: { pmType },
  } = useAuth();

  // *****
  // Filter States
  // *****
  function prefixSum(nums) {
    let psum = 0;
    return nums.map((x) => (psum += x));
  }
  const { filterRef, filterState, handleState } =
    useFilterState(initialFilterState);
  const { dateFilterRef, dateFilterState, handleDateState } =
    useDateFilterState(initialDateFilterState);

  const prevDateFilterState = dateFilterRef.current;
  const prevFilterState = filterRef.current;

  // *****
  // Series for the chart
  // *****

  // *****
  // Filter Events
  // *****

  const handlePracticeAreas = (value: number | "") => {
    handleState.handlePracticeArea(value);
  };
  const handleYearRange = (value: string) => {
    handleState.handleYearRange(value);
  };

  const handleUser = (value: number | "") => {
    handleState.handleUser(value);
  };

  const handleDateFilterRange = (dateRange: DateRange): void => {
    handleDateState.handleDateUpdate(dateRange);
  };

  // *****
  // Filter Submit
  // Use Case 1: If dates have been applied, then check for date consistency.
  // Use Case 2: All filters have been applied and changed.
  // Use Case 3: Dates have been applied and changed.
  // *****

  const handleFilterSubmit = () => {
    //if filters or date Filters have changed
    if (
      compareFilterDates(
        filterState,
        prevFilterState,
        dateFilterState,
        prevDateFilterState,
        initialFilterState
      ) === true
    ) {
      setLoader(true);
    }
  };

  // *****
  // Clear Filter States
  // *****

  const handleFilterClear = useCallback(() => {
    handleState.reset(initialFilterState);
    handleDateState.resetDate(initialDateFilterState);
    setLoader(true);
  }, []);

  // *****
  // Moving avg calculated after the initial number of months, this returns placeholder zero values for the initial months
  // *****

  useEffect(() => {
    let subscribe = true;
    if (loader) {
      let request = api.create(token);
      let queryParams = {
        user_id: filterState.user === "" ? null : filterState.user,
        practice_area_id:
          filterState.practiceArea === "" ? null : filterState.practiceArea,
        date_after: filterState.year.startDate,
        date_before: filterState.year.endDate,
      };
      let queryParamsTargetedBills = {
        user_id: filterState.user === "" ? null : filterState.user,
        created_after: filterState.year.startDate,
        created_before: filterState.year.endDate,
      };
      Promise.all([
        request.getBilledTime(firmId, pmType, queryParams),
        request.getTotalTargetedBill(firmId, pmType, queryParamsTargetedBills),
      ])
        .then((response) => {
          if (subscribe) {
            let billedResponse: ResponseObject[] = _.cloneDeep(
              response[0].data
            );

            let targetedbillsResponse = _.cloneDeep(response[1].data);
            // *****
            // Calculating total values and hours for the whole year
            // *****

            let targetedBillHours = targetedbillsResponse[0].total_hours * 12;
            let targetedBillValue = targetedbillsResponse[0].total_value * 12;

            // *****
            // Adding predicted data for the whole year
            // *****
            if (billedResponse.length > 0) {
              let [year, month] =
                billedResponse[billedResponse.length - 1].month.split("-");
              let addForcast: any[] = [];
              let hour =
                (targetedBillHours -
                  billedResponse[billedResponse.length - 1].forecast_hours) /
                (13 - parseInt(month));
              let value =
                (targetedBillValue -
                  billedResponse[billedResponse.length - 1].forecast_values) /
                (13 - parseInt(month));
              let temp: any[] = [];
              for (let i = parseInt(month) + 1; i <= 12; i++) {
                temp = addForcast;
                temp.length == 0
                  ? addForcast.push({
                      total_hours: 0,
                      total_value: 0,
                      month: `${year}-${i}`,
                      forecast_hours:
                        billedResponse[billedResponse.length - 1]
                          .forecast_hours + hour,
                      forecast_values:
                        billedResponse[billedResponse.length - 1]
                          .forecast_values + value,
                    })
                  : addForcast.push({
                      total_hours: 0,
                      total_value: 0,
                      month: `${year}-${i}`,
                      forecast_hours:
                        temp[temp.length - 1].forecast_hours + hour,
                      forecast_values:
                        temp[temp.length - 1].forecast_values + value,
                    });
              }
              let correctedBilledResponse = [...billedResponse, ...addForcast];

              let targetedBilledHoursArray = new Array(
                correctedBilledResponse.length
              ).fill({ value: targetedBillHours / 12 });
              let targetedBilledValuesArray = new Array(
                correctedBilledResponse.length
              ).fill({ value: targetedBillValue / 12 });
              let forcastHours = correctedBilledResponse.map((item) => ({
                value: item.month.includes("01")
                  ? item.forecast_hours
                  : (targetedBillHours - item.forecast_hours) /
                    (13 - parseInt(item.month.split("-")[1])),
              }));
              let forcastValues = correctedBilledResponse.map((item) => ({
                value: item.month.includes("01")
                  ? item.forecast_values
                  : (targetedBillValue - item.forecast_values) /
                    (13 - parseInt(item.month.split("-")[1])),
              }));
              let getBilledHours = getNestedListByPropertyFusion(
                correctedBilledResponse,
                "total_hours"
              );
              let getBilledValues = getNestedListByPropertyFusion(
                correctedBilledResponse,
                "total_value"
              );
              let getMonths = getMonthsFusion(correctedBilledResponse, "month");

              // Remove limit of 18 when date filter is applied

              setChart({
                actualBilledHours: prefixSum(
                  getBilledHours.map((i) => i.value)
                ).map((i) => ({ value: i })),
                actualBilledValue: prefixSum(
                  getBilledValues.map((i) => i.value)
                ).map((i) => ({ value: i })),
                targetBilledValues: prefixSum(
                  targetedBilledValuesArray.map((i) => i.value)
                ).map((i) => ({ value: i })),
                targetedBilledHours: prefixSum(
                  targetedBilledHoursArray.map((i) => i.value)
                ).map((i) => ({ value: i })),
                forcastedBilledValue: prefixSum(
                  forcastValues.map((i) => i.value)
                ).map((i) => ({ value: i })),
                forcastedBilledHours: prefixSum(
                  forcastHours.map((i) => i.value)
                ).map((i) => ({ value: i })),
                months: getMonths,
              });
            } else {
              setChart({
                actualBilledHours: [],
                actualBilledValue: [],
                months: [],
                forcastedBilledValue: [],
                targetBilledValues: [],
                targetedBilledHours: [],
                forcastedBilledHours: [],
              });
            }
            setTimeout(() => {
              setLoader(false);
            }, 1500);
          }
        })
        .catch((error) => {
          if (subscribe) {
            if (error.code === "ECONNABORTED") {
              setErrorMsg("The API request timed out. Please Refresh the page");
              setError(true);
            } else {
              setErrorMsg(error.response.data.message);
              setError(true);
            }
          }
        });
    }
    return () => {
      subscribe = false;
    };
  }, [loader]);

  const config = {
    yAxisName: "Hours",
    numberSuffix: " hrs",
  };
  return (
    <Grid item lg={12} md={12} sm={12} xs={12} xl={12}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          {loader ? (
            <ChartLoader />
          ) : (
            <React.Fragment>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <span className={classes.textTitle}>
                  Target, Forecast & Actual Billed Time <i>(Cumulative)</i>
                </span>
                {!(filterState.user == "") && (
                  <UserName id={filterState.user} />
                )}
                <span>
                  <InfoButton
                    title={
                      "Monitor or track employee billed hours based on set goals."
                    }
                  />
                  <FilterUserTimePractice
                    filterState={filterState}
                    disabled={loader}
                    handleUser={handleUser}
                    handleYearRange={handleYearRange}
                    handleFilterSubmit={handleFilterSubmit}
                    handleFilterClear={handleFilterClear}
                    handlePracticeAreas={handlePracticeAreas}
                  />
                </span>
              </Grid>
              <Grid container justifyContent="center">
                <StyledToggleButtonGroup
                  onChange={(event, value) =>
                    value !== null && setCurrent(value)
                  }
                  exclusive
                  value={current}
                  size="small"
                >
                  <StyledToggleButton key={1} value={"0"}>
                    Hour
                  </StyledToggleButton>
                  <StyledToggleButton key={2} value={"1"}>
                    Value
                  </StyledToggleButton>
                </StyledToggleButtonGroup>
              </Grid>

              {chart.actualBilledHours.length > 0 ||
              chart.actualBilledValue.length > 0 ||
              chart.targetedBilledHours.length > 0 ||
              chart.targetBilledValues.length > 0 ? (
                <Chart
                  type="scrollcombi2d"
                  dataSource={{
                    chart: {
                      placevaluesinside: "1",
                      showvalues: "0",
                      plottooltext: "<b>$dataValue</b> $seriesName",
                      theme: "fusion",
                    },
                    categories: [
                      {
                        category: chart.months,
                      },
                    ],
                    dataset:
                      current == "0"
                        ? [
                            {
                              seriesname: "Actual Billed Hour",
                              renderas: "spline",
                              data: chart.actualBilledHours,
                              color: Colors.blue,
                            },
                            {
                              seriesname: "Forecasted Billed Hour",
                              data: chart.forcastedBilledHours,
                              color: Colors.green,
                            },
                            {
                              seriesname: "Target Billed Hour",
                              data: chart.targetedBilledHours,
                              color: Colors.red,
                            },
                          ]
                        : [
                            {
                              seriesname: "Actual Billed Value",
                              renderas: "spline",
                              data: chart.actualBilledValue,
                              color: Colors.blue,
                            },

                            {
                              seriesname: "Forecasted Billed Value",
                              data: chart.forcastedBilledValue,
                              color: Colors.green,
                            },
                            {
                              seriesname: "Target Billed Values",
                              data: chart.targetBilledValues,
                              color: Colors.red,
                            },
                          ],
                  }}
                  height={400}
                />
              ) : (
                <EmptyPlaceholder />
              )}
            </React.Fragment>
          )}
        </Paper>
      </Grid>
      {error ? <ErrorSnackBar errorMsg={errorMsg} /> : null}
    </Grid>
  );
};

export default CumulativeTBvsAB;
