import React, { useEffect, useState } from "react";
import PrivateRoute from "./PrivateRoute";
import { useRouteMatch, useHistory } from "react-router-dom";
import useAuth from "../hooks/useAuth";
// import { ClioContext } from "../containers/Dashboard";

const PrivateOnboardingRoute = ({ component: Component, ...props }) => {
  const {
    generalStates,
    authorizationCodeState: { clio, xero },
  } = useAuth();
  const clioCode = clio?.code || "";
  const user = generalStates?.user;
  const [check, setCheck] = useState<boolean>(true);
  const { path } = useRouteMatch();
  const history = useHistory();

  useEffect(() => {
    if (check) {
      if (user.firmRoles.length === 0) {
        history.replace(`${path}/onboarding`);
        setCheck(false);
        // }else if (user.firmRoles.length > 0 && clioCode.length > 0) {
        //     history.replace(`${path}/onboarding`);
        //     setCheck(false);
      } else {
        setCheck(false);
      }
    }
  }, [check]);

  return user.firmRoles.length > 0  ? (
    <PrivateRoute {...props} component={Component} />
  ) : null;
};

export default PrivateOnboardingRoute;
