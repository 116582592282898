import React, { useEffect, useState, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { DateRange } from "materialui-daterange-picker";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import clsx from "clsx";
import _ from "lodash";
import useDateFilterState, {
  DateStateType,
} from "../../../filters/genericFilter/dateFilterStateHook";
import useFilterState from "../../../filters/genericFilter/filterStateHook";
import { FilterClientsMattersTypes } from "../../../filters/genericFilter/types";
import api from "../../../../../services";
import Colors from "../../../../../theme/ChartTheme";
import ChartLoader from "../../../commons/loaders/chartLoader";
import FilterUserTimePractice from "../../../filters/genericFilter/filters";
import EmptyPlaceholder from "../../../commons/placeholders/emptyPlaceholder";
import ErrorSnackBar from "../../../commons/SnackBar";
import usePreviousState, {
  handleCommClients,
  limitLengthToEighteen,
  checkDate,
  compareMonths,
  getMonthsFusion,
  getNestedListByPropertyFusion,
  getAmountFusion,
} from "../../utils";
import { useMarketingExpenseApi } from "../../MarketingExpenseHook";
import Chart from "../../../charts/fusionCharts";
import InfoButton from "../../../infoButton";
import useAuth from "../../../../../hooks/useAuth";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "visible",
    flexDirection: "column",
    justifyContent: "center",
  },
  lineAreaHeight: {
    height: 500,
  },
  textTitle: {
    color: theme.palette.text.primary,
    fontSize: "1.2em",
  },
  filterWrapper: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  filterInner: {
    marginLeft: theme.spacing(1),
  },
  headingText: {
    fontSize: "1.2em",
    fontWeight: 600,
    color: theme.palette.text.secondary,
  },
  divider: {
    backgroundColor: "#EDEFF7",
    height: 1,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export interface ClientGrowthTrendsAreaProps {}

// *****
// Initial Filter State
// *****

const initialFilterState: FilterClientsMattersTypes = {
  practiceArea: "",
  billingMethod: "",
  originatingAtt: "",
  responsibleAtt: "",
};

const initialDateFilterState: DateStateType = {
  dateAfter: null,
  dateBefore: null,
};

const ClientGrowthTrendsArea: React.FC<ClientGrowthTrendsAreaProps> = () => {
  const [skeleton, setSkeleton] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string | null>();
  const [marketingState, handleInvoke, handleFilters] =
    useMarketingExpenseApi();

  const {
    generalStates: { token, activeFirm },
    integrationConfigState: { integrationConfig, pmType },
  } = useAuth();
  const [totalClientsData, setTotalClientsData] = useState<any[]>([]);
  const [totalClients, setTotalClients] = useState<number[]>([]);
  const [commClients, setCommClients] = useState<number[]>([]);
  const [months, setMonths] = useState<string[]>([]);
  const [movingAvgSize, setMovingAvgSize] = useState<number>(3);
  const [filterMA, setFilterMA] = useState<number>(3);
  const [movAvgSeries, setMovAvgSeries] = useState<number[]>([]);
  const [movingAvgCheck, setMovingAvgCheck] = useState<boolean>(false);
  const [marketingFlag, setMarketingFlag] = useState<boolean>(false);
  const [marketingExpense, setmarketingExpense] = useState<number[]>([]);

  const prevFilterMA = usePreviousState(filterMA);

  // *****
  // Conditionally render useEffect on Filtered Area Chart
  // *****

  const [filteredArea, setFilteredArea] = useState(false);

  // *****
  // Current Values of Filters
  // *****

  const { filterRef, filterState, handleState } =
    useFilterState(initialFilterState);

  // *****
  // Previous Values of Filters
  // *****

  const prevFilterState = filterRef.current;

  // *****
  // Current Values of Date Filters
  // *****

  const { dateFilterRef, dateFilterState, handleDateState } =
    useDateFilterState(initialDateFilterState);

  // *****
  // Previous Values of Date Filters
  // *****

  const prevDateFilterState = dateFilterRef.current;

  const classes = useStyles();
  const lineArea = clsx(classes.paper, classes.lineAreaHeight);

  const newClientSeries = [
    {
      seriesname: "New Clients",
      color: Colors.blue,
      data: totalClients,
    },
  ];
  const newClientMovingAvgSeries = [
    {
      seriesname: "New Clients",
      color: Colors.blue,
      data: totalClients,
    },
    {
      seriesname: "Moving Avg.",
      color: Colors.red,
      renderas: "spline",
      data: movAvgSeries,
    },
  ];

  const marketingExpenseSeries = [
    {
      seriesname: "Marketing Expense",
      renderas: "spline",
      color: Colors.purple,
      data: marketingExpense,
      parentyaxis: "S",
      plottooltext: "<b>$dataValue</b> $seriesName",
      numberprefix: "$",
    },
  ];
  // *****
  // Calculates the moving averages
  // *****

  function sum(numbers) {
    return _.reduce(numbers, (a, b) => a + b, 0);
  }

  function average(numbers) {
    return Math.ceil(sum(numbers) / movingAvgSize);
  }

  function window(_number, index, array) {
    const start = Math.max(0, index - movingAvgSize);
    const end = Math.min(array.length, index);
    return _.slice(array, start, end);
  }

  function movingAverage(numbers) {
    return _.filter(
      _.chain(numbers).map(window).map(average).value(),
      (number, index) => {
        if (index >= movingAvgSize) {
          return number;
        }
      }
    );
  }

  // *****
  // Moving avg calculated after the initial number of months, this returns placeholder zero values for the initial months
  // *****

  function refineMovingAvgArray(array: number[]): number[] {
    let arr = movingAverage(array);
    if (movingAvgSize === 3) {
      arr.splice(0, 0, 0, 0, 0);
    }
    if (movingAvgSize === 6) {
      arr.splice(0, 0, 0, 0, 0, 0, 0, 0);
    }
    if (movingAvgSize === 9) {
      arr.splice(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0);
    }
    return arr;
  }

  // *****
  // Filter state updates
  // *****

  const handlePracticeAreas = (value: number | "") => {
    handleState.handlePracticeArea(value);
  };

  const handleBillingMethod = (value: string) => {
    handleState.handleBillingMethod(value);
  };

  const handleOriginatingAtt = (value: number | "") => {
    handleState.handleOriginatingAtt(value);
  };

  const handleResponsibleAtt = (value: number | "") => {
    handleState.handleResponsibleAtt(value);
  };

  const handleDateFilterRange = (dateRange: DateRange): void => {
    handleDateState.handleDateUpdate(dateRange);
    handleFilters(dateRange);
  };

  const handleMovingAvg = useCallback(
    (value: number) => {
      setFilterMA(value);
    },
    [filterMA]
  );

  // *****
  // Filter Submit
  // Use Case 1: if moving average is the only one that has changed and all others have not, then invoke moving avg effect
  // Use Case 2: If dates have been applied, then check for date consistency.
  // Use Case 3: All filters have been applied and changed.
  // *****

  const handleFilterSubmit = () => {
    //if filters or date Filters have changed
    if (
      _.isEqual(filterState, prevFilterState) === false ||
      _.isEqual(dateFilterState, prevDateFilterState) === false
    ) {
      //nested - then check if both date parameters are applied with second greater than the first
      if (
        checkDate(dateFilterState.dateAfter, dateFilterState.dateBefore) ===
        true
      ) {
        setFilteredArea(true);
        //nested - or if none of them are applied
      } else if (
        dateFilterState.dateAfter === null &&
        dateFilterState.dateBefore === null
      ) {
        setFilteredArea(true);
        //nested - or if both(filters and date filters) have been 'cleared'
      } else if (
        _.isEqual(filterState, initialFilterState) === true &&
        _.isEqual(dateFilterState, initialDateFilterState) === true
      ) {
        setFilteredArea(true);
      }
      // else check if only moving averages have been applied
    } else if (filterMA !== prevFilterMA) {
      setMovingAvgCheck(true);
      setMovingAvgSize(filterMA);
    }
  };

  // *****
  // Clear Filter States
  // *****

  const handleFilterClear = useCallback(() => {
    handleState.reset(initialFilterState);
    handleDateState.resetDate(initialDateFilterState);
    handleFilters(initialFilterState);
    setFilteredArea(true);
    setFilterMA(3);
  }, [filterMA]);

  // *****
  // Set Marketing Expense flag to true, if integration state is "CLIO_XERO"
  // *****

  const checkMarketingHookResponse = () => {
    if (integrationConfig === "CLIO_XERO") {
      setMarketingFlag(true);
    } else {
      setSkeleton(false);
    }
  };

  // *****
  //  Marketing Expense flag runs this effect to calculate expense data
  // *****

  useEffect(() => {
    if (marketingFlag) {
      if (
        marketingState.response.data.length > 0 &&
        !marketingState.error.status
      ) {
        let marketingData = _.cloneDeep(marketingState.response.data);
        let result = compareMonths(marketingData, totalClientsData);
        let totalList = limitLengthToEighteen(getAmountFusion(result));
        setmarketingExpense(totalList);
        setMarketingFlag(false);
        setSkeleton(false);
      } else if (marketingState.error.status) {
        setSkeleton(false);
        setError(true);
        setErrorMsg(marketingState.error.message);
      } else {
        setSkeleton(false);
        setMarketingFlag(false);
      }
    }
  }, [marketingFlag]);

  useEffect(() => {
    let subscribe = true;
    if (subscribe) {
      if (filteredArea || skeleton) {
        let request = api.create(token);
        let firmId = activeFirm.firm._id;
        let queryParams = {
          practice_area_id:
            filterState.practiceArea === "" ? null : filterState.practiceArea,
          billing_method:
            filterState.billingMethod === "" ? null : filterState.billingMethod,
          originating_attorney_id:
            filterState.originatingAtt === ""
              ? null
              : filterState.originatingAtt,
          responsible_attorney_id:
            filterState.responsibleAtt === ""
              ? null
              : filterState.responsibleAtt,
          created_after: dateFilterState.dateAfter,
          created_before: dateFilterState.dateBefore,
        };

        //Runs the marketing expense API - hook limits api call if integrationConfig !== "CLIO_XERO"
        handleInvoke();

        request
          .getNewClients(firmId, pmType, queryParams)
          .then((res) => {
            if (subscribe) {
              let data = res.data;
              setTotalClientsData(data);
              let getClients = getNestedListByPropertyFusion(
                data,
                "total_clients"
              );
              // Remove limit of 18 on date filters
              let clients =
                dateFilterState.dateAfter !== null &&
                dateFilterState.dateBefore !== null
                  ? getClients
                  : limitLengthToEighteen(getClients);

              setCommClients(handleCommClients(clients));
              setTotalClients(clients);

              // Remove limit of 18 on date filters
              let getMonths = getMonthsFusion(data, "month");

              let months =
                dateFilterState.dateAfter !== null &&
                dateFilterState.dateBefore !== null
                  ? getMonths
                  : limitLengthToEighteen(getMonths);
              setMonths(months);

              //If Moving Avg was an applied filter as well.

              if (movingAvgSize !== filterMA) {
                setMovingAvgSize(filterMA);
                setMovingAvgCheck(true);
                setFilteredArea(false);
              } else {
                let refined = refineMovingAvgArray(
                  _.flatMap(clients, (item) => item.value)
                );

                setMovAvgSeries(
                  _.flatMap(refined, (item) => ({ value: item }))
                );
                setTimeout(() => {
                  setFilteredArea(false);
                  // Check integrationConfig to update effect
                  checkMarketingHookResponse();
                }, 1000);
              }
            }
          })
          .catch((error) => {
            if (subscribe) {
              if (error.code === "ECONNABORTED") {
                setErrorMsg(
                  "The API request timed out. Please Refresh the page"
                );
                setError(true);
              } else {
                setErrorMsg(error.response.data.message);
                setError(true);
              }
            }
          });
      }
    }
    return () => {
      subscribe = false;
    };
  }, [filteredArea, skeleton]);

  // *****
  // Update Moving Average and rerender
  // *****

  useEffect(() => {
    let refined = refineMovingAvgArray(
      _.flatMap(totalClients, (item) => item.value)
    );
    setMovAvgSeries(_.flatMap(refined, (item) => ({ value: item })));
    setTimeout(() => {
      setMovingAvgCheck(false);
    }, 500);
  }, [movingAvgSize]);
  const chartConfig = {
    plottooltext: "<b>$dataValue</b> $seriesName",
    yaxisname: "Number of Clients",
    lineThickness: "4",
    snumberprefix: "$",
  };

  return (
    <Grid item lg={6} md={6} sm={12} xs={12} xl={6}>
      <Paper className={lineArea}>
        {filteredArea || skeleton || movingAvgCheck ? (
          <ChartLoader />
        ) : (
          <React.Fragment>
            <Grid container justifyContent="space-between" alignItems="center">
              <span className={classes.textTitle}>New Clients per month</span>
              <span>
                <InfoButton
                  title={
                    "Number of new Clients during a specified time period."
                  }
                />
                <FilterUserTimePractice
                  filterState={filterState}
                  movingAvg={filterMA}
                  dateFilterState={dateFilterState}
                  disabled={skeleton || filteredArea || movingAvgCheck}
                  handleFilterSubmit={handleFilterSubmit}
                  handleFilterClear={handleFilterClear}
                  handlePracticeAreas={handlePracticeAreas}
                  handleBillingMethod={handleBillingMethod}
                  handleMovingAvg={handleMovingAvg}
                  handleOriginatingAtt={handleOriginatingAtt}
                  handleResponsibleAtt={handleResponsibleAtt}
                  handleDateFilterRange={handleDateFilterRange}
                />
              </span>
            </Grid>
            {totalClients.length > 0 ? (
              <Chart
                labels={months}
                type={
                  marketingExpense.length > 0
                    ? "scrollcombidy2d"
                    : "scrollcombi2d"
                }
                series={
                  marketingExpense.length > 0 && totalClients.length > 3
                    ? [...newClientMovingAvgSeries, ...marketingExpenseSeries]
                    : marketingExpense.length > 0 && totalClients.length < 3
                    ? [...newClientSeries, ...marketingExpenseSeries]
                    : totalClients.length > 3
                    ? newClientMovingAvgSeries
                    : newClientSeries
                }
                height={400}
                config={chartConfig}
              />
            ) : (
              <EmptyPlaceholder />
            )}
          </React.Fragment>
        )}
      </Paper>
      {error ? <ErrorSnackBar errorMsg={errorMsg} /> : null}
    </Grid>
  );
};

export default ClientGrowthTrendsArea;
