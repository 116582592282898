import InputBase from "@material-ui/core/InputBase";
import {
  createStyles,
  alpha,
  Theme,
  withStyles,
} from "@material-ui/core/styles";

const StyledInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "label + &": {
        marginTop: theme.spacing(1),
      },
      "&:error": {
        boxShadow: `${alpha(theme.palette.error.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.error.main,
      },
    },
    error: {
      boxShadow: `${alpha(theme.palette.error.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.error.main,
    },
    input: {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.common.white,
      border: "1px solid #dadaed",
      fontSize: 16,
      width: "100%",
      padding: "10px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      "&:focus": {
        boxShadow: `${alpha(theme.palette.primary.light, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.light,
      },
    },
  })
)(InputBase);

export { StyledInput };
