import React, { useContext, useState, useEffect } from "react";
import PrivateRoute from "../../../router/PrivateRoute";
import { Switch, useRouteMatch } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import StripeConnect from "./stripeConnect";
import StripeInput from "./stripe/stripeInput";
import SubscriptionInfo from "./subscriptionInfo";
import {
  StyledToggleButtonGroup,
  StyledToggleButton,
} from "../../dashboard/tabs/styledTabs";
import TrialExpiry from "../../dashboard/commons/trialExpiry";
import useAuth from "../../../hooks/useAuth";
import SyncPlaceholder from "../../dashboard/commons/placeholders/SyncPlaceholder";
export interface PaymentsProps {}

const Payments: React.FC<PaymentsProps> = () => {
  const {
    generalStates: { paymentInfo },
    currentAppState: {
      status: { code, clioStatus, xeroStatus, ppStatus },
    },
  } = useAuth();

  const [current, setCurrent] = useState<string>("");

  useEffect(() => {
    if (!paymentInfo?.subscribedClio && paymentInfo?.subscribedXero) {
      setCurrent("xero");
    } else if (paymentInfo?.subscribedClio && !paymentInfo?.subscribedXero) {
      setCurrent("clio");
    } else if (paymentInfo?.subscribedPP && !paymentInfo?.subscribedXero) {
      setCurrent("pp");
    } else {
      setCurrent("clio");
    }
  }, [paymentInfo, code]);
  const { path } = useRouteMatch();
  function returnTruth() {
    if (current == "xero") {
      if (xeroStatus === 2) {
        return true;
      } else {
        return false;
      }
    } else if (current == "clio") {
      if (clioStatus === 2) {
        return true;
      } else {
        return false;
      }
    } else if (current == "pp") {
      console.log("current pp");
      if (ppStatus === 2) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  function returnObject() {
    if (current === "xero") {
      return paymentInfo?.subscribedXero?.billingSubscriptionDetails?.isFree;
    }
    if (current === "clio") {
      return paymentInfo?.subscribedClio?.billingSubscriptionDetails?.isFree;
    }
    if (current === "pp") {
      return paymentInfo?.subscribedPP?.billingSubscriptionDetails?.isFree;
    }
  }
  return (
    <Grid container spacing={3}>
      <StyledToggleButtonGroup
        onChange={(event, value) => value !== null && setCurrent(value)}
        exclusive
        value={current}
        size="small"
      >
        <StyledToggleButton
          disabled={clioStatus == 0}
          key={"clio"}
          value={"clio"}
        >
          Clio
        </StyledToggleButton>
        <StyledToggleButton
          disabled={xeroStatus == 0}
          key={"xero"}
          value={"xero"}
        >
          Xero
        </StyledToggleButton>
        <StyledToggleButton disabled={ppStatus == 0} key={"pp"} value={"pp"}>
          Practice Panther
        </StyledToggleButton>
      </StyledToggleButtonGroup>
      {code.includes("4") && (current == "clio" || current == "pp") ? (
        <Grid item xs={12}>
          <SyncPlaceholder />
        </Grid>
      ) : (
        <>
          <Grid item xs={12}>
            <TrialExpiry current={current} />
          </Grid>
          <Grid item xs={12}>
            {returnObject() === false && (
              <Switch>
                <PrivateRoute
                  path={`${path}/subscription`}
                  component={
                    returnTruth()
                      ? () => <StripeConnect current={current} />
                      : () => <StripeInput current={current} />
                  }
                />
                <PrivateRoute
                  exact
                  path={`${path}`}
                  component={() => <StripeConnect current={current} />}
                />
              </Switch>
            )}
          </Grid>
          {(paymentInfo?.subscribedXero ||
            paymentInfo?.subscribedClio ||
            paymentInfo?.subscribedPP) && (
            <Grid item xs={12}>
              <SubscriptionInfo current={current} />
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};

export default Payments;
