const data = [
  {
    heading: {
      title: "CLIO Integration",
      url: "https://support.firmtrak.com/hc/en-us/categories/4402384135195-CLIO-Integration-",
    },
    list: [
      {
        title: "Getting Started",
        sub: true,
        url: "https://support.firmtrak.com/hc/en-us/sections/4402384146971-Getting-Started",
      },
      {
        title: "Registration",
        url: "https://support.firmtrak.com/hc/en-us/articles/1260806411310-Registration-",
      },
      {
        title: "Firm Integration ",
        url: "https://support.firmtrak.com/hc/en-us/articles/1260806473870-Firm-integration",
      },
      {
        title: "Data Sync",
        url: "https://support.firmtrak.com/hc/en-us/articles/1260806474130-Data-Sync-",
      },
      {
        title: "Setups",
        sub: true,
        url: "https://support.firmtrak.com/hc/en-us/sections/4402376165787-Setups-",
      },
      {
        title: "Billing",
        url: "https://support.firmtrak.com/hc/en-us/articles/1260806474570-Billing-",
      },
      {
        title: "Users",
        url: "https://support.firmtrak.com/hc/en-us/articles/1260806435769-Users",
      },
      {
        title: "Employee Setup",
        url: "https://support.firmtrak.com/hc/en-us/articles/1260806474170-Employee-Setup",
      },
      {
        title: "Dashboard",
        sub: true,
        url: "https://support.firmtrak.com/hc/en-us/sections/4402376237595-Dashboard-",
      },
      {
        title: "AR",
        url: "https://support.firmtrak.com/hc/en-us/articles/1260806615610-AR",
      },
      {
        title: "Trust",
        url: "https://support.firmtrak.com/hc/en-us/articles/1260806587309-Trust",
      },
      {
        title: "Matter Tracker",
        url: "https://support.firmtrak.com/hc/en-us/articles/1260806615550-Matter-Tracker",
      },
      {
        title: "Productivity ",
        url: "https://support.firmtrak.com/hc/en-us/articles/1260806504410-Productivity",
      },
      {
        title: "Financials",
        url: "https://support.firmtrak.com/hc/en-us/articles/1260806467629-Financials",
      },
      {
        title: "Matters",
        url: "https://support.firmtrak.com/hc/en-us/articles/1260806504050-Matters",
      },
      {
        title: "Clients",
        url: "https://support.firmtrak.com/hc/en-us/articles/1260806437109-Clients",
      },
      {
        title: "Home",
        url: "https://support.firmtrak.com/hc/en-us/articles/1260806474750-Home",
      },
      {
        title: "Frequently Asked Questions",
        sub: true,
        url: "https://support.firmtrak.com/hc/en-us/sections/4402376235931-Frequently-Asked-Questions-",
      },
      {
        title: "How is pricing determined?",
        url: "https://support.firmtrak.com/hc/en-us/articles/4402032358427-How-is-pricing-determined-",
      },
      {
        title: "How much time does it take to sync CLIO?",
        url: "https://support.firmtrak.com/hc/en-us/articles/4402032221083-How-much-time-does-it-take-to-sync-CLIO-",
      },
      {
        title: "How do I add multiple firms?",
        url: "https://support.firmtrak.com/hc/en-us/articles/4402032094235-How-do-I-add-multiple-firms-",
      },
      {
        title: "Does each user have to create their own firm?",
        url: "https://support.firmtrak.com/hc/en-us/articles/4402024238107-Does-each-user-have-to-create-their-own-firm-",
      },
    ],
  },
  {
    heading: {
      title: "XERO Integration",
      url: "https://support.firmtrak.com/hc/en-us/categories/4402384126235-XERO-Integration",
    },
    list: [
      {
        title: "Getting Started",
        sub: true,
        url: "https://support.firmtrak.com/hc/en-us/articles/4402384085019-Registration",
      },
      {
        title: "Firm Integration",
        url: "https://support.firmtrak.com/hc/en-us/articles/4402384030363-Firm-integration",
      },
      {
        title: "Data Sync",
        url: "https://support.firmtrak.com/hc/en-us/articles/4402384081563-Data-Sync-",
      },
      {
        title: "Data Flow Diagram",
        url: "https://support.firmtrak.com/hc/en-us/articles/4402384455707-Data-Flow-Diagram-",
      },
      {
        title: "Setups",
        sub: true,
        url: "https://support.firmtrak.com/hc/en-us/sections/4402384238619-Setups",
      },
      {
        title: "Billing",
        url: "https://support.firmtrak.com/hc/en-us/articles/4402384249243-Billing",
      },
      {
        title: "Users",
        url: "https://support.firmtrak.com/hc/en-us/articles/4402384253851-Users",
      },
      {
        title: "Account Setup",
        url: "https://support.firmtrak.com/hc/en-us/articles/1260806435569-Accounts-Setup",
      },
      {
        title: "Dashboard",
        sub: true,
        url: "https://support.firmtrak.com/hc/en-us/sections/4402376241691-Dashboard-",
      },
      {
        title: "Financials",
        url: "https://support.firmtrak.com/hc/en-us/articles/4402376312219-Financials",
      },
      {
        title: "Frequently Asked Questions",
        sub: true,
        url: "https://support.firmtrak.com/hc/en-us/sections/4402384296091--Frequently-Asked-Questions-",
      },
      {
        title: "How is pricing determined?",
        url: "https://support.firmtrak.com/hc/en-us/articles/4402376256283-How-is-pricing-determined-",
      },
      {
        title: "How much time does it take to sync XERO?",
        url: "https://support.firmtrak.com/hc/en-us/articles/4402384320795-How-much-time-does-it-take-to-sync-XERO-",
      },
      {
        title: "How can I disconnect my firm?",
        url: "https://support.firmtrak.com/hc/en-us/articles/4402384324123-How-can-I-disconnect-my-firm-",
      },
      {
        title: "How do I add multiple firms?",
        url: "https://support.firmtrak.com/hc/en-us/articles/4402384329499-How-do-I-add-multiple-firms-",
      },
      {
        title: "Does each user have to create their own firm?",
        url: "https://support.firmtrak.com/hc/en-us/articles/4402376295579-Does-each-user-have-to-create-their-own-firm-",
      },
    ],
  },
];

export { data };
