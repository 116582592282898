import { Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import ChartLoader from "../../commons/loaders/chartLoader";
import EmptyPlaceholder from "../../commons/placeholders/emptyPlaceholder";
import api from "../../../../services";
import numeral from "numeral";
import FilterUserTimePractice from "../../filters/genericFilter/filters";
import useDateFilterState, { DateStateType } from "../../filters/genericFilter/dateFilterStateHook";
import { thisYear } from "../../filters/commons/dateRangePicker/static";
import useFilterState from "../../filters/genericFilter/filterStateHook";
import { DateRange } from "materialui-daterange-picker";
import { compareFilterDates } from "../utils";

type Props = {
  firmId: string;
  isAllowed: boolean;
  token: string;
};


const useStyles = makeStyles((theme) => ({
  headingText: {
      fontSize: '1.5em',
      fontWeight: theme.typography.fontWeightMedium,
      color: theme.palette.text.primary
  },
  paperConatiner: {
      padding: theme.spacing(2),
  },
  paperBox: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'visible',
    flexDirection: 'column',
    justifyContent: 'center',
    height: 150,
  },
  textTitle: {
      color: theme.palette.text.primary,
      fontSize: '1.2em',
      fontWeight: 600
  }
}));

function ETT({isAllowed, firmId, token}: Props) {
  const [loader, setLoader] = useState(true);
  const [data, setData] = useState<any>({});
  const classes = useStyles();

  const initialFilterState: any = {
    billingMethod: ""
  };

  const initialDateFilterState: DateStateType = {
    dateAfter: thisYear.startDate,
    dateBefore: thisYear.endDate,
  };

  const { filterRef, filterState, handleState } = useFilterState(initialFilterState);
  const { dateFilterRef, dateFilterState, handleDateState } = useDateFilterState(initialDateFilterState);

  const prevDateFilterState = dateFilterRef.current;
  const prevFilterState = filterRef.current;

  useEffect(() => {
    if (loader && firmId && token && isAllowed) {

      let request = api.create(token);
      request.getEstimatedTrustTransfer(firmId, {
        date_after: dateFilterState.dateAfter,
        date_before: dateFilterState.dateBefore,
        billing_method: filterState.billingMethod === "" ? null : filterState.billingMethod,
      })
        .then(res => {
          setData(res.data)
          setLoader(false)
        })
        .catch(error => {
          setLoader(false)
          console.log(error)
        })
    }
  }, [loader, isAllowed])

  const handleDateFilterRange = (dateRange: DateRange): void => {
    handleDateState.handleDateUpdate(dateRange);
  };

  const handleBillingMethod = (value: string | "") => {
    handleState.handleBillingMethod(value);
  };

  const handleFilterSubmit = () => {
    //if filters or date Filters have changed
    const isChange = compareFilterDates(
      filterState,
      prevFilterState,
      dateFilterState,
      prevDateFilterState,
      initialFilterState
    )
    if (isChange) {
      console.log("FILTER", {filterState, dateFilterState})
      setLoader(true);
    } 
  };

  const handleFilterClear = useCallback(() => {
    handleState.reset(initialFilterState);
    handleDateState.resetDate(initialDateFilterState);
    setLoader(true);
  }, []);

  if (!isAllowed) {
    return <></>
  }
  
  if (loader) {
    return (
      <Grid item lg={12} md={12} sm={12} xs={12} xl={12}>
        <Paper className={classes.paperConatiner}>
          <ChartLoader />
        </Paper>
      </Grid>
    )
  }

  return (
    <Paper className={classes.paperConatiner}>
    <Grid container spacing={1}>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between" alignItems="center">
            <span className={classes.textTitle}>Estimated Trust Transfer</span>
            
            <FilterUserTimePractice
              filterState={filterState}
              dateFilterState={dateFilterState}
              disabled={loader}
              handleDateFilterRange={handleDateFilterRange}
              handleFilterSubmit={handleFilterSubmit}
              handleFilterClear={handleFilterClear}
              handleBillingMethod={handleBillingMethod}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          {data.length === 0 ? <EmptyPlaceholder /> : (
            <Grid container spacing={3}>
              <Grid item xs>
                <Paper className={classes.paperBox} elevation={6}>
                  <Typography variant="h5" gutterBottom align="center">Unbilled Fee</Typography>
                  <Typography variant="h4" align="center">{numeral(data.billed_value).format("($ 0.00 a)")}</Typography>
                </Paper>
              </Grid>
              
              <Grid item xs>
                <Paper className={classes.paperBox} elevation={6}>
                  <Typography variant="h5" gutterBottom align="center">Unbilled Expense</Typography>
                  <Typography variant="h4" align="center">{numeral(data.billed_expense).format("($ 0.00 a)")}</Typography>
                </Paper>
              </Grid>
              
              <Grid item>
                <Paper className={classes.paperBox} elevation={6}>
                  <Typography variant="h5" gutterBottom align="center">Total Work in Progress</Typography>
                  <Typography variant="h4" align="center">{numeral(data.total_CWIP).format("($ 0.00 a)")}</Typography>
                </Paper>
              </Grid>
          
              <Grid item>
                <Paper className={classes.paperBox} elevation={6}>
                  <Typography variant="h5" gutterBottom align="center">Estimated Trust Transfer</Typography>
                  <Typography variant="h4" align="center">{numeral(data.trust_transfer).format("($ 0.00 a)")}</Typography>
                </Paper>
              </Grid>

              <Grid item xs>
                <Paper className={classes.paperBox} elevation={6}>
                  <Typography variant="h3" align="center">{numeral(data.trust_transfer / data.total_CWIP).format("0.00%")}</Typography>
                </Paper>
              </Grid>
            </Grid>
          )}
        </Grid>
    </Grid>
    </Paper>
  )
}

export default ETT;