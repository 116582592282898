import { useEffect, useState } from "react";
import { Route, Redirect, useRouteMatch } from "react-router-dom";
import jwt_decode from "jwt-decode";
import useAuth from "../hooks/useAuth";
//If the user is not authenticated, redirect to login.

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const {
    generalStates: { token, user },
    generalActions,
    onboardingActions,
  } = useAuth();
  const { path } = useRouteMatch();
  const [expired, setExpired] = useState<Boolean>(false);
  useEffect(() => {
    if (token && path.includes("dashboard")) {
      const decoded: any = jwt_decode(token);
      let current = Date.now() / 1000;
      // alert(decoded.exp > current);
      if (decoded.exp && decoded.exp < current) {
        generalActions.setToken(null);
        generalActions.setRefreshToken(null);
        onboardingActions.setFirmName("");
        onboardingActions.setOnboardingStep(0);
        generalActions.setActiveFirm(null);
        generalActions.setUser(null);
        setExpired(true);
      } else {
        setExpired(false);
      }
    }
  }, [path]);
  return (
    <Route
      {...rest}
      render={(props) =>
        user && !expired ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
};
export default PrivateRoute;
