const Images = {
  CLIO: require("./clio.png").default,
  logo: require("./logo.png").default,
  integration: require("./integration.png").default,
  logoLarge: require("./logoLarge.png").default,
  stripeLogo: require("./stripeLogo.png").default,
  login: require("./login.png").default,
  ftLogo: require("./ft-logo.png").default,
  ftBanner: require("./ft-banner.png").default,
  ftBannerSignup: require("./ft-banner-2.png").default,
  circle: require("./shapes/circle.png").default,
  outlinedCircle: require("./shapes/outlinedCircle.png").default,
  pie: require("./shapes/pie.png").default,
  boxesBox: require("./shapes/boxesBox.png").default,
  dashboard: require("./dashboard.png").default,
  trendAnalysis: require("./trendAnalysis.png").default,
  topBottomTen: require("./topBottomTen.png").default,
  matterManagement: require("./matterManagement.png").default,
  employeeManagement: require("./employeeManagement.png").default,
  arManagement: require("./arManagement.png").default,
  FT: require("./FT.png").default,
  notification: require("./notification.png").default,
  switch: require("./switch.png").default,
  XERO: require("./xero.png").default,
  settings: require("./settings.png").default,
  integrationIcon: require("./integrationIcon.png").default,
  bars: require("./bars.png").default,
  gBars: require("./gBars.png").default,
  rBars: require("./rBars.png").default,
  yBars: require("./yBars.png").default,
  bBars: require("./bBars.png").default,
  linkIcon: require("./linkIcon.png").default,
  ytd: require("./ytd.png").default,
  currentMonth: require("./currentMonth.png").default,
  previousYear: require("./previousYear.png").default,
  quarterly: require("./quarterly.png").default,
  PP: require("./pp.png").default,
};

export default Images;
