import React, { useContext, Fragment } from "react";
import TBvsAb from "./TBvsAb";
import CumulativeTBvsAB from "./CumulativeTBvsAB";
import WorkedBilledCollectedRadial from "./Radial";
import Utilization from "./Utilization";
import Grid from "@material-ui/core/Grid";
import HeatMap from "./HeatMap";
import useAuth from "../../../../../hooks/useAuth";
import { PlaceholderAcl } from "../../../../commons/placeholderAcl";
export interface ProductivityGrowthTrendsProps {}

const ProductivityGrowthTrends: React.FC<ProductivityGrowthTrendsProps> =
  () => {
    const {
      integrationConfigState: {
        integrationObject: {
          productivity: { growthTrends },
        },
      },
      generalStates: { acl },
    } = useAuth();
    console.log("GROWT", {growthTrends})
    return (
      <Grid container spacing={2}>
        {!acl?.productivity_growth_and_trend && <PlaceholderAcl />}
        {acl?.productivity_growth_and_trend &&
          growthTrends.map((item, index) => (
            <Fragment key={index}>{item}</Fragment>
          ))}
      </Grid>
    );
  };

export default ProductivityGrowthTrends;
export {
  TBvsAb,
  WorkedBilledCollectedRadial,
  Utilization,
  HeatMap,
  CumulativeTBvsAB,
};
