import React from "react";
import useAuth from "../../../../hooks/useAuth";
import { PlaceholderAcl } from "../../../commons/placeholderAcl";
import AR from "./ar";
interface Props {}

const ARContainer = (props: Props) => {
  const {
    generalStates: { acl },
  } = useAuth();
  return acl?.ar ? <AR /> : <PlaceholderAcl />;
};
export default ARContainer;
