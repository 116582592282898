import { IconButton, Tooltip } from "@material-ui/core";
import React from "react";
import InfoIcon from "@material-ui/icons/Info";

interface IInfoButtonProps {
  title: string | any;
}

const InfoButton: React.FunctionComponent<IInfoButtonProps> = ({ title }) => {
  return (
    <Tooltip title={title}>
      <IconButton>
        <InfoIcon />
      </IconButton>
    </Tooltip>
  );
};

export default InfoButton;
