import { useEffect, useState, useLayoutEffect, useRef } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import AdminPayments from "./payments/admin/adminPayments";
import {
  Grid,
  Paper,
  ListItemText,
  ListSubheader,
  MenuList,
  MenuItem,
} from "@material-ui/core";

import {
  Link,
  useRouteMatch,
  Redirect,
  Switch,
  useLocation,
} from "react-router-dom";

// import Definitions from "./definitions";
import EmployeeSetup from "./employeeSetup";
import AccountSetup from "./accountSetup";
import BasicInformation from "./basicInformation";
import Users from "./users";
import Integrations from "./integrations/integrations";
import Payments from "./payments/payments";
import TrialExpiry from "../dashboard/commons/trialExpiry";
import IntegrationPlaceholder from "../dashboard/commons/placeholders/IntegrationPlaceholder";
import PersonalInformation from "./personalInformation";
import ChangePassword from "./changePassword";
import SyncPlaceholder from "../dashboard/commons/placeholders/SyncPlaceholder";
import useAuth from "../../hooks/useAuth";
import PrivateRoute from "../../router/PrivateRoute";
import MatterTrackerSetup from "./matterTrackerSetup";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(8),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    position: "sticky",
    top: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    height: `calc(100vh - ${theme.spacing(8)}px)`,
  },
  container: {
    paddingTop: theme.spacing(6),
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  listHeader: {
    color: theme.palette.text.primary,
    fontSize: "1.2em",
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

const listItems = [
  {
    category: "Setups",
    disabled: true,
    items: [
      // { label: "Definitions", number: 0, url: "definitions" },
      { label: "Employee Setup", url: "employee-setup", number: 0 },
      { label: "Account Setup", url: "account-setup", number: 1 },
      { label: "Matter Tracker Setup", url: "matter-tracker-setup", number: 2 },
      // { label: "Chart of Accounts", url: "chart-of-accounts", number: 2 }
    ],
  },
  {
    category: "Firm Settings",
    disabled: false,
    items: [
      { label: "Basic Information", url: "basic-information", number: 3 },
      // { label: "Roles", url: "roles", number: 4 },
      { label: "Users", url: "users", number: 4 },
      { label: "Integration", url: "integration", number: 5 },
      // {
      //   label: "Secondary Integration",
      //   url: "secondary-integration",
      //   number: 7
      // },
      { label: "Billing", url: "payments", number: 6 },
    ],
  },
  {
    category: "Profile",
    disabled: false,
    items: [
      {
        label: "Personal Information",
        url: "personal-information",
        number: 7,
      },
      { label: "Change Password", url: "change-password", number: 8 },
    ],
  },
];

export default function SelectedListItem() {
  const classes = useStyles();
  const { path } = useRouteMatch();
  const { pathname } = useLocation();
  const {
    generalStates: {
      tutorial,
      activeFirm,
      user: { userType },
    },
    currentAppState: {
      clioHasDuplicate,
      status: { code, clioStatus, ppStatus, xeroStatus },
    },
    generalActions: { setTutorialPosition },
  } = useAuth();
  const empSetupRef = useRef<any>(null);
  const integrationRef = useRef<any>(null);
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (tutorial) {
      const { offsetTop: empTop, offsetWidth: empWidth } = empSetupRef.current;
      const { offsetTop: integrationTop, offsetWidth: integrationWidth } =
        integrationRef.current;
      if (pathname.includes("employee-setup")) {
        setTutorialPosition({
          top: empTop,
          width: empWidth,
        });
      } else if (pathname.includes("account-setup")) {
        setTutorialPosition({
          top: empTop + 100,
          width: empWidth,
        });
      } else {
        setTutorialPosition({
          top: integrationTop + 50,
          width: integrationWidth,
        });
      }
    }
  }, [pathname]);
  // const handleListItemClick = (event, index: number) => {
  //   setSelectedIndex(index);
  // };);
  const selected = {
    height: 44,
    color: "#1488CC",
    backgroundColor: "white",
    fontSize: "14px",
  };
  const unSelected = { height: 44, color: "#6e707e", fontSize: "14px" };
  return (
    <Grid container>
      <Grid item xl={2} xs={12} sm={12} md={2} lg={2}>
        <Paper className={classes.root}>
          <MenuList
            style={{ paddingBottom: 32 }}
            ref={empSetupRef}
            subheader={
              <ListSubheader
                classes={{ root: classes.listHeader }}
                component="span"
                id="nested-list-subheader"
              >
                {listItems[0].category}
              </ListSubheader>
            }
          >
            {listItems[0].items.map((item, index) => (
              <MenuItem
                key={index}
                to={`${path}/${item.url}`}
                component={Link}
                style={pathname.endsWith(item.url) ? selected : unSelected}
                button
                selected={pathname.endsWith(item.url)}
              >
                <ListItemText primary={item.label} />
              </MenuItem>
            ))}
          </MenuList>
          <MenuList
            style={{ paddingBottom: 32 }}
            ref={integrationRef}
            subheader={
              <ListSubheader
                classes={{ root: classes.listHeader }}
                component="span"
                id="nested-list-subheader"
              >
                {listItems[1].category}
              </ListSubheader>
            }
          >
            {listItems[1].items.map((item, index) => (
              <MenuItem
                key={index}
                to={`${path}/${item.url}`}
                component={Link}
                style={pathname.endsWith(item.url) ? selected : unSelected}
                button
                selected={pathname.endsWith(item.url)}
              >
                <ListItemText primary={item.label} />
              </MenuItem>
            ))}
          </MenuList>
          <MenuList
            style={{ paddingBottom: 32 }}
            subheader={
              <ListSubheader
                classes={{ root: classes.listHeader }}
                component="span"
                id="nested-list-subheader"
              >
                {listItems[2].category}
              </ListSubheader>
            }
          >
            {listItems[2].items.map((item, index) => (
              <MenuItem
                key={index}
                to={`${path}/${item.url}`}
                component={Link}
                style={pathname.endsWith(item.url) ? selected : unSelected}
                button
                selected={pathname.endsWith(item.url)}
              >
                <ListItemText primary={item.label} />
              </MenuItem>
            ))}
          </MenuList>
        </Paper>
      </Grid>
      <Grid
        item
        xl={10}
        lg={10}
        xs={12}
        sm={12}
        md={10}
        className={classes.container}
      >
        <Switch>
          <PrivateRoute
            path={`${path}/employee-setup`}
            component={
              clioStatus == 0 && ppStatus == 0 && !clioHasDuplicate
                ? IntegrationPlaceholder
                : (ppStatus == 2 || clioStatus == 2) &&
                  userType !== "SUPER_ADMIN" &&
                  !clioHasDuplicate
                ? TrialExpiry
                : clioHasDuplicate
                ? SyncPlaceholder
                : EmployeeSetup
            }
          />
          <PrivateRoute
            path={`${path}/account-setup`}
            component={
              xeroStatus == 0
                ? IntegrationPlaceholder
                : xeroStatus == 2 && userType !== "SUPER_ADMIN"
                ? TrialExpiry
                : AccountSetup
            }
          />
          <PrivateRoute
            path={`${path}/matter-tracker-setup`}
            component={
              clioStatus == 0 && ppStatus == 0 && !clioHasDuplicate
                ? IntegrationPlaceholder
                : (ppStatus == 2 || clioStatus == 2) &&
                  userType !== "SUPER_ADMIN" &&
                  !clioHasDuplicate
                ? TrialExpiry
                : clioHasDuplicate
                ? SyncPlaceholder
                : MatterTrackerSetup
            }
          />
          <PrivateRoute
            path={`${path}/basic-information`}
            component={BasicInformation}
          />
          <PrivateRoute
            path={`${path}/users`}
            component={
              code == "000" && !clioHasDuplicate
                ? IntegrationPlaceholder
                : code.includes("2") &&
                  userType !== "SUPER_ADMIN" &&
                  !clioHasDuplicate
                ? TrialExpiry
                : activeFirm?.firm?.clioIntegration && clioHasDuplicate
                ? SyncPlaceholder
                : Users
            }
          />
          <PrivateRoute path={`${path}/integration`} component={Integrations} />

          <PrivateRoute
            path={`${path}/payments`}
            component={
              code == "000" && !clioHasDuplicate
                ? IntegrationPlaceholder
                : xeroStatus == 0 &&
                  (activeFirm?.firm?.clioIntegration ||
                    activeFirm?.firm?.ppIntegration) &&
                  clioHasDuplicate
                ? SyncPlaceholder
                : userType === "SUPER_ADMIN"
                ? AdminPayments
                : Payments
            }
          />
          <PrivateRoute
            path={`${path}/personal-information`}
            component={PersonalInformation}
          />
          <PrivateRoute
            path={`${path}/change-password`}
            component={ChangePassword}
          />
          <Redirect from={path} to={`${path}/employee-setup`} />
        </Switch>
      </Grid>
    </Grid>
  );
}
