import React, { useContext, Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import ProductivityTopTensAOP from "./topTenByAOP";
import useAuth from "../../../../../hooks/useAuth";
import { PlaceholderAcl } from "../../../../commons/placeholderAcl";
export interface ProductivityTopTensProps {}

const ProductivityTopTens: React.FC<ProductivityTopTensProps> = () => {
  const {
    integrationConfigState: {
      integrationObject: {
        productivity: { aop },
      },
    },
    generalStates: { acl },
  } = useAuth();
  return (
    <Grid container spacing={2}>
      {!acl?.productivity_aop && <PlaceholderAcl />}
      {acl?.productivity_aop &&
        aop.map((item, index) => <Fragment key={index}>{item}</Fragment>)}
    </Grid>
  );
};

export default ProductivityTopTens;
export { ProductivityTopTensAOP };
