import React, { useState, useEffect, useMemo, useContext } from "react";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { makeStyles } from "@material-ui/core/styles";
import {
  Badge,
  IconButton,
  Tooltip,
  Typography,
  Popover,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import {
  SocketContext,
  SocketStateType,
} from "../../../../services/socket/socket";
import api from "../../../../services";
import SyncStatus from "./syncStatus";
import ProgressBar from "./progressBar";
import Popup from "../../../commons/popup";
import useAuth from "../../../../hooks/useAuth";

const useStyles = makeStyles(theme => ({
  color: {
    color: theme.palette.primary.main,
  },
  background: {
    backgroundColor: theme.palette.primary.main,
    "& hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  icon: {
    color: theme.palette.secondary.dark,
  },
  margin: {
    marginRight: 20,
  },
  badge: {
    borderStyle: "solid",
    borderColor: "#fff",
  },
  button: {
    backgroundColor: theme.palette.secondary.light,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
  },
  typography: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}));

export default function Notifications() {
  const history = useHistory();
  const classes = useStyles();
  const {
    firmUpdatesClio: { state: clioFirmState, info: clioFirmInfo },
    firmUpdatesXero: { state: xeroFirmState, info: xeroFirmInfo },
  } = useContext<SocketStateType>(SocketContext);
  const [current, setCurrent] = useState<boolean>(true);
  const [displayNotification, setDisplayNotification] =
    useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [accCatNot, setAccCatNot] = useState<boolean>(false);
  const [billing, setBilling] = useState<boolean>(false);
  const {
    generalStates: { paymentInfo, activeFirm, token, user },
    integrationConfigState: { integrationConfig },
  } = useAuth();
  const open = Boolean(anchorEl);
  // console.log(paymentInfo);
  // let differenceInDays =
  //   paymentInfo &&
  //   30 -
  //     moment().diff(
  //       paymentInfo.billingSubscriptionDetails.trialStarted,
  //       "days"
  //     );

  // *****
  // Handle Notification Badge
  // *****

  const handleNotificationClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
    if (displayNotification) {
      setDisplayNotification(false);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (paymentInfo) {
      if (paymentInfo?.subscribedClio || paymentInfo?.subscribedXero) {
        if (
          ((paymentInfo?.subscribedClio &&
            paymentInfo?.subscribedClio.isBlocked) ||
            (paymentInfo?.subscribedXero &&
              paymentInfo?.subscribedXero.isBlocked)) &&
          user.userType !== "SUPER_ADMIN"
        ) {
          setBilling(true);
        }
      }
    }
  }, [paymentInfo, user]);

  // *****
  // Render only when firm updates
  // *****

  useEffect(() => {
    if (clioFirmState || xeroFirmState) {
      setDisplayNotification(true);
    }
  }, [clioFirmState, xeroFirmState]);

  useEffect(() => {
    if (integrationConfig === "XERO" || integrationConfig === "CLIO_XERO") {
      let id = activeFirm.firm._id;
      let request = api.create(token);
      request.checkMinAccount(id).then(res => {
        if (res.data === false) {
          setAccCatNot(true);
          setDisplayNotification(true);
        }
      });
    }
  }, [integrationConfig, activeFirm, token]);

  // *****
  // Return notification updates incase of syncing progress
  // *****

  const memoizedSyncStatus = useMemo(
    () => () => {
      return (
        <>
          {clioFirmInfo !== null &&
            (!clioFirmInfo.sync_had_error ||
            clioFirmInfo.error_log.length! > 0 ? (
              <React.Fragment>
                <SyncStatus
                  syncStatus={clioFirmInfo.sync_status}
                  integrationName={"Clio"}
                />
                {clioFirmInfo.sync_status === "update_in_progress" ||
                clioFirmInfo.sync_status === "first_in_progress" ? (
                  <ProgressBar value={clioFirmInfo.sync_progress} />
                ) : (
                  <Typography className={classes.typography}>
                    Clio Sync Complete
                  </Typography>
                )}
              </React.Fragment>
            ) : (
              <Typography className={classes.typography}>
                {clioFirmInfo.error_log.length > 0
                  ? clioFirmInfo.error_log
                  : "Clio sync had an error."}
              </Typography>
            ))}
          {xeroFirmInfo !== null &&
            (!xeroFirmInfo.sync_had_error ||
            xeroFirmInfo.error_log.length > 0 ? (
              <React.Fragment>
                <SyncStatus
                  syncStatus={xeroFirmInfo.sync_status}
                  integrationName={"Xero"}
                />
                {xeroFirmInfo.sync_status === "update_in_progress" ||
                xeroFirmInfo.sync_status === "first_in_progress" ? (
                  <ProgressBar value={xeroFirmInfo.sync_progress} />
                ) : (
                  <Typography className={classes.typography}>
                    Xero Sync Complete
                  </Typography>
                )}
              </React.Fragment>
            ) : (
              <Typography className={classes.typography}>
                {xeroFirmInfo.error_log.length > 0
                  ? xeroFirmInfo.error_log
                  : "Clio sync had an error."}
              </Typography>
            ))}
        </>
      );
    },
    [clioFirmInfo, xeroFirmInfo, classes]
  );
  useEffect(() => {
    xeroFirmInfo && xeroFirmInfo.sync_status === "disconnected"
      ? setCurrent(true)
      : setCurrent(false);
  }, [xeroFirmInfo]);

  return (
    <>
      <Popup
        title={`Xero is currently not Connected!`}
        warning={true}
        open={current}
        buttonText={"Ok"}
        handleClick={() => {
          history.push("/dashboard/settings/integration");
          setCurrent(false);
        }}
        handleClose={() => {
          setCurrent(false);
        }}
        body={`To reconnect with Xero, click "Reconnect xero" button.`}
      />
      <Popup
        title={`Start your trial period.`}
        warning={true}
        open={billing}
        buttonText={"Ok"}
        handleClick={() => {
          history.push("/dashboard/settings/payments");
          setBilling(false);
        }}
        handleClose={() => {
          setBilling(false);
        }}
        body={`Please proceed to payments as one or more of your integrations await payment for starting 30 days trial period`}
      />
      <Badge
        classes={{
          root: classes.margin,
          badge: classes.badge,
        }}
        color="secondary"
        overlap="circular"
        invisible={!displayNotification}
      >
        <Tooltip title={"Notifications"}>
          <IconButton
            className={classes.button}
            size="small"
            onClick={handleNotificationClick}
          >
            <NotificationsIcon fontSize="medium" className={classes.icon} />
          </IconButton>
        </Tooltip>
      </Badge>
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        {memoizedSyncStatus()}
        {/* {billing && (
          <Typography className={classes.typography}>
            {`Your trial period is going to end within ${differenceInDays} days.`}
          </Typography>
        )} */}
        {accCatNot && (
          <Typography className={classes.typography}>
            Please add Xero Account Categories in
            <br />
            Settings -&gt; Account Setup.
          </Typography>
        )}
        {!accCatNot &&
          clioFirmInfo === null &&
          xeroFirmInfo === null &&
          !billing && (
            <Typography className={classes.typography}>
              No new notification
            </Typography>
          )}
      </Popover>
    </>
  );
}
