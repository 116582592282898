import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import FinancialsGrowthTrendsBillExpColl from "./growthTrendsBillExpColl";
import GrowthTrendsCashflow from "./growthTrendsCashFlow";
import GrowthTrendsNetIncomeMargin from "./growthTrendsNetIncomeMargin";
import GrowthTrendsSalesCashflow from "./growthTrendsSalesCashflow";
import GrowthTrendsExpenseOverheadsSalaries from "./growthTrendsExpenseOverheadsSalaries";
import GrowthTrendsMarketingExpense from "./growthTrendsMarketingExpense";
import useAuth from "../../../../../hooks/useAuth";
import { PlaceholderAcl } from "../../../../commons/placeholderAcl";
export interface FinancialsGrowthTrendsProps {}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

const FinancialsGrowthTrends: React.FC<FinancialsGrowthTrendsProps> = () => {
  const classes = useStyles();
  const {
    integrationConfigState: {
      integrationObject: {
        financials: { growthTrends },
      },
    },
    generalStates: { acl },
  } = useAuth();

  return (
    <Grid container spacing={2} className={classes.root}>
      {!acl?.financials && <PlaceholderAcl />}
      {acl?.financials &&
        growthTrends.map((item, index) => (
          <React.Fragment key={index}>{item}</React.Fragment>
        ))}
    </Grid>
  );
};

export default FinancialsGrowthTrends;
export {
  FinancialsGrowthTrendsBillExpColl,
  GrowthTrendsSalesCashflow as FinancialsGrowthTrendsSalesCashflow,
  GrowthTrendsCashflow as FinancialsGrowthThrendsCashflow,
  GrowthTrendsNetIncomeMargin as FinancialsGrowthTrendsNetIncomeMargin,
  GrowthTrendsExpenseOverheadsSalaries as FinancialsGrowthTrendsExpenseOverheadsSalaries,
  GrowthTrendsMarketingExpense as FinancialsGrowthMarketingExpense,
};
