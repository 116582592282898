// *****
// Dashboard Onboarding States
// *****
interface OnboardingStateType {
  firmName: any;
  firmId: any;
  onboardingStep: any;
  currentIntegration: any;
}

export const onboardingState: OnboardingStateType = {
  firmName:
    localStorage.getItem("firmName") == null
      ? ""
      : (localStorage.getItem("firmName") as any),
  firmId:
    localStorage.getItem("firmId") == null
      ? ""
      : localStorage.getItem("firmId"),
  onboardingStep:
    localStorage.getItem("onboardingStep") == null
      ? 0
      : parseInt(localStorage.getItem("onboardingStep") as any),
  currentIntegration:
    localStorage.getItem("currentIntegration") == null
      ? null
      : localStorage.getItem("currentIntegration"),
};

export const onboardingReducer = (state, action) => {
  switch (action.type) {
    case "SET_FIRMNAME":
      return {
        ...state,
        firmName: action.data,
      };
    case "SET_FIRMID":
      return {
        ...state,
        firmId: action.data,
      };
    case "SET_ONBOARDINGSTEP":
      return {
        ...state,
        onboardingStep: action.data,
      };
    case "SET_CURRENT_INTEGRATION":
      return {
        ...state,
        currentIntegration: action.data,
      };
    default:
      return state;
  }
};
