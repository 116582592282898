import React from "react";

import {
  createStyles,
  Theme,
  useTheme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import { StyledGradientButton } from "./styledButtons";
import CloseIcon from "@material-ui/icons/Close";
import {
  Typography,
  IconButton,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  DialogContentText,
  useMediaQuery,
} from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";

interface HandleCloseType {
  (event: any): void | undefined;
}

export interface PopupProps {
  handleClose: HandleCloseType;
  handleClick?: HandleCloseType;
  open: boolean;
  title: any;
  body: string;
  buttonLink?: string;
  buttonText: string;
  disableBackdropClick?: boolean;
  disableEscapeKeyDown?: boolean;
  cancel?: boolean;
  cancelText?: string;
  warning?: boolean;
  disabled?: boolean;
}
export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: any;
}
const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });
/*
Generic popup that accepts a title, body and an onclose method
*/
const MUIDialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Typography style={{ paddingTop: 32, fontWeight: "bold" }} variant="h6">
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

const MUIDialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(DialogContent);

const MUIDialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(DialogActions);
const Popup: React.FC<PopupProps> = ({
  open,
  handleClose,
  handleClick,
  title,
  buttonText,
  buttonLink,
  body,
  cancel,
  cancelText,
  warning,
  disabled,
  ...dialogProps
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  //Add an external link if the buttonLink property is available
  const buttonProps = buttonLink
    ? {
        href: buttonLink,
      }
    : {};

  return (
    <React.Fragment>
      <Dialog
        fullScreen={fullScreen}
        {...dialogProps}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <MUIDialogTitle id="customized-dialog-title" onClose={handleClose}>
          <div style={{ display: "flex", alignItems: "center" }}>
            {warning && <WarningIcon style={{ marginRight: 20 }} />}
            {title}
          </div>
        </MUIDialogTitle>
        <MUIDialogContent style={{ marginLeft: 16, marginRight: 16 }}>
          <DialogContentText id="popver-dialog-body">{body}</DialogContentText>
        </MUIDialogContent>
        <MUIDialogActions>
          <StyledGradientButton
            style={{ marginRight: 32 }}
            onClick={handleClick ? handleClick : handleClose}
            disabled={disabled}
            variant="contained"
            color="primary"
            {...buttonProps}
          >
            {buttonText}
          </StyledGradientButton>
        </MUIDialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default Popup;
