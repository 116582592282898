import React, { useContext, Fragment } from "react";
import ClientGrowthTrendsAreaClio from "./growthTrendsArea";
import ClientGrowthTrendsRadial from "./growthTrendsRadial";
import ClientGrowthThrendsStackedBar from "./growthTrendsStackedBar";
import useAuth from "../../../../../hooks/useAuth";
import Grid from "@material-ui/core/Grid";
import { PlaceholderAcl } from "../../../../commons/placeholderAcl";

function ClientGrowthTrends() {
  /* 
Map integration Object Components
*/
  const {
    integrationConfigState: {
      integrationObject: {
        clients: { growthTrends },
      },
    },
    generalStates: { acl },
  } = useAuth();

  let charts = growthTrends.map((item, index) => (
    <Fragment key={index}>{item}</Fragment>
  ));
  return (
    <Grid container spacing={2}>
      {!acl?.clients_growth_and_trend && <PlaceholderAcl />}
      {acl?.clients_growth_and_trend && charts}
    </Grid>
  );
}

export default ClientGrowthTrends;
export {
  ClientGrowthTrendsAreaClio,
  ClientGrowthTrendsRadial,
  ClientGrowthThrendsStackedBar,
};
