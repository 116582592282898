import React from "react";
import { Switch, Redirect } from "react-router-dom";
import Login from "../containers/LoginContainer";
import Signup from "../containers/SignupContainer";
import EmailVerify from "../containers/EmailVerify";
import ResetPassword from "../components/login/ResetPassword";
import { Dashboard } from "../containers/Dashboard";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import ForgotPassword from "../components/login/ForgotPassword";
import ZendeskWidget from "../components/zendesk";

const xeroSignUp = () => {
  window.location.replace(
    `https://login.xero.com/identity/connect/authorize?response_type=code&client_id=${process.env.REACT_APP_XERO_ID}&redirect_uri=${process.env.REACT_APP_XERO_SIGNUP_REDIRECT_URI}&scope=${process.env.REACT_APP_XERO_SCOPE}&state=signup`
  );
};

const Router = () => {
  return (
    <>
      <ZendeskWidget />
      <Switch>
        <PublicRoute path="/login" component={Login} />
        <PublicRoute path="/xero-signup" component={xeroSignUp} />
        <PublicRoute path="/signup" component={Signup} />
        <PublicRoute path="/signup-with-xero" component={Signup} />
        <PublicRoute path="/accept-invitation" component={Signup} />
        <PublicRoute path="/reset-password" component={ResetPassword} />
        <PublicRoute path="/verify/email" component={EmailVerify} />
        <PublicRoute path={`/forgot-password`} component={ForgotPassword} />
        <PrivateRoute path="/dashboard" component={Dashboard} />
        <Redirect from="*" to="/login" />
        {/* <PublicRoute exact path="/forgotpassword" component={ForgotPassword} /> */}
      </Switch>
    </>
  );
};

export default Router;
