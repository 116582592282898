import React, { useEffect, useState } from 'react';
import api from '../../../../services';
import { Grid, Paper, makeStyles } from '@material-ui/core';
import Chart from '../../charts/fusionCharts';
import EmptyPlaceholder from '../../commons/placeholders/emptyPlaceholder';
import ChartLoader from '../../commons/loaders/chartLoader';

type Props = {
    firmId: string;
    isAllowed: boolean;
    token: string;
};

const useStyles = makeStyles((theme) => ({
    headingText: {
        fontSize: '1.5em',
        fontWeight: theme.typography.fontWeightMedium,
        color: theme.palette.text.primary
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'visible',
        flexDirection: 'column',
        justifyContent: 'center',
        height: 500
    },
    textTitle: {
        color: theme.palette.text.primary,
        fontSize: '1.2em',
        fontWeight: 600
    }
}));

function sortArrayBySequence(arrayToSort, sequenceArray) {
    const sortedArray: any = [];
    for (const name of sequenceArray) {
        const objectsWithName = arrayToSort.filter((obj) => obj.name === name);
        sortedArray.push(...objectsWithName);
    }
    // Remove objects with names that are not in the sequence array
    for (const obj of arrayToSort) {
        if (!sequenceArray.includes(obj.name)) {
            const index = sortedArray.indexOf(obj);
            if (index !== -1) {
                sortedArray.splice(index, 1);
            }
        }
    }
    return sortedArray;
}

function sumSeriesData(arr) {
    const sums = {};
    for (const item of arr) {
        const seriesname = item.seriesname;
        const data = item.data;
        if (!(seriesname in sums)) {
            sums[seriesname] = data;
        } else {
            for (let i = 0; i < data.length; i++) {
                const datapoint = data[i];
                for (const [key, value] of Object.entries(datapoint)) {
                    sums[seriesname][i][key] += value;
                }
            }
        }
    }
    return Object.entries(sums).map(([seriesname, data]) => ({ seriesname, data }));
}

function sortAndExtractNames(objects) {
    // Sort the array based on the 'name' property
    objects.sort((a, b) => a.name.localeCompare(b.name));

    // Extract only the names from the sorted array
    var names = objects.map((obj) => obj.name);
    var uniqueNames = Array.from(new Set(names));

    return uniqueNames;
}

const CustomChartsContainer = ({ firmId, isAllowed, token }: Props) => {
    const [loader, setLoader] = useState(true);
    const [attorneyList, setAttorneyList] = useState<any>([]);

    const [data, setData] = useState<any>(null);

    const classes = useStyles();

    useEffect(() => {
        let subscribe = true;
        if (loader && firmId && token && isAllowed) {
            let request = api.create(token);
            request
                .getOpenFilesWithParalegals(firmId)
                .then((res) => {
                    if (subscribe) {
                        const { practice_area_labels, paralegal_names, matters_count_by_PL } = res.data;
                        setData(practice_area_labels.map((i) => ({ seriesname: i, data: [] })));
                        const attorney = paralegal_names.map((i) => i.name);
                        const attorneyA = sortAndExtractNames(matters_count_by_PL);
                        setAttorneyList(attorneyA.map((i) => ({ label: i })));

                        const sorteddata = sortArrayBySequence(matters_count_by_PL, attorneyA);
                        const series = sumSeriesData(
                            sorteddata.map((i) => ({
                                seriesname: i.practice_area_name,
                                data: attorneyA.map((k) => ({ value: k === i.name ? i.total : 0 }))
                            }))
                        );
                        setData(series);
                        setLoader(false);
                    }
                })
                .catch((e) => {
                    setLoader(false);
                });
        }
        return () => {
            subscribe = false;
        };
    }, [loader, isAllowed]);

    const chartConfig = {
        xAxisName: 'Attorney Name',
        yAxisName: 'Total Matters',
        showValues: '1',
        showLegend: '0',
        decimals: '0',
        showSum: '1',
        formatNumberScale: '0',
        placevaluesinside: '1',
        showvalues: '0',
        plottooltext: '<b>$dataValue</b> $seriesName',
        theme: 'fusion'
    };
    return (
        <>
            <Grid item lg={12} md={12} sm={12} xs={12} xl={12}>
                <Paper className={classes.paper}>
                    {loader ? (
                        <ChartLoader />
                    ) : (
                        <>
                            <Grid container justifyContent="space-between" alignItems="center" style={{ marginBottom: 8 }}>
                                <span className={classes.textTitle}>Open Files with Paralegals Assigned</span>
                                <span />
                            </Grid>
                            {data.length > 0 ? (
                                <Chart config={chartConfig} labels={attorneyList} series={data} type={'stackedcolumn2d'} height={400} />
                            ) : (
                                <EmptyPlaceholder />
                            )}
                        </>
                    )}
                </Paper>
            </Grid>
        </>
    );
};

export default CustomChartsContainer;
