// *****
// Component Blocking Actions : duplicate clio integration, payment expiry
// *****

export const currentAppStateActions = (props) => {
  return {
    setClioHasDuplicate: (data) => {
      externalSetClioHasDuplicate(props, data);
    },
    setPaymentExpired: (data) => {
      externalSetPaymentExpired(props, data);
    },
    setStatus: (data) => {
      externalSetStatus(props, data);
    },
    setLoader: (data) => {
      externalSetLoader(props, data);
    },
  };
};
function externalSetLoader(props, data) {
  props.dispatch({ type: "SET_LOADER", data });
}

function externalSetStatus(props, data) {
  props.dispatch({ type: "SET_STATUS", data });
}

function externalSetClioHasDuplicate(props, data) {
  props.dispatch({ type: "SET_CLIO_HAS_DUPLICATE", data });
}

function externalSetPaymentExpired(props, data) {
  props.dispatch({ type: "SET_PAYMENT_EXPIRED", data });
}
