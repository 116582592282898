import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Checkbox, FormControlLabel, Grid, Paper } from "@material-ui/core";
import clsx from "clsx";
import ChartLoader from "../../../commons/loaders/chartLoader";
import api from "../../../../../services";
import Colors from "../../../../../theme/ChartTheme";
import FilterUserTimePractice from "../../../filters/genericFilter/filters";
import ErrorSnackBar from "../../../commons/SnackBar";
import { DateRange } from "materialui-daterange-picker";
import {
  checkDate,
  compareNames,
  returnSum,
  returnNames,
  calcUtilizationPercentage,
  orderList,
} from "../utils";
import useDateFilterState, {
  DateStateType,
} from "../../../filters/genericFilter/dateFilterStateHook";
import EmptyPlaceholder from "../../../commons/placeholders/emptyPlaceholder";
import Chart from "../../../charts/fusionCharts";
import InfoButton from "../../../infoButton";
import useFilterState from "../../../filters/genericFilter/filterStateHook";
import { returnValueHours } from "../growthTrends/utils";
import TopBottomSelect from "../../../commons/topBottomSelect";
import { thisYear } from "../../../filters/commons/dateRangePicker/static";
import {
  StyledToggleButtonGroup,
  StyledToggleButton,
} from "../../../tabs/styledTabs";
import useAuth from "../../../../../hooks/useAuth";
export interface ProductivityTopTensAOPProps {}

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "visible",
    flexDirection: "column",
    justifyContent: "center",
  },
  paperHeight: {
    minHeight: 550,
  },
  textTitle: {
    color: theme.palette.text.primary,
    fontSize: "1.2em",
    fontWeight: 600,
  },
  textSubtitle: {
    color: theme.palette.text.secondary,
    fontSize: "0.8em",
  },
  headingText: {
    fontSize: "1.2em",
    fontWeight: 600,
    color: theme.palette.text.secondary,
  },
  divider: {
    backgroundColor: "#EDEFF7",
    height: 1,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

// *****
// Initial Filter State
// *****

const initialDateFilterState: DateStateType = {
  dateAfter: thisYear.startDate,
  dateBefore: thisYear.endDate,
};

const initialFilterState: any = {
  billingMethod: "",
  status: "",
};

const ProductivityTopTensAOP: React.FC<ProductivityTopTensAOPProps> = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [utilization, setUtilization] = useState<any[]>([]);
  const [collection, setCollection] = useState<any[]>([]);
  const [realization, setRealization] = useState<any[]>([]);
  const [months, setMonths] = useState<any[]>([]);
  const [error, setError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [hourValue, setHourValue] = useState<number>(0);
  const [excludeNonBillable, setExcludeNonBillable] = useState<boolean>(false);
  const [current, setCurrent] = useState<string>("0");

  // *****
  // Current Values of Date Filters
  // *****

  const { dateFilterState, handleDateState } = useDateFilterState(
    initialDateFilterState
  );

  const { filterRef, filterState, handleState } =
    useFilterState(initialFilterState);

  const {
    generalStates: {
      token,
      activeFirm: {
        firm: { _id },
      },
    },
    integrationConfigState: { pmType },
  } = useAuth();

  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.paperHeight);

  const seriesColumn1 = [
    {
      seriesName: "Utilization",
      color: Colors.orange,
      data:
        hourValue == 0
          ? utilization[parseInt(current)]
          : utilization[parseInt(current)].reverse(),
    },
    {
      seriesName: "Realization",
      color: Colors.red,
      data:
        hourValue == 0
          ? realization[parseInt(current)]
          : realization[parseInt(current)].reverse(),
    },
    {
      seriesName: "Collection",
      color: Colors.green,
      data:
        hourValue == 0
          ? collection[parseInt(current)]
          : collection[parseInt(current)].reverse(),
    },
  ];

  // *****
  // Filter state updates
  // *****

  const handleDateFilterRange = (dateRange: DateRange): void => {
    handleDateState.handleDateUpdate(dateRange);
  };

  const handleStatus = (value) => {
    handleState.handleStatus(value);
  };

  const handleBillingMethod = (value) => {
    handleState.handleBillingMethod(value);
  };

  // *****
  // Filter Submit
  // *****

  const handleFilterSubmit = () => {
    if (checkDate(dateFilterState.dateAfter, dateFilterState.dateBefore)) {
      setLoading(true);
    } else if (
      dateFilterState.dateAfter === null &&
      dateFilterState.dateBefore === null
    ) {
      setLoading(true);
    }
  };
  const handleSelect = (val: number) => {
    if (val === 0) {
      setHourValue(1);
    } else {
      setHourValue(0);
    }
  };

  // *****
  // Clear Filter States
  // *****

  const handleFilterClear = () => {
    handleDateState.resetDate(initialDateFilterState);
    setLoading(true);
  };

  useEffect(() => {
    let subscribe = true;
    if (loading) {
      let request = api.create(token);

      let queryParams = {
        user_id: null,
        date_after: dateFilterState.dateAfter,
        date_before: dateFilterState.dateBefore,
        billing_method:
          filterState.billingMethod === "" ? null : filterState.billingMethod,

        status: filterState.status === "" ? null : filterState.status,
      };
      let queryParamsWorkedTime = {
        user_id: null,
        date_after: dateFilterState.dateAfter,
        date_before: dateFilterState.dateBefore,
        exclude_non_billable: excludeNonBillable,
        billing_method:
          filterState.billingMethod === "" ? null : filterState.billingMethod,

        status: filterState.status === "" ? null : filterState.status,
      };
      let queryParamsAvailableTime = {
        user_id: null,
        created_after: dateFilterState.dateAfter,
        created_before: dateFilterState.dateBefore,
      };
      Promise.all([
        request.getTotalWorkedTimeByAOP(_id, pmType, queryParamsWorkedTime),
        request.getTotalBilledTimeByAOP(_id, pmType, queryParams),
        request.getTotalCollectedTimeByAOP(_id, pmType, queryParams),
        request.getAvailableTime(_id, pmType, queryParamsAvailableTime),
      ])
        .then((responses) => {
          if (subscribe) {
            let workedTimeData = responses[0].data;
            let billedTimeData = responses[1].data;
            let collectedTimeData = responses[2].data;
            let availableTimeData = responses[3].data;
            let totalAvailableHours = parseInt(
              availableTimeData[0].total_hours
            );
            let totalAvailableValue = parseInt(
              availableTimeData[0].total_value
            );
            let fixedCollectedTimeData, fixedBilledTimeData;

            if (workedTimeData.length > billedTimeData.length) {
              fixedBilledTimeData = compareNames(
                billedTimeData,
                workedTimeData
              );
            } else {
              fixedBilledTimeData = billedTimeData;
            }
            if (workedTimeData.length > collectedTimeData.length) {
              fixedCollectedTimeData = compareNames(
                collectedTimeData,
                workedTimeData
              );
            } else {
              fixedCollectedTimeData = collectedTimeData;
            }
            let month = returnNames(workedTimeData);

            let { hours: billedHours, value: billedValue } = returnValueHours(
              orderList(fixedBilledTimeData, month)
            );

            let { hours: collectedHours, value: collectedValue } =
              returnValueHours(orderList(fixedCollectedTimeData, month));

            let { hours: workedHours, value: workedValue } =
              returnValueHours(workedTimeData);
            console.log(workedHours, workedValue);

            let totalBilledHours = returnSum(billedHours);
            let totalWorkedHours = returnSum(workedHours);
            let totalCollectedHours = returnSum(collectedHours);
            let totalBilledValue = returnSum(billedValue);
            let totalWorkedValue = returnSum(workedValue);
            let totalCollectedValue = returnSum(collectedValue);
            let utilizationPercentagePerHour = [
              calcUtilizationPercentage(
                workedTimeData,
                totalWorkedHours,
                totalAvailableHours,
                "total_hours"
              ).map((item) => ({ value: item })),
              calcUtilizationPercentage(
                workedTimeData,
                totalWorkedValue,
                totalAvailableValue,
                "total_value"
              ).map((item) => ({ value: item })),
            ];
            let realizationPercentagePerHour = [
              calcUtilizationPercentage(
                fixedBilledTimeData,
                totalBilledHours,
                totalAvailableHours,
                "total_hours"
              ).map((item) => ({ value: item })),
              calcUtilizationPercentage(
                fixedBilledTimeData,
                totalBilledValue,
                totalAvailableValue,
                "total_value"
              ).map((item) => ({ value: item })),
            ];
            let collectionPercentagePerHour = [
              calcUtilizationPercentage(
                fixedCollectedTimeData,
                totalCollectedHours,
                totalAvailableHours,
                "total_hours"
              ).map((item) => ({ value: item })),
              calcUtilizationPercentage(
                fixedCollectedTimeData,
                totalCollectedValue,
                totalAvailableValue,
                "total_value"
              ).map((item) => ({ value: item })),
            ];
            if (utilizationPercentagePerHour.length > 10) {
              utilizationPercentagePerHour = [
                utilizationPercentagePerHour[0],
                utilizationPercentagePerHour[1],
              ];
              realizationPercentagePerHour = [
                realizationPercentagePerHour[0],
                realizationPercentagePerHour[1],
              ];
              collectionPercentagePerHour = [
                collectionPercentagePerHour[0],
                collectionPercentagePerHour[1],
              ];
            }
            setMonths(
              month.map((item) => ({
                label: item,
              }))
            );

            setUtilization(utilizationPercentagePerHour);
            setRealization(realizationPercentagePerHour);
            setCollection(collectionPercentagePerHour);
            setTimeout(() => {
              setLoading(false);
            }, 500);
          }
        })
        .catch((error) => {
          console.log(error);
          if (subscribe) {
            if (error.code === "ECONNABORTED") {
              setErrorMsg("The API request timed out. Please Refresh the page");
              setError(true);
            } else {
              setErrorMsg(error.response.data.message);
              setError(true);
            }
          }
        });
    }
    return () => {
      subscribe = false;
    };
  }, [loading]);
  useEffect(() => {
    setLoading(true);
  }, [excludeNonBillable]);
  const hoursconfig = {
    yAxisName: "Hours",
    numberSuffix: " hrs",
  };
  const valueconfig = {
    yAxisName: "Amount",
    numberPrefix: "$",
  };
  return (
    <Grid item lg={12} md={12} sm={12} xs={12} xl={12}>
      <Paper className={fixedHeightPaper}>
        {loading ? (
          <ChartLoader />
        ) : (
          <React.Fragment>
            <Grid container justifyContent="space-between" alignItems="center">
              <span className={classes.textTitle}>
                <TopBottomSelect
                  title={"AOP by Productivity"}
                  current={hourValue}
                  handleSelect={handleSelect}
                />
              </span>
              <span>
                <InfoButton title={"AOP by Productivity"} />
                <FilterUserTimePractice
                  dateFilterState={dateFilterState}
                  filterState={filterState}
                  disabled={loading}
                  handleStatus={handleStatus}
                  handleBillingMethod={handleBillingMethod}
                  handleFilterClear={handleFilterClear}
                  handleFilterSubmit={handleFilterSubmit}
                  handleDateFilterRange={handleDateFilterRange}
                />
              </span>
            </Grid>
            <Grid
              container
              justifyContent={"space-around"}
              alignItems={"center"}
              spacing={4}
            >
              <StyledToggleButtonGroup
                onChange={(event, value) => setCurrent(value)}
                exclusive
                value={current}
                size="small"
              >
                <StyledToggleButton key={1} value={"0"}>
                  Hour
                </StyledToggleButton>
                <StyledToggleButton key={2} value={"1"}>
                  Value
                </StyledToggleButton>
              </StyledToggleButtonGroup>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={excludeNonBillable}
                      onChange={() =>
                        setExcludeNonBillable(!excludeNonBillable)
                      }
                      name="checkedA"
                    />
                  }
                  label="Exclude Non Billable"
                />
              </Grid>
            </Grid>
            {utilization.length > 0 || collection.length > 0 ? (
              <Chart
                type={"scrollcombi2d"}
                config={current == "0" ? hoursconfig : valueconfig}
                labels={months}
                series={seriesColumn1}
                height={400}
              />
            ) : (
              <EmptyPlaceholder />
            )}
          </React.Fragment>
        )}
      </Paper>
      {error ? <ErrorSnackBar errorMsg={errorMsg} /> : null}
    </Grid>
  );
};

export default ProductivityTopTensAOP;
