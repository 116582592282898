import _ from "lodash";

// *****
// Filter - Check date use cases
// *****

const checkDate = (startDate: Date | null, endDate: Date | null): boolean => {
  if (startDate && endDate) {
    if (startDate.getTime() <= endDate.getTime()) {
      //start is less than end
      return true;
    } else {
      //Start is greater than end
      return false;
    }
  } else {
    //Date: one or both is null
    return false;
  }
  //Default false return - Typescript
  return false;
};

//   **************************************
//   Top Tens & Worst Tens Utility Functions
//   **************************************

// ********
// Returns Utilitzation, Realization Percentage
// ********

function calcUtilizationPercentage(timeData, totalTime, availableTime, type) {
  return _.map(
    _.flatMap(timeData, (item, i) => item[type]),
    (hours) =>
      Math.round(
        Math.round(
          ((Math.ceil(hours) / totalTime) * availableTime + Number.EPSILON) *
            100
        ) / 100
      )
  );
}

// ********
// Return flat array of elements from a collection based on total_hours
// ********

function returnHours(list: Response[]) {
  return _.flatMap(list, (item, i) => Math.ceil(item.total_hours));
}
function returnExpenses(list: Response[]) {
  return _.flatMap(list, (item, i) => Math.ceil(item.total));
}

// ********
// Return flat array of elements from a collection based on name
// ********

function returnNames(list) {
  return _.flatMap(list, (item, i) => item.name);
}

// ********
// Returns sum of an array
// ********

function returnSum(list) {
  return _.sum(list);
}

// ********
// Compares a list against a benchmark list and adds items to the comparison list at index where benchmark has an item and comparison does not.
// ********

function orderList(array, order) {
  let sortedArray = order.map((i) =>
    array.find((j) => j.name.toLowerCase() === i.toLowerCase())
  );
  return sortedArray;
}
function compareNames(comparisonList, benchmarkList) {
  benchmarkList.map((item, i) => {
    if (!_.find(comparisonList, (item2) => item2.name === item.name)) {
      comparisonList.splice(i, 0, {
        total_hours: 0,
        total_value: 0,
        name: item.name,
      });
    }
  });
  return comparisonList;
}

function PercentageRounded(numerator: number, denominator: number): number {
  if (numerator === 0 && denominator === 0) return 0;
  return (
    Math.round(
      ((Math.ceil(numerator) / denominator) * 100 + Number.EPSILON) * 100
    ) / 100
  );
}

export {
  checkDate,
  compareNames,
  returnSum,
  returnNames,
  returnHours,
  PercentageRounded,
  calcUtilizationPercentage,
  returnExpenses,
  orderList,
};
