import React from "react";
import { Box, LinearProgress, Typography } from "@material-ui/core";

export interface ProgressBarProps {
  value: number;
  variant?: "determinate" | "indeterminate" | "buffer" | "query";
}

/*
Progress Bar for firm syncing
*/

const ProgressBar: React.FC<ProgressBarProps> = ({
  variant = "determinate",
  ...props
}) => {
  return (
    <div style={{ padding: 16 }}>
      <Box display="flex" alignItems="center">
        <Box width="100%" mr={1}>
          <LinearProgress variant={variant} {...props} />
        </Box>
        <Box minWidth={35}>
          <Typography variant="body2" color="textSecondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    </div>
  );
};

export default ProgressBar;
