import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import {
  InputLabel,
  InputAdornment,
  Divider,
  CircularProgress,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Grid,
} from "@material-ui/core";
import api from "../../services";
import Alert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import LocationWrapper from "../../components/locationWrapper";
import { StyledInput } from "../settings/payments/stripe/styledInput";
import { StyledGradientButton } from "../commons/styledButtons";
import useAuth from "../../hooks/useAuth";
import images from "../../images";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
    background: theme.palette.background.default,
    paddingTop: theme.spacing(8),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    background: `linear-gradient(90deg, ${theme.palette.primary.main} , ${theme.palette.primary.light} )`,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
    "&:disabled": {
      color: "#eaeaea",
    },
  },

  bodyText: {
    display: "block",
    fontSize: "1em",
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.primary,
    width: theme.spacing(20),
    textAlign: "center",
    backgroundColor: "#fff",
    marginLeft: theme.spacing(18),
    marginBottom: theme.spacing(1),

    [theme.breakpoints.down("md")]: {
      marginLeft: theme.spacing(2),

      fontSize: "1em",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "1.4em",
    },
  },
  headingText: {
    display: "block",
    fontFamily: "Open Sans",
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(6),
    fontSize: "2em",
    fontWeight: 600,
    color: theme.palette.text.primary,
    [theme.breakpoints.down("md")]: {
      fontSize: "1.4em",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "1.8em",
    },
  },
  circularProgress: {
    color: "#fff",
    backgroundColor: "#fff",
  },
  link: {
    textDecoration: "none",
    color: theme.palette.primary.light,
    fontSize: "1em",
    fontWeight: 500,
    [theme.breakpoints.up("xl")]: {
      fontSize: "1.1em",
    },
  },
  iconHide: {
    color: "#cfd1d6",
  },
  iconShow: {
    color: theme.palette.primary.light,
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  image: {
    width: 150,
  },
  error: {
    color: theme.palette.error.dark,
    fontSize: 12,
    fontFamily: "Open Sans",
    marginTop: theme.spacing(1),
  },
}));

export default function Login() {
  const classes = useStyles();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [helperText, setHelperText] = useState<string>("");
  const {
    generalStates: { clientID },
    generalActions,
    integrationConfigActions,
    currentAppStateActions,
  } = useAuth();
  const [conflict, setConflict] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [passwordHide, setPasswordHide] = useState<boolean>(true);

 
  // *****
  // Check email format
  // *****

  useEffect(() => {
    let emailRgx =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email.trim() && password.trim()) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
    setError(
      emailRgx.test(email.length > 0 ? email : "test@test.com") ? false : true
    );
  }, [email, password]);

  // *****
  // Invoke login API when loading state changes
  // *****

  useEffect(() => {
    let subscribe = true;
    if (loading) {
      const request = api.create("");
      request
        .login({
          email: email,
          password: password,
          clientID: clientID,
        })
        .then((res) => {
          if (subscribe) {
            const { token, refreshToken, user } = res.data;
            setIsButtonDisabled(false);
            generalActions.setToken(token);
            generalActions.setRefreshToken(refreshToken);
            let activeFirm =
              user.firmRoles.length > 0 ? res.data.user.firmRoles[0] : null;

            // Set active Integration Type
            generalActions.setActiveFirm(activeFirm);
            generalActions.setUser(user);
            currentAppStateActions.setLoader(true);
            setLoading(false);
          }
        })
        .catch((error) => {
          if (subscribe) {
            setLoading(false);
            setIsButtonDisabled(false);
            if ((error.response.status = 403)) {
              setConflict(true);
              setHelperText(error.response.data.message);
            } else if (error.code === "ECONNABORTED") {
              setConflict(true);
              setHelperText(
                "The API request timed out. Please Refresh the page"
              );
            } else {
              setError(true);
            }
          }
        });
    }
    return () => {
      subscribe = false;
    };
  }, [loading]);

  // *****
  // Update Loading State
  // *****

  const handleLogin = () => {
    if (!loading) {
      setLoading(true);
    }
    setIsButtonDisabled(true);
  };

  const handlePasswordToggle = () => {
    setPasswordHide(!passwordHide);
  };

  const handleKeyPress = (e: any) => {
    if (e.keyCode === 13 || e.which === 13) {
      isButtonDisabled || handleLogin();
    }
  };

  return (
    <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
      <LocationWrapper>
        <Grid
          container
          direction="column"
          alignContent="center"
          justifyContent="space-between"
          spacing={2}
        >
          <div className={classes.header}>
            <img className={classes.image} src={images.logo} />
            <Button
              component={Link}
              to={"/signup"}
              classes={{ root: classes.bodyText }}
            >
              Register
            </Button>
          </div>
          <Grid item xs={12}>
            <span className={classes.headingText}>Login</span>
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Email Address</InputLabel>
            <StyledInput
              error={error}
              required
              fullWidth
              id="email"
              // label="Email Address"
              name="email"
              autoComplete="email"
              onChange={(e) => setEmail(e.target.value)}
              autoFocus
              onKeyPress={(e) => handleKeyPress(e)}
            />
            {error && (
              <span className={classes.error}>
                Must include '@' and ends with '.com' or '.co'
              </span>
            )}
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Password</InputLabel>
            <StyledInput
              required
              fullWidth
              name="password"
              type={passwordHide ? "password" : "text"}
              id="password"
              onChange={(e) => setPassword(e.target.value)}
              autoComplete="current-password"
              onKeyPress={(e) => handleKeyPress(e)}
              endAdornment={
                <InputAdornment position="end">
                  {passwordHide ? (
                    <VisibilityOffIcon
                      onClick={handlePasswordToggle}
                      className={classes.iconHide}
                    />
                  ) : (
                    <VisibilityIcon
                      onClick={handlePasswordToggle}
                      className={classes.iconShow}
                    />
                  )}
                </InputAdornment>
              }
            />
          </Grid>
          <Grid item xs={12}>
            <div className={classes.header}>
              <FormControlLabel
                control={
                  <Checkbox value="remember" color="primary" size="small" />
                }
                label="Save User"
              />
              <Link to="/forgot-password" className={classes.link}>
                {" Forgot password?"}
              </Link>
            </div>
          </Grid>
          <Grid item xs={12}>
            {conflict ? (
              <Alert
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setConflict(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {helperText}
              </Alert>
            ) : null}
            <Divider flexItem />
          </Grid>
          <Grid item xs={12}>
            <StyledGradientButton
              type="submit"
              fullWidth
              onClick={() => handleLogin()}
              disabled={isButtonDisabled}
            >
              {loading ? (
                <CircularProgress
                  size={24}
                  classes={{ circle: classes.circularProgress }}
                />
              ) : (
                "Login"
              )}
            </StyledGradientButton>
          </Grid>
        </Grid>
      </LocationWrapper>
    </Grid>
  );
}
