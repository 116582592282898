import React, { useState, useEffect, useCallback } from "react";
import useFilterState from "../../../filters/genericFilter/filterStateHook";
import { ProductivityGTTypes } from "../../../filters/genericFilter/types";
import FilterUserTimePractice from "../../../filters/genericFilter/filters";
import { compareFilterDates } from "../../utils";
import { makeStyles } from "@material-ui/core/styles";
import ChartLoader from "../../../commons/loaders/chartLoader";
import Colors from "../../../../../theme/ChartTheme";
import ErrorSnackBar from "../../../commons/SnackBar";
import api from "../../../../../services";
import {
  calcRoundedPercentage,
  aggregateValues,
  compareMonths,
  returnValueHours,
} from "./utils";
import useDateFilterState, {
  DateStateType,
} from "../../../filters/genericFilter/dateFilterStateHook";
import _ from "lodash";
import { DateRange } from "materialui-daterange-picker";
import Chart from "../../../charts/fusionCharts";
import InfoButton from "../../../infoButton";
import { FormControlLabel, Grid, Paper, Checkbox } from "@material-ui/core";
import UserName from "../../../filters/commons/userName";
import numeral from "numeral";
import { thisYear } from "../../../filters/commons/dateRangePicker/static";
import {
  StyledToggleButtonGroup,
  StyledToggleButton,
} from "../../../tabs/styledTabs";
import useAuth from "../../../../../hooks/useAuth";

interface InitialState {
  totalWorkedPercentage: any[];
  totalBilledPercentage: any[];
  totalCollectedPercentage: any[];
  totalWorked: any[];
  totalBilled: any[];
  totalCollected: any[];
}

export interface WorkecBilledCollectedRadialProps {}

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    display: "flex",
    overflow: "visible",
    flexDirection: "column",
    alignItems: "center",
    minHeight: 400,
  },
  textTitle: {
    color: theme.palette.text.primary,
    fontSize: "1.2em",
    fontWeight: 600,
  },
  headingText: {
    fontSize: "1.2em",
    fontWeight: 600,
    color: theme.palette.text.secondary,
  },
  divider: {
    backgroundColor: "#EDEFF7",
    height: 1,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  radialLoader: {
    marginTop: theme.spacing(16),
  },
}));

// *****
// Initial Filter state
// *****

const initialFilterState: ProductivityGTTypes = {
  practiceArea: "",
  user: "",
};

const initialDateFilterState: DateStateType = {
  dateAfter: thisYear.startDate,
  dateBefore: thisYear.endDate,
};

const WorkedBilledCollectedRadial: React.FC<WorkecBilledCollectedRadialProps> =
  () => {
    const [loader, setLoader] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string>("");
    const [topFilter, setTopFilter] = useState<string>("hourly");
    const [rate, setRate] = useState<boolean>(false);
    const [excludeNonBillable, setExcludeNonBillable] =
      useState<boolean>(false);
    const [charts, setCharts] = useState<InitialState>({
      totalWorkedPercentage: [],
      totalBilledPercentage: [],
      totalCollectedPercentage: [],
      totalWorked: [],
      totalBilled: [],
      totalCollected: [],
    });
    const [current, setCurrent] = useState<string>("0");
    const {
      generalStates: {
        activeFirm: {
          firm: { _id: firmId },
        },
        token,
      },
      integrationConfigState: { pmType },
    } = useAuth();
    const classes = useStyles();

    // *****
    // Filter States
    // *****

    const { filterRef, filterState, handleState } =
      useFilterState(initialFilterState);
    const { dateFilterRef, dateFilterState, handleDateState } =
      useDateFilterState(initialDateFilterState);
    const prevFilterState = filterRef.current;
    const prevDateFilterState = dateFilterRef.current;

    // *****
    // Filter Events
    // *****

    const handlePracticeAreas = (value: number | "") => {
      handleState.handlePracticeArea(value);
    };

    const handleUser = (value: number | "") => {
      handleState.handleUser(value);
    };

    const handleDateFilterRange = (dateRange: DateRange): void => {
      handleDateState.handleDateUpdate(dateRange);
    };
    const handleStatus = (value) => {
      handleState.handleStatus(value);
    };

    // *****
    // Filter Submit
    // Use Case 1: If dates have been applied, then check for date consistency.
    // Use Case 2: All filters have been applied and changed.
    // Use Case 3: Dates have been applied and changed.
    // *****

    const handleFilterSubmit = () => {
      //if filters or date Filters have changed
      if (
        compareFilterDates(
          filterState,
          prevFilterState,
          dateFilterState,
          prevDateFilterState,
          initialFilterState
        ) === true
      ) {
        setLoader(true);
      }
    };

    // *****
    // Clear Filter States
    // *****

    const handleFilterClear = useCallback(() => {
      handleState.reset(initialFilterState);
      handleDateState.resetDate(initialDateFilterState);
      setLoader(true);
    }, []);

    function queryParams() {
      let query = {
        user_id: filterState.user === "" ? null : filterState.user,
        practice_area_id:
          filterState.practiceArea === "" ? null : filterState.practiceArea,
        date_after: dateFilterState.dateAfter,
        date_before: dateFilterState.dateBefore,
        status: filterState.status === "" ? null : filterState.status,
        exclude_non_billable: excludeNonBillable,
      };
      if (pmType == "clio") {
        return { ...query, top_filter: topFilter };
      } else {
        return { ...query, rate: rate };
      }
    }

    useEffect(() => {
      let subscribe = true;
      if (loader) {
        let request = api.create(token);

        request
          .getProductivity(firmId, pmType, queryParams())
          .then((response) => {
            if (subscribe) {
              let data = response.data;
              let billedArray = _.cloneDeep(data.billed_time);
              let availableArray = _.cloneDeep(data.available_time);
              let collectedArray = _.cloneDeep(data.collected_time);
              let workedArray = _.cloneDeep(data.worked_time);
              let { hours: billedHours, value: billedValue } = returnValueHours(
                compareMonths(billedArray, availableArray)
              );
              let { hours: workedHours, value: workedValue } = returnValueHours(
                compareMonths(workedArray, availableArray)
              );
              let { hours: collectedHours, value: collectedValue } =
                returnValueHours(compareMonths(collectedArray, availableArray));
              let { hours: availableHours, value: availableValue } =
                returnValueHours(availableArray);

              let totalCollected = [
                aggregateValues(collectedHours),
                aggregateValues(collectedValue),
              ];

              let totalAvailable = [
                aggregateValues(availableHours),
                aggregateValues(availableValue),
              ];
              let totalBilled = [
                aggregateValues(billedHours),
                aggregateValues(billedValue),
              ];

              let totalWorked = [
                aggregateValues(workedHours),
                aggregateValues(workedValue),
              ];

              let totalCollectedPercentage = [
                calcRoundedPercentage(totalCollected[0], totalAvailable[0]),
                calcRoundedPercentage(totalCollected[1], totalAvailable[1]),
              ];
              let totalBilledPercentage = [
                calcRoundedPercentage(totalBilled[0], totalAvailable[0]),
                calcRoundedPercentage(totalBilled[1], totalAvailable[1]),
              ];
              let totalWorkedPercentage = [
                calcRoundedPercentage(totalWorked[0], totalAvailable[0]),
                calcRoundedPercentage(totalWorked[1], totalAvailable[1]),
              ];
              setCharts({
                totalWorkedPercentage: totalWorkedPercentage,
                totalBilledPercentage: totalBilledPercentage,
                totalCollectedPercentage: totalCollectedPercentage,
                totalBilled: totalBilled,
                totalCollected: totalCollected,
                totalWorked: totalWorked,
              });
              setTimeout(() => {
                setLoader(false);
              }, 1000);
            }
          })
          .catch((error) => {
            if (subscribe) {
              if (error.code === "ECONNABORTED") {
                setErrorMsg(
                  "The API request timed out. Please Refresh the page"
                );
                setError(true);
              } else {
                setErrorMsg(error.response.data.message);
                setError(true);
              }
            }
          });
      }
      return () => {
        subscribe = false;
      };
    }, [loader]);
    useEffect(() => {
      setLoader(true);
    }, [topFilter, excludeNonBillable, rate]);

    return (
      <Grid item lg={12} md={12} sm={12} xs={12} xl={12}>
        <Paper className={classes.paper}>
          {!loader && (
            <Grid container justifyContent="space-between" alignItems="center">
              <span className={classes.textTitle}>
                {`Worked, Billed, Collected ${
                  current == "0" ? "Time" : "Amount"
                }`}
              </span>
              {!(filterState.user == "") && <UserName id={filterState.user} />}
              <span>
                <InfoButton
                  title={
                    "Compare Worked, Billed and Collected Time against Total Available Time to track over all firm productivity as well as individual Employee Productivity."
                  }
                />
                <FilterUserTimePractice
                  filterState={filterState}
                  dateFilterState={dateFilterState}
                  disabled={loader}
                  handleUser={handleUser}
                  handleDateFilterRange={handleDateFilterRange}
                  handleFilterSubmit={handleFilterSubmit}
                  handleFilterClear={handleFilterClear}
                  handlePracticeAreas={handlePracticeAreas}
                  handleStatus={handleStatus}
                />
              </span>
            </Grid>
          )}
          <Grid
            container
            justifyContent={"space-around"}
            alignItems={"center"}
            spacing={4}
          >
            {pmType == "clio" && (
              <Grid item>
                <StyledToggleButtonGroup
                  onChange={(event, value) =>
                    value !== null && setTopFilter(value)
                  }
                  exclusive
                  value={topFilter}
                  size="small"
                >
                  <StyledToggleButton key={1} value={"hourly"}>
                    Hourly
                  </StyledToggleButton>
                  <StyledToggleButton key={2} value={"contingency"}>
                    Contingency
                  </StyledToggleButton>
                  <StyledToggleButton key={3} value={"flat"}>
                    Flat
                  </StyledToggleButton>
                </StyledToggleButtonGroup>
              </Grid>
            )}
            {pmType == "pp" && (
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={rate}
                      onChange={() => setRate(!rate)}
                      name="checkedA"
                    />
                  }
                  label="Zero Rate"
                />
              </Grid>
            )}
            <Grid item>
              <StyledToggleButtonGroup
                onChange={(event, value) => setCurrent(value)}
                exclusive
                value={current}
                size="small"
              >
                <StyledToggleButton key={1} value={"0"}>
                  Hour
                </StyledToggleButton>
                <StyledToggleButton key={2} value={"1"}>
                  Value
                </StyledToggleButton>
              </StyledToggleButtonGroup>
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={excludeNonBillable}
                    onChange={() => setExcludeNonBillable(!excludeNonBillable)}
                    name="checkedA"
                  />
                }
                label="Exclude Non Billable"
              />
            </Grid>
          </Grid>

          <Grid container justifyContent="space-between">
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              {loader ? (
                <div className={classes.radialLoader}>
                  <ChartLoader />
                </div>
              ) : (
                <React.Fragment>
                  <Chart
                    data={
                      isNaN(charts.totalWorkedPercentage[parseInt(current)])
                        ? 0
                        : charts.totalWorkedPercentage[parseInt(current)]
                    }
                    height={250}
                    type="angulargauge"
                    color={Colors.red}
                    config={{
                      captionOnTop: false,
                      caption: `Worked ${
                        current == "0" ? "Time" : "Amount"
                      }: ${numeral(charts.totalWorked[current]).format(
                        "0.00a"
                      )}`,
                    }}
                  />
                </React.Fragment>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              {loader ? (
                <div className={classes.radialLoader}>
                  <ChartLoader />
                </div>
              ) : (
                <React.Fragment>
                  <Chart
                    data={
                      isNaN(charts.totalBilledPercentage[parseInt(current)])
                        ? 0
                        : charts.totalBilledPercentage[parseInt(current)]
                    }
                    height={250}
                    type="angulargauge"
                    color={Colors.blue}
                    config={{
                      captionOnTop: false,
                      caption: `Billed ${
                        current == "0" ? "Time" : "Amount"
                      }: ${numeral(charts.totalBilled[current]).format(
                        "0.00a"
                      )}`,
                    }}
                  />
                </React.Fragment>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              {loader ? (
                <div className={classes.radialLoader}>
                  <ChartLoader />
                </div>
              ) : (
                <React.Fragment>
                  <Chart
                    data={
                      isNaN(charts.totalCollectedPercentage[parseInt(current)])
                        ? 0
                        : charts.totalCollectedPercentage[parseInt(current)]
                    }
                    type="angulargauge"
                    height={250}
                    color={Colors.green}
                    config={{
                      captionOnTop: false,
                      caption: `Collected ${
                        current == "0" ? "Time" : "Amount"
                      }: ${numeral(charts.totalCollected[current]).format(
                        "0.00a"
                      )}`,
                    }}
                    // label={["Collected Time"]}
                  />
                </React.Fragment>
              )}
            </Grid>
          </Grid>
        </Paper>

        {error ? <ErrorSnackBar errorMsg={errorMsg} /> : null}
      </Grid>
    );
  };

export default WorkedBilledCollectedRadial;
