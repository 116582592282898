import React, { useContext, useState } from "react";
import Dropdown from "../commons/dropdown";
import DatePicker from "../commons/datePicker";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { FilterContext } from "../../tabs";
import { IconButton, Button } from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import {
  billingMethodArrayCLIO,
  billingMethodArrayPP,
  movingAvgArray,
  statusArray,
} from "../commons/static";
import DateTextField from "../commons/dateRangePicker/dateTextField";
import { DateRange } from "materialui-daterange-picker";
// import { DateStateType } from "../genericFilter/dateFilterStateHook";
// import { FilterStateType } from "./types";
import clsx from "clsx";
import { Drawer } from "@material-ui/core";
import { yearlyRanges } from "../commons/dateRangePicker/static";
import useAuth from "../../../../hooks/useAuth";

// *****
// Filter object
// *****

// *****
// Pass the value Props according to what filters are required and the component will only return thos
// eg if practiceArea is a required filter, pass practiceArea prop with the value, or Time isn't required, leave it and so on.
// *****

export interface FilterUserTimePracticeProps {
  practiceArea?: any;
  user?: any;
  originatingAtt?: any;
  responsibleAtt?: any;
  movingAvg?: any;
  date?: Date | null;
  billingMethod?: any;
  handleBillingMethod?: any;
  handleStatus?: any;
  dateAfter?: Date | null;
  handleFilterSubmit?: any;
  handlePracticeAreas?: any;
  handleUser?: any;
  handleDateBefore?: any;
  disabled?: boolean;
  handleDateAfter?: any;
  handleFilterClear?: any;
  handleOriginatingAtt?: any;
  handleResponsibleAtt?: any;
  yearFilterState?: any;
  handleMovingAvg?: any;
  handleSingleDate?: (date: Date | string) => void;
  filterState?: any;
  dateFilterState?: any;
  handleYearRange?: any;
  handleDateFilterRange?: (dateRange: DateRange) => void | any;
  handleFromComponent?: boolean;
  setCloseFromComponent?: any;
  handleMatterType?: any
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(4),
  },
  element: {
    listStyle: "none",
    marginTop: theme.spacing(1),
  },
  datePicker: {
    listStyle: "none",
    marginRight: theme.spacing(1),
  },
  button: {
    backgroundColor: theme.palette.primary.light,
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    marginTop: theme.spacing(1),

    paddingBottom: theme.spacing(1),
    fontSize: "0.9em",
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
    "&:disabled": {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.text.disabled,
    },
    "&:startIcon": {
      color: "#fff",
    },
  },
  clear: {
    backgroundColor: theme.palette.secondary.main,
    marginTop: theme.spacing(0),

    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
    },
    "&:disabled": {
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.text.disabled,
    },
  },
  paper: {
    overflowY: "unset",
  },
  modal: {},
  header: {
    fontFamily: "Open Sans",
    fontSize: "1.2em",
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(2),
  },
}));

// ******
// Return the memoized component where prevProps=nextProps
// ******

const FilterUserTimePractice: React.FC<FilterUserTimePracticeProps> = ({
  filterState,
  disabled,
  movingAvg,
  dateFilterState,
  handleBillingMethod,
  handleFilterSubmit,
  handlePracticeAreas,
  handleUser,
  handleOriginatingAtt,
  handleResponsibleAtt,
  handleMovingAvg,
  handleDateBefore,
  handleDateAfter,
  handleSingleDate,
  handleFilterClear,
  handleDateFilterRange,
  handleYearRange,
  handleStatus,
  handleFromComponent,
  setCloseFromComponent,
  handleMatterType
}) => {
  const { practiceAreas, users, attorneys } = useContext(FilterContext);
  const classes = useStyles();
  const clearButton = clsx(classes.button, classes.clear);
  const [open, setOpen] = useState<boolean>(false);
  const {
    integrationConfigState: { pmType },
  } = useAuth();

  const matterTypeList = [
    {id: "hourly", name:"Hourly"},
    {id: "flat", name: "Flat Fee"},
    {id: "contingency", name: "Contingency"}
  ]

  return (
    <React.Fragment>
      {typeof handleFromComponent === "undefined" && (
        <IconButton onClick={() => setOpen(true)}>
          <FilterListIcon />
        </IconButton>
      )}

      <Drawer
        classes={{ paper: classes.paper, modal: classes.modal }}
        anchor={"right"}
        open={open || handleFromComponent}
        onClose={() => {
          if (typeof handleFromComponent === "undefined") {
            setOpen(false);
          } else {
            setCloseFromComponent();
          }
        }}
      >
        <div className={classes.root}>
          <span className={classes.header}>Filters</span>
          {handleDateFilterRange && (
            <li className={classes.element}>
              <DateTextField
                dateFilterState={dateFilterState}
                handleDateFilterRange={handleDateFilterRange}
              />
            </li>
          )}

          {typeof filterState.year !== "undefined" ? (
            <li className={classes.element}>
              <Dropdown
                listItems={yearlyRanges}
                name={"Years"}
                handleChange={handleYearRange}
                value={filterState.year}
                yearly
              />
            </li>
          ) : null}

          {typeof filterState.date !== "undefined" ? (
            <li className={classes.datePicker}>
              <DatePicker
                handleDateChange={handleSingleDate}
                selectedDate={filterState.date}
                label={"date"}
                dateId={"date"}
                maxDate={new Date()}
              />
            </li>
          ) : null}

          {typeof filterState.practiceArea !== "undefined"? (
            <li className={classes.element}>
              <Dropdown
                listItems={practiceAreas}
                name={"Practice Area"}
                handleChange={handlePracticeAreas}
                value={filterState.practiceArea}
              />
            </li>
          ) : null}

          {typeof filterState.billingMethod !== "undefined" ? (
            <li className={classes.element}>
              <Dropdown
                listItems={
                  pmType == "clio"
                    ? billingMethodArrayCLIO
                    : billingMethodArrayPP
                }
                name={"Billing Method"}
                handleChange={handleBillingMethod}
                value={filterState.billingMethod}
              />
            </li>
          ) : null}

          {typeof filterState.status !== "undefined" ? (
            <li className={classes.element}>
              <Dropdown
                listItems={statusArray}
                name={"Status"}
                handleChange={handleStatus}
                value={filterState.status}
              />
            </li>
          ) : null}

          {typeof filterState.user !== "undefined" ? (
            <li className={classes.element}>
              <Dropdown
                listItems={users}
                name={"Users"}
                handleChange={handleUser}
                value={filterState.user}
              />
            </li>
          ) : null}

          {typeof filterState.originatingAtt !== "undefined" && pmType == "clio" ? (
            <li className={classes.element}>
              <Dropdown
                listItems={attorneys}
                name={"Originating Att."}
                handleChange={handleOriginatingAtt}
                value={filterState.originatingAtt}
              />
            </li>
          ) : null}

          {typeof filterState.responsibleAtt !== "undefined" && pmType == "clio" ? (
            <li className={classes.element}>
              <Dropdown
                listItems={attorneys}
                name={"Responsible Att."}
                handleChange={handleResponsibleAtt}
                value={filterState.responsibleAtt}
              />
            </li>
          ) : null}

          {typeof movingAvg !== "undefined" ? (
            <li className={classes.element}>
              <Dropdown
                listItems={movingAvgArray}
                name={"Moving Avg."}
                handleChange={handleMovingAvg}
                value={movingAvg}
                movingAvg
              />
            </li>
          ) : null}

          {/* {typeof dateFilterState.dateBefore !== "undefined" ? (
        <li className={classes.datePicker}>
          <DatePicker
            handleDateChange={handleDateBefore}
            selectedDate={dateFilterState.dateBefore}
            label={"date to"}
            dateId={"date-before"}
            minDate={dateFilterState.dateAfter}
          />
        </li>
      ) : null} */}

          <li className={classes.element}>
            <Button
              type="submit"
              size="small"
              className={classes.button}
              startIcon={<FilterListIcon />}
              onClick={(e) => {
                if (typeof handleFromComponent === "undefined") {
                  setOpen(false);
                } else {
                  setCloseFromComponent();
                }
                handleFilterSubmit(e);
              }}
              fullWidth
              disabled={disabled}
            >
              Filter
            </Button>
          </li>

          <li className={classes.element}>
            <Button
              type="submit"
              size="small"
              fullWidth
              className={clearButton}
              startIcon={<ClearAllIcon />}
              onClick={(e) => {
                if (typeof handleFromComponent === "undefined") {
                  setOpen(false);
                } else {
                  setCloseFromComponent();
                }
                handleFilterClear(e);
              }}
              disabled={disabled}
            >
              Clear
            </Button>
          </li>
        </div>
      </Drawer>
    </React.Fragment>
  );
};

export default FilterUserTimePractice;
