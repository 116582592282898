import React, { useState, useEffect } from "react";
import SubscriptionInfo from "../subscriptionInfo";
import AdminUpdates from "./adminUpdates";
import Grid from "@material-ui/core/Grid";
import {
  StyledToggleButtonGroup,
  StyledToggleButton,
} from "../../../dashboard/tabs/styledTabs";
import useAuth from "../../../../hooks/useAuth";
export interface AdminPaymentsProps {}

const AdminPayments: React.FC<AdminPaymentsProps> = () => {
  const {
    generalStates: { paymentInfo },
    currentAppState: {
      status: { code, xeroStatus, ppStatus, clioStatus },
    },
  } = useAuth();
  const [current, setCurrent] = useState<string>("");

  useEffect(() => {
    if (!paymentInfo?.subscribedClio && paymentInfo?.subscribedXero) {
      setCurrent("xero");
    } else if (paymentInfo?.subscribedClio && !paymentInfo?.subscribedXero) {
      setCurrent("clio");
    } else if (paymentInfo?.subscribedPP && !paymentInfo?.subscribedXero) {
      setCurrent("pp");
    } else {
      setCurrent("clio");
    }
  }, [paymentInfo]);
  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <StyledToggleButtonGroup
          onChange={(event, value) => value !== null && setCurrent(value)}
          exclusive
          value={current}
          size="small"
        >
          <StyledToggleButton
            disabled={clioStatus == 0 || !paymentInfo?.subscribedClio}
            key={"clio"}
            value={"clio"}
          >
            Clio
          </StyledToggleButton>
          <StyledToggleButton
            disabled={xeroStatus == 0 || !paymentInfo?.subscribedXero}
            key={"xero"}
            value={"xero"}
          >
            Xero
          </StyledToggleButton>
          <StyledToggleButton
            disabled={ppStatus == 0 || !paymentInfo?.subscribedPP}
            key={"pp"}
            value={"pp"}
          >
            Practice Panther
          </StyledToggleButton>
        </StyledToggleButtonGroup>

        {code != "000" && (
          <Grid item xs={12}>
            <AdminUpdates
              type={current}
              subscriptionObject={
                current === "xero"
                  ? paymentInfo?.subscribedXero
                  : current === "clio"
                  ? paymentInfo?.subscribedClio
                  : paymentInfo?.subscribedPP
              }
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <SubscriptionInfo current={current} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default AdminPayments;
