import React from "react";
import Grid from "@material-ui/core/Grid";
import { InputLabel } from "@material-ui/core";
import { StyledInput } from "../settings/payments/stripe/styledInput";

export default function PaymentForm({
  companyName,
  address,
  phoneNumber,
  handleAddress,
  handleCompanyName,
  handlePhoneNumber,
}) {
  let rgx = /(?=^.{3,30}$)^([A-Za-z][\s]?)+$/;
  let companyError = companyName.trim() ? false : true;
  let addressError = address.trim() ? false : true;
  let phoneError = phoneNumber.trim() ? false : true;

  return (
    <React.Fragment>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <InputLabel required>Company Name</InputLabel>
          <StyledInput
            value={companyName}
            onChange={(event) => handleCompanyName(event.target.value)}
            error={companyError}
            required
            fullWidth
            id="company"
            name="company"
            autoFocus
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel required>Address</InputLabel>
          <StyledInput
            value={address}
            error={addressError}
            onChange={(event) => handleAddress(event.target.value)}
            required
            fullWidth
            id="address"
            name="address"
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel required>Phone Number</InputLabel>
          <StyledInput
            value={phoneNumber}
            error={phoneError}
            onChange={(event) => handlePhoneNumber(event.target.value)}
            required
            fullWidth
            id="phonenumber"
            name="phone number"
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
