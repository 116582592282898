import _ from "lodash";

// ******
// Accepts worked-time response and total available time to output percentage of utilization per month
// ******

export interface Response {
  total_hours: number;
  month: string;
}

function calcUtilizationPercentage(
  workedTimeData: Response[],
  availableTime: number
) {
  return _.map(
    _.flatMap(workedTimeData, (item, i) => item.total_hours),
    (hours) =>
      Math.round(
        ((Math.ceil(hours) / availableTime) * 100 + Number.EPSILON) * 100
      ) / 100
  );
}

// ********
// Return flat array of elements from a collection based on total_hours
// ********

function returnHours(list: Response[]) {
  return _.flatMap(list, (item, i) => item.total_hours);
}
function returnValueHours(list: Response[]) {
  return {
    value: _.flatMap(list, (item, i) => item?.total_value),
    hours: _.flatMap(list, (item, i) => item?.total_hours),
  };
}

// ********
// Return flat array of elements from a collection based on total_hours
// ********

function returnMonths(list: Response[]) {
  return _.flatMap(list, (item, i) => item.month);
}

// *******
// Iterates over two arrays calculating percentage values
// of numeratorArray elements against denominatorArray elements
// *******

function calcIterativePercentage(numArray: number[], denomArray: number[]) {
  return _.map(numArray, (item, i) =>
    item === 0 || denomArray[i] === 0
      ? 0
      : Math.round((item / denomArray[i]) * 100 * 100 + Number.EPSILON) / 100
  );
}

function calcIterativePercentageHeat(numArray: any[], denomArray: any[]) {
  return _.map(numArray, (item, i) =>
    item.total_hours == 0 || denomArray[i].total_hours == 0
      ? { total_hours: 0, name: item.name }
      : {
          total_hours:
            Math.round(
              (item.total_hours / denomArray[i].total_hours) * 100 * 100 +
                Number.EPSILON
            ) / 100,
          name: item.name,
        }
  );
}

// *******
// Compare months and remove month inconsistencies between lists
// *******

function compareMonths(comparisonList: Response[], benchmarkList: Response[]) {
  // Add new missing elements
  benchmarkList.map((item, i) => {
    if (!_.find(comparisonList, (item2) => item2.month === item.month)) {
      comparisonList.splice(i, 0, { total_hours: 0, month: item.month });
    }
  });
  let sortedArray = _.sortBy(comparisonList, (item) => item.month);
  //Remove extra elements from comparison list
  let compareList = _.intersectionWith(
    sortedArray,
    benchmarkList,
    (arrVal, otherVal) =>
      arrVal.month === otherVal.month &&
      arrVal.month !== null &&
      otherVal.month !== null
  );
  return compareList;
}

// *******
// Aggregate hours
// *******

function aggregateValues(array: any[]) {
  let sum = 0;
  array.map((item) => (sum = sum + (isNaN(item) ? 0 : item)));
  return sum;
}

// *******
// calc rounded percentage
// *******

function calcRoundedPercentage(numerator: number, denominator: number) {
  if (numerator !== 0 && denominator !== 0) {
    return (
      Math.round(
        ((Math.ceil(numerator) / denominator) * 100 + Number.EPSILON) * 100
      ) / 100
    );
  } else {
    return 0;
  }
}

// *******
// Return the largest array from a nested array
// *******

function identifyLongestArray(nestedArray) {
  let longestArray = nestedArray.reduce((largest, currentValue) => {
    if (currentValue.length >= largest.length) {
      return (largest = currentValue);
    } else return (largest = largest);
  }, nestedArray[0]);
  return longestArray;
}

export {
  calcRoundedPercentage,
  aggregateValues,
  compareMonths,
  calcIterativePercentage,
  returnMonths,
  returnHours,
  returnValueHours,
  calcUtilizationPercentage,
  identifyLongestArray,
  calcIterativePercentageHeat,
};
