import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import ArrowDropUpTwoToneIcon from "@material-ui/icons/ArrowDropUpTwoTone";
import ArrowDropDownTwoToneIcon from "@material-ui/icons/ArrowDropDownTwoTone";
import { IconButton, Tooltip } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    textTitle: {
      color: theme.palette.text.primary,
      fontSize: "1.2em",
      fontWeight: 600,
    },
    wrapper: {
      display: "flex",
      alignItems: "center",
    },
  })
);

interface TopBottomSelectProps {
  current: number;
  handleSelect: any;
  title: string;
}

const TopBottomSelect: React.FC<TopBottomSelectProps> = ({
  current,
  handleSelect,
  title,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <span className={classes.textTitle}>{title}</span>
      <Tooltip title={current === 0 ? "Bottom" : "Top"}>
        <IconButton onClick={() => handleSelect(current)}>
          {current === 0 ? (
            <ArrowDropDownTwoToneIcon />
          ) : (
            <ArrowDropUpTwoToneIcon />
          )}
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default TopBottomSelect;
