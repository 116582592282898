import "date-fns";
import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

interface DatePickerProps {
  handleDateChange?: any;
  selectedDate?: Date | null;
  label?: string;
  minDate?: Date | null;
  maxDate?: Date | null;
  dateId?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  popover: {
    backgroundColor: "transparent",
  },
  input: {
    minWidth: 200,
    backgroundColor: theme.palette.text.secondary,
    color: theme.palette.text.secondary,
    fontWeight: 500,
    fontSize: "0.8em",
    borderStyle: "solid",
    borderWidth: 0.5,
    borderRadius: theme.spacing(1) / 2,
    borderColor: "#EDEFF7",
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(1) / 2 + 2,
    paddingBottom: theme.spacing(1) / 2 + 2,
  },
  icon: {
    color: theme.palette.text.secondary,
    "&:hover": {
      background: "0px 5px 5px -3px rgba(0,0,0,0.1)",
    },
  },
}));

const DatePicker: React.FC<DatePickerProps> = ({
  selectedDate,
  handleDateChange,
  label,
  dateId,
  minDate,
  maxDate,
}) => {
  const classes = useStyles();

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        disableToolbar
        variant="inline"
        autoOk
        emptyLabel={label ? label : "date"}
        format="yyyy/MM/dd"
        margin="normal"
        id={dateId}
        maxDate={maxDate ? maxDate : new Date()}
        minDate={minDate ? minDate : new Date("January 01, 2000 00:00:00")}
        value={selectedDate}
        onChange={(date) => handleDateChange(date)}
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
        PopoverProps={{
          classes: {
            root: classes.popover,
          },
        }}
        InputProps={{
          classes: {
            root: classes.input,
          },
          disableUnderline: true,
        }}
        InputAdornmentProps={{}}
      />
    </MuiPickersUtilsProvider>
  );
};
export default DatePicker;
