import { useContext, useReducer, useEffect, useState } from "react";
import { AsyncState } from "./types";
import reducer from "./reducer";
import api from "../../../../services";
import useAuth from "../../../../hooks/useAuth";
const initialState: AsyncState = {
  request: { status: false },
  response: { status: false, data: [] },
  error: { status: false, message: "" },
};

export const useMarketingExpenseApi = () => {
  const [marketingState, dispatch] = useReducer(reducer, initialState);
  const [invoke, setInvoke] = useState<boolean>(false);
  const [queryParams, setQueryParams] = useState({
    startDate: null,
    endDate: null,
  });
  const {
    integrationConfigState: { integrationConfig },
    generalStates: {
      token,
      activeFirm: {
        firm: { _id },
      },
    },
  } = useAuth();

  const handleInvoke = () => {
    if (integrationConfig === "CLIO_XERO" || integrationConfig === "XERO") {
      setInvoke(true);
    }
  };

  const handleFilters = dateRange => {
    setQueryParams({
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
    });
  };

  useEffect(() => {
    let subscribe = true;
    if (invoke) {
      dispatch({ type: "SET_REQUEST" });
      let request = api.create(token);
      request
        .getMarketingExpense(_id, queryParams)
        .then(res => {
          if (subscribe) {
            subscribe && dispatch({ type: "SET_RESPONSE", payload: res.data });
            setInvoke(false);
          }
        })
        .catch(error => {
          if (subscribe) {
            if (error.code === "ECONNABORTED") {
              dispatch({
                type: "SET_ERROR",
                payload: "The API request timed out. Please Refresh the page",
              });
              setInvoke(false);
            } else {
              dispatch({
                type: "SET_ERROR",
                payload: error.response.data.message,
              });
              setInvoke(false);
            }
          }
        });
    }
    return () => {
      subscribe = false;
    };
  }, [invoke]);

  return [marketingState, handleInvoke, handleFilters];
};
