import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";

interface RowDataType {
  clientAgent: string | null;
  errorTrace: string | null;
  errorCause: string | null;
}

export interface NestedRowProps {
  data: RowDataType;
}

const NestedRow: React.FC<NestedRowProps> = ({
  data: { clientAgent, errorTrace, errorCause },
}) => {
  return (
    <>
      <Table size="small" aria-label="purchases">
        <TableHead>
          <TableRow>
            <TableCell>Client Agent</TableCell>
            <TableCell>Error Trace</TableCell>
            <TableCell>Error Cause</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              {clientAgent}
            </TableCell>
            <TableCell>{errorTrace === null ? "N/A" : errorTrace}</TableCell>
            <TableCell>{errorCause === null ? "N/A" : errorCause}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};

export default NestedRow;
