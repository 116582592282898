import React, { useContext, useState, useEffect } from "react";
import {
  TextField,
  Grid,
  Button,
  Paper,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import api from "../../services";
import ErrorSnackBar from "../dashboard/commons/SnackBar";
import useAuth from "../../hooks/useAuth";
const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  titleText: {
    display: "block",
    fontSize: "1.3rem",
    fontWeight: 600,
    fontColor: theme.palette.text.primary,
    [theme.breakpoints.down("md")]: {
      fontSize: "1.2rem",
    },
  },
  button: {
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
}));
export default function BasicInformation() {
  const {
    generalStates: {
      user: { userType, companyInfo: company },
      token,
    },
    integrationConfigState: { pmType },
    generalActions,
  } = useAuth();

  const [companyName, setCompanyName] = useState(company?.companyName || "");
  const [companyPhone, setCompanyPhone] = useState(company?.phone || "");
  const [companyAddress, setCompanyAddress] = useState(company?.address || "");
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [apiError, setApiError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (
      companyPhone === company?.phone &&
      companyName === company?.companyName &&
      companyAddress === company?.address
    ) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [companyName, companyAddress, companyPhone]);
  const handleSubmit = () => {
    let request = api.create(token);
    setLoading(true);
    request
      .updateUserProfile({
        companyInfo: {
          companyName: companyName,
          address: companyAddress,
          phone: companyPhone,
        },
      })
      .then((res) => {
        request.getUserProfile().then((response) => {
          setIsDisabled(false);
          setLoading(false);
          generalActions.setUser(response.data);
        });
      })
      .catch((error) => {
        if (error.code === "ECONNABORTED") {
          setIsDisabled(false);
          setLoading(false);
          setErrorMsg("The API request timed out. Please Refresh the page");
          setApiError(true);
        } else {
          setIsDisabled(false);
          setLoading(false);
          setErrorMsg(error.response.data.message);
          setApiError(true);
        }
      });
  };
  const classes = useStyles();
  return (
    <React.Fragment>
      <Paper className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <span className={classes.titleText}>Basic Information</span>
          </Grid>
          <Grid item xs={12} lg={12} md={12} xl={12} sm={12}>
            <TextField
              variant="outlined"
              value={companyName}
              label="Name"
              onChange={(e) => setCompanyName(e.target.value)}
              required
              fullWidth
              disabled={userType === "USER" ? true : false}
            />
          </Grid>
          <Grid item xs={12} lg={6} md={6} xl={6} sm={12}>
            <TextField
              variant="outlined"
              label="Integration"
              value={
                pmType == "clio"
                  ? "Clio"
                  : pmType == "pp"
                  ? "Practice Panther"
                  : ""
              }
              required
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={12} lg={6} md={6} xl={6} sm={12}>
            <TextField
              variant="outlined"
              onChange={(e) => setCompanyPhone(e.target.value)}
              value={companyPhone}
              label="Contact #"
              required
              fullWidth
              disabled={userType === "USER" ? true : false}
            />
          </Grid>
          <Grid item xs={12} lg={12} md={12} xl={12} sm={12}>
            <TextField
              id="outlined-multiline-static"
              label="Address"
              value={companyAddress}
              onChange={(e) => setCompanyAddress(e.target.value)}
              multiline
              rows="6"
              fullWidth
              variant="outlined"
              disabled={userType === "USER" ? true : false}
            />
          </Grid>

          <Grid
            style={{ padding: 16 }}
            item
            xs={12}
            lg={12}
            md={12}
            xl={12}
            sm={12}
          >
            <Button
              disabled={isDisabled}
              onClick={handleSubmit}
              variant="contained"
              color="primary"
              className={classes.button}
            >
              {loading ? <CircularProgress size={24} /> : "Change"}
            </Button>
          </Grid>
        </Grid>
      </Paper>
      {apiError && <ErrorSnackBar errorMsg={errorMsg} />}
    </React.Fragment>
  );
}
